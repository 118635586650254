import {
  mdiDesktopMacDashboard,
  mdiShape,
  mdiCash100,
  mdiAccountBox,
  mdiSemanticWeb,
} from '@mdi/js'

export default [
  {
    subheader: 'Account Management',
  },
  // {
  //   title: 'Dashboard',
  //   icon: mdiDesktopMacDashboard,
  //   to: 'account-dashboard',
  // },
  {
    title: 'Account',
    icon: mdiAccountBox,
    to: 'account-setup',
  },
  {
    title: 'Category',
    icon: mdiShape,
    to: 'account-category',
  },
  {
    title: 'Cost Code',
    icon: mdiCash100,
    to: 'account-cost-code',
  },
  {
    title: 'Recent Changes',
    icon: mdiSemanticWeb,
    to: 'account-recent-change',
  },
]
