<template>
  <v-dialog
    v-model="isVisible"
    max-width="1250px"
    scrollable
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <v-card class="document-modal">
      <v-card-text class="pa-0">
        <div class="position-relative doc-bg mb-15">
          <v-icon color="white" style="position: absolute; top:10px; right:10px; z-index: 9" @click="closeModal">
            {{ icons.mdiClose }}
          </v-icon>
          <div class="position-absolute content">
            <p class="hero-title content-top mb-0">
              WELCOME TO <span class="warning--text">BLDON</span> ERP SOFTWARE
            </p>
            <p class="hero-title">SOLUTION SERVICE <span class="text-base">®</span></p>
            <!-- <p class="details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed <br />
              do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> -->
          </div>
          <div class="mid-content">
            <div class="middle pa-5">
              <p class="text-lg dark--text font-weight-semibold">How to Start ?</p>
              <p class="dark--text">
                Sign up with BLDON and then log in to the software to start using it. Utilize the
                user guide manuals to better understand the tool and set up your dashboards. Access
                customer support channels via the Support feature in the top right corner of the
                Menu bar if you encounter any issues.
              </p>
            </div>
            <div class="middle pa-5">
              <p class="text-lg dark--text font-weight-semibold">Who We Are ?</p>
              <p class="dark--text">
                BLDON is a construction software company. We work to revolutionize the construction
                sector with our highly advanced tools from Estimating to CRM, Accounting to Service
                Management, Project Management to Field Management and many more.
              </p>
            </div>
          </div>
        </div>
        <!-- <KnowMore /> -->
        <ModuleList />
        <ExpertSection />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from '@vue/composition-api'
import hero from '@/assets/images/svg/Hero_Bg.svg'
import callImg from '@/assets/images/svg/call.svg'
import { mdiClose } from '@mdi/js'
import store from '@/store'
import ModuleList from './components/ModuleList.vue'
import ExpertSection from './components/ExpertSection.vue'
import KnowMore from './components/KnowMore.vue'

export default {
  components: { ModuleList, ExpertSection, KnowMore },
  props: {
    isDocumentModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.isDocumentModal,
      set: () => {
        return false
      },
    })

    const closeModal = () => {
      store.commit('documentation/SET_DOCUMENT_MODAL', false)
    }

    return {
      isVisible,
      closeModal,
      hero,

      callImg,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.doc-bg {
  // background: url('~@/assets/images/svg/Hero_Bg.svg');
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url('~@/assets/images/svg/Hero_Bg.svg');
  height: 600px;
  opacity: 0.95;
}
.document-modal {
  .content-top {
    margin-top: 170px;
  }
  .content {
    top: 0;
    width: 100%;
  }
  .details {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }
  .mid-content {
    position: absolute;
    bottom: -80px;
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    .middle {
      width: 450px;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 1px 1px 0px #00000040;
      border-top: 6px solid black;
    }
  }
}
.hero-title {
  font-size: 52px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}
</style>
