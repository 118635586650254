import { mdiHomeOutline, mdiMonitorDashboard, mdiCogOutline, mdiFileDocumentMultipleOutline, mdiSemanticWeb } from '@mdi/js'

export default {
  title: 'TakeOffs',
  icon: mdiHomeOutline,
  children: [
    {
      icon: mdiMonitorDashboard,
      title: 'TakeOffs',
      to: { name: 'estimation-dashboard' },
    },
    {
      icon: mdiCogOutline,
      title: 'Configuration',
      to: { name: 'estimation-variable' },
    },
    {
      icon: mdiFileDocumentMultipleOutline,
      title: 'Quote Configuration',
      to: { name: 'estimation-quote-template-list' },
    },
    {
      icon: mdiSemanticWeb,
      title: 'Recent Changes',
      to: { name: 'estimation-recent-changes' },
    },
  ],
}
