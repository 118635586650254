import {
  mdiViewDashboard,
  mdiCash,
  mdiSecurity,
  mdiFaceAgent,
  mdiPhone,
  mdiAccountBoxOutline,
  mdiDesktopMac,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'partner-dashboard',
  },
  {
    title: 'Partner Details',
    icon: mdiDesktopMac,
    to: 'partner-details',
  },
  {
    title: 'Customers',
    icon: mdiAccountBoxOutline,
    to: 'partner-customers',
  },
  {
    title: 'Partner Support ',
    icon: mdiFaceAgent,
    to: 'partner-support',
  },
  {
    title: 'Bldon Support ',
    icon: mdiPhone,
    to: 'bldon-support',
  },
  {
    title: 'Payments ',
    icon: mdiCash,
    to: '',
  },
  {
    title: 'Security ',
    icon: mdiSecurity,
    to: 'partner-security',
  },
]
