import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'

const { FETCH_USERS } = storeActions
const { GET_ALL_USER } = storeGetters
const { SET_USERS, RESET_USERS } = storeMutations

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    [GET_ALL_USER]: state => {
      return state.users
    },
  },
  mutations: {
    [SET_USERS]: (state, res) => {
      state.users = res
    },
    [RESET_USERS]: state => {
      state.users = []
    },
  },
  actions: {
    [FETCH_USERS]: ({ commit }, payload) => {
      api
        .findByParams(`${payload.component}`, { params: payload.filter })
        .then(({ data }) => {
          if (data.success && data.data) {
            const userData = data.data.map(opt => {
              return {
                id: opt.uuid,
                value: `${opt.firstname} ${opt.lastname}`,
              }
            })
            commit(SET_USERS, userData)
          } else {
            commit(RESET_USERS)
          }
        })
        .catch(() => {
          commit(RESET_USERS)
        })
    },
  },
}
