import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import tabPersist from './module/tabPersist'
// eslint-disable-next-line import/no-cycle
import common from './module/common'
import auth from './module/auth'
import tabs from './module/tabs'
import setupTab from './module/setup-tab'
import tabSystem from './module/tab-system'
import estimateToolTab from './module/tab-system/estimate-tool-tab'
import notify from './module/notify'
import estimation from './module/estimation'
import takeOff from './module/estimation/takeoff'
import pages from './module/estimation/pages'
import projectManagement from './module/project-management'
import taskManagement from './module/task-management'
import tableView from './module/task-management/tableView'
import kanbanView from './module/task-management/kanbanView'
import konvaEstimator from './module/konva-estimator'
import documentation from './module/documentation'
import workDrive from './module/work-drive'
import users from './module/users'
import notification from './module/notification'
import material from './module/setup/material'
import labor from './module/setup/labor'
import inventoryManagement from './module/inventory-management'
import authPartner from './module/auth-partner'
import pos from './module/pos'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    tabPersist,
    common,
    tabs,
    setupTab,
    auth,
    users,
    notify,
    estimation,
    projectManagement,
    tabSystem,
    estimateToolTab,
    konvaEstimator,
    takeOff,
    pages,
    workDrive,
    notification,
    material,
    kanbanView,
    taskManagement,
    tableView,
    labor,
    documentation,
    inventoryManagement,
    authPartner,
    pos,
  },
})
