const security = [
  {
    path: '/estimation/dashboard',
    name: 'estimation-dashboard',
    component: () => import('@/views/estimation/Estimation.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/variable',
    name: 'estimation-variable',
    component: () => import('@/views/estimation/EstimationConfiguration.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/material-formula',
    name: 'estimation-material-formula',
    component: () => import('@/views/estimation/EstimationMaterialFormula.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/stamp',
    name: 'estimation-stamp',
    component: () => import('@/views/estimation/stamp/StampList.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/stamp/add',
    name: 'estimation-stamp-create',
    component: () => import('@/views/estimation/stamp/StampCreate.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/calculator/template',
    name: 'estimation-calculator-template',
    component: () => import('@/views/estimation/EstimationTemplate.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/calculator/group',
    name: 'estimation-calculator-group',
    component: () => import('@/views/estimation/EstimationGroup.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/configuration/calculator/:template_id',
    name: 'estimation-calculator',
    component: () => import('@/views/estimation/EstimationCalculator.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/view/:id?',
    name: 'estimation-view',
    component: () => import('@/views/estimation/EstimationView.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/pdf/:pdfId',
    name: 'estimation-pdf',
    component: () => import('@/views/estimation/ui/EstimationPdf.vue'),
    meta: {
      layout: 'horizontal',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid',
    name: 'estimator-tool-layout',
    component: () => import('@/views/estimation/konva/EstimatorTabLayout.vue'),
    meta: {
      layout: 'horizontal',
      app: 'estimate',
      appId: 7,
    },
    children: [
      {
        path: 'tool',
        name: 'estimator-overview',
        component: () => import('@/views/estimation/ui/EstimationPageOverview.vue'),
        meta: {
          layout: 'horizontal',
          app: 'estimate',
          appId: 7,
        },
      },
      {
        path: 'tool/:pdfId/:pageId',
        name: 'estimator-pdf',
        component: () => import('@/views/estimation/konva/KonvaDrawing.vue'),
        meta: {
          layout: 'horizontal',
          app: 'estimate',
          appId: 7,
        },
      },
    ],
  },
  {
    path: '/estimation/old/:uuid',
    name: 'estimation-tool-layout',
    component: () => import('@/views/estimation/ui/EstimationTabLayout.vue'),
    meta: {
      layout: 'horizontal',
      app: 'estimate',
      appId: 7,
    },
    children: [
      {
        path: 'tool',
        name: 'estimation-tool-overview',
        component: () => import('@/views/estimation/ui/EstimationPageOverview.vue'),
        meta: {
          layout: 'horizontal',
          app: 'estimate',
          appId: 7,
        },
      },
      {
        path: 'tool/:pdfId/:pageId',
        name: 'estimation-tool-pdf',
        component: () => import('@/views/estimation/ui/EstimationTool.vue'),
        meta: {
          layout: 'horizontal',
          app: 'estimate',
          appId: 7,
        },
      },
    ],
  },
  {
    path: '/estimation/:uuid/estimate',
    name: 'estimation-pdf-calculator',
    component: () => import('@/views/estimation/EstimateCalculator.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/estimate/alternate/:calculator_id?',
    name: 'estimation-alternate',
    component: () => import('@/views/estimation/EstimateAlternate.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/quotes',
    name: 'estimation-quote-list',
    component: () => import('@/views/estimation/quote/QuoteList.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/quote-template',
    name: 'estimation-quote-template-list',
    component: () => import('@/views/estimation/quote/template/QuoteTemplateList.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/recent-changes',
    name: 'estimation-recent-changes',
    component: () => import('@/views/estimation/EstimationRecentChanges.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/quote/inclusion-exclusion',
    name: 'estimation-inclusion-exclusion',
    component: () => import('@/views/estimation/quote/inclusion-exclusion/EstimationInclusionExclusion.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/quote-template/:quoteTemplateId',
    name: 'estimation-quote-template',
    component: () => import('@/views/estimation/quote/template/QuoteTemplateView.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/quotes/:quoteId',
    name: 'estimation-quote',
    component: () => import('@/views/estimation/quote/QuoteView.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
  {
    path: '/estimation/:uuid/pdf/:pdfId/compare',
    name: 'estimation-compare',
    component: () => import('@/views/estimation/EstimationCompare.vue'),
    meta: {
      layout: 'tab',
      app: 'estimate',
      appId: 7,
    },
  },
]

export default security
