import { storeGetters, storeMutations } from '@/store/storeConstants'

const { GET_NOTIFY_TITLE, GET_UPLOADING_STATUS, GET_FILE_UPLOADING, GET_RESPONSE_STATUS } =
  storeGetters
const { SET_FILES_ARRAY, SET_FILES_STATUS, SET_RESET_NOTIFY, SET_UPLOADING_START, SET_UPLOADING_PERCENTAGE } = storeMutations

export default {
  namespaced: true,
  state: {
    notifyTitle: null,
    filesUploading: [],
    uploadingStatus: false,
    responseStatus: 'success', // pending | success | failed
  },
  getters: {
    [GET_NOTIFY_TITLE]: state => state.notifyTitle,
    [GET_FILE_UPLOADING]: state => state.filesUploading,
    [GET_UPLOADING_STATUS]: state => state.uploadingStatus,
    [GET_RESPONSE_STATUS]: state => state.responseStatus,
  },
  mutations: {
    [SET_FILES_ARRAY]: (state, payload) => {
      state.notifyTitle = 'Uploading...'
      state.uploadingStatus = false
      // state.filesUploading = payload
      state.responseStatus = 'pending'
    },
    [SET_FILES_STATUS]: (state, payload = 'success') => {
      state.notifyTitle = 'Complete'
      state.uploadingStatus = true
      state.responseStatus = payload
    },
    [SET_RESET_NOTIFY]: state => {
      state.notifyTitle = null
      state.uploadingStatus = false
      state.filesUploading = []
      state.responseStatus = 'success'
    },
    [SET_UPLOADING_START]: (state, payload) => {
      state.filesUploading.push(payload)
    },
    [SET_UPLOADING_PERCENTAGE]: (state, payload) => {
      const index = state.filesUploading.findIndex(opt => {
        return opt.id === payload.id
      })

      if (index !== -1) {
        const fileUploadObj = state.filesUploading[index]
        fileUploadObj.percentage = payload.percentage
        fileUploadObj.status = payload.status ? payload.status : 'uploading'
        state.filesUploading.splice(index, fileUploadObj)
      }
    },
  },
  actions: {},
}
