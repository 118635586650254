import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'

const {
  WORKSPACE_TAB_REQUEST,
  SUB_TAB_REQUEST,
  ACTIVE_TAB_REQUEST,
  CANCEL_TAB_REQUEST,
  CANCEL_SUBTAB_REQUEST,
  TAB_POSITION_CHANGE,
} = storeActions
const { GET_WORKSPACE_TAB, GET_SUB_TAB, GET_ACTIVETAB_ID } = storeGetters
const {
  SET_WORKSPACE_STATE,
  SET_SUBTAB_STATE,
  SET_ACTIVETAB_ID,
  SET_WORKSPACE_ARRAY,
  RESET_WORKSPACE_TAB,
} = storeMutations

const getPersistStorage = () => {
  const consoleState = localStorage.getItem('space-tab')
  if (consoleState) {
    return JSON.parse(consoleState)
  }

  return []
}

export default {
  namespaced: true,
  state: {
    workSpaceTab: getPersistStorage(),
    activeTabId: null,
  },
  getters: {
    [GET_WORKSPACE_TAB]: state => state.workSpaceTab,
    [GET_ACTIVETAB_ID]: state => state.activeTabId,
    [GET_SUB_TAB]: state => {
      const tabId = state.activeTabId
      const tabArray = state.workSpaceTab
      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        return tabArray[index].subTab
      }

      return []
    },
  },
  mutations: {
    [SET_WORKSPACE_STATE]: (state, payload) => {
      state.workSpaceTab = payload
    },
    [SET_WORKSPACE_ARRAY]: (state, payload) => {
      state.workSpaceTab = payload
    },
    [SET_SUBTAB_STATE]: (state, payload) => {
      state.subTabs.push(payload)
    },
    [SET_ACTIVETAB_ID]: (state, payload) => {
      state.activeTabId = payload
    },
    [RESET_WORKSPACE_TAB]: state => {
      state.workSpaceTab = []
      localStorage.removeItem('space-tab')
    },
  },
  actions: {
    [WORKSPACE_TAB_REQUEST]: ({ commit, getters }, payload) => {
      const tabArray = [...getters[GET_WORKSPACE_TAB]]
      const position = tabArray
        .map(opt => {
          return opt.key
        })
        .indexOf(payload.key)
      if (position === -1) {
        const tabPosition = (window.innerWidth - 234) / 200 - 1
        // tabArray.push(payload)
        tabArray.splice(tabPosition - 1, 0, payload)
        localStorage.setItem('space-tab', JSON.stringify(tabArray))
        commit(SET_WORKSPACE_STATE, tabArray)
      }
    },
    [SUB_TAB_REQUEST]: ({ commit, getters }, payload) => {
      const tabArray = [...getters[GET_WORKSPACE_TAB]]
      const tabId = getters[GET_ACTIVETAB_ID]

      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        const position = tabArray[index].subTab
          .map(opt => {
            return opt.key
          })
          .indexOf(payload.key)
        if (position === -1) {
          tabArray[index].subTab.push(payload)
          localStorage.setItem('space-tab', JSON.stringify(tabArray))
          commit(SET_WORKSPACE_ARRAY, tabArray)
        }
      }
    },
    [ACTIVE_TAB_REQUEST]: ({ commit }, payload) => {
      localStorage.setItem('active-tab', JSON.stringify(payload))
      commit(SET_ACTIVETAB_ID, payload)
    },
    [CANCEL_TAB_REQUEST]: ({ commit, getters }, payload) => {
      const tabArray = [...getters[GET_WORKSPACE_TAB]]
      const newTabArray = tabArray.filter(opt => {
        return opt.id !== payload.id
      })
      localStorage.setItem('space-tab', JSON.stringify(newTabArray))
      commit(SET_WORKSPACE_ARRAY, newTabArray)
    },
    [CANCEL_SUBTAB_REQUEST]: ({ commit, getters }, payload) => {
      const tabArray = [...getters[GET_WORKSPACE_TAB]]
      const tabId = getters[GET_ACTIVETAB_ID]

      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        const newSubArr = tabArray[index].subTab.filter(opt => {
          return opt.id !== payload.id
        })
        tabArray[index].subTab = newSubArr
      }

      localStorage.setItem('space-tab', JSON.stringify(tabArray))
      commit(SET_WORKSPACE_ARRAY, tabArray)
    },
    [TAB_POSITION_CHANGE]: ({ commit, getters }, payload) => {
      const alltabs = [...getters[GET_WORKSPACE_TAB]]
      const position = alltabs.findIndex(opt => {
        return opt.key === payload.key
      })
      if (position !== -1) {
        const tabPosition = (window.innerWidth - 234) / 200 - 1
        alltabs.splice(position, 1)
        alltabs.splice(tabPosition - 1, 0, payload)
        commit(SET_WORKSPACE_STATE, alltabs)
        localStorage.setItem('space-tab', JSON.stringify(alltabs))
      }
    },
  },
}
