export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    routeLoader: false,
  },
  getters: {
    getRouteLoader: state => state.routeLoader,
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    ROUTER_LOADER(state, value) {
      state.routeLoader = value
    },
  },
  actions: {},
}
