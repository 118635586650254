<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isVisible"
    max-width="600px"
    scrollable
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <v-card id="task-board-modal" class="user-edit-info">
      <v-card-title class="d-flex justify-space-between text-base px-7 pt-7">
        <span
          >Share <b>"{{ folderData.name }}"</b>
        </span>
        <span>
          <v-icon @click="closeModal">{{ icons.mdiClose }}</v-icon>
        </span>
      </v-card-title>

      <v-card-text class="px-7 pb-7">
        <v-form ref="folderForm" class="multi-col-validation mt-4">
          <v-row>
            <v-col cols="9" class="d-flex align-center">
              <v-autocomplete
                v-model="receiverId"
                :items="userList"
                outlined
                item-text="username"
                item-value="uuid"
                hide-details="auto"
                :rules="[required]"
                dense
                autocomplete="null"
                placeholder="Add people by name"
              ></v-autocomplete>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="skyBlue align-self-top"
                    min-height="38"
                    depressed
                    tile
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ getPermissionType }}

                    <v-icon>{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item in permissionList.filter(opt => opt.visible)"
                    :key="item.value"
                    @click="permissionType = item.value"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col cols="2" class="text-right">
              <v-btn
                color="primary"
                tile
                depressed
                class="py-4 mt-1"
                :loading="loading"
                small
                @click.prevent="add"
              >
                Share
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <p class="dark--text font-weight-semibold">Shared With</p>
        <div v-for="item in sharedUserList" :key="item.uuid" class="d-flex justify-space-between">
          <div>
            <p>{{ item.name }}</p>
          </div>
          <div class="d-flex align-center">
            <div class="mb-0 mr-3">
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="align-self-top"
                    min-height="38"
                    depressed
                    text
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ getPermissionText(item.permission) }}

                    <v-icon>{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="permission in permissionList.filter(opt => opt.visible)"
                    :key="permission.value"
                    @click="handleChange(item, permission)"
                  >
                    <v-list-item-title>{{ permission.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-icon @click="deleteUser(item)"> {{ icons.mdiDelete }}</v-icon>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import Toaster from '@core/utils/sweetToast'
import {
  mdiContentCopy,
  mdiClose,
  mdiDelete,
  mdiLockOpenOutline,
  mdiRefresh,
  mdiCalendar,
  mdiChevronDown,
} from '@mdi/js'
import { ERROR_RESPONSE, SUCCESS_RESPONSE, taskAccessOptions as permissionList } from '@/constants/appConstants'

export default {
  components: {
  },
  props: {
    isShareDialogOpen: {
      type: Boolean,
      required: true,
    },
    folderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { GET_ALL_RECORDS, CREATE, DELETE_BY_ID } = storeActions
    const folderForm = ref(null)
    const loading = ref(false)
    const receiverId = ref(null)
    const permissionType = ref(3)
    const publicPermission = ref(null)
    const userList = ref([])
    const sharedUserList = ref([])

    const tableHeaders = [
      {
        text: 'Shared with',
        value: 'name',
        align: 'start',
        sortable: false,
      },
      {
        text: '',
        value: 'permission',
        sortable: false,
      },
      {
        text: '',
        value: 'action',
        align: 'end',
        sortable: false,
      },
    ]

    const isVisible = computed({
      get: () => props.isShareDialogOpen,
      set: () => {
        return false
      },
    })

    const getPermissionType = computed(() => {
      const find = permissionList.find(opt => {
        return opt.value === permissionType.value
      })

      if (find) {
        return find.text
      }

      return 'N/A'
    })

    const getPermissionText = text => {
      const find = permissionList.find(opt => {
        return opt.value === text
      })
      if (find) {
        return find.text
      }

      return 'N/A'
    }

    const closeModal = () => {
      folderForm.value?.reset()

      emit('closeModal')
    }

    const loadSharedUser = () => {
      const payload = {
        component: `task-management/spaces/item/share/${props.folderData.uuid}/users`,
      }
      store.dispatch(`common/${GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          sharedUserList.value = data.data
        }
      })
    }

    const deleteUser = item => {
      Toaster.confirmation().then(resp => {
        if (resp.isConfirmed) {
          const config = {
            component: `task-management/spaces/item/share/${props.folderData.uuid}`,
            object: { user_ids: [item.uuid] },
          }
          store
            .dispatch(`common/${storeActions.DELETE}`, config)
            .then(({ data }) => {
              if (data.success) {
                loadSharedUser()
                Toaster.success(SUCCESS_RESPONSE, 'warning')
              } else {
                Toaster.error(data.message, 'warning')
              }
            })
            .catch(err => {
              if (err?.response.status === 403) {
                Toaster.error(err.response.data.message)
              } else {
                Toaster.error()
              }
            })
        }
      })
    }

    loadSharedUser()

    const loadMore = () => {
      const payload = {
        component: 'security/users',
        filter: {
          limit: -1,
        },
      }
      store.dispatch(`common/${GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          userList.value = data.data
        }
      })
    }

    loadMore()

    const add = () => {
      const isValid = folderForm.value.validate()
      if (!isValid) return
      loading.value = true
      const payLoad = {
        object: {
          user_id: receiverId.value,
          type: permissionType.value,
        },
        component: `task-management/spaces/item/share/${props.folderData.uuid}`,
      }

      store
        .dispatch(`common/${CREATE}`, payLoad)
        .then(() => {
          loading.value = false
          Toaster.success('Item shared successfully', 'success')
          emit('fetchData')
          closeModal()
        })
        .catch(err => {
          if (err?.response.status === 403) {
            Toaster.error(err.response.data.message)
          } else {
            Toaster.error()
          }
          loading.value = false
        })
    }

    const handleChange = (item, permit) => {
      const payLoad = {
        component: `task-management/spaces/item/share/${props.folderData.uuid}/permission`,
        object: {
          permission: permit.value,
        },
        objectId: item.uuid,
      }
      store
        .dispatch(`common/${storeActions.UPDATE_BY_ID}`, payLoad)
        .then(({ data }) => {
          if (data.success) {
            loadSharedUser()
          } else {
            Toaster.error(data.mesage, 'warning')
          }
        })
        .catch(() => {
          Toaster.error('An eror occur, please try again later', 'warning')
        })
    }

    return {
      folderForm,
      loading,
      isVisible,
      getPermissionType,
      mdiChevronDown,
      userList,
      receiverId,
      handleChange,
      permissionList,
      permissionType,
      mdiRefresh,
      publicPermission,
      sharedUserList,
      tableHeaders,
      deleteUser,
      getPermissionText,

      // methods
      add,
      closeModal,
      loadSharedUser,

      // validation rules
      required,
      icons: {
        mdiContentCopy,
        mdiClose,
        mdiLockOpenOutline,
        mdiDelete,
        mdiRefresh,
        mdiCalendar,
      },
    }
  },
}
</script>
<style lang="scss">
.shared-link-container {
  button.link-btn {
    border: thin solid #e2e1e4;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    height: 32px !important;
    min-height: 32px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.custom-tab {
  &.active-email {
    padding-bottom: 7px;
    border-bottom: 3px solid #007eb9;
  }
  &.active-link {
    padding-bottom: 7px;
    border-bottom: 3px solid #007eb9;
  }
}

.align-self-top {
  align-self: flex-start;
}
</style>
