<template>
  <div class="video-sec px-12">
    <div class="d-flex align-center my-15" style="gap: 30px">
      <div style="width: 50%">
        <p
          class="dark--text font-weight-semibold"
          style="font-size: 32px"
          v-html="getPageDetail.title"
        ></p>

        <p class="dark--text">
          {{ getPageDetail.content }}
        </p>
        <v-btn class="warning">
          Learn More
          <v-icon class="ml-2" size="18">{{ mdiArrowDown }}</v-icon>
        </v-btn>
      </div>
      <div>
        <v-img :src="under" height="260px"></v-img>
        <!-- <youtube video-id="BBJa32lCaaY" /> -->
      </div>
    </div>
  </div>
</template>
<script>
// import VueYoutube from 'vue-youtube'
import { mdiClose, mdiArrowDown } from '@mdi/js'
import { computed } from '@vue/composition-api'
import store from '@/store'
import under from '@/assets/images/svg/under.svg'

export default {
  props: {
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit('close')
    }

    const pageDetail = {
      crm: {
        title: 'Helps you understand <br /> what your customers need',
        content:
          'he CRM tool includes features to help you list all your leads and opportunities. You develop an understanding of what your customers need and then connect with them to ensure a smooth sales process. ',
        video: 'BBJa32lCaaY',
      },
      workDrive: {
        title: 'Document management was never this easy',
        content:
          'Workdrive is a cloud-based storage platform designed for the construction industry. The tool allows you to store, access, and share files and documents from anywhere with your team. It enhances productivity and efficiency.',
        video: 'BBJa32lCaaY',
      },
      estimation: {
        title: 'Project cost calculation has never been this easy',
        content:
          'Calculate the cost of any construction project using the Takeoff feature, which utilizes construction drawings and blueprints. You can also explore alternatives to commonly used materials using the VE options, thus creating your project bids at the optimum cost.',
        video: 'BBJa32lCaaY',
      },
      taskManagement: {
        title: 'Accompanies you in every step of your construction process ',
        content:
          'Want to know how many tasks are pending in each of the modules? Use the Task Management Tool as it acts as a central repository where you can track your task statuses and get them done in time. ',
        video: 'BBJa32lCaaY',
      },
      vendorManagement: {
        title: 'Equips you to find the best partners for your business',
        content:
          "Can you get the best prices on your purchases when you have a limited number of suppliers? No, you cannot. That/'s why you need a good Vendor Management tool. With it, you can record the data of a list of suppliers along with their contact details. This information can be used when making purchases to ensure the best possible decision",
        video: 'BBJa32lCaaY',
      },
      materialManagement: {
        title: 'Keeps your project materials and resources in check',
        content: 'Can you get the best prices on your purchases when you have a limited number',
        video: 'BBJa32lCaaY',
      },
      projectManagement: {
        title: 'Guarantees on-time successful project completion ',
        content:
          "As a construction company, you're simultaneously handling multiple projects. In such cases, it's important to monitor each project closely to ensure timely and successful completion. This is where a Project Management tool comes in handy.",
        video: 'BBJa32lCaaY',
      },
      reports: {
        title: 'A step towards transparency & efficiency in your construction projects',
        content:
          "You can use project reports as official documentation, showcasing the project's history, timeline, budget, quality assurance measures, and decisions. These reports serve various purposes, including legal use, audits, and future reference.",
        video: 'BBJa32lCaaY',
      },
      security: {
        title: 'Allows you to manage the entire workforce single-handedly.',
        content:
          'You can control access levels by assigning permissions to different users, such as ‘all admin’, or an admin of a specific module etc. You can define permissions for each role and determine what actions each user can perform. You can also add users by giving them a unique id and password and grant them appropriate access based on their roles and responsibilities.',
        video: 'BBJa32lCaaY',
      },
      inventoryManagement: {
        title: 'Keep track of all your construction materials with ease',
        content:
          'Incidunt id voluptates et voluptatibus dolor ullam nostrum id. Odio et occaecati ea ipsum enim sunt odit. Dolores odio eligendi eos. Aut officiis consequatur temporibus magni non sunt enim aut.',
        video: 'BBJa32lCaaY',
      },
      fieldManagement: {
        title: 'Manage The Work On The Field Management',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        video: 'BBJa32lCaaY',
      },
    }

    const app = computed(() => store.getters['documentation/getDetailApp'])

    const getPageDetail = computed(() => {
      return pageDetail[app.value] ? pageDetail[app.value] : pageDetail.crm
    })

    return {
      closeModal,
      mdiClose,
      under,
      getPageDetail,
      mdiArrowDown,
    }
  },
}
</script>
<style scoped lang="scss">
.video-sec {
  .header {
    background: #072146;
  }
}
</style>
