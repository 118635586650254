import { mdiHomeOutline, mdiMonitorDashboard } from '@mdi/js'

export default {
  title: 'Folders',
  icon: mdiHomeOutline,
  to: { name: 'reports' },
  children: [
    {
      icon: mdiMonitorDashboard,
      title: 'Report Folders',
      to: { name: 'reports' },
    },
    {
      icon: mdiMonitorDashboard,
      title: 'Shared Reports',
      to: { name: 'reports-shared' },
    },
  ],
}
