const getPersistStorage = () => {
  const consoleState = localStorage.getItem('persistTab')
  if (consoleState) {
    return JSON.parse(consoleState)
  }

  return {}
}
const getBreadcrumbStorage = () => {
  const consoleState = JSON.parse(localStorage.getItem('path'))
  if (consoleState) {
    return consoleState
  }

  return []
}
const getRecentStorage = () => {
  const consoleState = JSON.parse(localStorage.getItem('recent-path'))
  if (consoleState) {
    return consoleState
  }

  return []
}
const getpublicBreadcrumbStorage = () => {
  const consoleState = JSON.parse(localStorage.getItem('public'))
  if (consoleState) {
    return consoleState
  }

  return []
}

const getEstimateBreadcrump = () => {
  const consoleState = JSON.parse(localStorage.getItem('drive-path'))
  if (consoleState) {
    return consoleState
  }

  return []
}

const getTaskFolderBreadcrump = () => {
  try {
    const consoleState = JSON.parse(localStorage.getItem('task-folder-path'))
    if (consoleState && Array.isArray(consoleState)) {
      return consoleState
    }

    return []
  } catch (error) {
    return []
  }
}

export default {
  namespaced: true,
  state: {
    persistTab: getPersistStorage(),
    hideDropdownMenu: true,
    breadcrumpArray: getBreadcrumbStorage(),
    breadcrumpRecentArray: getRecentStorage(),
    publicBreadcrumpArray: getpublicBreadcrumbStorage(),
    estimateBreadcrump: getEstimateBreadcrump(),
    taskFolderBreadcrump: getTaskFolderBreadcrump(),
  },
  getters: {
    getPersistTab: state => state.persistTab,
    getHideDropdownMenu: state => state.hideDropdownMenu,
    getBreadcrumpArray: state => state.breadcrumpArray,
    getBreadcrumpRecentArray: state => state.breadcrumpRecentArray,
    getPublicBreadcrumpArray: state => state.publicBreadcrumpArray,
    getEstimateBreadcrumpArray: state => state.estimateBreadcrump,
    getTaskFolderBreadcrumpArray: state => state.taskFolderBreadcrump,
  },
  mutations: {
    SET_PERSIST_TAB(state, value) {
      state.persistTab = value
    },
    SET_TOGGLE_DROPDOWN(state) {
      state.hideDropdownMenu = !state.hideDropdownMenu
    },
    RESET_TOGGLE_DROPDOWN(state) {
      state.hideDropdownMenu = true
    },
    SET_BREADCRUMP_ARRAY(state, payload) {
      state.breadcrumpArray = payload || []
    },
    SET_BREADCRUMP_RECENT_ARRAY(state, payload) {
      state.breadcrumpRecentArray = payload || []
    },
    SET_BREADCRUMP_PUBLIC(state, payload) {
      state.publicBreadcrumpArray = payload || []
    },
    SET_ESTIMATE_DRIVE(state, payload) {
      state.estimateBreadcrump = payload || []
    },
    SET_TASKFOLDER_CRUMP(state, payload) {
      state.taskFolderBreadcrump = payload || []
    },
  },
  actions: {
    SET_TAB_STATE({ commit }, payload) {
      localStorage.setItem('persistTab', JSON.stringify(payload))
      commit('SET_PERSIST_TAB', payload)
    },
    SET_BREADCRUMP_STATE({ commit }, payload) {
      localStorage.setItem('path', JSON.stringify(payload))
      commit('SET_BREADCRUMP_ARRAY', payload)
    },
    SET_BREADCRUMP_RECENT_STATE({ commit }, payload) {
      localStorage.setItem('recent-path', JSON.stringify(payload))
      commit('SET_BREADCRUMP_RECENT_ARRAY', payload)
    },
    SET_PUBLIC_BREADCRUMP({ commit }, payload) {
      localStorage.setItem('public', JSON.stringify(payload))
      commit('SET_BREADCRUMP_PUBLIC', payload)
    },
    SET_ESTIMATE_BREADCRUMP({ commit }, payload) {
      localStorage.setItem('drive-path', JSON.stringify(payload))
      commit('SET_ESTIMATE_DRIVE', payload)
    },
    SET_TASKFOLDER_BREADCRUMP({ commit }, payload) {
      localStorage.setItem('task-folder-path', JSON.stringify(payload))
      commit('SET_TASKFOLDER_CRUMP', payload)
    },
  },
}
