<template>
  <div>
    <notifications group="success" :width="420" animation-name="v-fade-left" position="top center">
      <template slot="body" slot-scope="props">
        <div data-cy="success-toaster" class="custom-template">
          <div class="custom-template-icon">
            <i class="icon ion-android-checkmark-circle"></i>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{ props.item.title }}
            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
            <v-icon size="18" class="notify-close">{{ mdiClose }}</v-icon>
          </div>
        </div>
      </template>
    </notifications>
    <notifications group="error" :width="420" animation-name="v-fade-left" position="top center">
      <template slot="body" slot-scope="props">
        <div class="custom-template error">
          <div class="custom-template-icon">
            <i class="icon ion-close-circled" />
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{ props.item.title }}
            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div class="custom-template-close" @click="props.close">
             <v-icon size="18" class="notify-error-close">{{ mdiClose }}</v-icon>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  setup() {
    return {
      mdiClose,
    }
  },
}
</script>

<style lang="scss">
.custom-template {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;

  &,
  & > div {
    box-sizing: border-box;
  }

  color: #fff;
  background: #5db761; // 4caf50
  border: 1px solid #4caf50;
  .custom-template-icon {
    flex: 0 1 auto;
    color: #fff;
    font-size: 32px;
    padding: 0 10px;
  }
  &.error {
    color: #ff5252;
    background: #ffebeb;
    border: 1px solid #ff5252;
    .custom-template-icon {
      color: #ff5252; //  15C371
    }
  }
  .custom-template-close {
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.9;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .custom-template-content {
    padding: 10px;
    flex: 1 0 285px;

    .custom-template-title {
      letter-spacing: 0.8px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .notify-close svg {
    fill: white;
  }
   .notify-error-close svg {
    fill: red;
  }
}
.v-fade-left-enter-active,
.v-fade-top-enter-active,
.v-fade-left-leave-active,
.v-fade-top-leave-active,
.v-fade-left-move,
.v-fade-top-move {
  transition: all 0.5s;
}

.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translatey(-500px) scale(0.2);
}

.v-fade-top-enter,
.v-fade-top-leave-to {
  opacity: 0;
  transform: translatex(800px) scale(0.2);
}
</style>
