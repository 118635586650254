import {
  mdiDesktopMacDashboard,
  mdiAccountGroupOutline,
  mdiAccountTie,
  mdiAccountLockOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Goal Management',
  },
  {
    title: 'Dashboard',
    icon: mdiDesktopMacDashboard,
    to: 'goals-dashboard',
  },
  {
    title: 'Company Goals',
    icon: mdiAccountLockOutline,
    to: 'company-goals',
  },
  {
    title: 'Manager Goals',
    icon: mdiAccountGroupOutline,
    to: 'manager-goals',
  },
  {
    title: 'User Goals',
    icon: mdiAccountTie,
    to: 'user-goals',
  },
]
