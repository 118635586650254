<template>
  <div class="mt-5">
    <template v-if="isLoading"> </template>
    <template v-else>
      <p class="mb-1 text-sm font-weight-medium d-flex align-center">
        <v-icon v-if="!showMoreField" size="21" @click="showMoreField = !showMoreField">
          {{ mdiChevronRight }}
        </v-icon>
        <v-icon v-else size="21" @click="showMoreField = !showMoreField">
          {{ mdiChevronDown }}
        </v-icon>
        <span class="ml-2">More Field</span>
      </p>
      <template v-if="showMoreField">
        <div v-for="item in customHeader" :key="item.uuid" class="d-flex align-center mb-3 px-2">
          <p class="label-sec text-xs3 mb-0 mr-3">{{ item.label }}</p>
          <template v-if="isTextBox(item.type)">
            <v-text-field
              v-model="additionalData[item.code]"
              :type="item.type"
              hide-details="auto"
              :readonly="hasReadOnlyAccess"
              :placeholder="'Enter ' + item.label"
              outlined
              class="filter-input"
              dense
              @blur="saveChanges(item)"
            ></v-text-field>
          </template>
          <template v-if="item.type === 'date'">
            <DynamicDatePicker
              :date.sync="additionalData[item.code]"
              :readonly="hasReadOnlyAccess"
              :label="item.label"
              @inputEvent="saveChanges(item)"
            />
          </template>
          <template v-if="item.type === 'time'">
            <DynamicTimePicker
              :date.sync="additionalData[item.code]"
              :readonly="hasReadOnlyAccess"
              :label="item.label"
              @inputEvent="saveChanges(item)"
            />
          </template>
          <template v-if="item.type === 'selectbox' || item.type === 'multiselectbox'">
            <v-select
              v-model="additionalData[item.code]"
              :items="dropdownArray(item.option)"
              outlined
              :readonly="hasReadOnlyAccess"
              :multiple="item.type === 'multiselectbox'"
              hide-details
              dense
              :placeholder="item.label"
              @blur="saveChanges(item)"
            ></v-select>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import { mdiChevronRight, mdiChevronDown } from '@mdi/js'
import DynamicDatePicker from '@/components/shared-components/DynamicDatePicker.vue'
import DynamicTimePicker from '@/components/shared-components/DynamicTimePicker.vue'
import { dropdownArray, isTextBox } from '@core/utils'
import eventBus from '@/@core/utils/eventBus'
import { useRouter } from '@core/utils'

export default {
  components: { DynamicDatePicker, DynamicTimePicker },
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const customHeader = ref([])
    const isLoading = ref(true)
    const showMoreField = ref(false)
    const additionalData = ref({})

    const stateInit = () => {
      const data = {}
      customHeader.value.forEach(opt => {
        data[opt.code] = props.taskInfo[opt.code]
      })

      additionalData.value = { ...data }
    }

    const fetchHeader = () => {
      const payload = {
        component: `/task-management/spaces/item/${props.taskInfo.uuid}/custom_field`,
      }

      isLoading.value = true
      store
        .dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
        .then(({ data }) => {
          customHeader.value = data.data
          stateInit()
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchHeader()

    const updateTask = payload => {
      const routeName = route.value.name
      if (routeName === 'table-task' || routeName === 'share-list') {
        store.dispatch('tableView/UPDATE_TASK', {
          ...payload,
        })
      } else if (routeName === 'kanban-task') {
        store.dispatch('kanbanView/UPDATE_TASK', {
          ...payload,
        })
      } else {
        store
          .dispatch('tableView/UPDATE_TASK_ONLY', {
            ...payload,
          })
          .then(() => {
            eventBus.$emit('onTaskUpdate')
          })
      }
    }

    const saveChanges = item => {
      if (!additionalData.value[item.code]) return

      updateTask({ [item.code]: additionalData.value[item.code], uuid: props.taskInfo.uuid })
    }

    const hasReadOnlyAccess = computed(() => {
      if (!props.taskInfo?.permission) return false
      // 0 is no access 1 is read, 2 is editor, 3-4 is full or admin access
      if ([1, 0].includes(props.taskInfo.permission)) {
        return true
      }

      return false
    })

    return {
      isLoading,
      customHeader,
      additionalData,
      mdiChevronRight,
      hasReadOnlyAccess,
      dropdownArray,
      isTextBox,
      mdiChevronDown,
      showMoreField,
      saveChanges,
    }
  },
}
</script>

<style scoped lang="scss">
.label-sec {
  width: 30%;
}
::v-deep {
  .v-input.filter-input .v-input__control .v-input__slot {
    min-height: 32px !important;
  }
}
</style>
