<template>
  <v-dialog
    v-model="isVisible"
    max-width="1250px"
    scrollable
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <v-card class="document-detail pa-0">
      <v-card-title class="primary white--text py-3 d-flex align-center justify-space-between">
        <p class="font-weight-semibold mb-0" style="font-size: 18px">{{ title }}</p>
        <v-icon style="float: right; z-index: 9" color="white" @click="closeModal">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-0">
        <VideoSection />
        <component :is="resolveComponent"> </component>
        <ExpertSection />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from '@vue/composition-api'
import hero from '@/assets/images/svg/Hero_Bg.svg'
import callImg from '@/assets/images/svg/call.svg'
import { mdiClose } from '@mdi/js'
import store from '@/store'
import ExpertSection from './components/ExpertSection.vue'
import VideoSection from './components/VideoSection.vue'
import CrmFeature from './components/CrmFeature.vue'
import WorkdriveFeature from './components/WorkdriveFeature.vue'
import EstimationFeature from './components/EstimationFeature.vue'
import TaskFeature from './components/TaskFeature.vue'
import VendorFeature from './components/VendorFeature.vue'
import MaterialFeature from './components/MaterialFeature.vue'
import ReportFeature from './components/ReportFeature.vue'
import ProjectFeature from './components/ProjectFeature.vue'
import SecurityFeature from './components/SecurityFeature.vue'
import InventoryFeature from './components/InventoryFeature.vue'
import FieldFeature from './components/FieldFeature.vue'

export default {
  components: {
    VideoSection,
    TaskFeature,
    ProjectFeature,
    ReportFeature,
    MaterialFeature,
    SecurityFeature,
    ExpertSection,
    CrmFeature,
    VendorFeature,
    WorkdriveFeature,
    EstimationFeature,
    InventoryFeature,
    FieldFeature,
  },
  props: {
    isDocumentModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.isDocumentModal,
      set: () => {
        return false
      },
    })

    const app = computed(() => store.getters['documentation/getDetailApp'])

    const closeModal = () => {
      store.commit('documentation/SET_DETAIL_MODAL', false)
    }

    const title = computed(() => {
      let separatedWords = app.value.split(/(?=[A-Z])/)
      separatedWords = separatedWords.map(word => word.charAt(0).toUpperCase() + word.slice(1))

      return separatedWords.join(' ')
    })

    const resolveComponent = computed(() => {
      if (app.value === 'workDrive') return 'Workdrive-feature'
      if (app.value === 'estimation') return 'estimation-feature'
      if (app.value === 'vendorManagement') return 'vendor-feature'
      if (app.value === 'taskManagement') return 'task-feature'
      if (app.value === 'materialManagement') return 'material-feature'
      if (app.value === 'projectManagement') return 'project-feature'
      if (app.value === 'security') return 'security-feature'
      if (app.value === 'reports') return 'report-feature'
      if (app.value === 'inventoryManagement') return 'inventory-feature'
      if (app.value === 'fieldManagement') return 'field-feature'

      return 'crm-feature'
    })

    return {
      isVisible,
      closeModal,
      resolveComponent,
      app,
      title,
      hero,
      callImg,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style scoped lang="scss"></style>
