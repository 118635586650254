var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"w-one-third"},[_c('v-autocomplete',{staticClass:"inline-input",attrs:{"items":_vm.workflowOptions,"hide-details":"auto","filled":"","readonly":_vm.hasReadOnlyAccess,"rounded":"","label":"workflow"},on:{"change":_vm.onWorkflowChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-title',{staticClass:"text-xs3"},[_vm._v(_vm._s(data.item.text))])]}}]),model:{value:(_vm.generalInfo.workflow_id),callback:function ($$v) {_vm.$set(_vm.generalInfo, "workflow_id", $$v)},expression:"generalInfo.workflow_id"}})],1),_c('div',{staticClass:"w-one-third"},[_c('v-autocomplete',{staticClass:"inline-input ml-2",attrs:{"items":_vm.workflowStatusOptions,"readonly":_vm.hasReadOnlyAccess,"hide-details":"auto","label":"Status","filled":"","rounded":""},on:{"change":_vm.updateWorkFlow},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-title',{staticClass:"text-xs3"},[_vm._v(_vm._s(data.item.text))])]}}]),model:{value:(_vm.generalInfo.workflow_status_id),callback:function ($$v) {_vm.$set(_vm.generalInfo, "workflow_status_id", $$v)},expression:"generalInfo.workflow_status_id"}})],1),_c('div',{staticClass:"ml-2 w-one-third"},[_c('v-menu',{ref:"menuRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto","return-value":_vm.dates,"clearable":""},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"autocomplete-btn"},'div',attrs,false),on),[_c('p',{staticClass:"mb-1 text-xs2 label"},[_vm._v("Start & End Date")]),_c('p',{staticClass:"mb-0 text"},[_vm._v(_vm._s(_vm.dateRangeText))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","readonly":_vm.hasReadOnlyAccess},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onChangeDate}},[_vm._v(" Save ")])],1)],1)],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-one-third"},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"250px","nudge-top":"35","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"autocomplete-btn"},'div',attrs,false),on),[_c('p',{staticClass:"mb-1 text-xs2 label"},[_vm._v("Assignee")]),_vm._l((_vm.generalInfo.assigned_users),function(item){return _c('span',{key:item.uuid,staticClass:"chip mr-1"},[_vm._v(" "+_vm._s(_vm.avatarText(item.name))+" ")])})],2)]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-autocomplete',{staticClass:"inline-input",attrs:{"items":_vm.userOptions,"hide-details":"auto","outlined":"","readonly":_vm.hasReadOnlyAccess,"multiple":"","placeholder":"Assignee"},on:{"change":_vm.updateAssignee},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"task-chip",attrs:{"label":"","close":"","light":"","input-value":data.selected},on:{"click:close":function($event){return _vm.removeAssignee(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"warning","rounded":"","size":"30"}},[_c('span',{staticClass:"font-weight-semibold white--text text-xs"},[_vm._v(_vm._s(_vm.avatarText(data.item.text)))])]),_c('span',{staticClass:"dark--text font-weight-medium"},[_vm._v(" "+_vm._s(data.item.text)+" ")])],1)]}}]),model:{value:(_vm.generalInfo.assignee_ids),callback:function ($$v) {_vm.$set(_vm.generalInfo, "assignee_ids", $$v)},expression:"generalInfo.assignee_ids"}})],1)],1)],1)],1),_c('div',{staticClass:"ml-2 w-one-third"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"autocomplete-btn"},'div',attrs,false),on),[_c('p',{staticClass:"mb-1 text-xs2 label"},[_vm._v("Due Date")]),_c('p',{staticClass:"mb-0 text"},[_vm._v(_vm._s(_vm.generalInfo.due_date))])])]}}]),model:{value:(_vm.dueMenu),callback:function ($$v) {_vm.dueMenu=$$v},expression:"dueMenu"}},[_c('v-date-picker',{attrs:{"readonly":_vm.hasReadOnlyAccess,"no-title":""},on:{"input":_vm.onChangeDueDate},model:{value:(_vm.generalInfo.due_date),callback:function ($$v) {_vm.$set(_vm.generalInfo, "due_date", $$v)},expression:"generalInfo.due_date"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }