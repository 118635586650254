import { useRouter } from '@core/utils'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'

export default function useSubTabService(item) {
  const { route } = useRouter()
  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isSubTabActive = ref(false)
  const isFixedSubTabActive = ref(false)

  const updateIsSubActive = () => {
    const currentPath = router.currentRoute.path
    const resolvedpath = router.resolve({
      name: item.to,
      params: { [item.paramName]: item.key },
    })
    if (resolvedpath.href === currentPath) {
      isSubTabActive.value = true
    } else {
      isSubTabActive.value = false
    }

    const resolvedFixedpath = router.resolve({
      name: item.to,
      params: { id: item.key },
    })
    if (resolvedFixedpath.href === currentPath) {
      isFixedSubTabActive.value = true
    } else {
      isFixedSubTabActive.value = false
    }
  }
  watch(() => route.value, updateIsSubActive, { immediate: true })

  return {
    isFixedSubTabActive,
    isSubTabActive,
    updateIsSubActive,
  }
}

export function useEstimateToolActice(item) {
  const { route } = useRouter()
  const isTabActive = ref(false)

  const updateIsToolActive = () => {
    const currentPath = router.currentRoute.path

    const resolvedpath = router.resolve({
      name: item.to,
      params: { uuid: item.estimateId, pdfId: item.pdfId, pageId: item.pageId },
    })

    if (resolvedpath.href === currentPath) {
      isTabActive.value = true
    } else {
      isTabActive.value = false
    }
  }

  watch(() => route.value, updateIsToolActive, { immediate: true })

  return {
    isTabActive,
    updateIsToolActive,
  }
}
