<template>
  <div v-resize="onResize" class="nav-header w-full h-full">
    <div class="d-flex align-center" style="border-bottom: 2px solid #e3e1e1; background: #f3f3f3">
      <h4 class="mx-3" style="color: #fe724c !important; width: 40px">CRM</h4>
      <HorizontalTabMenuHeaderGroup
        v-for="item in navMenuItems"
        :key="item.header || item.title"
        :item="item"
      />
      <ul v-if="isVisible" class="nav-ul d-flex flex-grow-1">
        <TabHeaderLink
          v-for="(item, index) in tabs"
          :key="index"
          class="justify-center"
          :item="item"
          :loading="loading"
          @tabClick="tabClick"
          @closeTab="closeTab"
          @onResize="onResize"
        />
      </ul>
      <MoreTabMenu
        v-if="more.length"
        class="flex-grow-1"
        :more="more"
        @addItem="addItem"
        @closeTab="closeTab"
        @onResize="onResize"
      />
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/tab'
import { computed, ref, nextTick, watch } from '@vue/composition-api'
import { storeGetters, storeActions } from '@/store/storeConstants'
import store from '@/store'
import { useRouter } from '@core/utils'
import HorizontalTabMenuHeaderGroup from './components/horizontal-tab-menu-header-group/HorizontalTabMenuHeaderGroup.vue'
import MoreTabMenu from './components/MoreTabMenu.vue'
import TabHeaderLink from './components/TabHeaderLink.vue'
import SubtabHeaderLink from './components/SubtabHeaderLink.vue'

export default {
  name: 'CrmMenu',
  components: { TabHeaderLink, MoreTabMenu, HorizontalTabMenuHeaderGroup, SubtabHeaderLink },

  methods: {
    goto() {
      console.log('goto tab')
    },
    pin() {
      console.log('pin')
    },
  },
  setup() {
    const { GET_SUB_TAB } = storeGetters
    const { CANCEL_SUBTAB_REQUEST, FETCH_TABS } = storeActions
    const { router } = useRouter()

    const loadMore = () => {
      const payload = {
        component: 'setup/user-preferences',
        key: 'crm',
        filter: {
          key: 'crm',
        },
      }
      store.dispatch(`tabSystem/${FETCH_TABS}`, payload)
    }

    loadMore()

    const tabs = ref([])
    const more = ref([])
    const isVisible = ref(true)

    const loading = computed(() => store.getters[`tabSystem/${storeGetters.TAB_LOADING}`])
    const totalTabs = computed(() => store.getters[`tabSystem/${storeGetters.GET_CRM_TABS}`])
    const subTabArr = computed(() => store.getters[`tabs/${GET_SUB_TAB}`])

    const onResize = () => {
      isVisible.value = false
      const temp = totalTabs.value.slice()
      tabs.value = temp.splice(0, (window.innerWidth - 234) / 190 - 1)
      more.value = temp.splice(0)
      nextTick(() => {
        isVisible.value = true
      })
    }

    const addItem = item => {
      const payload = {
        item,
        key: 'crm',
        prop_type: 'accounts',
        component: 'setup/user-preferences',
      }
      store.dispatch(`tabSystem/${storeActions.TAB_POSITION_CHANGE}`, payload)

      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})
      onResize()
    }

    const tabClick = item => {
      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})
    }

    const addTab = () => {
      onResize()
    }

    const closeTab = ({ item, isActive }) => {
      const payload = {
        item,
        key: 'crm',
        component: 'setup/user-preferences',
        prop_type: 'accounts',
      }
      store.dispatch(`tabSystem/${storeActions.CANCEL_TAB_REQUEST}`, payload)
      // store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')

      if (isActive) {
        // redirect when active tab is closed
        if (totalTabs.value.length) {
          const redirectTab = totalTabs.value[0]
          router.push({ name: redirectTab.to, params: { uuid: redirectTab.key } }).catch(() => {})
        } else {
          router.push({ name: 'account-layout' })
        }
      }

      onResize()
    }
    const subTabClick = item => {
      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})
    }

    const closeSubTab = item => {
      store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')
      store.dispatch(`tabs/${CANCEL_SUBTAB_REQUEST}`, item)
    }

    watch(
      () => totalTabs.value,
      () => {
        addTab()
      },
    )

    return {
      tabs,
      more,
      isVisible,
      subTabArr,
      onResize,
      addItem,
      tabClick,
      subTabClick,
      closeTab,
      closeSubTab,
      navMenuItems,
      loading,
    }
  },
}
</script>
<style scoped>
.nav-ul {
  padding: 0px !important;
}

.menu-custom {
  background: #007eb9;
  color: black !important;
  border-radius: 0px;
}
.v-btn__content {
  color: black !important;
}
</style>
