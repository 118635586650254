<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What security you will get
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Reports List</p>
        <p class="dark--text">
          Access a list of all the reports created, including information such as the 'Report
          Description,' 'Created By,' and the day the report was 'Created on.'
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Shared Reports</p>
        <p class="dark--text">
          You can also access a list of all the shared reports created, including information such
          as the 'Report Description,' 'Shared By,' and the day the report was 'Created on.'
        </p>
      </div>
    </div>
    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">User</p>
        <p class="dark--text">
          All team members using the BLDON tool are listed here. You have the option to verify them
          as users, create a username for each to establish a unique identity, activate or
          deactivate their access to the platform, and add new users.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
