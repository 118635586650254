const security = [
  {
    path: '/security/dashboard',
    name: 'security-dashboard',
    component: () => import('@/views/security/dashboard.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/roles',
    name: 'security-roles',
    component: () => import('@/views/security/roles/Roles.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/role-overview',
    name: 'security-roles-overview',
    component: () => import('@/views/security/roles/RoleOverview.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },

  {
    path: '/security/role-overview/:id',
    name: 'security-edits-role',
    component: () => import('@/views/security/roles/RoleOverview.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/add-role',
    name: 'security-add-role',
    component: () => import('@/views/security/roles/AddRole.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
      permission: 'policy',
      permissionType: 'a',
    },
  },
  {
    path: '/security/add-role/:id',
    name: 'security-edit-role',
    component: () => import('@/views/security/roles/AddRole.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
      permission: 'policy',
      permissionType: 'e',
    },
  },
  {
    path: '/security/group-view/:id',
    name: 'security-group-view',
    component: () => import('@/views/security/user-group/GroupView.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/user-group',
    name: 'security-usergroup',
    component: () => import('@/views/security/user-group/UserGroups.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/user-management',
    name: 'security-user',
    component: () => import('@/views/security/UserManagement.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
    },
  },
  {
    path: '/security/user-overview',
    name: 'security-user-overview',
    component: () => import('@/views/security/UserOverview.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
      permission: 'users',
      permissionType: 'a',
    },
  },
  {
    path: '/security/user-overview/:userId',
    name: 'security-edit-user',
    component: () => import('@/views/security/UserOverview.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
      permission: 'users',
      permissionType: 'e',
    },
  },
  {
    path: '/security/recent-changes',
    name: 'security-recent-changes',
    component: () => import('@/views/security/SecurityRecentChanges.vue'),
    meta: {
      layout: 'content',
      app: 'security',
      appId: 3,
      permission: 'users',
      permissionType: 'e',
    },
  },
]

export default security
