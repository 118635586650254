<template>
  <div class="pa-3 w-full">
    <VueSkeletonLoader type="rect" :height="20" :width="500" animation="wave" rounded />
    <div class="d-flex mt-3">
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="65"
        :width="300"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="65"
        :width="300"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader type="rect" :height="65" :width="300" animation="wave" rounded />
    </div>
    <div class="d-flex mt-3 mb-3">
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="65"
        :width="200"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="65"
        :width="200"
        animation="wave"
        rounded
      />
    </div>
    <VueSkeletonLoader type="rect" :height="30" :width="600" animation="wave" rounded />

    <div class="d-flex mt-3 mb-3">
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="25"
        :width="80"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="25"
        :width="80"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader
        class="mr-2"
        type="rect"
        :height="25"
        :width="80"
        animation="wave"
        rounded
      />
      <VueSkeletonLoader type="rect" :height="25" :width="80" animation="wave" rounded />
    </div>
    <VueSkeletonLoader type="rect" :height="90" :width="700" animation="wave" rounded />
    <VueSkeletonLoader
      class="mt-3"
      type="rect"
      :height="90"
      :width="700"
      animation="wave"
      rounded
    />
    <VueSkeletonLoader
      class="mt-3"
      type="rect"
      :height="90"
      :width="700"
      animation="wave"
      rounded
    />
    <VueSkeletonLoader
      class="mt-2"
      type="rect"
      :height="30"
      :width="600"
      animation="wave"
      rounded
    />
  </div>
</template>
<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: { VueSkeletonLoader },
}
</script>
