export default {
  namespaced: true,
  state: {
    isDocumentModal: false,
    isDetailModal: false,
    detailApp: 'crm',
  },
  getters: {
    getOpenDocumentModal: state => state.isDocumentModal,
    getOpenDetailModal: state => state.isDetailModal,
    getDetailApp: state => state.detailApp,
  },
  mutations: {
    SET_DOCUMENT_MODAL: (state, payload) => {
      state.isDocumentModal = payload
    },
    SET_DETAIL_MODAL: (state, payload) => {
      state.isDetailModal = payload
    },
    SET_DETAIL_APP: (state, payload) => {
      state.detailApp = payload
    },
  },
  actions: {},
}
