<template>
  <div class="d-flex second-content justify-center align-center">
    <div class="mr-6">
      <v-img :src="under" height="240px"></v-img>
    </div>
    <div>
      <p class="text-lg dark--text font-weight-semibold">Know more about us?</p>
      <p class="dark--text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />
        sed do eiusmod tempor incididunt dolore magna aliqua.
      </p>
      <v-btn class="warning">Click Here</v-btn>
    </div>
  </div>
</template>
<script>
import under from '@/assets/images/svg/under.svg'

export default {
  setup() {
    return {
      under,
    }
  },
}
</script>
<style scoped>
.second-content {
  margin-top: 140px;
  gap: 20px;
  color: #221122;
}
</style>
