import { bldonApps } from '@/constants/appConstants'

const map = [
  {
    path: '/crm/map/view',
    name: 'map-view',
    component: () => import('@/views/map/MapView.vue'),
    meta: {
      layout: 'tab',
      app: bldonApps.map.text,
      appId: 1,
    },
  },
]

export default map
