var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediumGrey--text text-xs2"},[_c('div',{staticClass:"d-flex justify-space-between px-2 mb-1"},[_c('p',{staticClass:"mb-1"},[_vm._v("Space")]),_c('v-menu',{attrs:{"offset-y":"","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"19","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.createSpace}},[_c('v-list-item-title',{staticClass:"text-sm"},[_vm._v(" Create Space")])],1)],1)],1)],1),_c('div',{staticClass:"px-3 space-content"},[_c('v-autocomplete',{attrs:{"hide-details":"","items":_vm.spaces,"item-value":"uuid","item-text":"name","dense":"","solo":"","label":"Select Space"},on:{"change":_vm.handleSpaceChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex w-full justify-space-between"},[_c('v-list-item-title',[_vm._v(_vm._s(data.item.name))]),(data.item.uuid !== '-1')?_c('div',[_c('v-icon',{attrs:{"size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.spaceSetting(data.item.uuid)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.spaceId),callback:function ($$v) {_vm.spaceId=$$v},expression:"spaceId"}})],1),_c('div',{staticClass:"cursor-pointer mt-4",staticStyle:{"font-size":"0.85rem"},on:{"click":function($event){_vm.isShown = !_vm.isShown}}},[_c('div',{staticClass:"d-flex align-center justify-space-between px-2"},[_vm._m(0),(_vm.getSelectedSpace && _vm.$route.params.spaceId !== '-1')?_c('v-icon',{attrs:{"size":"21","color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.openTaskProjectModal = true}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mt-2 pr-2"},[(_vm.isRerender)?_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.tree,"load-children":_vm.fetchChild,"item-key":"uuid","dense":"","expand-icon":_vm.icons.mdiChevronDown,"transition":"","hoverable":"","activatable":"","return-object":""},on:{"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var obj = ref.item;
return [_c('div',{staticClass:"\n            white--text\n            text-xs3\n            d-flex\n            justify-space-between\n            align-center\n            cursor-pointer\n            py-3\n          ",class:obj.uuid === _vm.$route.params.taskId ? 'active-tree' : null,on:{"mouseover":function($event){_vm.isElmHover = true},"mouseleave":function($event){_vm.isElmHover = false}}},[_c('span',{staticStyle:{"text-overflow":"ellipsis","max-width":"170px","white-space":"nowrap","overflow":"hidden","display":"flex","align-items":"center"}},[(obj.type === 'project')?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17","color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarTextOutline)+" ")]):(obj.type === 'folder')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info","size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFolderOutline)+" ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17","color":"info"}},[_vm._v(_vm._s(_vm.icons.mdiFileOutline))]),_vm._v(" "+_vm._s(obj.name)+" ")],1),_c('div',{staticClass:"mr-1"},[_c('v-menu',{attrs:{"offset-y":"","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"19","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.addItem(obj)}}},[_c('v-list-item-title',{staticClass:"text-xs3"},[_c('v-icon',{staticClass:"mr-2 dark--text",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" Add Item ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.shareItem(obj)}}},[_c('v-list-item-title',{staticClass:"text-xs3"},[_c('v-icon',{staticClass:"mr-2 dark--text",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.icons.mdiShareAll))]),_vm._v(" Share ")],1)],1)],1)],1)],1)])]}}],null,false,1217838526)}):_vm._e()],1),_c('TaskProjectModal',{attrs:{"open-task-project-modal":_vm.openTaskProjectModal,"location":_vm.taskLocation},on:{"close":_vm.closeModal,"fetchData":_vm.renderComponent}}),(_vm.openShareModal)?_c('TaskShareModal',{attrs:{"is-share-dialog-open":_vm.openShareModal,"folder-data":_vm.taskInfo},on:{"closeModal":function($event){_vm.openShareModal = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Projects & Folders")])])}]

export { render, staticRenderFns }