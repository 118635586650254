import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'BLDON',
    logo: require('@/assets/images/svg/LightBackground.svg'),
    darkLogo: require('@/assets/images/svg/DarkBackground.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-y-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'semi-dark', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#072146',
      secondary: '#706D6D',
      success: '#28a745',
      info: '#1C6CAB',
      warning: '#fe724c',
      orange: '#ff9900',
      green: '#008000',
      red: '#ff0000',
      blue: '#0000ff',
      error: '#FF4C51',
      accent: '#605E5C',
      dark: '#212121',
      black: '#000000',
      semiDark: '#0A1A36',
      grey: '#666666',
      lightGrey: '#f3f3f3',
      mediumGrey: '#BDBDBD',
      lightBlue: '#EBF3FF',
      skyBlue: '#dff1ff',
      mediumBlue: '4B97D2',
      skyblue: '#F0F8FE',
      blueGrey: '#394D6B',
      darkBrown: '#28313e',
    },
    dark: {
      primary: '#007eb9',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
}

export default themeConfig
