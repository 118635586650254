<template>
  <v-menu
    offset-y
    left
    nudge-bottom="20"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :color="notifications.newNotifications ? 'info' : 'transparent'"
        offset-x="11"
        offset-y="13"
        overlap
        :content="notifications.newNotifications"
      >
        <v-icon v-bind="attrs" size="22" :color="light ? 'secondary' : 'white'" v-on="on">
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar class="ps-user-notifications" :options="perfectScrollbarOptions">
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item class="d-flex" link>
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Notifications</span>
              <v-chip
                v-if="notifications.newNotifications"
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ notifications.newNotifications }} New
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(notification, index) in notificationArray">
            <v-list-item
              :key="notification.uuid"
              link
              :style="{ background: !notification.isviewed ? '#eee' : '#fff' }"
              @click="handleNotificationClick(notification)"
            >
              <!-- Avatar -->
              <v-list-item-avatar class="v-avatar-light-bg primary--text justify-center" size="40">
                <span class="text-sm">{{
                  getInitialName(notification.data.name ? notification.data.name : 'M')
                }}</span>
              </v-list-item-avatar>

              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification.data.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  {{ notification.data.message }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{
                  formatDateToMonthShort(notification.createdAt)
                }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item key="read-all-btn" class="read-all-btn-list-item">
            <v-btn
              block
              color="primary"
              @click="$router.push({ name: 'user-profile', query: { notification: 'true' } })"
            >
              Read All Notifications
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import { getInitialName, useRouter } from '@core/utils'
import { ref, onUnmounted } from '@vue/composition-api'

import store from '@/store'
import { storeActions, storeMutations } from '@/store/storeConstants'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { formatDateToMonthShort } from '@core/utils/filter'
import useTabService from '@/@core/comp-functions/useTabService'
import Toaster from '@core/utils/sweetToast'
import { exportDataToExcel } from '@core/utils/csvService'
import axios from 'axios'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { router } = useRouter()
    const { estimationTab, gotoCrmTab } = useTabService()
    const page = ref(1)
    const count = ref(0)
    const notificationArray = ref([])
    const notifications = ref({})
    const userNotification = ref([])
    const lastNotificationId = ref(undefined)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const downloadReport = (data) => {
      const baseUrl = `${process.env.VUE_APP_ROOT_API}/reportservice/download/pdf/${data.uuid}`
      axios
        .get(
          baseUrl,
          { responseType: 'blob' }, // !!!
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${data.name}.${data.file_type}`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          // do nothing
        })
    }

    const loadNotifications = () => {
      page.value = 1
      const payload = {
        component: '/notification/',
        filter: {
          limit: 10,
          offset: 0,
          last_notification: lastNotificationId.value,
        },
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success && data.data) {
          // if policyUpdated key is coming than show notification
          if (data.data.policyUpdated) {
            Toaster.success('Your policy has been updated please login again')
          }

          if (lastNotificationId.value) {
            notificationArray.value.unshift(...data.data.rows)
            if (notificationArray.value.length > 10) {
              notificationArray.value.splice(10)
            }
          } else {
            notificationArray.value = data.data.rows
          }

          if (notificationArray.value.length) {
            lastNotificationId.value = notificationArray.value[0].uuid
          }
          notifications.value = data.data

          if (data.meta !== undefined) {
            count.value = data.meta.count
            // for estimation ocr functionality
            if (data.meta.userNotification && data.meta.userNotification.length) {
              data.meta.userNotification.forEach(element => {
                const userNotify = JSON.parse(element)
                if (userNotify?.code === 200) {
                  window.open(userNotify.signedUrl, '_blank')
                }
                if (userNotify?.code === 5000) {
                  downloadReport(userNotify)
                }
                if (userNotify?.code === 2001) {
                  store.commit(`estimation/${storeMutations.SET_OCR_STATUS}`, false)
                }
                Toaster.success(userNotify?.message, 'success')
              })
            }
          }
        }
      })
    }

    const myInterval = setInterval(() => {
      loadNotifications()
    }, 50000)

    loadNotifications()
    const updateNotification = uuid => {
      const payload = {
        component: `/notification/?uuid=${uuid}`,
      }

      store.dispatch(`common/${storeActions.UPDATE}`, payload).then(({ data }) => {
        if (data.success) {
          loadNotifications()
        }
      })
    }

    const isCrmSection = submodule => {
      const submodules = ['accounts', 'opportunities', 'contacts', 'leads']

      return !!submodules.includes(submodule)
    }

    const getRouteView = module => {
      if (module === 'accounts') return 'account-view'
      if (module === 'opportunities') return 'opportunity-view'
      if (module === 'contacts') return 'contact-view'

      return 'lead-view'
    }

    const handleNotificationClick = notification => {
      if (notification.isviewed === 0) {
        updateNotification(notification.uuid)
      }

      const { data } = notification
      if (!data) return
      if (data.errorList) {
        const excelData = data.errorList.map(opt => {
          const { serialNo, tenant_id, createdAt, updatedAt, error, ...restData } = opt

          return {
            ...restData,
            error: error.join(', '),
          }
        })
        exportDataToExcel(excelData, `${data.name}.xlsx`)
        Toaster.success('Item list downloaded successfully.', 'success')

        return
      }
      if (isCrmSection(data.module)) {
        const payload = {
          paramName: 'uuid',
          name: data.name,
          uuid: data.uuid,
        }
        gotoCrmTab(payload, getRouteView(data.module))
        router
          .push({ name: getRouteView(data.module), params: { uuid: data.uuid } })
          .catch(() => {})
      } else if (data.module === 'estimationPdf') {
        window.open(data.signedUrl, '_blank')
      } else if (data.module === 'estimation') {
        const payload = {
          paramName: 'uuid',
          uuid: data.uuid,
          name: data.name,
        }
        estimationTab(payload, 'estimation-view')
        router.push({ name: 'estimation-view', params: { uuid: data.uuid } }).catch(() => {})
      } else if (data.module === 'estimation-comment') {
        if (data.page_id && data.pdf_id) {
          router
            .push({
              name: 'estimator-pdf',
              params: {
                uuid: data.uuid,
                pdfId: data.pdf_id,
                pageId: data.page_id,
              },
            })
            .catch(() => {})
        } else {
          router
            .push({
              name: 'estimation-tool-overview',
              params: {
                uuid: data.uuid,
              },
            })
            .catch(() => {})
        }
      } else if (data.module === 'task-management') {
        router.push({
          name: 'all-task',
          params: {
            module: data.prop_type,
            moduleId: data.prop_id,
          },
          query: { task: data.uuid, dialog: true },
        })
      } else if (data.module === 'comment' && data.prop_type === 'tasks') {
        router.push({
          name: 'all-task',
          params: {
            module: data.prop_type,
            moduleId: data.prop_id,
          },
          query: { task: data.prop_id, dialog: true },
        })
      } else if (data.module === 'report') {
        store.commit('notification/setReportData', data)
        router
          .push({
            name: 'report-download',
            params: { uuid: notification.uuid },
          })
          .catch(() => {})
      } else if (data.module === 'comment') {
        const payload = {
          paramName: 'uuid',
          name: data.name,
          uuid: data.prop_id,
        }
        const queryParam = { chatter: 'true', chatterId: data.uuid }
        gotoCrmTab(payload, getRouteView(data.prop_type))
        router
          .push({
            name: getRouteView(data.prop_type),
            params: { uuid: data.prop_id },
            query: queryParam,
          })
          .catch(() => {})
      }
      if (data.module === 'warehouse') {
        router.push({ name: 'warehouse-details', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'location') {
        router.push({ name: 'warehouse-details', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'inventory') {
        router.push({ name: 'view-inventory', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'purchaseOrder') {
        router.push({ name: 'view-po', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'receipt') {
        router.push({ name: 'view-receipt', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'delivery') {
        router.push({ name: 'view-delivery', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'requisition') {
        router.push({ name: 'view-requisition', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'internalTransfer') {
        router.push({ name: 'view-internal-transfer', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'signout') {
        router.push({ name: 'view-signout', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'bringback') {
        router.push({ name: 'view-bring-back', params: { uuid: data.uuid } }).catch(() => {})
      }
      if (data.module === 'inventoryAdjustment') {
        router
          .push({ name: 'view-inventory-adjustment', params: { uuid: data.uuid } })
          .catch(() => {})
      }
      // for chatter mention
      if (data.module === 'comment') {
        const payload = {
          paramName: 'uuid',
          name: data.name,
          uuid: data.prop_id,
        }
        const queryParam = { chatter: 'true', chatterId: data.uuid }
        gotoCrmTab(payload, getRouteView(data.prop_type))
        router
          .push({
            name: getRouteView(data.prop_type),
            params: { uuid: data.prop_id },
            query: queryParam,
          })
          .catch(() => {})
      }
    }

    onUnmounted(() => {
      clearTimeout(myInterval)
    })

    return {
      notifications,
      getInitialName,
      userNotification,
      perfectScrollbarOptions,
      notificationArray,
      handleNotificationClick,
      formatDateToMonthShort,
      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
