import {
  mdiMonitorDashboard,
  mdiFileChartOutline,
  mdiCalendarCheck,
  mdiDelete,
  mdiPlus,
  mdiCircleEditOutline,
} from '@mdi/js'
import { required } from '@/@core/utils/validation'

export default {
  headerTabs: {
    tasks: {
      tabs: [
        {
          id: 'uQ4HUsfrdG3CGmmEx4LQDz',
          key: 'account',
          icon: mdiMonitorDashboard,
          subTab: [],
          title: 'Dashboard',
          to: 'task-dashboard',
        },
        {
          id: 'uQ4HUsUdG3CGmmtrx4LQDz',
          key: 'opportunity',
          icon: mdiCalendarCheck,
          subTab: [],
          title: 'Tasks',
          to: 'task-management',
        },
        {
          id: 'uQ4HUsUdG3CGmmtrx4LQDz',
          key: 'lead',
          icon: mdiFileChartOutline,
          subTab: [],
          title: 'Reports',
          to: 'task-report',
        },
        {
          id: 'uQdHUsUcG3CGmmtrx4LQDz',
          key: 'calendar',
          icon: mdiFileChartOutline,
          subTab: [],
          title: 'Calendar',
          to: 'task-bid-calendar',
        },
      ],
      subTabs: [],
    },
  },
  task: {
    title: 'Task',
    url: 'task-management',
    key: 'uuid',
    module: 'task',
    showSelect: true,
    showCreateBtn: true,
    expandable: true,
    app: 'taskManagement',
    actions: [
      {
        icon: mdiPlus,
        title: 'Add',
        ability: 'a',
      },
      {
        icon: mdiDelete,
        title: 'Delete',
        ability: 'd',
      },
    ],
    headers: [
      { text: 'Task', value: 'name' },
      { text: 'Assignee', value: 'assignee.name' },
      { text: 'Status', value: 'workflow_status.name' },
      { text: 'UpdatedAt', value: 'updatedAt' },
      { text: 'Deadline', value: 'due_date' },
      { text: 'Subtasks', value: 'subtasks_count' },
      { text: 'action', value: 'action', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    formFields: [
      {
        type: 'textbox',
        key: 'name',
        label: 'Name',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'details',
        label: 'Details',
        validations: [required],
      },
      {
        type: 'autocomplete',
        key: 'assignee_id',
        label: 'Assignee',
        'item-value': '_id',
        'item-text': 'name',
        api: '/crm/accounts',
        options: [],
        search: '',
        validations: [],
      },
      {
        type: 'date',
        key: 'due_date',
        label: 'Due Date(YYY-MM-DD)',
        validations: [],
      },
      {
        type: 'date',
        key: 'from_date',
        label: 'Start Date(YYY-MM-DD)',
        validations: [],
      },
      {
        type: 'date',
        key: 'to_date',
        label: 'End Date(YYY-MM-DD)',
        validations: [],
      },
      {
        type: 'dropdown',
        key: 'workflow_uuid',
        label: 'Workflow',
        options: [],
        validations: [required],
      },
      {
        type: 'dropdown',
        key: 'workflow_status_id',
        label: 'Workflow Status',
        options: [],
        validations: [required],
      },
      // {
      //   type: 'dropdown',
      //   key: 'prop_type',
      //   label: 'prop_type',
      //   options: [],
      //   validations: [required],
      // },
      // {
      //   type: 'dropdown',
      //   key: 'prop_id',
      //   label: 'prop_id',
      //   options: [],
      //   validations: [required],
      // },
    ],
  },
  report: {
    title: 'Reports',
    url: '/setup/reports/related-list?related_to=tasks',
    key: 'uuid',
    showHeader: true,
    showSearch: true,
    showSelect: false,
    showCreateBtn: true,
    headers: [
      { text: 'Report Name', value: 'name' },
      { text: 'Description', value: 'description' },
      // { text: 'Folder', value: 'folder_name', sortable: false },
      { text: 'Related To', value: 'related_to' },
      { text: 'Created By', value: 'created_by', sortable: false, disabled: true },
      { text: 'Created On', value: 'createdAt', sortable: false, disabled: true },
    ],
  },
  // report: {
  //   title: 'Report',
  //   url: 'taskManagement/workflow',
  //   key: 'uuid',
  //   module: 'account',
  //   showSelect: true,
  //   app: 'taskManagement',
  //   actions: [
  //     {
  //       icon: mdiPlus,
  //       title: 'Add',
  //       ability: 'a',
  //     },
  //     {
  //       icon: mdiCircleEditOutline,
  //       title: 'Edit',
  //       ability: 'e',
  //     },
  //     {
  //       icon: mdiDelete,
  //       title: 'Delete',
  //       ability: 'd',
  //     },
  //   ],
  //   headers: [
  //     { text: 'Name', value: 'name' },
  //     { text: 'Description', value: 'desc' },
  //     { text: 'Status', value: 'status' },
  //     { text: 'UpdatedAt', value: 'updatedAt' },
  //   ],
  // },
  workFlow: {
    title: 'Work Flow',
    url: 'setup/taskManagement/workflow',
    key: 'uuid',
    module: 'taskManagement',
    showSelect: false,
    showCreateBtn: true,
    app: 'taskManagement',
    actions: [
      // {
      //   icon: mdiPlus,
      //   title: 'Add',
      //   ability: 'a',
      // },
      {
        icon: mdiCircleEditOutline,
        title: 'Edit',
        ability: 'e',
      },
      {
        icon: mdiDelete,
        title: 'Delete',
        ability: 'd',
      },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Module', value: 'module' },
      { text: 'Description', value: 'description' },
      { text: 'UpdatedAt', value: 'updatedAt' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      {
        type: 'textbox',
        key: 'name',
        label: 'Name',
        validations: [required],
      },
      {
        type: 'textarea',
        key: 'description',
        label: 'Description',
        validations: [required],
      },
    ],
  },
}
