<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What We Provide In Estimation
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Take Offs</p>
        <p class="dark--text">
          Use the TakeOffs feature in the Estimation tool to quantify and measure materials,
          quantities, and dimensions from construction drawings and blueprints to accurately
          calculate the materials and resources needed for a construction project. 
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Alternate</p>
        <p class="dark--text">
          As an estimator, you can use the VE feature and can easily explore alternative materials,
          methods, or designs and optimize your project cost without compromising quality or
          performance. 
        </p>
      </div>
    </div>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Users</p>
        <p class="dark--text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Lead</p>
        <p class="dark--text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
