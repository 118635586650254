import { ref } from '@vue/composition-api'

export default () => {
  const timer = ref(null)

  const debounce = (func, timeout = 1000) => {
    clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      func()
    }, timeout)
  }

  return {
    debounce,
  }
}
