export const storeActions = {
  // common actions
  CREATE: 'CREATE',
  GET_ALL_RECORDS: 'GET_ALL_RECORDS',
  UPDATE_BY_ID: 'UPDATE_BY_ID',
  UPDATE: 'UPDATE',
  UPDATE_FILE: 'UPDATE_FILE',
  DELETE_BY_ID: 'DELETE_BY_ID',
  GET_BY_ID: 'GET_BY_ID',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  DOWNLOAD: 'DOWNLOAD',
  CUSTOM_REQUEST: 'CUSTOM_REQUEST',

  // Auth
  LOGIN: 'login',
  REGISTER: 'REGISTER',
  LOGOUT: 'logout',
  USER_PROFILE: 'user_profile',
  FETCH_USERS: 'FETCH_USERS',
  EMAIL_TOKEN: 'EMAIL_TOKEN',
  FETCH_APPS: 'FETCH_APPS',

  // Tab Persistant Crm
  CRAETE_TABS: 'CRAETE_TABS',
  FETCH_TABS: 'FETCH_TABS',
  WORKSPACE_TAB_REQUEST: 'WORKSPACE_TAB_REQUEST',
  SUB_TAB_REQUEST: 'SUB_TAB_REQUEST',
  ACTIVE_TAB_REQUEST: 'ACTIVE_TAB_REQUEST',
  TAB_POSITION_CHANGE: 'TAB_POSITION_CHANGE',
  SUBTAB_POSITION_CHANGE: 'SUBTAB_POSITION_CHANGE',

  // Tab Persistant Setup
  SETUP_TAB_REQUEST: 'SETUP_TAB_REQUEST ',
  CANCEL_TAB_REQUEST: 'CANCEL_TAB_REQUEST',

  REPORT_TAB_REQUEST: 'REPORT_TAB_REQUEST',
  DASHBOARD_TAB_REQUEST: 'DASHBOARD_TAB_REQUEST',
  VENDOR_TAB_REQUEST: 'VENDOR_TAB_REQUEST',
  ESTIMATE_TAB_REQUEST: 'ESTIMATE_TAB_REQUEST',
  FETCH_VISUAL_DATA: 'SET_VISUAL_DATA',
  CHANGE_TAKEOFF: 'CHANGE_TAKEOFF',

  // project management
  SET_PROJECT: 'SET_PROJECT',
  FETCH_PDF_PAGES: 'FETCH_PDF_PAGES',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  FETCH_TAKEOFF: 'FETCH_TAKEOFF',
  ASSEMBLY_SELECTED: 'ASSEMBLY_SELECTED',
  FETCH_ASSEMBLY_DETAILS: 'FETCH_ASSEMBLY_DETAILS',
  ESTIMATE_SELECTED: 'ESTIMATE_SELECTED',
  TAKE_OFF_FILTERS: 'TAKE_OFF_FILTERS',
  SET_PROJECT_DETAIL_STATE: 'SET_PROJECT_DETAIL_STATE',

  // material management
  FETCH_LABOR_PRODUCTIVITY: 'FETCH_LABOR_PRODUCTIVITY',

  // inventory management
  SET_INVENTORY_CONFIG_STATE: 'SET_INVENTORY_CONFIG_STATE',
  SET_INVENTORY_CUSTOM_ID_STATE: 'SET_INVENTORY_CUSTOM_ID_STATE',

}

export const storeGetters = {
  // auth
  GET_ALL_PERMISSIONS: 'GET_ALL_PERMISSIONS',
  GET_USER_INFO: 'GET_USER_INFO',
  DECODE_TOKEN_INFO: 'DECODE_TOKEN_INFO',
  GET_AUTH_TOKEN: 'GET_AUTH_TOKEN',
  GET_ALL_USER: 'GET_ALL_USER',
  GET_PURCHASE_APP: 'GET_PURCHASE_APP',
  GET_TAKE_OFF: 'GET_TAKE_OFF',
  GET_EMAIL_TOKEN: 'GET_EMAIL_TOKEN',
  GET_LAUNCHER_APP: 'GET_LAUNCHER_APP',

  // Get Persistant Crm Tab
  GET_CRM_TABS: 'GET_CRM_TABS',
  TAB_LOADING: 'TAB_LOADING',
  GET_WORKSPACE_TAB: 'GET_WORKSPACE_TAB',
  GET_SUB_TAB: 'GET_SUB_TAB',
  GET_ACTIVETAB_ID: 'GET_ACTIVETAB_ID',
  GET_SETUP_PERSIST_TAB: 'GET_SETUP_PERSIST_TAB',
  CANCEL_SUBTAB_REQUEST: 'CANCEL_SUBTAB_REQUEST',

  GET_ESTIMATION_TABS: 'GET_ESTIMATION_TABS',
  GET_REPORT_TABS: 'GET_REPORT_TABS',
  GET_RESPONSE_STATUS: 'GET_RESPONSE_STATUS',
  // fixed tab
  GET_SETUP_ACTIVE_ID: 'GET_SETUP_ACTIVE_ID',
  GET_FIXED_SUBTAB: 'GET_FIXED_SUBTAB',

  // file uploading notification
  GET_NOTIFY_TITLE: 'GET_NOTIFY_TITLE',
  GET_FILE_UPLOADING: 'GET_FILE_UPLOADING',
  GET_UPLOADING_STATUS: 'GET_UPLOADING_STATUS',
  GET_ESTIMATE_PERSIST_TAB: 'GET_ESTIMATE_PERSIST_TAB',
  GET_REPORT_PERSIST_TAB: 'GET_REPORT_PERSIST_TAB',

  // estimation
  GET_VISUAL_DATA: 'GET_VISUAL_DATA',
  GET_VISUAL_COUNT: 'GET_VISUAL_COUNT',
  GET_LOADING: 'GET_LOADING',
  GET_TAKE_OFF_PATH_STYLE: 'GET_TAKE_OFF_PATH_STYLE',
  GET_COMPARE_VISUAL_DATA: 'GET_COMPARE_VISUAL_DATA',
  GET_OCR_STATUS: 'GET_OCR_STATUS',
  GET_MATERIALS: 'GET_MATERIALS',
  GET_TAKE_OFF_LOADER: 'GET_TAKE_OFF_LOADER',
  GET_ESTIMATE_ID: 'GET_ESTIMATE_ID',
  GET_TAKE_OFF_BY_ASSEMBLY: 'GET_TAKE_OFF_BY_ASSEMBLY',
  GET_ALL_TAKE_OFF: 'GET_ALL_TAKE_OFF',

  // project management
  GET_PROJECT_ID: 'GET_PROJECT_ID',
  GET_PDF_PAGES: 'GET_PDF_PAGES',
  GET_COMMENTS: 'GET_COMMENTS',
  GET_ACTIVE_PAGEID: 'GET_ACTIVE_PAGEID',
  GET_ACTIVE_PAGENUMBER: 'GET_ACTIVE_PAGENUMBER',
  GET_CURRENT_PAGE: 'GET_CURRENT_PAGE',
  GET_PROJECT_DETAIL: 'GET_PROJECT_DETAIL',
  // dashboard
  GET_DASHBOARD_PERSIST_TAB: 'GET_DASHBOARD_PERSIST_TAB',
  GET_DASHBOARD_TABS: 'GET_DASHBOARD_TABS',

  // vendor management
  GET_VENDOR_PERSIST_TAB: 'GET_VENDOR_PERSIST_TAB',
  GET_VENDOR_TABS: 'GET_VENDOR_TABS',

  // material managment
  GET_LABOR_PRODUCTIVITY: 'GET_LABOR_PRODUCTIVITY',
  GET_SUBLABOR_PRODUCTIVITY: 'GET_SUBLABOR_PRODUCTIVITY',

  // inventory management
  GET_INVENTORY_CONFIG: 'GET_INVENTORY_CONFIG',
  GET_INVENTORY_CUSTOM_ID: 'GET_INVENTORY_CUSTOM_ID',

}
export const storeMutations = {
  // Auth
  SET_PERMISSION: 'SET_PERMISSION',
  AUTH_USER: 'AUTH_USER',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_SET_TOKEN: 'AUTH_SET_TOKEN',
  SET_USERS: 'SET_USERS',
  RESET_USERS: 'RESET_USERS',
  SET_PURCHASE_APP: 'SET_PURCHASE_APP',
  SET_LAUNCHER_BASE_APP: 'SET_LAUNCHER_BASE_APP',
  SET_LAUNCHER_MANAGEMENT_APP: 'SET_LAUNCHER_MANAGEMENT_APP',

  // Set Persistant crm
  SET_TAB_LOADING: 'SET_TAB_LOADING',
  SET_CRM_TABS: 'SET_CRM_TABS',
  SET_WORKSPACE_STATE: 'SET_WORKSPACE_STATE',
  SET_SUBTAB_STATE: 'SET_SUBTAB_STATE',
  SET_ACTIVETAB_ID: 'SET_ACTIVETAB_ID',
  SET_SETUP_TAB: 'SET_SETUP_TAB',
  ACTIVE_SETUP_ID: 'ACTIVE_SETUP_ID',
  SET_FIXED_SUBTAB: 'SET_FIXED_SUBTAB',
  SET_ESTIMATION_TABS: 'SET_ESTIMATION_TABS',
  SET_REPORT_TABS: 'SET_REPORT_TABS',
  SET_TAB_SETUP_STATE: 'SET_TAB_SETUP_STATE',
  SET_WORKSPACE_ARRAY: 'SET_WORKSPACE_ARRAY',
  RESET_WORKSPACE_TAB: 'RESET_WORKSPACE_TAB',

  SET_ESTIMATE_TAB_STATE: 'SET_ESTIMATE_TAB_STATE',
  SET_REPORT_TAB_STATE: 'SET_REPORT_TAB_STATE',
  SET_DASHBOARD_TAB_STATE: 'SET_DASHBOARD_TAB_STATE',
  SET_VENDOR_TAB_STATE: 'SET_VENDOR_TAB_STATE',

  SET_FILES_ARRAY: 'SET_FILES_ARRAY',
  SET_FILES_STATUS: 'SET_FILES_STATUS',
  SET_RESET_NOTIFY: 'SET_RESET_NOTIFY',
  SET_UPLOADING_START: 'SET_UPLOADING_START',
  SET_UPLOADING_PERCENTAGE: 'SET_UPLOADING_PERCENTAGE',

  // estimation
  SET_VISUAL_STATE: 'SET_VISUAL_STATE',
  RESET_VISUAL_STATE: 'RESET_VISUAL_STATE',
  STATE_LOADING: 'STATE_LOADING',
  RESET_TAKE_OFF: 'RESET_TAKE_OFF',
  SET_TAKE_OFF: 'SET_TAKE_OFF',
  SET_COMPARE_VISUAL_DATA: 'SET_COMPARE_VISUAL_DATA',
  SET_OCR_STATUS: 'SET_OCR_STATUS',
  SET_ACTIVE_ASSEMBLY: 'SET_ACTIVE_ASSEMBLY',
  SET_ASSEMBLY_DETAILS: 'SET_ASSEMBLY_DETAILS',
  SET_TAKE_OFF_LOADER: 'SET_TAKE_OFF_LOADER',
  SET_ESTIMATE_ID: 'SET_ESTIMATE_ID',
  SET_TAKE_OFF_FILTERS: 'SET_TAKE_OFF_FILTERS',

  // project management
  SET_PROJECT_ID: 'SET_PROJECT_ID',

  SET_PDF_PAGES: 'SET_PDF_PAGES',
  RESET_PDF_PAGES: 'RESET_PDF_PAGES',
  SET_ACTIVE_PAGEID: 'SET_ACTIVE_PAGEID',
  SET_ACTIVE_PAGENUMBER: 'SET_ACTIVE_PAGENUMBER',

  SET_COMMENTS: 'SET_COMMENTS',
  RESET_COMMENTS: 'RESET_COMMENTS',
  SET_PROJECT_DETAIL: 'SET_PROJECT_DETAIL',

  // vendor management
  SET_VENDOR_TABS: 'SET_VENDOR_TABS',

  // material management
  SET_LABOR_PRODUCTIVITY: 'SET_LABOR_PRODUCTIVITY',
  RESET_LABOR_PRODUCTIVITY: 'RESET_LABOR_PRODUCTIVITY',

  // inventory management
  SET_INVENTORY_CONFIG: 'SET_INVENTORY_CONFIG',
  SET_INVENTORY_CUSTOM_ID: 'SET_INVENTORY_CUSTOM_ID',
}
