<template>
  <div class="notify-area" :class="files.length ? '' : 'fade-bottom'">
    <div v-if="isVisible" class="">
      <div class="notify-header">
        <p class="p-margin">{{ title }}</p>
        <div class="" style="display: flex">
          <v-icon size="17" class="icon-color" @click="closeNotify">{{ icons.mdiClose }}</v-icon>
        </div>
      </div>

      <div class="notify-content">
        <div v-for="(item, index) in files" :key="index" class="notify-content-item">
          <p class="p-margin">{{ item.name }}</p>

          <div v-if="item.status === 'uploading'" class="uploading-status">
            <p class="mb-0 text-xs2">{{ item.percentage + '%' }}</p>
            <div>
              <v-progress-circular
                class="pl-2"
                size="13"
                :width="2"
                indeterminate
                color="red"
              ></v-progress-circular>
            </div>
          </div>
          <v-icon v-else-if="item.status === 'success'" size="18" class="icon-color">
            {{ icons.mdiCheckCircle }}
          </v-icon>
          <v-icon v-else-if="item.status === 'failed'" size="18" class="icon-color">
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiCheckCircle, mdiCloseCircleOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import { storeGetters, storeMutations } from '@/store/storeConstants'

const { GET_NOTIFY_TITLE, GET_FILE_UPLOADING, GET_UPLOADING_STATUS, GET_RESPONSE_STATUS } =
  storeGetters
const { SET_RESET_NOTIFY } = storeMutations

export default {
  data() {
    return {
      isVisible: true,
    }
  },
  computed: {
    ...mapGetters({
      title: `notify/${GET_NOTIFY_TITLE}`,
      files: `notify/${GET_FILE_UPLOADING}`,
      status: `notify/${GET_UPLOADING_STATUS}`,
      response: `notify/${GET_RESPONSE_STATUS}`,
    }),
  },
  methods: {
    closeNotify() {
      const notify = document.querySelector('.notify-area')
      notify.classList.add('fade-bottom')
      setTimeout(() => {
        this.$store.commit(`notify/${SET_RESET_NOTIFY}`)
      }, 500)
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiCheckCircle,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.notify-area {
  position: fixed;
  bottom: 0;
  right: 22px;
  width: 430px;
  z-index: 9999;
}
.notify-header {
  background: #232f3e;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  .icon-color {
    color: white;
  }
}
.notify-content {
  background: #fff;
  border-left: 1px solid #dadcde;
  border-right: 1px solid #dadcde;
  overflow-y: auto;
  max-height: 350px;
}
.notify-content-item {
  display: flex;
  justify-content: space-between;
  padding: 7px 14px;
  .icon-color {
    color: green;
  }
}
.notify-content-item:first-child {
  padding-top: 17px;
}
.notify-content-item:last-child {
  padding-bottom: 17px;
}

.fade-bottom {
  transition: all 1.5s;
  opacity: 0;
  transform: translatey(500px) scale(0.2);
}

.theme--light.v-progress-linear {
  background: #8bbbf6;
}

.uploading-status {
  display: flex;
    align-items: center;
    gap: 5px;
}
</style>
