import { nanoid } from 'nanoid'
import { mdiAccount, mdiAccountBoxOutline, mdiAccountCircleOutline } from '@mdi/js'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import router from '@/router'

const { WORKSPACE_TAB_REQUEST, SUB_TAB_REQUEST, GET_ALL_RECORDS, FETCH_USERS, CRAETE_TABS } =
  storeActions

export default () => {
  const gotoTab = (item, redirectUrl) => {
    const payload = {
      title: item.name,
      icon: mdiAccountCircleOutline,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
    }
    store.dispatch(`tabs/${WORKSPACE_TAB_REQUEST}`, payload)

    router.push({ name: payload.to, params: { uuid: payload.key } }).catch(() => {})
  }
  const gotoSubTab = (item, redirectUrl) => {
    const tabObj = {
      title: item.name,
      icon: mdiAccountBoxOutline,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: 'uuid',
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'crm',
      prop_type: 'accounts',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
    router.push({ name: tabObj.to, params: { uuid: tabObj.key } }).catch(() => {})
    // router.push({ name: payload.to, params: { id, moduleId: payload.key } }).catch(() => {})
  }

  const checkUinqueName = async (value, modalName) => {
    const config = {
      component: 'crm/leads/check',
      filter: {
        name: value,
        model: modalName,
      },
    }

    try {
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, config)
      if (data.success) {
        return true
      }

      return false
    } catch {
      return false
    }
  }

  const fetchUsers = async (val = null) => {
    const payload = {
      component: 'security/users',
      filter: {
        search: val,
      },
    }
    await store.dispatch(`users/${FETCH_USERS}`, payload)
  }

  const gotoCrmTab = (item, redirectUrl, queryParam) => {
    const tabObj = {
      title: item.name,
      icon: mdiAccountCircleOutline,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'crm',
      prop_type: 'accounts',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
    router
      .push({ name: redirectUrl, params: { uuid: item.uuid }, query: queryParam })
      .catch(() => {})
  }

  return {
    gotoTab,
    gotoSubTab,
    checkUinqueName,
    fetchUsers,
    gotoCrmTab,
    icons: {
      mdiAccount,
    },
  }
}
