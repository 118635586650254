<template>
  <li
    class="nav-list flex-grow-1 mainlist text-center"
    :class="{ active: isActiveSetupTab }"
    @click="$emit('tabClick', item)"
  >
    <div style="height: 100%" class="li-text">
      <p class="mb-0" style="font-size: 13px" :color="isActiveSetupTab ? 'primary' : 'grey'">
        <v-icon :size="16" class="mr-1" :color="isActiveSetupTab ? 'primary' : 'grey'">
          {{ item.icon || alternateIcon }}
        </v-icon>
        {{ textFormat(item.title) }}
      </p>
    </div>
  </li>
</template>

<script>
import useActiveLink from '@core/layouts/composable/horizontal-tab/useActiveLink'
import themeConfig from '@themeConfig'
import { mdiChevronDown, mdiClose } from '@mdi/js'
import useResizer from '@core/layouts/composable/horizontal-tab/useResizer'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    pinned: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { isActiveSetupTab } = useActiveLink(props.item)
    const { CANCEL_TAB_REQUEST } = storeActions

    const closeTab = () => {
      store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')
      store.dispatch(`tabs/${CANCEL_TAB_REQUEST}`, props.item)
      useResizer()
    }
    const textFormat = text => {
      if (text.length > 21) {
        return `${text.substring(0, 21)}..`
      }

      return text
    }

    return {
      isActiveSetupTab,
      closeTab,
      textFormat,
      // alternate icons
      alternateIcon: themeConfig.menu.groupChildIcon,
      icons: {
        mdiChevronDown,
        mdiClose,
      },
    }
  },
}
</script>
<style scoped>
.nav-list {
  list-style: none;
  min-width: 180px;
  width: 180px;
  max-width: 220px;
  height: 42px;
  border-right: thin solid #e3e1e1;

  color: black;
}
.nav-list.mainlist.active {
  border-top: 4px solid #fe724c;
  border-bottom: 0px;
}
.nav-list .li-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.nav-list.mainlist.active .li-text {
  background: #f5f5f5;
  color: black;
  margin-top: 2px;
}
.nav-list.active {
  background: #fff;
  color: black;
}
</style>
