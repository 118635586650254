<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What We Provide In Task Management
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Dashboard</p>
        <p class="dark--text">
          The Task Management dashboard keeps you and your team on the toe. How? By helping you keep
          track of the total number of tasks, completed tasks, and overdue tasks. This way, you know
          where and how to prioritize your time to get your tasks done.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Folders</p>
        <p class="dark--text">
          You can organize all related tasks using different folders. Then, you can easily navigate
          through the folders to track your task status and share access with your team members.
        </p>
      </div>
    </div>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Reports</p>
        <p class="dark--text">
          The best way to track your progress on your projects is to use this tab. You'll receive a
          list of all your tasks, along with a Kanban view that allows you to categorize tasks under
          different phases such as CRM and Estimation workflow etc. This feature helps you visually
          understand your tasks and includes a calendar to track your activities.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Shared</p>
        <p class="dark--text">
          This feature allows users to collaborate and share files and folders. Multiple members of
          your team can access and work on the same document in real time, making it easier to
          complete projects on time.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
