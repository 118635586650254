<template>
  <v-list nav dense class="px-9 py-7">
    <h2 class="dark--text font-weight-semibold text-xl">APPLICATIONS</h2>
    <v-divider class="mediumGrey mt-3"></v-divider>
    <div class="grid mt-6">
      <div
        v-for="(app, index) in launcherApps.purchasedBaseApps"
        :key="index"
        :data-cy="app.value"
        class="child text-center py-4 px-2 cursor-pointer"
        @click="gotoApp(app)"
      >
        <v-img :src="app.icon" width="40px" class="mx-auto" height="40px"></v-img>
        <p class="dark--text mb-0 mt-3 text-sm">{{ app.title }}</p>
      </div>
    </div>

    <h2 class="dark--text mt-5 font-weight-semibold text-xl">MANAGEMENT APPLICATIONS</h2>
    <v-divider class="mediumGrey mt-3"></v-divider>

    <div class="grid mt-5">
      <div
        v-for="(app, index) in launcherApps.purchasedManagementApps"
        :key="index"
        class="child text-center py-4 px-2 cursor-pointer"
        @click="gotoApp(app)"
      >
        <div class="justify-self-center">
          <v-img :src="app.icon" width="40px" class="mx-auto"></v-img>
          <p class="dark--text mb-0 mt-3 text-sm">{{ app.title }}</p>
        </div>
      </div>
    </div>
  </v-list>
</template>
<script>
import CrmSvg from '@/assets/images/svg/CRM.svg'
import Estimation from '@/assets/images/svg/Estimation.svg'
import Security from '@/assets/images/svg/Security.svg'
import Setup from '@/assets/images/svg/Setup.svg'
import workDrive from '@/assets/images/svg/workDrive.svg'
import { mdiClose } from '@mdi/js'
import { useRouter } from '@core/utils'
import store from '@/store'
import { storeActions, storeGetters } from '@/store/storeConstants'
import { computed } from '@vue/composition-api'

const { GET_LAUNCHER_APP } = storeGetters
export default {

  setup(_, { emit }) {
    const {
      ESTIMATE_TAB_REQUEST,
      SETUP_TAB_REQUEST,
      REPORT_TAB_REQUEST,
      DASHBOARD_TAB_REQUEST,
    } = storeActions
    const { router } = useRouter()

    const gotoApp = item => {
      if (item.title === 'CRM') {
        store.dispatch('tabPersist/SET_TAB_STATE', { attribute: 'CRM Dashboard' })
      }
      if (item.title === 'Estimation') {
        store.dispatch(`setupTab/${ESTIMATE_TAB_REQUEST}`, { attribute: 'TakeOffs' })
      }
      if (item.title === 'Setup') {
        store.dispatch(`setupTab/${SETUP_TAB_REQUEST}`, { attribute: 'CRM' })
      }
      if (item.title === 'Reports') {
        store.dispatch(`setupTab/${REPORT_TAB_REQUEST}`, { attribute: 'Reports' })
      }
      if (item.title === 'Dashboard') {
        store.dispatch(`setupTab/${DASHBOARD_TAB_REQUEST}`, { attribute: 'Dashboards' })
      }
      if (item.title === 'Vendor Management') {
        store.dispatch('tabPersist/SET_TAB_STATE', { attribute: 'Vendors' })
      }

      router.push(item.to).catch(() => {})
      emit('close')
    }

    const launcherApps = computed(() => store.getters[`auth/${GET_LAUNCHER_APP}`])

    const fetchApps = () => {
      const { purchasedBaseApps, purchasedManagementApps } = launcherApps.value
      if (purchasedBaseApps.length || purchasedManagementApps.length) return

      store
        .dispatch(`auth/${storeActions.FETCH_APPS}`)
    }

    fetchApps()

    return {
      // purchasedBaseApps,
      // purchasedManagementApps,
      gotoApp,
      // icons
      CrmSvg,
      launcherApps,
      Estimation,
      Security,
      Setup,
      workDrive,
      mdiClose,
    }
  },
}
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  align-items: center;
  /* grid-template-rows: repeat(3, calc(100%/3)); */
}

.child {
  border: 1px solid #f8ebe7;
  box-shadow: 0px 14px 18px rgba(225, 119, 89, 0.09);
  border-radius: 10px;
  height: 100%;
}
</style>
