const admin = [
    {
      path: '/admin/dashboard',
      name: 'admin-dashboard',
      component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/admin-console/Dashboard.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
      },
    },
    {
      path: '/admin/customer',
      name: 'customer-license',
      component: () => import(/* webpackChunkName: "customer-license" */ '@/views/admin-console/customer-lisence/Customer.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
  
    {
      path: '/admin/knowledge-base',
      name: 'knowledge-base',
      component: () => import(/* webpackChunkName: "customer-alerts" */ '@/views/admin-console/customer-lisence/KnowledgeBase.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/knowledge-base/knowledge-base-form',
      name: 'knowledge-base-form',
      component: () => import(/* webpackChunkName: "knowledge-base-form" */ '@/views/admin-console/customer-lisence/KnowledgeBaseForm.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
      {
      path: '/admin/knowledge-base/knowledge-base-form/:uuid/Edit',
      name: 'edit-knowledge-base-form',
      component: () => import(/* webpackChunkName: "knowledge-base-form" */ '@/views/admin-console/customer-lisence/KnowledgeBaseForm.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/customer-alert/knowledge-base-detail/:topic_id/:uuid',
      name: 'knowledge-base-detail',
      component: () => import(/* webpackChunkName: "knowledge-base-form" */ '@/views/admin-console/customer-lisence/KnowledgeBaseDetail.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
      },
    },
    {
      path: '/admin/coupon',
      name: 'coupon',
      component: () => import(/* webpackChunkName: "create-coupon" */ '@/views/admin-console/customer-lisence/Coupon.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/pending-activation',
      name: 'pending-activation',
      component: () => import(/* webpackChunkName: "pending-activation" */ '@/views/admin-console/customer-lisence/PendingActivation.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/offline-purchase',
      name: 'offline-purchase',
      component: () => import(/* webpackChunkName: "offline-purchase" */ '@/views/admin-console/customer-lisence/OfflinePurchase.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/approval-request',
      name: 'approval-request',
      component: () => import(/* webpackChunkName: "approval-request" */ '@/views/admin-console/customer-lisence/ApprovalRequest.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/approval-history',
      name: 'approval-history',
      component: () => import(/* webpackChunkName: "approval-history" */ '@/views/admin-console/customer-lisence/ApprovalHistory.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/support/',
      name: 'admin-support',
      component: () => import(/* webpackChunkName: "support" */ '@/views/admin-console/Support.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },   
    {
      path: '/admin/support/support-ticket/:support_ticket_id/:uuid',
      name: 'admin-support-detail',
      component: () => import(/* webpackChunkName: "SupportDetail" */ '@/components/admin-console/support/SupportDetail.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    }, 
  
    {
      path: '/admin/support-ticket/:uuid/update',
      name: 'update-admin-support-ticket',
      component: () => import(/* webpackChunkName: "support" */ '@/views/admin-console/SupportTicket.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/support-ticket',
      name: 'admin-support-ticket',
      component: () => import(/* webpackChunkName: "support" */ '@/views/admin-console/SupportTicket.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/escalation',
      name: 'escalation',
      component: () => import(/* webpackChunkName: "escalation" */ '@/views/admin-console/Escalation.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/escalation/escalation-ticket/:escalation_ticket_id/:uuid',
      name: 'escalation-detail',
      component: () => import(/* webpackChunkName: "SupportDetail" */ '@/components/admin-console/escalation/EscalationDetail.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    }, 
    {
      path: '/admin/escalation-ticket',
      name: 'escalation-ticket',
      component: () => import(/* webpackChunkName: "escalation-ticket" */ '@/views/admin-console/EscalationTicket.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
    {
      path: '/admin/escalation-ticket/:uuid/update',
      name: 'update-escalation-ticket',
      component: () => import(/* webpackChunkName: "escalation-ticket" */ '@/views/admin-console/EscalationTicket.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
    },
  
    {
      path: '/admin/customer/:uuid/',
      name: 'CustomerView',
      component: () => import(/* webpackChunkName: "security" */ '@/components/admin-console/customer/CustomerView.vue'),
      meta: {
        layout: 'content',
        app: 'adminConsole',
        appId: 18,
      },
      redirect: () => ({
        name: 'Dashboard',
      }),
      children: [
        {
          path:'dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/components/admin-console/customer/Dashboard.vue'),
          meta: {
            layout: 'content',
            app: 'adminConsole',
            appId: 18,
          },
  
        },
        {
          path:'invoice',
          component: () => import(/* webpackChunkName: "invoice" */ '@/components/admin-console/customer/Invoice.vue'),
          name: 'Invoice',
          meta: {
            layout: 'content',
            app: 'adminConsole',
            appId: 18,
          },
        },
        {
          path:'payment',
          component: () => import(/* webpackChunkName: "payment" */ '@/components/admin-console/customer/Payment.vue'),
          name: 'Payment',
          meta: {
            layout: 'content',
            app: 'adminConsole',
            appId: 18,
          },
        },
        {
          path:'usage',
          component: () => import(/* webpackChunkName: "usage" */ '@/components/admin-console/customer/Usage.vue'),
          name: 'Usage',
          meta: {
            layout: 'content',
            app: 'adminConsole',
            appId: 18,
          },
        },
      ]
      
    },
  
  ]
  
  export default admin
  