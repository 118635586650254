import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { mappingAppsWithId } from '@/plugins/authService'
import crm from './crm'
import map from './map'
import workDrive from './work-drive'
import setUp from './set-up'
import security from './security'
import estimation from './estimation'
import taskManagement from './task-management'
import vendorManagement from './vendor-management'
import materialManagement from './material-management'
import projectManagement from './project-management'
import report from './report'
import email from './email'
import goals from './goal-management'
import inventory from './inventory-management'
import accounting from './accounting'
import dashboard from './dashboard'
import support from './support'
import forms from './forms'
import pos from './pos'
import admin from './admin-console'
import partnerPortal from './partner-portal'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes.ACL is based on abilities.
  {
    path: '/',
    redirect: () => {
      return { name: 'user-dashboard' }
    },
  },
  {
    path: '/setup/master-dashboard',
    name: 'user-dashboard',
    component: () => import('@/views/dashboards/MasterDashboard.vue'),
    meta: {
      layout: 'horizontal',
      resource: 'Public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/resetPassword',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('@/views/profile/UserProfile.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/download/:uuid',
    name: 'report-download',
    component: () => import('@/views/DownloadReport.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/access/:token/:id?',
    name: 'Public-Url',
    component: () => import('@/views/work-drive/PublicUrl.vue'),
    meta: {
      layout: 'blankNav',
      resource: 'Public',
      publicAccessUrl: true,
    },
  },
  {
    path: '/calculator',
    name: 'Public-calculator',
    component: () => import('@/views/Calculator.vue'),
    meta: {
      layout: 'blankNav',
      resource: 'Public',
      publicAccessUrl: true,
    },
  },
  {
    path: '/under',
    name: 'under-maintanance',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/configure-module',
    name: 'configure-module',
    component: () => import('@/views/add-module/ConfigureModule.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  ...crm,
  ...workDrive,
  ...setUp,
  ...security,
  ...estimation,
  ...taskManagement,
  ...vendorManagement,
  ...materialManagement,
  ...projectManagement,
  ...report,
  ...email,
  ...goals,
  ...dashboard,
  ...map,
  ...accounting,
  ...inventory,
  ...forms,
  ...support,
  ...admin,
  ...partnerPortal,
  ...pos,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, from, next) => {
  store.commit('app/ROUTER_LOADER', true)

  const isLoggedIn = localStorage.getItem('token')
  if (isLoggedIn) {
    const appType = store.getters['auth/DECODE_TOKEN_INFO']
    // partner portal route protection
    if (appType && appType.is_partner) {
      if (to.meta.redirectIfLoggedIn) {
        return next('/partner/dashboard')
      }
      if (to.meta.app === 'partnerPortal') {
        return next()
      }

      return next('/partner/dashboard')
    }
    // if app is not partner than user is accessing to partner redirect the user
    if (appType && !appType.is_partner && to.meta.app === 'partnerPortal') {
      return next('/')
    }
  }

  // Redirect to login if not logged in
  if (to.meta.redirectIfLoggedIn && !isLoggedIn) {
    return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }

  if (!isLoggedIn && !to.meta.publicAccessUrl) {
    return next({ name: 'auth-login' })
  }

  const canNavigate = () => {
    const permission = store.getters['auth/GET_ALL_PERMISSIONS']
    const decodeToken = store.getters['auth/DECODE_TOKEN_INFO']
    let isAdmin = false
    if (decodeToken && decodeToken.a === 1) {
      isAdmin = true
    }
    const purchasedApp = store.getters['auth/GET_PURCHASE_APP']

    // root user permission
    if (isAdmin) {
      // allowed routes other than appId eg not authorized route
      if (!to.meta.appId) return true

      if (Array.isArray(purchasedApp) && purchasedApp.indexOf(to.meta.appId) !== -1) {
        return true
      }

      return false
    }
    try {
      const keys = Object.keys(permission)
      const appIdArray = mappingAppsWithId(keys)
      if (!to.meta.appId) return true

      if (appIdArray.indexOf(to.meta.appId) !== -1) {
        if (to.meta.permission) {
          if (
            permission &&
            permission[to.meta.app] &&
            permission[to.meta.app][to.meta.permission] &&
            permission[to.meta.app][to.meta.permission][to.meta.permissionType] === 1
          ) {
            return true
          }

          return false
        }

        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  if (!canNavigate()) {
    return next()
  }

  return next()
})

router.afterEach(() => {
  store.commit('app/ROUTER_LOADER', false)
})

export default router
