import { render, staticRenderFns } from "./LayoutMoveable.vue?vue&type=template&id=41bd1a1f&scoped=true&"
import script from "./LayoutMoveable.vue?vue&type=script&lang=js&"
export * from "./LayoutMoveable.vue?vue&type=script&lang=js&"
import style0 from "./LayoutMoveable.vue?vue&type=style&index=0&id=41bd1a1f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bd1a1f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VAppBar,VIcon,VMain,VNavigationDrawer})
