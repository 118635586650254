<template>
  <div style="width: 100%">
    <div
      style="position: relative"
      :class="[
        'pl-4 d-flex align-center crm-tab-bar',
        rootThemeClasses,
        isBorderVisible ? 'tab-border' : '',
      ]"
    >
      <h4 class="mr-5" style="color: #e47911 !important; width: 40px">CRM</h4>
      <component
        :is="resolveNavHeaderComponent(item)"
        v-for="item in navMenuItems"
        :key="item.header || item.title"
        :item="item"
      />
      <div class="custom-scrollbar -primary">
        <horizontal-tab-menu-header-link
          v-for="item in workSpace"
          :key="item.header || item.title"
          class="justify-center"
          :item="item"
          @tabClick="tabClick"
        />
        <horizontal-tab-header-dropdown :items="workSpace"> </horizontal-tab-header-dropdown>
      </div>
    </div>
    <div :class="['pl-4 d-flex align-center', rootThemeClasses]">
      <div class="custom-scrollbar d-flex">
        <SubtabMenuHeaderLink
          v-for="item in subTabArr"
          :key="item.header || item.title"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalTabMenuHeaderLink from '@core/layouts/components/horizontal-tab-menu/components/HorizontalTabMenuHeaderLink.vue'
import SubtabMenuHeaderLink from '@core/layouts/components/horizontal-tab-menu/components/SubtabMenuHeaderLink.vue'
import HorizontalTabHeaderDropdown from '@core/layouts/components/horizontal-tab-menu/components/HorizontalTabHeaderDropdown'
import HorizontalTabMenuHeaderGroup from '@core/layouts/components/horizontal-tab-menu/components/horizontal-tab-menu-header-group/HorizontalTabMenuHeaderGroup'
import useVuetify from '@core/utils/vuetify'
import { computed, onMounted, onUnmounted } from '@vue/composition-api'
import useResizer from '@core/layouts/composable/horizontal-tab/useResizer'
import { storeGetters } from '@/store/storeConstants'
import navMenuItems from '@/navigation/tab'
import { useRouter } from '@core/utils'

export default {
  components: {
    HorizontalTabMenuHeaderLink,
    HorizontalTabMenuHeaderGroup,
    HorizontalTabHeaderDropdown,
    SubtabMenuHeaderLink,
  },
  props: {
    isBorderVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root }) {
    const { GET_WORKSPACE_TAB, GET_SUB_TAB } = storeGetters
    const { router } = useRouter()

    const resizeHandler = () => {
      useResizer()
    }
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })
    onMounted(() => {
      window.addEventListener('resize', resizeHandler)
      setTimeout(() => {
        useResizer()
      }, 500)
    })
    const workSpaceArr = computed(() => root.$store.getters[`tabs/${GET_WORKSPACE_TAB}`])
    const subTabArr = computed(() => root.$store.getters[`tabs/${GET_SUB_TAB}`])
    const workSpace = computed(() => [...workSpaceArr.value])
    const { rootThemeClasses } = useVuetify()
    const resolveNavHeaderComponent = item => {
      if (item.children) return 'horizontal-tab-menu-header-group'
      if (item.tabChild) return 'horizontal-tab-header-dropdown'

      return 'horizontal-tab-menu-header-link'
    }

    const tabClick = item => {
      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})

      setTimeout(() => {
        useResizer()
      }, 0)
    }

    return {
      resolveNavHeaderComponent,
      rootThemeClasses,
      workSpace,
      navMenuItems,
      subTabArr,
      tabClick,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.tab-border {
  border-bottom: 2px solid rgba(231, 227, 252, 0.87) !important;
}

.horizontal-nav-menu {
  // ? As v-menu is sibling of button activator we also have to exclude it
  > *:not(:last-child):not(.v-menu) {
    @include ltr() {
      margin-right: 8px;
    }
    @include rtl() {
      margin-left: 8px;
    }
  }
  > .v-btn {
    .v-icon {
      transition: none !important;
    }
  }

  // Fixes: Items drop on second row
  @media screen and (max-width: 1400px) {
    .v-application .v-app-bar.v-toolbar.navigation-menu .v-toolbar__content & {
      padding: 0 !important;
      display: flex;
      justify-content: center;

      > .v-btn {
        @include ltr() {
          margin-right: 0 !important;
        }
        @include rtl() {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.v-application.horizontal-nav {
  .v-system-bar {
    .app-bar-autocomplete-box {
      left: 3rem !important;
    }
  }
}
</style>
