import { storeActions } from '@/store/storeConstants'

export default {
  namespaced: true,
  state: {
    openTaskBar: false,
    taskBarId: null,
    spaces: [],
    selectedSpace: null,
  },
  getters: {
    getOpenTaskBar: state => state.openTaskBar,
    getTaskBarId: state => state.taskBarId,
    getSpaces: state => state.spaces,
    getSelectedSpace: state => state.selectedSpace,
    getSelectedSpaceInfo: state => {
      return state.spaces.find(opt => opt.uuid === state.selectedSpace)
    },
    hasEditAndFullAccess: state => {
      const findSpace = state.spaces.find(opt => opt.uuid === state.selectedSpace)
      if (!findSpace) return false
      if (!findSpace.permission) return false
      // 1 is read, 2 is editor, 3-4 is full or admin access
      if ([2, 3, 4].includes(findSpace.permission)) {
        return true
      }

      return false
    },
    hasFullAndAdminAccess: state => {
      const findSpace = state.spaces.find(opt => opt.uuid === state.selectedSpace)
      if (!findSpace) return false
      if (!findSpace.permission) return false
      // 1 is read, 2 is editor, 3-4 is full or admin access
      if ([3, 4].includes(findSpace.permission)) {
        return true
      }

      return false
    },
  },
  mutations: {
    SET_TASKBAR_ID(state, payload) {
      state.taskBarId = payload
    },
    SET_OPEN_BAR(state, payload) {
      state.openTaskBar = payload
    },
    SET_SPACE_DATA(state, payload) {
      state.spaces = [...payload, { name: 'Shared with me', uuid: '-1', type: 'custom' }]
    },
    SET_SELECTED_SPACE(state, payload) {
      state.selectedSpace = payload
    },
  },
  actions: {
    FETCH_SPACES: ({ commit, dispatch }) => {
      const payload = {
        component: 'task-management/spaces',
      }
      dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload, { root: true }).then(
        ({ data }) => {
          commit('SET_SPACE_DATA', data.data)
        },
      )
    },
  },
}
