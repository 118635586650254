import { storeGetters } from '@/store/storeConstants'
import { bldonApps } from '@/constants/appConstants'
import store from '../store'

export const isAdmin = () => {
  const decodeToken = store.getters[`auth/${storeGetters.DECODE_TOKEN_INFO}`]

  if (decodeToken && decodeToken.a === 1) {
    return true
  }

  return false
}

export const mappingAppsWithId = apps => {
  const mappingValue = {
    crm: bldonApps.crm.value,
    workdrive: bldonApps.workdrive.value,
    security: bldonApps.security.value,
    setup: bldonApps.setup.value,
    vendorManagement: bldonApps.vendorManagement.value,
    materialManagement: bldonApps.materialManagement.value,
    inventoryManagement: bldonApps.inventoryManagement.value,
    estimation: bldonApps.estimation.value,
    taskManagement: bldonApps.taskManagement.value,
    projectManagement: bldonApps.projectManagement.value,
    report: bldonApps.reports.value,
    email: bldonApps.email.value,
    goalManagement: bldonApps.goalManagement.value,
    dashboards: bldonApps.dashboards.value,
    map: bldonApps.map.value,
    pos: bldonApps.pos.value,
  }

  // FIXME: adding reports will remove it once API will have report
  apps.push('reports')
  apps.push('dashboards')

  return apps.map(opt => {
    if (mappingValue[opt]) {
      return mappingValue[opt]
    }

    return 0
  })
}
