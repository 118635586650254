<template>
    <div class="px-12 mb-8">
      <p class="dark--text font-weight-semibold" style="font-size: 26px">How to manage Field Management</p>

      <div class="d-flex" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Site Plans</p>
          <p class="dark--text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor hic totam adipisci repudiandae voluptas quod ducimus? Laborum sit, omnis ipsum hic vero accusantium magnam quaerat. Eum voluptates corrupti pariatur facilis!
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Forms</p>
          <p class="dark--text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor hic totam adipisci repudiandae voluptas quod ducimus? Laborum sit, omnis ipsum hic vero accusantium magnam quaerat. Eum voluptates corrupti pariatur facilis!
          </p>
        </div>
      </div>

      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Gallery</p>
          <p class="dark--text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor hic totam adipisci repudiandae voluptas quod ducimus? Laborum sit, omnis ipsum hic vero accusantium magnam quaerat. Eum voluptates corrupti pariatur facilis!
          </p>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
  <style scoped>
  .w-half {
    width: 50%;
  }
  </style>
