export default {
  namespaced: true,
  state: {
    openPasswordProtectedModal: false,
  },
  getters: {
    getPasswordProtectedModalState: state => state.openPasswordProtectedModal,
  },
  mutations: {
    setPasswordProtectedState(state, value) {
      state.openPasswordProtectedModal = value
    },
  },
  actions: {},
}
