import {
  mdiAccountSupervisorCircle,
  mdiAccountTie,
  mdiDelete,
  mdiPlus,
  mdiCircleEditOutline,
  mdiLinkOff,
  mdiPlaylistPlus,
  mdiContentCopy,
  mdiInformation,
  mdiReload,
} from '@mdi/js'
import { required, minValue, minDecimalValue } from '@/@core/utils/validation'

export default {
  headerTabs: {
    tasks: {
      tabs: [
        {
          id: 'uQ4HUsfrdG3C9mmEx4LQDz',
          key: 'materials',
          icon: mdiAccountSupervisorCircle,
          subTab: [],
          title: 'Material Management',
          to: 'material-management',
        },
        {
          id: 'uQ4H4sUdG3CGmmtrx4LQDz',
          key: 'material',
          icon: mdiAccountTie,
          subTab: [],
          title: 'Material Brand',
          to: 'material',
        },
        {
          id: 'uQ4H4sUdG3CGmmtrx4LQDz',
          key: 'assembly-note',
          icon: mdiAccountTie,
          subTab: [],
          title: 'Assembly Notes',
          to: 'assemblyNotes',
        },
        {
          id: 'uQ4H4sUd0Gmmtrx4LQDz',
          key: 'material-assemblies',
          icon: mdiAccountTie,
          subTab: [],
          title: 'Assemblies',
          to: 'material-assemblies',
        },
        {
          id: 'uQ4H4sUd0Gmmtrx4LQDz',
          key: 'material-recent-changes',
          icon: mdiAccountTie,
          subTab: [],
          title: 'Recent Changes',
          to: 'material-recent-changes',
        },
      ],
      subTabs: [],
    },
  },
  material: {
    title: 'Material',
    url: 'material/list',
    key: 'uuid',
    module: 'material',
    app: 'materialManagement',
    showSelect: true,
    showCreateBtn: true,
    actions: [{ icon: mdiInformation, title: 'W/ Identifiers', ability: 'i' }, { icon: mdiInformation, title: 'W/O Identifiers', ability: 'wi' },
    { icon: mdiInformation, title: 'Mark Active/Inactive', ability: 'mai' },
    { icon: mdiReload, title: 'Reset', ability: 'r' }, { icon: mdiDelete, title: 'Delete', ability: 'd' }],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Code', value: 'code', align: 'start' },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', key: 'code', label: 'Code', validations: [required] },
      { type: 'textbox', key: 'escalation', label: 'Est. Escalation', validations: [required] },
      { type: 'dropdown', key: 'unit_of_measure', label: 'Unit Of Measure', validations: [required] },
      { type: 'dropdown', key: 'brand_id', label: 'Brand', validations: [required] },
      { type: 'dropdown', key: 'parent_id', label: 'Parent Group', validations: [required] },
    ],
  },
  brand: {
    title: 'Brand',
    url: 'material/brands',
    key: 'uuid',
    module: 'account',
    showSelect: true,
    showCreateBtn: true,
    app: 'materialManagement',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Description', value: 'description', align: 'start' },
      { text: 'Action', value: 'action', align: 'start' },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', key: 'email', label: 'Email', validations: [required] },
      { type: 'textarea', key: 'summary', label: 'Summary', validations: [required] },
    ],
  },
  assemblyNotes: {
    title: 'Assembly Notes',
    url: 'material/assembly/notes',
    key: 'uuid',
    module: 'account',
    showSelect: true,
    showCreateBtn: true,
    app: 'materialManagement',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Description', value: 'description', align: 'start' },
      { text: 'Action', value: 'action', align: 'start' },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'textarea', key: 'description', label: 'Description', validations: [required] },
    ],
  },
  category: {
    title: 'Category',
    url: 'material/categories/',
    key: '_id',
    module: 'account',
    showSelect: true,
    showCreateBtn: true,
    app: 'materialManagement',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start', width: '150px' },
      { text: 'Description', value: 'description', align: 'start' },
    ],
  },
  assemblies: {
    title: 'Assemblies',
    url: 'material/assembly/list',
    key: 'uuid',
    module: 'account',
    showSelect: true,
    showCreateBtn: true,
    app: 'materialManagement',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiInformation, title: 'Mark Active/Inactive', ability: 'mai' },
      { icon: mdiContentCopy, title: 'Copy', ability: 'c' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Code', value: 'code', align: 'start' },
      { text: '# of Materials', value: 'noOfMaterial', align: 'start' },
    ],
    formFields: [],
  },
  assembliesMaterial: {
    title: 'Assembly Materials',
    url: 'material/assemblies',
    key: 'mapping_id',
    module: 'account',
    app: 'materialManagement',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiPlaylistPlus, title: 'Add', ability: 'a' },
      { icon: mdiLinkOff, title: 'Detach', ability: 'e' },
    ],
    headers: [
      { text: 'Code', value: 'code', align: 'start' },
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Take Off type', value: 'take_off_type', align: 'start' },
    ],
    formFields: [],
  },
  materialInfoImport: {
    title: 'Import Materials Information',
    url: 'crm/import/materialsprice',
    key: 'uuid',
    module: 'material',
    app: 'materialManagement',
    formFields: [
      {
        type: 'textbox',
        key: 'material_id',
        label: 'Material',
        options: [],
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'vendor_id',
        label: 'Vendor',
        options: [],
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'price',
        label: 'Price',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'cushion',
        label: 'Cushion (0-100)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'freight_percentage',
        label: 'Freight Percentage(0-100)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'duty_percentage',
        label: 'Duty Percentage(0-100)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'packing_percentage',
        label: 'Packing Percentage(0-100)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'tariff_percentage',
        label: 'Tariff Percentage(0-100)',
        validations: [required],
      },
      {
        type: 'autocomplete',
        key: 'landed_price',
        label: 'Landed Price',
        validations: [required],
      },
      {
        type: 'autocomplete',
        key: 'estimate_price',
        label: 'Estimate Price',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'sell_price',
        label: 'Sell Price',
        validations: [],
      },
      {
        type: 'textbox',
        key: 'lead_time',
        label: 'Delivery lead time(days)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'length',
        label: 'Length (Inches)',
        validations: [],
      },
      {
        type: 'textbox',
        key: 'breath',
        label: 'Width (Inches)',
        validations: [],
      },
      {
        type: 'textbox',
        key: 'height',
        label: 'Height',
        validations: [],
      },
      {
        type: 'textbox',
        key: 'weight',
        label: 'Weight',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'volume',
        label: 'Volume (Number)',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'order_quantity',
        label: 'Order Quantity',
        validations: [required],
      },
      {
        type: 'date',
        key: 'last_price_date',
        label: 'Last Price Date',
        validations: [],
      },
      {
        type: 'textbox',
        key: 'prefered_vendor_id',
        label: 'Prefered Vendor',
        options: [],
        validations: [required],
      },
    ],
  },
  labor_productivity: {
    title: 'Labor Productivity',
    url: 'setup/materials/labor-productivity',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Code', value: 'code', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', field_type: 'text', key: 'code', label: 'Code', validations: [required] },
      { type: 'dropdown', key: 'unit', label: 'Unit', options: [{ text: 'Hours', value: 'h'}, { text: 'Days', value: 'D'}, { text: 'Weeks', value: 'w'}, { text: 'Months', value: 'm'}] , validations: [required] },
      { type: 'textbox', field_type: 'number', key: 'value', label: 'Value', validations: [required, minValue] },
    ],
  },
  labor_cost: {
    title: 'Labor Cost',
    url: 'setup/materials/labor-cost',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Unit', value: 'unit', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'unit', label: 'Unit', options: [{ text: 'Hours', value: 'h'}, { text: 'Days', value: 'D'}, { text: 'Weeks', value: 'w'}, { text: 'Months', value: 'm'}, { text: 'Unit', value: 'u'}] , validations: [required] },
      { type: 'textbox', field_type: 'number', key: 'regular_cost', suffix: '$', label: 'Regular Cost', validations: [required, minDecimalValue] },
      { type: 'textbox', field_type: 'number', key: 'overtime_cost', suffix: '$',label: 'Overtime Cost', validations: [required, minDecimalValue] },
      { type: 'textbox', field_type: 'number', key: 'double_overtime_cost', suffix: '$',label: 'Double Overtime Cost', validations: [required, minDecimalValue] },
      { type: 'textbox', field_type: 'number', key: 'nightshift_cost', suffix: '$',label: 'Nightshift Cost', validations: [required, minDecimalValue] },
    ],
  },
  labor_cost_template: {
    title: 'Labor Cost Template',
    url: 'setup/materials/labor-cost-template',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Description', value: 'description', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', key: 'description', label: 'Description', validations: [required] },
    ],
  },
  labor_cost_item: {
    title: 'Labor Cost Item',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'formula', value: 'formula', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'labor_costs', label: 'Labor Costs', validations: [required] },
      { type: 'formula', key: 'formula', label: 'Formula', validations: [required] },
    ],
  },
}
