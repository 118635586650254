<template>
  <v-app
    :class="[
      { 'nav-drawer-mini': menuIsVerticalNavMini },
      { 'content-full': appContentWidth === 'full' },
    ]"
    class="content-layout vertical-nav"
  >
    <!-- sidebar -->
    <v-navigation-drawer
      ref="layoutDrawer"
      v-model="isVerticalNavMenuActive"
      app
      mini-variant-width="60"
      :mini-variant.sync="menuIsVerticalNavMini"
      :width="sidebarWidth"
      class="primary white--text"
    >
      <TaskNavMenu
        :nav-menu-items="navMenuItems"
        @close-nav-menu="isVerticalNavMenuActive = false"
      ></TaskNavMenu>
    </v-navigation-drawer>
    <transition name="fade">
      <div
        v-show="!menuIsVerticalNavMini && !$vuetify.breakpoint.mdAndDown"
        id="vl-drag"
        ref="vlDrag"
        class="vl-drag"
      ></div>
    </transition>
    <v-navigation-drawer
      v-model="openTaskBar"
      temporary
      overlay-opacity="0.1"
      app
      right
      width="720"
    >
      <ViewTaskBar v-if="openTaskBar" />
    </v-navigation-drawer>
    <!-- App laucher -->
    <v-navigation-drawer v-model="launcherDrawer" temporary app right width="450">
      <div class="text-right my-3 mx-9">
        <v-icon color="dark" @click="launcherDrawer = false">{{ mdiClose }}</v-icon>
      </div>
      <Launcherdrawer @close="launcherDrawer = false" />
    </v-navigation-drawer>

    <!-- TODO: top navbar -->
    <v-app-bar
      v-if="appBarType !== 'hidden'"
      ref="refAppBar"
      app
      :absolute="appBarType === 'static'"
      flat
      :elevate-on-scroll="appBarType !== 'static'"
      :elevation="appBarType !== 'static' ? ($vuetify.theme.isDark ? 4 : 3) : 0"
      class="mx-auto app-bar-static"
      :class="[
        { 'px-5 app-bar-shinked': appBarType === 'fixed' && scrollY },
        { 'bg-blur': appBarIsBlurred && appBarType === 'fixed' && scrollY },
      ]"
    >
      <slot
        name="navbar"
        :isVerticalNavMenuActive="isVerticalNavMenuActive"
        :toggleVerticalNavMenuActive="toggleVerticalNavMenuActive"
        :launcherDrawer="setLauncherDrawer"
      ></slot>
    </v-app-bar>
    <v-main :style="{ paddingLeft: $vuetify.breakpoint.mdAndDown ? 0 : `${sidebarWidth}px` }">
      <app-content-container>
        <slot />
      </app-content-container>
    </v-main>
  </v-app>
</template>

<script>
import { onMounted, onUnmounted, ref, watch, computed } from '@vue/composition-api'
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import { getVuetify } from '@/@core/utils'
import useAppConfig from '@core/@app-config/useAppConfig'
import TaskNavMenu from '@core/layouts/components/task-nav-menu/TaskNavMenu.vue'
import { useWindowScroll } from '@vueuse/core'
import { mdiClose } from '@mdi/js'
import Launcherdrawer from '@/components/shared-components/Launcherdrawer.vue'
import store from '@/store'
import ViewTaskBar from '@/components/task-management/ViewTaskBar.vue'

export default {
  components: {
    AppContentContainer,
    TaskNavMenu,
    Launcherdrawer,
    ViewTaskBar,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const launcherDrawer = ref(false)
    const vlDrag = ref(null)
    const mainWrapper = ref(null)
    const mini = ref(false)
    const startDrag = ref(false)
    const sidebarWidth = ref(260)
    const setLauncherDrawer = () => {
      launcherDrawer.value = !launcherDrawer.value
    }

    const openTaskBar = computed({
      get() {
        return store.getters['taskManagement/getOpenTaskBar']
      },
      set(value) {
        store.commit('taskManagement/SET_OPEN_BAR', value)
      },
    })

    function dragElement(event) {
      const maxWidth = window.innerWidth / 2
      if (event.clientX < maxWidth && event.clientX > 240) {
        vlDrag.value.style.left = `${event.clientX - 3}px`
      }
    }

    const onMouseDown = () => {
      startDrag.value = true
      document.addEventListener('mousemove', dragElement)
    }

    const onMouseUp = () => {
      if (startDrag.value) {
        sidebarWidth.value = `${vlDrag.value.offsetLeft}`
        document.removeEventListener('mousemove', dragElement)
        startDrag.value = false
      }
    }

    onMounted(() => {
      vlDrag.value.addEventListener('mousedown', onMouseDown)
      document.addEventListener('mouseup', onMouseUp)
    })

    onUnmounted(() => {
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', dragElement)
    })

    const group = ref(false)
    /* eslint-disable object-curly-newline, operator-linebreak */
    const {
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
    } = useAppConfig()
    const $vuetify = getVuetify()

    const isVerticalNavMenuActive = ref(true)
    const isActiveTaskBar = ref(false)
    const sharedFolderView = ref(false)

    watch(
      () => isVerticalNavMenuActive.value,
      () => {
        if (!isVerticalNavMenuActive.value) {
          isActiveTaskBar.value = false
        }
      },
    )
    // TODO: Check do we need below watch
    watch(
      () => $vuetify?.breakpoint.mdAndDown,
      value => {
        isVerticalNavMenuActive.value = !value
      },
      {
        immediate: true,
      },
    )

    const toggleVerticalNavMenuActive = () => {
      isVerticalNavMenuActive.value = !isVerticalNavMenuActive.value
    }

    const { y: scrollY } = useWindowScroll()

    return {
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive,
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      scrollY,
      launcherDrawer,
      group,
      mini,
      isActiveTaskBar,
      sidebarWidth,
      setLauncherDrawer,
      sharedFolderView,
      vlDrag,
      openTaskBar,
      mainWrapper,
      //
      mdiClose,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.app-content-container {
  // padding: $content-padding-vertical-navigation-menu;
  padding: 0rem;
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

// Vuetify Fix
// https://github.com/vuetifyjs/vuetify/issues/13327
$nav-drawer-mini-width: 65px;

.v-application {
  .v-main,
  .v-footer {
    transition-duration: 0.3s;
  }

  .v-footer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @include theme(v-footer) using ($material) {
    // Elevation 3 with -y
    &.v-footer--fixed {
      box-shadow: 0 -4px 8px -4px rgba(map-deep-get($material, 'shadow-color'), 0.42) !important;
    }
  }

  &.nav-drawer-mini {
    .v-main {
      // We haven't used `ltr` & `rtl` mixin because those doesn't work with top-level selectors: https://github.com/vuetifyjs/vuetify/issues/13987
      @at-root {
        .nav-drawer-mini {
          &.v-application--is-ltr {
            .v-main {
              padding-left: $nav-drawer-mini-width !important;
            }
          }
          &.v-application--is-rtl {
            .v-main {
              padding-right: $nav-drawer-mini-width !important;
            }
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      .app-navigation-menu ~ .v-footer,
      .app-navigation-menu + .v-app-bar {
        left: $nav-drawer-mini-width !important;
      }
    }
  }

  .v-app-bar,
  .v-footer {
    max-width: calc(1440px - (1.5rem * 2));
    @media screen and (max-width: 1456px) {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }

    @at-root .v-application {
      &.content-full {
        .v-footer,
        .v-app-bar {
          max-width: unset;
          margin-left: $content-padding-vertical-navigation-menu !important;
          margin-right: $content-padding-vertical-navigation-menu !important;
        }
      }

      &:not(.nav-drawer-mini) {
        @media screen and (max-width: 1711px) {
          .app-navigation-menu ~ .v-footer,
          .app-navigation-menu + .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
      &.nav-drawer-mini {
        @media screen and (max-width: 1523px) {
          .v-footer,
          .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
    }
  }

  .v-app-bar {
    border-radius: 0 0 10px 10px !important;
    z-index: 5;

    &.v-toolbar:not(.app-bar-shinked) {
      background-color: transparent;
    }

    &.app-bar-static {
      will-change: padding, background-color;
      transition: padding 0.2s ease, background-color 0.18s ease, left 0.3s ease;

      &.v-toolbar.v-sheet:not(.v-app-bar--is-scrolled) {
        box-shadow: none !important;
      }

      ::v-deep > .v-toolbar__content {
        padding: 0;
      }
    }
  }
}

@include theme(v-app-bar) using ($material) {
  &.v-toolbar.app-bar-shinked {
    background-color: map-deep-get($material, 'cards');
  }
}

.v-application.content-layout {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-main,
    .v-footer,
    .v-app-bar {
      max-width: unset;
      left: 0 !important;
      @include ltr() {
        padding-left: 0 !important;
      }
      @include rtl() {
        padding-right: 0 !important;
      }
    }
  }
}

.vl-drag {
  position: fixed;
  top: 0;
  left: 260px;
  height: 100%;
  width: 2px;
  padding: 1px;
  background: #072146;
  z-index: 6;
  cursor: col-resize;
  &:active {
    background: #0c3a7b;
  }
}

.fade-enter-active {
  transition: opacity 3s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
