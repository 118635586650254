import {
  mdiAccount,
  mdiBagCarryOn,
  mdiBookOpenBlankVariant,
  mdiPlus,
  mdiCircleEditOutline,
  mdiDelete,
  // mdiDeleteOutline,
} from '@mdi/js'
import { required } from '@/@core/utils/validation'

export default {
  headerTabs: {
    crm: {
      text: 'CRM',
      tabs: [
        {
          id: 'uQ4HU3CGmmEx4LQDz',
          key: 'account',
          icon: mdiAccount,
          title: 'Account',
          to: 'setup-manager',
          subTab: [
            // {
            //   id: 'dpp2lZiaMxfBR4syw8j',
            //   key: 'account-detail',
            //   icon: mdiAccountDetailsOutline,
            //   title: 'Account Detail',
            //   to: 'setup-manager',
            // },
          ],
        },
        {
          id: 'lQ4HUsUdG3CGmmtrxgf7jj',
          key: 'opportunity',
          icon: mdiBagCarryOn,
          subTab: [],
          title: 'Opportunity',
          to: 'setup-manager',
        },
        {
          id: 'AqwHUsUdGgdf5vfdd4f',
          key: 'lead',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Lead',
          to: 'setup-manager',
        },
        {
          id: 'HUsUdG3CGmmtwessz',
          key: 'contact',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Contact',
          to: 'setup-manager',
        },
        {
          id: 'HUsUdEG3CGmmtwessa',
          key: 'communication',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Communication',
          to: 'setup-communication',
        },
        {
          id: 'wUsUdydn7389CGmmtwessa',
          key: 'status',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Status',
          to: 'setup-status',
        },
        {
          id: 'wUsUdre89ddlGmmtwessa',
          key: 'formGroup',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Form Group',
          to: 'setup-formGroup',
        },
        {
          id: 'wUsUdre89trlGmmtwessa',
          key: 'territory',
          icon: mdiBookOpenBlankVariant,
          subTab: [],
          title: 'Territory',
          to: 'setup-territory',
        },
      ],
      subTabs: [],
    },
    taskManagement: {
      text: 'Task Management',
      tabs: [
        {
          id: 'ueq4H2gsfrdG3CGmmEx4LQDz',
          key: 'workflow',
          icon: mdiAccount,
          subTab: [],
          title: 'Work Flow',
          to: 'setup-workflow',
        },
      ],
    },
    vendorManagement: {
      text: 'Vendor Management',
      tabs: [
        {
          id: 'uQ4HUsUdGksGm0trx4LQDz',
          key: 'vendorManagement',
          icon: mdiBagCarryOn,
          subTab: [],
          title: 'Vendor',
          to: 'setup-form',
        },
      ],
    },
    materialManagement: {
      text: 'Material Management',
      tabs: [
        {
          id: 'uQlHUjUdGksGm0trx4LQDz',
          key: 'material',
          icon: mdiBagCarryOn,
          subTab: [],
          title: 'Material',
          to: 'setup-material-identifier',
        },
        {
          id: 'uQlH3vUdGksGm0trx4LQDz',
          key: 'material',
          icon: mdiBagCarryOn,
          subTab: [],
          title: 'Characteristics',
          to: 'setup-material-characteristic',
        },
      ],
    },
    company: {
      text: 'Company',
      tabs: [
        {
          id: 'uer4H2gsfrdG3CGmmEx4LQDz',
          key: 'company',
          icon: mdiAccount,
          subTab: [],
          title: 'Company Setup',
          to: 'setup-company',
        },
        {
          id: 'ueq4H2gsfrdG3CGmmxx4LQDz',
          key: 'company',
          icon: mdiAccount,
          subTab: [],
          title: 'License & Payment',
          to: 'setup-license',
        },
        // {
        //   id: 'ueq4H2gsf94G3CGmmxx4LQDz',
        //   key: 'company',
        //   icon: mdiAccount,
        //   subTab: [],
        //   title: 'Modules',
        //   to: 'setup-modules',
        // },
      ],
    },
  },
  module: {
    title: 'Module',
    url: 'setup/module',
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'Module',
    app: 'setup',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Monthly price', value: 'monthly_price' },
      { text: 'Monthly offer', value: 'monthly_offer' },
      { text: 'Yearly price', value: 'yearly_price' },
      { text: 'Yearly offer_price', value: 'yearly_offer_price' },
    ],
  },
  subModule: {
    title: 'Sub MOdule',
    url: 'setup/module/sub',
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'Module',
    app: 'setup',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
    ],
  },
  communication: {
    title: 'Communication Setup',
    key: 'uuid',
    url: 'setup/communication/code',
    showCreateBtn: true,
    headers: [
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Email',
        value: 'email',
      },
      // {
      //   text: 'SMS',
      //   value: 'sms',
      // },
      // {
      //   text: 'WhatsApp',
      //   value: 'whatsapp',
      // },
      {
        text: 'Notification',
        value: 'notification',
      },
    ],
    formFields: [
      {
        type: 'dropdown',
        key: 'module',
        label: 'Module',
        validations: [required],
      },
      {
        type: 'dropdown',
        key: 'group',
        label: 'Group',
        validations: [required],
      },
      {
        type: 'textbox',
        key: 'code',
        label: 'Code',
        validations: [required],
      },
      {
        type: 'textarea',
        key: 'desc',
        label: 'Description',
        validations: [required],
      },
    ],
  },
  pmFolderSetup: {
    title: 'Folder',
    url: 'global-configs',
    key: 'uuid',
    module: 'account',
    showSelect: true,
    showCreateBtn: true,
    app: 'set-up',
    actions: [
      {
        icon: mdiPlus,
        title: 'Add',
        ability: 'a',
      },
      {
        icon: mdiCircleEditOutline,
        title: 'Edit',
        ability: 'e',
      },
      {
        icon: mdiDelete,
        title: 'Delete',
        ability: 'd',
      },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Total Users', value: 'total_users' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      {
        type: 'textbox',
        key: 'name',
        label: 'Name',
        validations: [required],
      },
      {
        type: 'textarea',
        key: 'description',
        label: 'Description',
        validations: [required],
      },
    ],
  },
  territory: {
    title: 'Territory',
    url: 'setup/territories',
    key: 'uuid',
    module: 'territory',
    showSelect: false,
    app: 'set-up',
    actions: [
      {
        icon: mdiCircleEditOutline,
        title: 'Edit',
        ability: 'e',
      },
      // {
      //   icon: mdiDeleteOutline,
      //   title: 'Delete',
      //   ability: 'd',
      // },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Total Users', value: 'total_users' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      {
        type: 'textbox',
        key: 'name',
        label: 'Name',
        validations: [required],
      },
      {
        type: 'textarea',
        key: 'description',
        label: 'Description',
        validations: [required],
      },
    ],
  },
  plugin: {
    title: 'Plugin',
    url: '/plugins/plugin-detail',
    key: 'uuid',
    module: 'estimation',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    showGenerateToken: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Username', value: 'username', align: 'start' },
      { text: 'Created At', value: 'createdAt', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', key: 'value', label: 'Value', validations: [required] },
    ],
    fieldConfig: {
      quickbooks: [
        { key: 'clientId', label: 'Client Id', type: 'text', validations: [required] },
        { key: 'redirectUrl', label: 'Redirect Url', type: 'text', validations: [required] },
        { key: 'clientSecret', label: 'Client Secret', type: 'text', validations: [required] },
        { key: 'environment', label: 'Environment', type: 'text', validations: [required] },
      ],
      salesforce: [
        // { key: 'username', label: 'Username', type: 'text', validations: [required] },
        // { key: 'password', label: 'Password', type: 'text', validations: [required] },
        { key: 'clientId', label: 'Client Id', type: 'text', validations: [required] },
        { key: 'clientSecret', label: 'Client Secret', type: 'text', validations: [required] },
        // { key: 'securityToken', label: 'Security Token', type: 'text', validations: [required] },
        { key: 'salesForceURL', label: 'SalesForce URL', type: 'text', validations: [required] },
        { key: 'salesForceRedirectUri', label: 'SalesForce Redirect URL', type: 'text', validations: [required] },
      ],
    },
  },
  userMapping: {
    title: 'Territory',
    url: 'setup/territories',
    key: 'uuid',
    module: 'account',
    showSelect: true,
    app: 'set-up',
    actions: [
      {
        icon: mdiPlus,
        title: 'Add',
        ability: 'a',
      },
      {
        icon: mdiCircleEditOutline,
        title: 'Edit',
        ability: 'e',
      },
      {
        icon: mdiDelete,
        title: 'Delete',
        ability: 'd',
      },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Total Users', value: 'total_users', sortable: false },
      { text: 'Action', value: 'action', sortable: false },
    ],
    accessLevel: [
      { text: 'View', value: 'view' },
      { text: 'View/Edit', value: 'edit' },
      { text: 'Full Access', value: 'fullAccess' },
    ],
  },
  Unit_Of_Measure: {
    title: 'Unit Of Measure',
    url: 'setup/materials/unit-of-measurement',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Value', value: 'value', align: 'start' },
      { text: 'Created At', value: 'createdAt', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
    formFields: [
      { type: 'textbox', field_type: 'text', key: 'name', label: 'Name', validations: [required] },
      { type: 'textbox', key: 'value', label: 'Value', validations: [required] },
    ],
  },
  Shipping_Instruction: {
    title: 'Shipping Instruction',
    url: 'setup/inventory/shipping-instruction',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Code', value: 'code', align: 'start' },
      { text: 'Default', value: 'is_default', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
  },
  Stock_types_setup: {
    title: 'Stock Type Setup',
    url: 'setup/inventory/stock-type',
    key: 'uuid',
    module: 'materialManagement',
    app: 'setup',
    showSelect: true,
    showCreateBtn: true,
    actions: [
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      // { text: 'Default', value: 'is_default', align: 'start' },
      { text: 'Action', value: 'action', align: 'start', sortable: false },
    ],
  },
}
