<template>
  <div class="moreButton">
    <v-btn
      class="--moreTab"
      color="transparent"
      style="font-size: 12px !important"
      large
      depressed
      tile
      dark
      @click="toggleEvent"
    >
      More
      <v-icon size="20">
        {{ icons.mdiChevronDown }}
      </v-icon>
    </v-btn>
    <v-list
      :elevation="$vuetify.theme.dark ? 9 : 8"
      :class="{ '--hidden': isHidden }"
      class="set-pos"
    >
      <v-list-item-group
        color="primary more-dropdown"
        class="--secondary"
        style="background: #232f3e"
      >
        <HorizontalTabMenuHeaderLink
          v-for="child in items"
          :key="child.title"
          class="justify-space-between tab-dropdown-btn"
          :item="child"
          @closeTab="closeTab"
        ></HorizontalTabMenuHeaderLink>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import HorizontalTabMenuHeaderLink from '@core/layouts/components/horizontal-tab-menu/components/HorizontalTabMenuHeaderLink.vue'
import { mdiChevronDown } from '@mdi/js'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default {
  components: {
    HorizontalTabMenuHeaderLink,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const isHidden = computed(() => store.getters['tabPersist/getHideDropdownMenu'])

    const toggleEvent = () => {
      store.commit('tabPersist/SET_TOGGLE_DROPDOWN')
    }
    const closeTab = event => {
      context.emit('closeTab', event)
    }

    return {
      toggleEvent,
      isHidden,
      closeTab,
      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
<style lang="scss">
.moreButton {
  .tab-dropdown-btn .tab-button {
    min-width: 180px;
    max-width: 180px;
    justify-content: start;
  }
}
</style>
<style lang="scss" scoped>
.moreButton {
  .tab-dropdown-btn .tab-button {
    min-width: 180px;
    max-width: 180px;
    justify-content: start;
  }
  position: relative;
  .--moreTab.--hidden {
    visibility: hidden !important;
  }

  .v-list.--hidden {
    visibility: hidden !important;
  }
  .set-pos {
    position: absolute;
    right: 18%;
    z-index: 1;
    border-radius: 8px;
  }
}
</style>
