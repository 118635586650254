const projectManagement = [
  {
    path: '/project/dashboard',
    name: 'pm-dashboard',
    component: () => import('@/views/project-management/dashboard/dashboard.vue'),
    meta: {
      layout: 'content',
      app: 'project-management',
      appId: 9,
    },
  },
  {
    path: '/projects',
    name: 'project-management',
    component: () => import('@/views/project-management/Projects/ProjectsList.vue'),
    meta: {
      layout: 'content',
      app: 'project-management',
      appId: 9,
    },
  },
  {
    path: '/project/:projectNumber/:projectId',
    name: 'project-management-layout',
    component: () => import('@/components/project-management/common/ProjectLayout.vue'),
    meta: {
      layout: 'content',
      app: 'project-management',
      appId: 9,
    },
    children: [
      {
        path: 'dashboard',
        name: 'project-dashboard',
        component: () => import('@/views/project-management/Projects/ProjectDetails.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'create-rfi',
        name: 'create-rfi',
        component: () => import('@/views/project-management/RFI/CreateRfi.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'rfi',
        name: 'project-management-rfi',
        component: () => import('@/views/project-management/RFI/List.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'rfi/:rfiId',
        name: 'rfi-edit',
        component: () => import('@/views/project-management/RFI/Edit.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'contacts',
        name: 'pm-contacts',
        component: () => import('@/views/project-management/Contact/ContactsList.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'add-contact',
        name: 'add-contact',
        component: () => import('@/views/project-management/Contact/MapContact.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'document',
        name: 'document',
        component: () => import('@/views/project-management/Document/Document.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'trash',
        name: 'trash',
        component: () => import('@/views/project-management/Document/Trash.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'document/:folderId',
        name: 'document-folder',
        component: () => import('@/views/project-management/Document/DocumentFolderView.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'change-order',
        name: 'change-order',
        component: () => import('@/views/project-management/ChangeOrder/List.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'create-change-order',
        name: 'create-change-order',
        component: () => import('@/components/project-management/changeOrder/CreateChangeOrder.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'create-budget/:changeOrderId',
        name: 'create-budget',
        props: true,
        component: () => import('@/components/project-management/changeOrder/BudgetFormNext.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'change-order/:changeOrderId',
        name: 'change-order-edit',
        component: () => import('@/views/project-management/ChangeOrder/Edit.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'create-master-change-order',
        name: 'create-master-change-order',
        component: () => import('@/components/project-management/masterChangeOrder/CreateMasterChangeOrder.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'master-change-order',
        name: 'master-change-order',
        component: () => import('@/views/project-management/MasterChangeOrder/List.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'master-change-order/:masterId',
        name: 'master-change-order-edit',
        component: () => import('@/views/project-management/MasterChangeOrder/Edit.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'submittal',
        name: 'submittal',
        component: () => import('@/views/project-management/Submittal/List.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'submittal/:submittalId',
        name: 'submittal-edit',
        component: () => import('@/views/project-management/Submittal/Edit.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'punchlist',
        name: 'punchlist',
        component: () => import('@/views/project-management/Punchlist/List.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'create-punchlist',
        name: 'create-punchlist',
        component: () => import('@/components/project-management/punchlist/CreatePunchList.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'punchlist/:punchlistId',
        name: 'punchlist-edit',
        component: () => import('@/views/project-management/Punchlist/Edit.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'digital-sign',
        name: 'digital-sign',
        component: () => import('@/views/project-management/digital/digital.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
      {
        path: 'to-do-list-view/:taskId',
        name: 'veiw-to-do-list',
        component: () => import('@/components/project-management/project/components/ViewToDoList.vue'),
        meta: {
          layout: 'content',
          app: 'project-management',
          appId: 9,
        },
      },
    ],
  },
  {
    path: '/project/edit/:projectNumber/:projectId',
    name: 'project-edit',
    component: () => import('@/views/project-management/Projects/EditProject.vue'),
    meta: {
      layout: 'content',
      app: 'project-management',
      appId: 9,
    },
  },

]

export default projectManagement
