import api from '@/plugins/apiService'
import { reactive, toRefs } from '@vue/composition-api'
import Toaster from '@core/utils/sweetToast'
import { storeActions, storeMutations } from '@/store/storeConstants'
import store from '@/store'
import axios from 'axios'
import { nanoid } from 'nanoid'

const { SET_FILES_STATUS, SET_FILES_ARRAY, SET_UPLOADING_START, SET_UPLOADING_PERCENTAGE } = storeMutations

export default () => {
  const state = reactive({
    response: [],
    count: 0,
    loading: false,
  })
  const fetchApi = async (url, config) => {
    state.loading = true
    api
      .findByParams(url, { params: config })
      .then(({ data }) => {
        if (data.success) {
          state.response = data.data
          if (data.meta) {
            state.count = data.meta.count
          }
        } else {
          state.response = []
          state.count = 0
        }

        state.loading = false
      })
      .catch(() => {
        state.loading = false
      })
  }

  const deleteRecord = async (url, config) => {
    state.loading = true
    try {
      await api.delete(url, config)
      state.loading = false
      Toaster.success('Item Deleted Successfully', 'success')
    } catch (error) {
      state.loading = false
      Toaster.error('Please try again later', 'warning')
    }
  }

  const restoreRecord = async (url, config) => {
    state.loading = true
    try {
      await api.update(url, config)
      state.loading = false
      Toaster.success('Item Restore Successfully', 'success')
    } catch (error) {
      state.loading = false
      Toaster.error('An error occur Please try again later', 'warning')
    }
  }

  const deleteFileAndFolder = async uuid => {
    return new Promise(resolve => {
      Toaster.confirmation().then(async resp => {
        if (resp.isConfirmed) {
          const payLoad = {
            component: 'workdrive/trash',
            objectId: `${uuid}`,
          }
          try {
            const { data } = await store.dispatch(`common/${storeActions.DELETE_BY_ID}`, payLoad)
            if (data.success) {
              Toaster.success('Item Deleted Successfully', 'success')
              resolve(true)
            } else {
              Toaster.error('Please try again later', 'warning')
              resolve(false)
            }
          } catch (error) {
            Toaster.error('Please try again later', 'warning')
            resolve(false)
          }
        }

        resolve(false)
      })
    })
  }

  const downloadFolder = (item, token) => {
    const baseUrl = process.env.VUE_APP_ROOT_API
    window.open(`${baseUrl}/download/${item.uuid}?token=${token}`)
  }

  const getPdfPageUrl = async (item, filter) => {
    const payLoad = {
      component: `workdrive/downloadpdf/${item.uuid}`,
      object: {},
      objectId: item.uuid,
      filter,
    }

    try {
      const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payLoad)
      if (data.success) {
        return data.data.url
      }

      return null
    } catch (err) {
      if (err?.response.status === 403) {
        Toaster.error(err.response.data.message)
      } else {
        Toaster.error()
      }

      return null
    }
  }

  const downloadFile = item => {
    const payLoad = {
      component: 'workdrive/download',
      object: {
        prop_id: item.propId,
        prop_type: item.propType,
      },
      objectId: item.uuid,
    }
    store
      .dispatch(`common/${storeActions.DOWNLOAD}`, payLoad)
      .then(async ({ data: res }) => {
        const awsS3Response = await axios.get(res.data.url, { responseType: 'blob' })
        const blob = new Blob([awsS3Response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = res.data.name
        link.click()
        URL.revokeObjectURL(link.href)
        Toaster.success('File downloading...', 'success')
      })
      .catch(err => {
        if (err?.response.status === 403) {
          Toaster.error(err.response.data.message)
        } else {
          Toaster.error()
        }
      })
  }

  const fileUpload = async (file, parentId, url = 'workdrive') => {
    const fileObject = {
      name: file.name,
      id: nanoid(),
      percentage: 0,
      status: 'uploading',
    }
    try {
      store.commit(`notify/${SET_UPLOADING_START}`, fileObject)

      const config = {
        object: {
          name: file.name,
          type: 1,
          parent_id: parentId,
        },
        component: `${url}/`,
      }

      // upload first step
      const { data } = await store.dispatch(`common/${storeActions.CREATE}`, config)

      const object = {
        component: data.data.url,
        file,
        config: {
          onUploadProgress: progressEvent => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
              id: fileObject.id,
              percentage: 5 + Math.round(percentage / 1.052),
            })
          },
        },
      }

      // upload to s3
      await store.dispatch(`common/${storeActions.UPDATE_FILE}`, object)
      const payLoad = {
        object: {
          cloud_status: true,
          type: 1,
        },
        component: `${url}/${data.data.uuid}`,
      }

      // upload third step
      const { data: responeData } = await store.dispatch(`common/${storeActions.UPDATE}`, payLoad)
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 100,
        status: 'success',
      })

      return !!responeData.success
    } catch (error) {
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 0,
        status: 'failed',
      })

      return false
    }
  }

  const fileUploader = async (files, parentId, url) => {
    const names = Array.from(files).map(file => {
      return file.name
    })

    store.commit(`notify/${SET_FILES_ARRAY}`, names)
    const promisesToAwait = []

    files.forEach(file => {
      promisesToAwait.push(fileUpload(file, parentId, url))
    })

    await Promise.all(promisesToAwait)
    store.commit(`notify/${SET_FILES_STATUS}`)
  }

  const previewUrl = async item => {
    const payLoad = {
      component: 'workdrive/download',
      object: {
        prop_id: item.propId,
        prop_type: item.propType,
      },
      objectId: item.uuid,
    }

    try {
      const { data } = await store.dispatch(`common/${storeActions.DOWNLOAD}`, payLoad)
      if (data.success) {
        return data.data.url
      }

      return null
    } catch (err) {
      if (err?.response.status === 403) {
        Toaster.error(err.response.data.message)
      } else {
        Toaster.error()
      }

      return null
    }
  }

  // eslint-disable-next-line camelcase
  const folderUpload = async (item, parent_id) => {
    try {
      const payload = {
        component: '/workdrive/folderupload',
        object: {
          fileList: item,
          parent_id,
        },
      }
      const { data } = await store.dispatch(`common/${storeActions.CREATE}`, payload)
      if (data.success && data?.data?.outputData) {
        return data.data.outputData
      }

      return null
    } catch (error) {
      return null
    }
  }

  const getFilesDataTransferItems = dataTransferItems => {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            // eslint-disable-next-line no-param-reassign
            file.relativePath = item.fullPath.substring(1)
            folder.push(file)
            resolve(folder)
          })
        } else if (item.isDirectory) {
          const dirReader = item.createReader()
          dirReader.readEntries(entries => {
            const entriesPromises = []
            // eslint-disable-next-line no-restricted-syntax
            for (const entr of entries) {
              entriesPromises.push(
                traverseFileTreePromise(
                  entr,
                  folder,
                ),
              )
            }
            resolve(Promise.all(entriesPromises))
          })
        }
      })
    }

    const files = []
    let type = null

    return new Promise(resolve => {
      const entriesPromises = []
      // eslint-disable-next-line no-restricted-syntax
      for (const it of dataTransferItems) {
        if (it.webkitGetAsEntry().isFile) {
          type = 'file'
        } else {
          type = 'folder'
        }

        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files),
        )
      }

      Promise.all(entriesPromises).then(() => {
        resolve({ file: files, type })
      })
    })
  }
  const AddfileUpload = async (file, parentId, propsObj) => {
    const fileObject = {
      name: file.name,
      id: nanoid(),
      percentage: 0,
      status: 'uploading',
    }
    try {
      store.commit(`notify/${SET_UPLOADING_START}`, fileObject)
      const config = {
        object: {
          name: file.name,
          type: 1,
          parent_id: parentId || 0,
          ...propsObj,
        },
        component: 'workdrive',
      }
      const { data } = await store.dispatch(`common/${storeActions.CREATE}`, config)

      const object = {
        component: data.data.url,
        file,
        config: {
          onUploadProgress: progressEvent => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
              id: fileObject.id,
              uuid: data.data.uuid,
              percentage: 5 + Math.round(percentage / 1.052),
            })
          },
        },
      }

      // upload to s3
      await store.dispatch(`common/${storeActions.UPDATE_FILE}`, object)
      const payLoad = {
        object: {
          cloud_status: true,
          type: 1,
          requestUrl: true,
          ...propsObj,
        },
        component: `workdrive/${data.data.uuid}`,
      }

      // upload to thrid step
      const { data: responeData } = await store.dispatch(`common/${storeActions.UPDATE}`, payLoad)
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 100,
        status: 'success',
      })

      return !!responeData.success && { uuid: data.data.uuid, ...fileObject, url: responeData.data }

      // return !!responeData.success
    } catch (error) {
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 0,
        status: 'failed',
      })

      return false
    }
  }

  const AddfileUploader = async (files, parentId, propsObj) => {
    const names = Array.from(files).map(file => {
      return file.name
    })

    store.commit(`notify/${SET_FILES_ARRAY}`, names)
    const promisesToAwait = []

    files.forEach(file => {
      promisesToAwait.push(AddfileUpload(file, parentId, propsObj))
    })

    const promiseResp = await Promise.all(promisesToAwait)

    store.commit(`notify/${SET_FILES_STATUS}`)

    return promiseResp
  }

  return {
    fetchApi,
    deleteRecord,
    restoreRecord,
    deleteFileAndFolder,
    downloadFile,
    downloadFolder,
    getPdfPageUrl,
    folderUpload,
    previewUrl,
    fileUploader,
    getFilesDataTransferItems,
    ...toRefs(state),
    AddfileUploader,
    AddfileUpload,
  }
}
