import api from '@/plugins/apiService'

export default {
  namespaced: true,
  state: {
    estimateToolTab: [],
    loading: false,
    trigerLoading: true,
    activeTabId: null,
  },
  getters: {
    GET_ESTIMATE_TOOL_TABS: state => state.estimateToolTab,
    GET_TAB: state => tabId => {
      return state.estimateToolTab.find(i => i.pageId == tabId)
    },
  },
  mutations: {
    SET_ESTIMATE_TOOL_TABS: (state, payload) => {
      state.estimateToolTab = payload
    },
    SET_TAB_LOADING: (state, payload) => {
      state.loading = payload
    },
  },
  actions: {
    FETCH_TABS: ({ commit, getters, dispatch }, payload) => {
      if (getters.GET_TRIGGER_LOADING) {
        commit('SET_TAB_LOADING', true)
      }
      api
        .findByParams(`${payload.component}`, { params: payload.filter })
        .then(({ data }) => {
          if (data.success) {
            const value = data.data && data.data.length ? data.data[0].value : []
            if (payload?.filter?.key === 'estimate-tool' && value.length) {
              if (payload.estimateId === value[0].estimateId) {
                commit('SET_ESTIMATE_TOOL_TABS', value)
              } else {
                commit('SET_ESTIMATE_TOOL_TABS', [])
                const object = {
                  key: 'estimate-tool',
                  estimateId: payload.estimateId,
                  prop_id: 0,
                  prop_type: 'estimate-tool',
                  value: [],
                }
                dispatch('UPDATE_FETCH_TAB', object)
              }
            } else {
              commit('SET_ESTIMATE_TOOL_TABS', [])
            }
          }
        })
        .finally(() => {
          commit('SET_TAB_LOADING', false)
        })
    },
    CRAETE_TABS: async ({ getters, dispatch }, payload) => {
      // eslint-disable-next-line camelcase
      const { component, key, item, prop_type } = payload
      let tabArray = []
      if (key === 'estimate-tool') {
        tabArray = [...getters.GET_ESTIMATE_TOOL_TABS]
      }

      const position = tabArray
        .map(opt => {
          return opt.pageId
        })
        .indexOf(item.pageId)
      if (position === -1) {
        tabArray.push(item)
      }

      // final object send to api
      const object = {
        key,
        prop_id: 0,
        prop_type,
        value: tabArray,
      }
      api.update(`${component}`, object).finally(() => {
        // fetch all tabs after save directly from store
        const fetchObject = {
          component: 'setup/user-preferences',
          estimateId: item.estimateId,
          filter: {
            key: payload.key,
          },
        }
        dispatch('FETCH_TABS', fetchObject)
      })
    },
    CANCEL_TAB_REQUEST: ({ commit, getters, dispatch }, payload) => {
      // eslint-disable-next-line camelcase
      const { key, item, prop_type, estimateId } = payload
      let tabArray = []

      if (key === 'estimate-tool') {
        tabArray = [...getters.GET_ESTIMATE_TOOL_TABS]
      }
      const newTabArray = tabArray.filter(opt => {
        return opt.id !== item.id
      })

      if (key === 'estimate-tool') {
        commit('SET_ESTIMATE_TOOL_TABS', newTabArray)
      }

      // final object send to api
      const object = {
        key,
        prop_id: 0,
        prop_type,
        value: newTabArray,
        estimateId,
      }
      dispatch('UPDATE_FETCH_TAB', object)
    },
    UPDATE_FETCH_TAB: ({ commit, dispatch }, payload) => {
      const object = {
        key: payload.key,
        prop_id: 0,
        prop_type: 'estimate-tool',
        value: payload.value,
      }
      api.update('setup/user-preferences', object).finally(() => {
        // fetch all tabs after save directly from store
        const fetchObject = {
          component: 'setup/user-preferences',
          estimateId: payload.estimateId,
          filter: {
            key: payload.key,
          },
        }
        // commit('TRIGGER_LOADING', false) // when closing tab no loader is required
        dispatch('FETCH_TABS', fetchObject)
        // commit('TRIGGER_LOADING', true)
      })
    },
  },
}
