// eslint-disable-next-line object-curly-newline
import {
  mdiChartTimelineVariant,
  mdiHomeOutline,
  mdiMonitorDashboard,
  mdiTransition,
  mdiDomain,
  mdiFileTree,
} from '@mdi/js'

export default {
  title: 'CRM Dashboard',
  icon: mdiHomeOutline,
  children: [
    {
      icon: mdiMonitorDashboard,
      title: 'CRM',
      key: 'crm',
      to: { name: 'setup-manager', params: { id: 'account' } },
    },
    {
      icon: mdiFileTree,
      title: 'Task Management',
      key: 'taskManagement',
      to: 'setup-workflow',
    },
    {
      icon: mdiChartTimelineVariant,
      title: 'Vendor Management',
      key: 'vendorManagement',
      to: { name: 'setup-form', params: { id: 'vendor' } },
    },
    {
      icon: mdiTransition,
      title: 'Material Management',
      key: 'materialManagement',
      to: { name: 'setup-material-identifier' },
    },
    {
      icon: mdiDomain,
      title: 'Company',
      key: 'company',
      to: 'setup-company',
    },
    // {
    //   icon: mdiCartOutline,
    //   title: 'Project Management',
    //   to: 'setup',
    // },
  ],
}
