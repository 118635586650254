<template>
  <v-list-item
    v-bind="linkProps"
    :class="{ 'bg-gradient-primary white--text': isActive }"
    class="vertical-nav-menu-link"
    active-class="bg-gradient-primary"
    @click="clickItem(item)"
  >
    <v-list-item-icon style="margin-right: 6px">
      <v-icon v-show="item.icon" size="19" :class="{ 'alternate-icon-small': !item.icon }" color="white">
        {{ item.icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-title class="white--text" style="font-size: 0.85rem;">
      {{ item.title }}
    </v-list-item-title>
    <v-list-item-action v-if="item.badge" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="item.badge"> </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'
import themeConfig from '@themeConfig'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isActive, linkProps } = useVerticalNavLink(props.item)

    // when route is folder task & shared task we have to emit the event to open the new sidebar
    const clickItem = item => {
      if (item.to === 'folder-task') {
        emit('openTaskBar')
      } else if (item.to === 'shared-task') {
        emit('openSharedTaskBar')
      }
    }

    return {
      isActive,
      clickItem,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    // box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
