export default [
  {
    path: '/form-builder/list',
    name: 'form-builder-list',
    component: () => import('@/views/forms/FormsList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/create',
    name: 'form-builder-create',
    component: () => import('@/views/forms/CreateForm.vue'),
    meta: {
      layout: 'horizontal',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/:uuid/edit',
    name: 'form-builder-edit',
    component: () => import('@/views/forms/CreateForm.vue'),
    meta: {
      layout: 'horizontal',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/:uuid/view',
    name: 'form-builder-preview',
    component: () => import('@/views/forms/FormPreview.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/:uuid/response',
    name: 'form-builder-response',
    component: () => import('@/views/forms/FormResponseList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/:uuid/response/:response_id/view',
    name: 'form-builder-response-view',
    component: () => import('@/views/forms/ResponseView.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/templates',
    name: 'form-builder-template',
    component: () => import('@/views/forms/TemplateList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/trash/list',
    name: 'trash-form-list',
    component: () => import('@/views/forms/TrashList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/bookmark/list',
    name: 'bookmark-form-list',
    component: () => import('@/views/forms/BookmarkFormList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
  {
    path: '/form-builder/forms/list/shared',
    name: 'shared-form-list',
    component: () => import('@/views/forms/SharedFormList.vue'),
    meta: {
      layout: 'content',
      app: 'formBuilder',
      appId: 12,
    },
  },
]
