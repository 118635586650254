const folder = [
  {
    path: '/work-drive/folder/:id?',
    name: 'Drive-folder',
    component: () => import('@/views/work-drive/AllFiles.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/folder/:sharedParentId/:id',
    name: 'Drive-shared-view',
    component: () => import('@/views/work-drive/AllFiles.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/shared/:id?',
    name: 'Drive-shared',
    component: () => import('@/views/work-drive/SharedFile.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/recent/:id?',
    name: 'Drive-recent',
    component: () => import('@/views/work-drive/recentDrive.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/trash',
    name: 'Drive-trash',
    component: () => import('@/views/work-drive/TrashDrive.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/favorites/:id?',
    name: 'Drive-favorite',
    component: () => import('@/views/work-drive/Favorite.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
  {
    path: '/work-drive/recent-changes',
    name: 'drive-recent-changes',
    component: () => import('@/views/work-drive/DriveRecentChanges.vue'),
    meta: {
      layout: 'content',
      app: 'work-drive',
      appId: 2,
    },
  },
]

export default folder
