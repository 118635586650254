<template>
  <div class="h-full w-full overlay-modal">
    <div
      v-if="viewerobj.type !== '.pdf'"
      class="d-flex justify-space-between title w-full pa-3"
      style="position: fixed"
    >
      <label v-if="viewerobj.file" class="text-sm font-medium">{{ viewerobj.file.name }}</label>
      <div>
        <v-icon v-if="showDownload" color="white" class="mr-4" @click="$emit('download')">
          {{ mdiDownloadOutline }}
        </v-icon>
        <v-icon color="white" @click="$emit('close')">{{ mdiClose }}</v-icon>
      </div>
    </div>

    <div class="d-flex justify-center align-center h-full w-full">
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="white"
        indeterminate
      ></v-progress-circular>
      <div v-else class="css-shadow d-flex justify-center align-center h-full w-full">
        <div v-if="viewerobj.type === '.pdf'" class="h-full w-full">
          <vue-pdf-app :file-name="viewerobj.file.name" :pdf="viewerobj.path" theme="dark">
            <template #footer>
              <div class="text-sm font-medium">{{ viewerobj.file.name }}</div>
            </template>
            <template #toolbar-right-append>
              <v-icon class="mt-1 mr-2" color="white" @click="$emit('close')">
                {{ mdiClose }}
              </v-icon>
            </template>
          </vue-pdf-app>
        </div>
        <div v-else-if="viewerobj.type === '.svg'">
          <div v-html="svgTag"></div>
        </div>
        <img v-else class="mh-100" :src="getPath" />
      </div>
    </div>
  </div>
</template>
<script>
import { mdiDownloadOutline, mdiClose } from '@mdi/js'
import VuePdfApp from 'vue-pdf-app'
// import pdf viewer default icons for buttons
import 'vue-pdf-app/dist/icons/main.css'
import { computed, ref, watch } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: { VuePdfApp },
  props: {
    showDownload: {
      type: Boolean,
      default: true,
    },
    // object must contain url
    viewerobj: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      currentPage: 1,
      pageCount: 1,
      config: {
        toolbar: {
          toolbarViewerLeft: {
            previous: false,
          },
        },
      },
      idConfig: { zoomIn: 'zoomInId', zoomOut: 'zoomOutId' },
    }
  },

  setup(props) {
    const svgTag = ref(null)
    const getPath = computed(() => {
      return props.viewerobj.path
    })

    const fetchSvgTag = () => {
      axios.get(props.viewerobj.path).then(data => {
        svgTag.value = data.data
      })
    }
    watch(() => props.viewerobj.path, () => {
      if (props.viewerobj.type === '.svg' && props.viewerobj.path) {
        fetchSvgTag()
      }
    }, {
      immediate: true,
    })

    return {
      getPath,
      // icons
      mdiDownloadOutline,
      mdiClose,
      svgTag,
    }
  },
}
</script>
<style scoped>
.overlay-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.title {
  /* z-index: 100; */
  color: white;
}
.overlay-modal {
  background: rgb(0 0 0 / 85%);
}
.mh-100 {
  max-height: 100%;
}
</style>
