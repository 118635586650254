<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isVisible"
    max-width="600px"
    scrollable
    content-class="task-folder-modal"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    <v-card id="task-board-modal" class="task-edit-info pa-sm-8 pa-3">
      <v-card-title class="justify-center text-h6">
        {{
          Object.prototype.hasOwnProperty.call(modalForm, 'uuid')
            ? 'Rename Folder'
            : 'Create a New Folder '
        }}
      </v-card-title>

      <v-card-text class="mt-4">
        <v-form ref="folderForm" class="multi-col-validation">
          <v-row>
            <v-col cols="12">
              <p class="mb-2 font-weight-bold">Folder Name</p>
              <v-text-field
                v-model="modalFormData.name"
                outlined
                dense
                hide-details="auto"
                :rules="[required]"
                placeholder="Enter folder & board name"
              ></v-text-field>
            </v-col>
            <v-col v-if="!Object.prototype.hasOwnProperty.call(modalForm, 'uuid')" cols="12">
              <p class="mb-2 font-weight-bold">Location</p>
              <autocomplete-view
                v-if="!isEditing"
                :label="modalFormData.path === 'folders' ? 'Folders' : selectAccountLabel"
                @handleClose="isEditing = true"
              ></autocomplete-view>
              <v-autocomplete
                v-else
                :items="folderOptions"
                outlined
                clearable
                :search-input.sync="search"
                no-filter
                dense
                return-object
                placeholder="location"
                @update:search-input="processUserInput"
                @change="handleFolderChange"
              >
                <template v-slot:item="data">
                  <div class="py-2">
                    <div class="text-capitalize">
                      {{ data.item.text }}
                    </div>
                    <div style="font-size: 12.5px">{{ data.item.path }}</div>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="!Object.prototype.hasOwnProperty.call(modalForm, 'uuid')">
              <v-radio-group v-model="modalFormData.type" class="mt-0">
                <v-radio :label="`Folder`" value="folder"></v-radio>
                <v-radio :label="`Board (You can create task under board)`" value="board"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-if="Object.prototype.hasOwnProperty.call(modalFormData, 'uuid')"
                color="primary"
                class="me-3"
                :loading="loading"
                @click.prevent="update"
              >
                Update
              </v-btn>
              <v-btn v-else color="primary" class="me-3" :loading="loading" @click.prevent="add">
                Create
              </v-btn>

              <v-btn outlined color="secondary" @click="closeModal"> Cancel </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, set } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import Toaster from '@core/utils/sweetToast'
import { useRouter } from '@core/utils'
import useDebounce from '@/plugins/useDebounce'
import AutocompleteView from '@/components/shared-components/AutocompleteView.vue'

export default {
  components: { AutocompleteView },
  props: {
    isFolderDialogOpen: {
      type: Boolean,
      required: true,
    },
    modalForm: {
      type: Object,
      default: () => {},
    },
    propId: {
      type: [String, Number],
      default: 0,
    },
    propType: {
      type: String,
      default: 'workdrives',
    },
    parentId: {
      type: String,
      default: undefined,
    },
  },

  setup(props, { emit }) {
    const { debounce } = useDebounce()
    const { route } = useRouter()
    const folderForm = ref(null)
    const loading = ref(false)
    const isEditing = ref(false)
    const type = ref('folder')
    const selectAccountLabel = ref(null)
    const folderOptions = ref([])
    const search = ref('')
    const isVisible = computed({
      get: () => props.isFolderDialogOpen,
      set: () => {
        return false
      },
    })

    const modalFormData = computed(() => {
      return props.modalForm
    })

    const closeModal = () => {
      folderForm.value.resetValidation()
      isEditing.value = false
      emit('closeModal')
    }
    const add = () => {
      const isValid = folderForm.value.validate()
      if (!isValid) return
      loading.value = true
      const payLoad = {
        object: {
          ...modalFormData.value,
        },
        component: 'task-management/folders',
      }

      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(() => {
          loading.value = false
          Toaster.success('Folder added successfully', 'success')
          emit('fetchData')
          closeModal()
        })
        .catch(() => {
          loading.value = false
          Toaster.error('Please try again later', 'warning')
        })
    }
    const update = () => {
      const isValid = folderForm.value.validate()
      if (!isValid) return
      loading.value = true

      const payLoad = {
        component: `task-management/folders/${modalFormData.value.uuid}`,
        object: {
          name: modalFormData.value.name,
        },
      }

      store
        .dispatch(`common/${storeActions.UPDATE}`, payLoad)
        .then(() => {
          loading.value = false
          Toaster.success('Folder Updated successfully', 'success')
          emit('fetchData')
          closeModal()
        })
        .catch(() => {
          Toaster.error('An eror occur, please try again later', 'warning')
          loading.value = false
        })
    }
    const onSubmitForm = () => {
      if (Object.prototype.hasOwnProperty.call(modalFormData.value, 'uuid')) {
        update()
      } else {
        add()
      }
    }

    const getFolderList = async () => {
      const payload = {
        component: 'task-management/folders',
        filter: {
          allFolders: true,
          type: 'folder',
        },
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          folderOptions.value = data.data.map(opt => {
            return {
              value: opt.uuid,
              text: opt.name,
              path: opt.path,
            }
          })
        }
      })
    }

    const processUserInput = () => {
      debounce(() => getFolderList(), 800)
    }

    const handleFolderChange = obj => {
      selectAccountLabel.value = obj.text
      set(modalFormData.value, 'path', `${obj.path}/${obj.text}`)
      set(modalFormData.value, 'parent_folder_id', obj.value)
      isEditing.value = false
    }

    return {
      folderForm,
      loading,
      isVisible,
      type,
      search,
      isEditing,
      handleFolderChange,
      folderOptions,
      processUserInput,
      selectAccountLabel,
      modalFormData,
      // methods

      add,
      update,
      closeModal,
      onSubmitForm,
      // validation rules
      required,
    }
  },
}
</script>
