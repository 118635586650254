import { mdiHomeOutline, mdiMonitorDashboard } from '@mdi/js'

export default {
  title: 'Dashboards',
  icon: mdiHomeOutline,
  to: { name: 'dashboards' },
  children: [
    {
      icon: mdiMonitorDashboard,
      title: 'Dashboards',
      to: { name: 'dashboards' },
    },
    {
      icon: mdiMonitorDashboard,
      title: 'Shared Dashboards',
      to: { name: 'dashboards-shared' },
    },
  ],
}
