import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'

const { GET_INVENTORY_CONFIG, GET_INVENTORY_CUSTOM_ID } = storeGetters
const { SET_INVENTORY_CONFIG, SET_INVENTORY_CUSTOM_ID } = storeMutations
const { SET_INVENTORY_CONFIG_STATE, SET_INVENTORY_CUSTOM_ID_STATE } = storeActions

export default {
  namespaced: true,
  state: {
    config: {},
    customizeID: [],
  },
  getters: {
    [GET_INVENTORY_CONFIG]: state => state.config,
    [GET_INVENTORY_CUSTOM_ID]: state => state.customizeID,
  },
  mutations: {
    [SET_INVENTORY_CONFIG]: (state, payload) => {
      state.config = payload
    },
    [SET_INVENTORY_CUSTOM_ID]: (state, payload) => {
      state.customizeID = payload
    },
  },
  actions: {
    [SET_INVENTORY_CONFIG_STATE]: ({ commit }, payload) => {
      commit(SET_INVENTORY_CONFIG, payload)
    },
    [SET_INVENTORY_CUSTOM_ID_STATE]: ({ commit }, payload) => {
      commit(SET_INVENTORY_CUSTOM_ID, payload)
    },
  },
}
