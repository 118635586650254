import { mdiDesktopMacDashboard, mdiFolderOutline, mdiCalendarText,mdiShareVariantOutline, mdiBallotOutline } from '@mdi/js'

export default [
  {
    subheader: 'Task Management', // dont change this!, if it change than task drawer will appear on other app
  },
  {
    title: 'Dashboard',
    icon: mdiDesktopMacDashboard,
    to: 'task-dashboard',
  },
  {
    title: 'My to-do',
    icon: mdiBallotOutline,
    to: 'my-todo',
  },
  // {
  //   title: 'Folders',
  //   icon: mdiFolderOutline,
  //   to: 'folder-task',
  // },
  // {
  //   title: 'Tasks',
  //   icon: mdiCalendarText,
  //   to: 'all-task',
  // },
  // {
  //   title: 'Reports',
  //   icon: mdiBallotOutline,
  //   to: 'task-report',
  // },
  // {
  //   title: 'Shared',
  //   icon: mdiShareVariantOutline,
  //   to: 'shared-task',
  // },
]
