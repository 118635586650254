import {
  mdiDesktopMacDashboard,
  mdiAccountGroupOutline,
  mdiAccountTie,
  mdiAccountLockOutline,
  mdiSemanticWeb,
} from '@mdi/js'

export default [
  {
    subheader: 'SECURITY',
  },
  {
    title: 'Dashboard',
    icon: mdiDesktopMacDashboard,
    to: 'security-dashboard',
  },
  {
    title: 'Access Level',
    icon: mdiAccountLockOutline,
    to: 'security-roles',
  },
  {
    title: 'User Group',
    icon: mdiAccountGroupOutline,
    to: 'security-usergroup',
  },
  {
    title: 'Users',
    icon: mdiAccountTie,
    to: 'security-user',
  },
  {
    title: 'Recent Changes',
    icon: mdiSemanticWeb,
    to: 'security-recent-changes',
  },
]
