import {
  mdiFolderOutline,
  mdiHistory,
  mdiMonitorStar,
  mdiDeleteRestore,
  mdiFolderAccountOutline,
  mdiSemanticWeb,
} from '@mdi/js'

export default [
  {
    subheader: 'WORK DRIVE',
  },
  {
    title: 'All Files',
    icon: mdiFolderOutline,
    to: 'Drive-folder',
  },
  {
    title: 'Shared with me',
    icon: mdiFolderAccountOutline,
    to: 'Drive-shared',
  },
  {
    title: 'Recent',
    icon: mdiHistory,
    to: 'Drive-recent',
  },
  {
    title: 'Trash',
    icon: mdiDeleteRestore,
    to: 'Drive-trash',
  },
  {
    subheader: 'Collection',
  },
  {
    title: 'Favorites',
    icon: mdiMonitorStar,
    to: 'Drive-favorite',
  },
  {
    title: 'Recent Changes',
    icon: mdiSemanticWeb,
    to: 'drive-recent-changes',
  },
]
