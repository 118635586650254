<template>
  <div class="mediumGrey--text text-xs2">
    <div class="d-flex justify-space-between px-2 mb-1">
      <p class="mb-1">Space</p>
      <v-menu offset-y left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="19" color="white" v-bind="attrs" v-on="on">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </template>
        <v-list>
          <v-list-item @click="createSpace">
            <v-list-item-title class="text-sm"> Create Space</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="px-3 space-content">
      <v-autocomplete
        v-model="spaceId"
        hide-details
        :items="spaces"
        item-value="uuid"
        item-text="name"
        dense
        solo
        label="Select Space"
        @change="handleSpaceChange"
      >
        <template v-slot:item="data">
          <div class="d-flex w-full justify-space-between">
            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            <div v-if="data.item.uuid !== '-1'">
              <v-icon size="18" @click.stop="spaceSetting(data.item.uuid)">
                {{ icons.mdiCog }}
              </v-icon>
            </div>
          </div>
        </template>
      </v-autocomplete>
    </div>
    <div class="cursor-pointer mt-4" style="font-size: 0.85rem" @click="isShown = !isShown">
      <div class="d-flex align-center justify-space-between px-2">
        <div class="d-flex align-center">
          <!-- <v-icon v-show="!isShown">{{ icons.mdiChevronRight }}</v-icon>
          <v-icon v-show="isShown">{{ icons.mdiChevronDown }}</v-icon> -->
          <p class="mb-0">Projects & Folders</p>
        </div>

        <v-icon
          v-if="getSelectedSpace && $route.params.spaceId !== '-1'"
          size="21"
          color="white"
          @click.stop="openTaskProjectModal = true"
        >
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
    </div>
    <div class="mt-2 pr-2">
      <v-treeview
        v-if="isRerender"
        :active.sync="active"
        :items="tree"
        :load-children="fetchChild"
        item-key="uuid"
        dense
        :expand-icon="icons.mdiChevronDown"
        transition
        hoverable
        activatable
        return-object
      >
        <template #label="{ item: obj }">
          <div
            class="
              white--text
              text-xs3
              d-flex
              justify-space-between
              align-center
              cursor-pointer
              py-3
            "
            :class="obj.uuid === $route.params.taskId ? 'active-tree' : null"
            @mouseover="isElmHover = true"
            @mouseleave="isElmHover = false"
          >
            <span
              style="
                text-overflow: ellipsis;
                max-width: 170px;
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                align-items: center;
              "
            >
              <v-icon v-if="obj.type === 'project'" size="17" color="info" class="mr-2">
                {{ icons.mdiCalendarTextOutline }}
              </v-icon>
              <v-icon v-else-if="obj.type === 'folder'" color="info" size="17" class="mr-2">
                {{ icons.mdiFolderOutline }}
              </v-icon>
              <v-icon v-else size="17" color="info" class="mr-2">{{ icons.mdiFileOutline }}</v-icon>
              {{ obj.name }}
            </span>
            <div class="mr-1">
              <v-menu offset-y left bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="19" color="white" v-bind="attrs" v-on="on">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="addItem(obj)">
                    <v-list-item-title class="text-xs3">
                      <v-icon size="18" class="mr-2 dark--text">{{ icons.mdiPlus }}</v-icon>
                      Add Item
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="shareItem(obj)">
                    <v-list-item-title class="text-xs3">
                      <v-icon size="18" class="mr-2 dark--text">{{ icons.mdiShareAll }}</v-icon>
                      Share
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </template>
      </v-treeview>
    </div>

    <TaskProjectModal
      :open-task-project-modal="openTaskProjectModal"
      :location="taskLocation"
      @close="closeModal"
      @fetchData="renderComponent"
    />

    <TaskShareModal
      v-if="openShareModal"
      :is-share-dialog-open="openShareModal"
      :folder-data="taskInfo"
      @closeModal="openShareModal = false"
    />
  </div>
</template>
<script>
import {
  mdiChevronDown,
  mdiChevronRight,
  mdiCog,
  mdiPencil,
  mdiPlus,
  mdiDotsVertical,
  mdiCalendarTextOutline,
  mdiFolderOutline,
  mdiFileOutline,
  mdiShareAll,
} from '@mdi/js'
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import store from '@/store'
import useTaskService from '@/components/task-management/useTaskService'
import TaskShareModal from '@/components/task-management/modals/TaskShareModal.vue'
import eventBus from '@/@core/utils/eventBus'
import TaskProjectModal from '../modals/TaskProjectModal.vue'

export default {
  components: { TaskProjectModal, TaskShareModal },
  setup() {
    const { router, route } = useRouter()
    const { loadTask, loadSharedTask } = useTaskService()
    const openShareModal = ref(false)
    const taskInfo = ref(null)
    const isShown = ref(false)
    const isRerender = ref(true)
    const isElmHover = ref(false)
    const taskLocation = ref(undefined)

    const active = ref([])
    const open = ref([])
    const tree = ref([])
    const openTaskProjectModal = ref(false)
    const spaces = computed(() => store.getters['taskManagement/getSpaces'])
    const getSelectedSpace = computed(() => store.getters['taskManagement/getSelectedSpace'])

    store.dispatch('taskManagement/FETCH_SPACES')
    store.commit('taskManagement/SET_SELECTED_SPACE', route.value.params.spaceId)

    const spaceId = computed({
      get() {
        return getSelectedSpace.value
      },
      set(value) {
        store.commit('taskManagement/SET_SELECTED_SPACE', value)
      },
    })

    const createSpace = () => {
      router.push({
        name: 'create-space',
      })
    }

    const spaceSetting = uuid => {
      router.push({
        name: 'create-space',
        params: { spaceId: uuid },
      })
    }

    const closeModal = () => {
      taskLocation.value = undefined
      openTaskProjectModal.value = false
    }

    const fetchTask = async () => {
      if (!getSelectedSpace.value) return

      const path =
        spaceId.value === '-1'
          ? 'task-management/spaces/item/share/list'
          : 'task-management/spaces/item/list'
      const payload = {
        space_id: spaceId.value === '-1' ? undefined : getSelectedSpace.value,
        not_type: 'task',
      }
      loadTask(path, payload).then(({ data }) => {
        tree.value = data.data.map(opt => {
          return {
            ...opt,
            children: [],
          }
        })
      })
    }

    const fetchChild = async item => {
      try {
        const path =
          spaceId.value === '-1'
            ? 'task-management/spaces/item/share/list'
            : 'task-management/spaces/item/list'
        const payload = {
          space_id: item.space_id,
          dependent_id: item.uuid,
          not_type: 'task',
        }

        const { data } = await loadTask(path, payload)
        const childData = data.data.map(opt => {
          return {
            ...opt,
            children: [],
          }
        })
        item.children.push(...childData)
      } catch (error) {}
    }

    watch(
      () => active.value,
      () => {
        if (active.value.length) {
          if (spaceId.value === '-1') {
            router.push({
              name: 'share-list',
              params: { spaceId: -1, taskId: active.value[0].uuid },
            })
          } else {
            router.push({
              name: 'table-task',
              params: { spaceId: getSelectedSpace.value, taskId: active.value[0].uuid },
            })
          }
        }
      },
    )

    fetchTask()

    const renderComponent = async () => {
      await fetchTask()
      isRerender.value = false
      nextTick(() => {
        isRerender.value = true
      })
    }

    onMounted(() => {
      eventBus.$on('onTaskReload', val => {
        renderComponent()
      })
    })

    onUnmounted(() => {
      eventBus.$off('onTaskReload')
    })

    const handleSpaceChange = item => {
      if (item === '-1') {
        router.push({
          name: 'share-list',
          params: { spaceId: getSelectedSpace.value },
        })
      } else {
        router.push({
          name: 'table-task',
          params: { spaceId: getSelectedSpace.value },
        })
      }

      renderComponent()
    }

    const addItem = item => {
      taskLocation.value = {
        uuid: item.uuid,
        name: item.name,
      }
      openTaskProjectModal.value = true
    }

    const shareItem = item => {
      taskInfo.value = item
      openShareModal.value = true
    }

    return {
      isShown,
      tree,
      renderComponent,
      addItem,
      isElmHover,
      isRerender,
      open,
      openTaskProjectModal,
      closeModal,
      createSpace,
      spaceSetting,
      handleSpaceChange,
      fetchChild,
      taskLocation,
      active,
      spaces,
      spaceId,
      getSelectedSpace,
      openShareModal,
      taskInfo,
      shareItem,
      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiCog,
        mdiPlus,
        mdiChevronDown,
        mdiChevronRight,
        mdiCalendarTextOutline,
        mdiFolderOutline,
        mdiFileOutline,
        mdiShareAll,
      },
    }
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .v-input__control > .v-input__slot {
    background: transparent !important;
    border: 1px solid #36354e !important;
    box-shadow: none !important;
  }
  .v-input__slot .v-select__slot input {
    color: #fff !important;
    font-size: 13px !important;
  }

  .v-list-item.v-list-item--link {
    min-height: 2.4rem !important;
    height: 2.4rem !important;
  }

  .v-treeview .v-treeview-node__level {
    width: 12px !important;
  }

  .v-treeview--hoverable .v-treeview-node__root:hover:before {
    opacity: 0.15;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  .v-treeview-node__root:has(.active-tree) {
    background-color: #37475a;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }
}
</style>
