<template>
  <div class="px-3">
    <p class="mt-5 mb-0 blueGrey--text font-weight-semibold text-sm">Activity</p>

    <comment-list
      v-for="(comment, index) in commentList"
      :key="comment.uuid"
      :index="index"
      :comment="comment"
      :prop-id="taskInfo.uuid"
      prop-type="space_items"
      @fetchData="fetchComments"
    ></comment-list>
  </div>
</template>
<script>
import CommentList from '@/components/crm/components/CommentList.vue'
import { ref, computed, watch, onMounted, set } from '@vue/composition-api'
import { formatDate, avatarText, getFileThumbnail, getFileExtension } from '@core/utils/filter'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import Toaster from '@core/utils/sweetToast'

export default {
  components: { CommentList },
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showChatEditor = ref(false)
    const commentList = ref([])

    const commentForm = ref({
      attachments: [],
      comment: null,
      loading: false,
    })
    // const taskBarId = computed(() => store.getters['taskManagement/getTaskBarId'])

    const fetchComments = () => {
      const payload = {
        component: 'setup/comments/list',
        object: {
          prop_id: props.taskInfo.uuid,
          prop_type: 'space_items',
        },
      }
      store.dispatch(`common/${storeActions.CREATE}`, payload).then(({ data }) => {
        if (data.success) {
          commentList.value = data.data
        } else {
          commentList.value = []
        }
      })
    }

    const commentEvent = resetEditor => {
      commentForm.value.loading = true
      const pattern =
        /"[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let mentionIds = commentForm.value.comment.match(pattern)
      if (mentionIds) {
        mentionIds = mentionIds.map(opt => {
          return opt.substring(1)
        })
      }

      const attachmentPattern =
        /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let attachIds = commentForm.value.comment.match(attachmentPattern)
      if (attachIds) {
        attachIds = attachIds.map(opt => {
          return opt.substring(1)
        })
      }

      const payLoad = {
        object: {
          prop_id: props.taskInfo.uuid,
          prop_type: 'space_items',
          tagged_users: mentionIds || [],
          attachments: attachIds || [],
          comment: commentForm.value.comment,
        },
        component: 'setup/comments',
      }
      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(({ data }) => {
          commentForm.value.loading = false
          if (data.success) {
            commentForm.value = {
              loading: false,
              comment: null,
              attachments: [],
            }
            fetchComments()
            Toaster.success('SUCCESS_RESPONSE', 'success')
            resetEditor()
          } else {
            Toaster.error(data.message, 'warning')
          }
        })
        .catch(() => {
          commentForm.value.loading = false
          Toaster.error('An eror occur, please try again later', 'warning')
        })
    }

    fetchComments()

    return {
      showChatEditor,
      fetchComments,
      avatarText,
      commentEvent,
      commentList,
      commentForm,
    }
  },
}
</script>
<style scoped lang="scss"></style>
