const goals = [
  {
    path: '/goal-management/dashboard',
    name: 'goals-dashboard',
    component: () => import('@/views/goal-management/dashboard.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/company-goals',
    name: 'company-goals',
    component: () => import('@/views/goal-management/company/GoalsList.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/company-goals/:uuid',
    name: 'goals-details',
    component: () => import('@/views/goal-management/company/GoalView.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/company-goals/create',
    name: 'create-company-goals',
    component: () => import('@/components/goal-management/company/CreateGoalModal.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/company-goals/update/:uuid',
    name: 'update-company-goals',
    component: () => import('@/components/goal-management/company/CreateGoalModal.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/manager-goals',
    name: 'manager-goals',
    component: () => import('@/views/goal-management/manager/GoalsList.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/manager-goals/:uuid',
    name: 'manager-goals-details',
    component: () => import('@/views/goal-management/manager/GoalView.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/user-goals',
    name: 'user-goals',
    component: () => import('@/views/goal-management/user/GoalsList.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },
  {
    path: '/goal-management/user-goals/:uuid',
    name: 'user-goals-details',
    component: () => import('@/views/goal-management/user/GoalView.vue'),
    meta: {
      layout: 'content',
      app: 'goal-management',
      appId: 16,
    },
  },

]

export default goals
