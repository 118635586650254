import { required, emailValidator } from '@/@core/utils/validation'
import {
  mdiDelete,
  mdiDownload,
  mdiPlus,
  mdiCircleEditOutline,
  mdiRenameBox,
  mdiFolderMoveOutline,
  mdiCalculatorVariant,
  mdiGroup,
  mdiFunctionVariant,
  mdiFileDocumentMultiple,
  mdiLabel
} from '@mdi/js'

export default {
  headerTabs: {
    configuration: {
      text: 'Configuration',
      tabs: [
        {
          id: 'uQ4HUtfreG3bGamEx4LQDz',
          key: 'variable',
          icon: mdiFunctionVariant,
          subTab: [],
          title: 'Variable',
          to: 'estimation-variable',
        },
        {
          id: 'uHUI4HUsUdG3CGbntrx4LQDz',
          key: 'material-formula',
          icon: mdiCalculatorVariant,
          subTab: [],
          title: 'Material Formula',
          to: 'estimation-material-formula',
        },
        {
          id: 'uHUI4HUsUdG3CGbntrx4LQDz',
          key: 'calculator',
          icon: mdiCalculatorVariant,
          subTab: [],
          title: 'Calculator',
          to: 'estimation-calculator-template',
        },
        {
          id: 'uHUI4HUsUdG3CGbntrx4LQDz',
          key: 'group',
          icon: mdiGroup,
          subTab: [],
          title: 'Group',
          to: 'estimation-calculator-group',
        },
        {
          id: 'uHUI4HUsUdG3CGbntrx4LQDz',
          key: 'estimation-stamp',
          icon: mdiLabel,
          subTab: [],
          title: 'Stamps',
          to: 'estimation-stamp',
        },
      ],
      subTabs: [],
    },
    quote_configuration: {
      text: 'Quote Configuration',
      tabs: [
        {
          id: 'uQ4HUtfreG3bGamEx4LQDz',
          key: 'quote-template',
          icon: mdiFileDocumentMultiple,
          subTab: [],
          title: 'Quote Template',
          to: 'estimation-quote-template-list',
        },
        {
          id: 'uHUI4HUsUdG3CGbntrx4LQDz',
          key: 'inclusion-exclusion',
          icon: mdiCalculatorVariant,
          subTab: [],
          title: 'Inclusion/Exclusion',
          to: 'estimation-inclusion-exclusion',
        },
      ],
      subTabs: [],
    },

  },
  estimate: {
    title: 'Estimation',
    url: 'estimations/',
    showSelect: true,
    showCreateBtn: true,
    showCopyBtn: false,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    showExportBtn: false,
    showImportBtn: false,
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Customer Name', value: 'customer_name' },
      { text: 'Opportunity', value: 'opportunity' },
      { text: 'Bid date', value: 'bid_date' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      {
        type: 'autocomplete',
        key: 'opportunity_id',
        label: 'Opportunity',
        'item-value': '_id',
        'item-text': 'name',
        api: '/crm/opportunity',
        options: [],
        search: '',
        validations: [required],
      },
      { type: 'textbox', key: 'customer_name', label: 'Customer Name', validations: [required] },
      { type: 'date', key: 'bid_date', label: 'Bid Date', validations: [] },
      {
        type: 'textarea',
        key: 'description',
        label: 'Description',
        validations: [required],
      },
    ],
  },
  estimate_variable: {
    title: 'Estimate Variable',
    url: 'estimations/variable',
    showSelect: true,
    showCreateBtn: true,
    showExportBtn: false,
    showImportBtn: false,
    showCopyBtn: false,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Column', value: 'column' },
      { text: 'Variable', value: 'variable' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      {
        type: 'autocomplete',
        key: 'column_id',
        label: 'Column',
        'item-value': 'id',
        'item-text': 'name',
        api: 'setup/reports/columns',
        options: [],
        search: '',
        validations: [required],
      },
      { type: 'textbox', key: 'variable', label: 'Variable Name', validations: [required] },
    ],
  },
  estimate_material_formula: {
    title: 'Estimate Material Formula',
    url: 'material/formula/list',
    showExportBtn: false,
    showImportBtn: false,
    showCopyBtn: false,
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'name', value: 'name' },
      { text: 'Variable', value: 'variable' },
      { text: 'Material', value: 'material_name' },
      { text: 'formula variable', value: 'formula_variable' },
      { text: 'formula', value: 'formula' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'material_id', label: 'Material', validations: [required] },
      { type: 'select', key: 'unit', label: 'Unit', options: ['MM', 'IN', 'EA', 'EA', 'FT', 'SQFT', 'SQIN'], validations: [required] },
      { type: 'textbox', key: 'formula_variable', label: 'Formula Variable', validations: [] },
      { type: 'textarea', key: 'formula', label: 'Formula', validations: [required] },
    ],
  },
  estimate_calculator_template: {
    title: 'Estimate Template',
    url: 'estimations/calculator-template',
    showExportBtn: false,
    showImportBtn: false,
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    showCopyBtn: true,
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Default', value: 'is_default' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      {
        type: 'checkbox',
        key: 'is_default',
        label: 'Use this as default template?',
        validations: [],
      },
    ],
  },
  estimate_stamp: {
    title: 'Estimation Stamps',
    url: 'workdrive',
    showExportBtn: false,
    showImportBtn: false,
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    showCopyBtn: true,
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Action', value: 'actions'}
    ]
  },
  estimate_calculator_group: {
    title: 'Estimate Group',
    url: 'estimations/calculator-group',
    showSelect: true,
    showSettings: true,
    showExportBtn: false,
    showImportBtn: false,
    showCreateBtn: true,
    showCopyBtn: false,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Sequence', value: 'sequence' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [{ type: 'textbox', key: 'name', label: 'Name', validations: [required] }],
  },
  estimate_calculator: {
    title: 'Estimate Calculator',
    showSelect: true,
    showCreateBtn: true,
    showExportBtn: false,
    showImportBtn: false,
    showSettings: true,
    showCopyBtn: false,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Sequence', value: 'sequence' },
      { text: 'Name', value: 'name' },
      { text: 'Variable Name', value: 'variable' },
      { text: 'Column', value: 'column' },
      { text: 'Formula', value: 'dis_formula' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'group_id', label: 'Group', validations: [required] },
      { type: 'dropdown', key: 'field_type', label: 'Field Type', options: ['number', 'text'], validations: [required] },
      { type: 'custom_column', key: 'custom_column', label: 'Custom Column', validations: [required] },
      { type: 'checkbox', key: 'is_whole_number', label: 'Set Whole Number', validations: [] },
      { type: 'checkbox', key: 'is_monetary_field', label: 'Is Monetary Field', validations: [] },
      { type: 'checkbox', key: 'is_editable', label: 'Is Editable', validations: [] },
      { type: 'checkbox', key: 'is_labor_productivity', label: 'Is Labor Productivity Field', validations: [] },
      { type: 'checkbox', key: 'show_in_quote', label: 'Show in Quote', validations: [] },
      { type: 'checkbox', key: 'budget_column', label: 'Budget Column', validations: [] },
      { type: 'checkbox', key: 'qty_column', label: 'Quantity Column', validations: [] },
      { type: 'checkbox', key: 'sell_column', label: 'Sell Column', validations: [] },
      { type: 'dropdown', key: 'unit_type', label: 'Unit Type', options: ['static', 'dynamic'], validations: [] },
      { type: 'textbox', key: 'unit', label: 'Unit', validations: [required] },
      { type: 'textbox', key: 'variable', label: 'Variable Name', validations: [required] },
      { type: 'formula', key: 'formula', label: 'Formula', validations: [required] },
    ],
  },
  estimate_inclusion_exclusion: {
    title: 'Estimate Inclusion Exlusion',
    url: 'estimations/inclusion-exclusion',
    showSelect: true,
    showCreateBtn: true,
    showExportBtn: false,
    showImportBtn: false,
    showSettings: false,
    showCopyBtn: false,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Formula', value: 'formula' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'textarea', key: 'description', label: 'Description', validations: [required] },
      { type: 'dropdown', key: 'default_inclusion', label: 'Default Include', options: [{ text: 'Yes', value: true}, {text: 'No', value: false}], validations: [required] },
      { type: 'dropdown', key: 'include_on', label: 'Include On', options: [{ text: 'Assembly Level', value: 'assembly'}, {text: 'Project Level', value: 'project_level'}], validations: [required] },
      { type: 'dropdown', key: 'calculate_by', label: 'Calculate By', options: [{ text: 'No Calculation', value: 'no_calculation'}, { text: 'Fixed Value', value: 'fixed_value'}, {text: 'Formula', value: 'formula'}], validations: [required] },
    ],
  },
  drive: {
    title: 'All Files',
    url: 'workdrive/',
    showSelect: false,
    showCreateBtn: true,
    app: 'estimation',
    actions: [
      { icon: mdiDelete, title: 'Trash', ability: 'd', available: 'both' },
      { icon: mdiDownload, title: 'Download', ability: 'v', available: 'both' },
      { icon: mdiRenameBox, title: 'Rename', ability: 'e', available: 'both' },
      { icon: mdiFolderMoveOutline, title: 'Move', ability: 'e', available: 'both' },
    ],
    headers: [
      { text: 'Folders', value: 'name' },
      { text: 'Updated', value: 'updatedAt' },
      { text: 'Version', value: 'version', sortable: false },
      { text: 'Action', value: 'action', sortable: false },
    ],
  },
  bidderList: {
    title: 'Estimation',
    url: 'estimation/estimation',
    showSelect: true,
    showCreateBtn: true,
    showExportBtn: false,
    showImportBtn: false,
    showCopyBtn: false,
    app: 'estimation',
    actions: [
      { icon: mdiPlus, title: 'Add' },
      { icon: mdiCircleEditOutline, title: 'Edit' },
      { icon: mdiDelete, title: 'Delete' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Customer Name', value: 'customer' },
      { text: 'Status', value: 'status' },
      { text: 'Opportunity Id', value: 'opportunityId' },
      { text: 'owner', value: 'Owner', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      {
        type: 'dropdown',
        key: 'type',
        label: 'Type',
        options: [
          { text: 'Customer', value: 1 },
          { text: 'Vendor', value: 2 },
        ],
        validations: [required],
      },
      { type: 'textbox', key: 'email', label: 'email', validations: [required, emailValidator] },
      { type: 'textbox', key: 'phone', label: 'Phone', validations: [required] },
      {
        type: 'dropdown',
        key: 'status',
        label: 'Status',
        options: [
          { text: 'True', value: true },
          { text: 'False', value: false },
        ],
        validations: [required],
      },
      { type: 'textbox', key: 'website', label: 'Website', validations: [] },
      { type: 'textarea', key: 'address', label: 'Address', validations: [required] },
      { type: 'textbox', key: 'linkedin', label: 'Linkedin Address', validations: [] },
    ],
  },
  quotes: {
    title: 'Quotes',
    url: '',
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    showExportBtn: false,
    showImportBtn: false,
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Bidder', value: 'bidder' },
      { text: 'Email', value: 'bidder_email' },
      { text: 'Phone', value: 'bidder_phone' },
      { text: 'Template', value: 'template' },
      { text: 'Email', value: 'email_action' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'bidder_id', label: 'Bidder', options: 'bidderList', validations: [required] },
      { type: 'dropdown', key: 'template_id', label: 'Template', options: 'templateList', validations: [required] },
      { type: 'dropdown', key: 'estimate_calculator_id', label: 'Calculator', options: 'estimateCalculatorList', validations: [required] },
      { type: 'textarea', key: 'description', label: 'Description', validations: [] },
    ],
  },
  quote_template: {
    title: 'Quote Template',
    url: 'estimations/quote-template/',
    showSelect: true,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    showExportBtn: false,
    showImportBtn: false,
    actions: [
      { icon: mdiPlus, title: 'Add', ability: 'a' },
      { icon: mdiCircleEditOutline, title: 'Edit', ability: 'e' },
      { icon: mdiDelete, title: 'Delete', ability: 'd' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Action', value: 'action', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'dropdown', key: 'template', label: 'Quote Type', validations: [required], options: ['BasicTemplate', 'ChangeOrderTemplate'] },
      { type: 'textarea', key: 'description', label: 'Description', validations: [] },
    ],
  },
  quote_revisions: {
    title: 'Revisions',
    url: '',
    showSelect: false,
    showCreateBtn: true,
    key: 'uuid',
    module: 'estimation',
    app: 'estimation',
    headers: [
      { text: 'Revision', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Active', value: 'is_active', sortable: false },
      { text: 'Operations', value: 'operations', sortable: false },
    ],
    formFields: [
      { type: 'textbox', key: 'name', label: 'Name', validations: [required] },
      { type: 'textarea', key: 'description', label: 'Description', validations: [] },
    ],
  },
}
