import useNav from '@core/layouts/composable/useNav'
import { useRouter } from '@core/utils'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { storeActions, storeMutations } from '@/store/storeConstants'

export default function useActiveLink(item, module) {
  const { ACTIVE_TAB_REQUEST } = storeActions
  const { ACTIVE_SETUP_ID } = storeMutations
  const { isLinkPathActive, isSetupPathActive, isSubPathActive } = useNav()
  const { route } = useRouter()
  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false)
  const isSubTabActive = ref(false) // check for tab if subtab is active
  const activeId = ref(null)
  const isActiveSetupTab = ref(false)
  const updateIsActive = () => {
    isActive.value = isLinkPathActive(item, module)
    isSubTabActive.value = isSubPathActive(item)

    if (isActive.value) {
      activeId.value = item.id
      store.dispatch(`tabSystem/${ACTIVE_TAB_REQUEST}`, item.id)
    }

    // tab active for setup module
    isActiveSetupTab.value = isSetupPathActive(item)
    if (isActiveSetupTab.value) {
      store.commit(`setupTab/${ACTIVE_SETUP_ID}`, item.id)
    }
  }
  watch(() => route.value, updateIsActive, { immediate: true })

  return {
    isActive,
    isActiveSetupTab,
    activeId,
    isSubTabActive,
    updateIsActive,
  }
}
