const security = [
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import('@/views/vendor-management/Vendors.vue'),
    meta: {
      layout: 'tab',
      app: 'vendorManagement',
      appId: 5,
    },
  },
  {
    path: '/vendors/recent-changes',
    name: 'vendor-recent-changes',
    component: () => import('@/views/vendor-management/VendorRecentChanges.vue'),
    meta: {
      layout: 'tab',
      app: 'vendorManagement',
      appId: 5,
    },
  },
  {
    path: '/vendors',
    name: 'vendor-layout',
    component: () => import('@/views/vendor-management/VendorLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'vendorManagement',
      appId: 5,
    },
    children: [
      {
        path: ':uuid/view',
        name: 'vendor-view',
        component: () => import('@/views/vendor-management/VendorView.vue'),
        meta: {
          layout: 'tab',
          app: 'vendorManagement',
          appId: 5,
        },
      },
      {
        path: ':uuid/contact',
        name: 'vendor-contact',
        component: () => import('@/components/vendor-management/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'vendorManagement',
          // permission: 'contact',
          // permissionType: 'v',
          appId: 5,
        },
      },

    ],
  },
  {
    path: '/crm/:prop_type/import',
    name: 'import-vendor',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'tab',
      app: 'vendorManagement',
      appId: 5,
    },
  },
]

export default security
