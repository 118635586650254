import { bldonApps } from '@/constants/appConstants'

const report = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/report/folder/List.vue'),
    meta: {
      layout: 'tab',
      app: bldonApps.reports.text,
      appId: 10,
    },
  },
  {
    path: '/reports-shared',
    name: 'reports-shared',
    component: () => import('@/views/report/folder/SharedList.vue'),
    meta: {
      layout: 'tab',
      app: bldonApps.reports.text,
      appId: 10,
    },
  },
  {
    path: '/report/folder',
    name: 'report-layout',
    component: () => import('@/views/report/ReportLayout.vue'),
    meta: {
      layout: 'tab',
      app: bldonApps.reports.text,
      appId: 10,
    },
    children: [
      {
        path: ':folder_id?',
        name: 'report-folder',
        component: () => import('@/views/report/List.vue'),
        meta: {
          layout: 'tab',
          app: bldonApps.reports.text,
          appId: 10,
        },
      },
      {
        path: '/report/:id',
        name: 'report-view',
        component: () => import('@/views/report/View.vue'),
        meta: {
          layout: 'tab',
          app: bldonApps.reports.text,
          appId: 10,
        },
      },
      {
        path: 'opportunity/:uuid/view',
        name: 'report-opportunity-view',
        component: () => import('@/views/crm/opportunity/OpportunityView.vue'),
        meta: {
          layout: 'tab',
          app: bldonApps.reports.text,
          appId: 10,
        },
      },
      {
        path: 'account/:uuid/view',
        name: 'report-account-view',
        component: () => import('@/views/crm/accounts/AccountView.vue'),
        meta: {
          layout: 'tab',
          app: bldonApps.reports.text,
          appId: 10,
        },
      },
      {
        path: 'contact/:uuid/view',
        name: 'report-contact-view',
        component: () => import('@/views/crm/contacts/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: bldonApps.reports.text,
          appId: 10,
        },
      },
    ],
  },
  {
    path: '/report/edit/:id',
    name: 'report-edit',
    component: () => import('@/views/report/Edit.vue'),
    meta: {
      layout: 'tab',
      app: bldonApps.reports.text,
      appId: 10,
    },
  },
]

export default report
