const security = [
  {
    path: '/emails/:folder?',
    name: 'email-folder',
    component: () => import('@/views/emails/Email.vue'),
    meta: {
      layout: 'tab',
      app: 'email',
      appId: 15,
    },
  },
]

export default security
