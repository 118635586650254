export default () => {
  const primary = document.querySelector('.-primary')
  const moreBtn = document.querySelector('.--moreTab')
  const primaryItems = document.querySelectorAll('.-primary > .custom-tab-button')
  const secondaryItems = document.querySelectorAll('.--secondary  > .custom-tab-button')

  // reveal all items for the calculation
  primaryItems.forEach(item => {
    item.classList.remove('--hidden')
  })
  secondaryItems.forEach(item => {
    item.classList.remove('--hidden')
  })

  // hide items that won't fit in the Primary
  let stopWidth = moreBtn.offsetWidth
  const hiddenItems = []
  const primaryWidth = primary.offsetWidth
  primaryItems.forEach((item, i) => {
    if (primaryWidth >= stopWidth + item.offsetWidth) {
      stopWidth += item.offsetWidth
    } else {
      item.classList.add('--hidden')
      hiddenItems.push(i)
    }
  })

  if (!hiddenItems.length) {
    moreBtn.classList.add('--hidden')
  } else {
    moreBtn.classList.remove('--hidden')
    secondaryItems.forEach((item, i) => {
      if (!hiddenItems.includes(i)) {
        item.classList.add('--hidden')
      }
    })
  }
}
