<template>
    <v-list-item class="autocomplete mt-5 mb-4">
        <v-autocomplete v-model="model" clearable :items="[]" dark outlined dense hide-no-data hide-details hide-selected
            height="44px" item-text="name" item-value="uuid" label="Search" placeholder="Search"
            :prepend-icon="icons.mdiDatabaseSearchOutline" @change="goToProject"></v-autocomplete>
    </v-list-item>
</template>
<script>
import { mdiDatabaseSearchOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import { useRouter } from '@/@core/utils'

export default {

  setup() {
    const { router } = useRouter()
    const { SET_PROJECT } = storeActions
    const model = ref(null)
    onMounted(async () => {
      model.value = router.currentRoute.params.projectId
    })

    const goToProject = () => {
      const payload = {
        projectId: model.value,
      }
      store.dispatch(`projectManagement/${SET_PROJECT}`, payload)
      if (model.value == null) {
        router.push({ name: 'project-management' })

        return
      }
      router.push({ name: 'project-dashboard',
        params: {
          projectId: model.value,
        } })
    }

    return {
      goToProject,
      model,
      icons: {
        mdiDatabaseSearchOutline,
      },
    }
  },
}
</script>
