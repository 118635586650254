<template>
  <div v-resize="onResize" class="nav-header w-full h-full">
    <div class="d-flex align-center" style="border-bottom: 2px solid #e3e1e1; background: #f3f3f3">
      <h4 class="mx-3" style="color: #fe724c !important; width: 170px">Vendor Management</h4>
      <HorizontalTabMenuHeaderGroup
        v-for="item in navMenuItems"
        :key="item.header || item.title"
        :item="item"
      />
      <!-- <v-menu
        ref="refMenu"
        offset-y
        eagers
        color="transparent"
        dark
        attach
        class="horizontal-nav-header-group"
        content-class="list-style elevation-0"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            ref="refActivator"
            depressed
            large
            style="
              font-size: 13px !important;
              min-width: 170px;
              max-width: 170px;
              border-left: 1px solid #e3e1e1 !important;
              border-right: 1px solid #e3e1e1 !important;
            "
            tile
            color="transparent"
            dark
            v-bind="attrs"
            class="text-capitalize text-base px-5 mr-0 tab-button text--primary"
            :class="[{ 'menu-open': isMenuActive }]"
            v-on="on"
          >
            <span class="mr-3">{{ 'menuTabTitle.attribute' }}</span>
            <v-icon size="20">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list
          ref="refContent"
          :elevation="$vuetify.theme.dark ? 9 : 8"
          class="primary white--text"
        >
          <v-list-item-group>
            <MenuLink
              v-for="child in navMenuItems.children"
              :key="child.subheader || child.title"
              :item="child"
              @consolePersistant="consolePersistant"
            ></MenuLink>
          </v-list-item-group>
        </v-list>
      </v-menu> -->
      <ul v-if="isVisible" class="nav-ul d-flex flex-grow-1">
        <TabHeaderLink
          v-for="(item, index) in tabs"
          :key="index"
          class="justify-center"
          :item="item"
          :loading="loading"
          active-module="vendors"
          @tabClick="tabClick"
          @closeTab="closeTab"
          @onResize="onResize"
        />
      </ul>
      <MoreTabMenu
        v-if="more.length"
        class="flex-grow-1"
        :more="more"
        @addItem="addItem"
        @closeTab="closeTab"
        @onResize="onResize"
      />
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vendor-management'
import { computed, ref, nextTick, watch, watchEffect } from '@vue/composition-api'
import { storeGetters, storeActions } from '@/store/storeConstants'
import store from '@/store'
import { useRouter } from '@core/utils'
import MenuLink from '@core/layouts/components/horizontal-tab-menu/components/set-up/MenuLink.vue'
import { mdiChevronDown } from '@mdi/js'
import MoreTabMenu from './components/MoreTabMenu.vue'
import TabHeaderLink from './components/TabHeaderLink.vue'
import HorizontalTabMenuHeaderGroup from './components/horizontal-tab-menu-header-group/HorizontalTabMenuHeaderGroup.vue'

export default {
  name: 'CrmMenu',
  components: { TabHeaderLink, MoreTabMenu, MenuLink, HorizontalTabMenuHeaderGroup },

  setup() {
    const { GET_DASHBOARD_PERSIST_TAB, GET_DASHBOARD_TABS } = storeGetters
    const { router } = useRouter()

    const tabs = ref([])
    const more = ref([])
    const isVisible = ref(true)

    const loading = computed(() => store.getters[`tabSystem/${storeGetters.TAB_LOADING}`])
    const totalTabs = computed(() => store.getters[`tabSystem/${storeGetters.GET_VENDOR_TABS}`])
    const menuTabTitle = computed(() => store.getters[`setupTab/${storeGetters.GET_VENDOR_PERSIST_TAB}`])

    const onResize = () => {
      isVisible.value = false
      const temp = totalTabs.value.slice()
      tabs.value = temp.splice(0, (window.innerWidth - 234) / 190 - 1)
      more.value = temp.splice(0)
      nextTick(() => {
        isVisible.value = true
      })
    }

    const loadMore = () => {
      const payload = {
        component: 'setup/user-preferences',
        key: 'vendor',
        filter: {
          key: 'vendor',
        },
      }
      store.dispatch(`tabSystem/${storeActions.FETCH_TABS}`, payload)
    }

    loadMore()

    const addItem = item => {
      const payload = {
        item,
        key: 'vendor',
        prop_type: 'vendor',
        component: 'setup/user-preferences',
      }
      store.dispatch(`tabSystem/${storeActions.TAB_POSITION_CHANGE}`, payload)

      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})
      onResize()
    }

    const tabClick = item => {
      router.push({ name: item.to, params: { uuid: item.key } }).catch(() => {})
    }

    const addTab = () => {
      onResize()
    }
    const consolePersistant = item => {
      const menuTab = {
        attribute: item.title,
      }
      store.dispatch(`setupTab/${storeActions.VENDOR_TAB_REQUEST}`, menuTab)
    }

    const closeTab = ({ item, isActive }) => {
      const payload = {
        item,
        key: 'vendor',
        component: 'setup/user-preferences',
        prop_type: 'vendor',
      }
      store.dispatch(`tabSystem/${storeActions.CANCEL_TAB_REQUEST}`, payload)
      // store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')

      if (isActive) {
        // redirect when active tab is closed
        if (totalTabs.value.length) {
          const redirectTab = totalTabs.value[0]
          tabClick(redirectTab)
        } else {
          router.push({ name: 'vendor-layout' })
        }
      }

      onResize()
    }

    watch(
      () => totalTabs.value,
      () => {
        addTab()
      },
    )

    const refMenu = ref(null)
    const isMenuActive = ref(false)
    watchEffect(() => {
      isMenuActive.value = refMenu.value ? refMenu.value.isActive : false
    })

    return {
      tabs,
      more,
      loading,
      isVisible,
      closeTab,
      consolePersistant,
      onResize,
      addItem,
      tabClick,
      isMenuActive,
      menuTabTitle,
      navMenuItems,
      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
<style scoped>
.nav-ul {
  padding: 0px !important;
}

.menu-custom {
  background: #007eb9;
  color: black !important;
  border-radius: 0px;
}
.v-btn__content {
  color: black !important;
}
</style>
