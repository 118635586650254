<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">What We Provide In CRM</p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Dashboard</p>
        <p class="dark--text">
          Our CRM dashboard provides you a comprehensive overview of your customer data. You can
          easily gauge through the number of leads, access a list of accounts & opportunities, and
          examine the total revenue generated using dynamic visual charts.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Dashboard</p>
        <p class="dark--text">
          Our CRM dashboard provides you a comprehensive overview of your customer data. You can
          easily gauge through the number of leads, access a list of accounts & opportunities, and
          examine the total revenue generated using dynamic visual charts.
        </p>
      </div>
    </div>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Opportunity</p>
        <p class="dark--text">
          You can keep a record of all the project deals that you are working on in one place. This
          includes project name, location, scope of work, and estimated budget, as well as
          individuals involved in the decision-making process, such as project managers, procurement
          officers, or executives. Additionally, you can track the current status of the opportunity
          in the sales pipeline, details of any proposals, bids, or quotations submitted to the
          client as part of the sales process, including pricing, terms, and conditions, and details
          about any follow-up actions taken.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Contact</p>
        <p class="dark--text">
          Using the ‘Contact’ feature you can record information about individuals within the client
          organization who are involved in the decision-making process or have influence over the
          project, such as project managers, procurement officers, or executives.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
