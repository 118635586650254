import { reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import Toaster from '@/@core/utils/sweetToast'
import { SUCCESS_RESPONSE, ERROR_RESPONSE } from '@/constants/appConstants'

export default () => {
  const { GET_ALL_RECORDS, DELETE_BY_ID, GET_BY_ID, CREATE } = storeActions
  const state = reactive({
    response: [],
    count: 0,
    meta: {},
    loading: false,
  })

  const fetchApi = payload => {
    state.loading = true
    store
      .dispatch(`common/${GET_ALL_RECORDS}`, payload)
      .then(({ data }) => {
        state.loading = false
        if (data.success && data.data) {
          state.response = data.data
          if (data.meta !== undefined) {
            state.count = data.meta.count
            state.meta = data.meta
          } else {
            state.count = data.data.length
          }
        } else {
          state.response = []
          state.count = 0
        }
      })
      .catch(error => {
        console.log(error)
        state.loading = false
      })
  }

  const fetchApiPost = async payload => {
    state.loading = true
    store
      .dispatch(`common/${CREATE}`, payload)
      .then(({ data }) => {
        state.loading = false
        if (data.success) {
          state.response = data.data
          state.count = data.meta.count
        } else {
          state.response = []
          state.count = 0
        }
      })
      .catch(() => {
        state.loading = false
      })
  }
  const fetchById = async payload => {
    const config = {
      component: payload.url,
      objectId: payload.id,
    }
    try {
      const { data } = await store.dispatch(`common/${GET_BY_ID}`, config)
      if (data.success) {
        return data
      }
      if (!payload.showToaster) Toaster.error(data.message, 'warning')

      return false
    } catch (error) {
      if (!payload.showToaster) Toaster.error('Please try again later', 'warning')

      return false
    }
  }
  const deleteById = async (payload, url) => {
    const config = {
      component: url,
      objectId: payload.uuid,
    }
    try {
      const { data } = await store.dispatch(`common/${DELETE_BY_ID}`, config)
      if (data.success) {
        Toaster.success(SUCCESS_RESPONSE, 'success')

        return data
      }
      Toaster.error(data.message, 'warning')

      return false
    } catch (error) {
      Toaster.error(ERROR_RESPONSE, 'warning')

      return false
    }
  }

  const getFormGroups = async module => {
    try {
      const payLoad = {
        component: 'setup/global-configs',
        filter: {
          key: 'formGroup',
        },
      }
      let groups = []
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)

      if (data.data?.length) {
        try {
          groups = JSON.parse(data.data[0].value)
          groups = groups.filter(opt => {
            return opt.type === module
          })

          return groups
        } catch (error) {
          return []
        }
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  return {
    fetchApi,
    fetchApiPost,
    fetchById,
    deleteById,
    getFormGroups,
    ...toRefs(state),
  }
}
