export default {
  namespaced: true,
  state: {
    reportData: {},
  },
  getters: {
    getReportData: state => state.reportData,
  },
  mutations: {
    setReportData(state, value) {
      state.reportData = value
    },
  },
  actions: {},
}
