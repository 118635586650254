<template>
  <v-list-item
    v-bind="linkProps"
    :class="{ 'v-item--active v-list-item--active': isActive }"
    class="white--text"
    @click="consolePersistant(item)"
  >
    <v-list-item-icon>
      <v-icon :size="item.icon ? 22 : 15" color="white">
        {{ item.icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import useHorizontalNavMenuLink from '@core/layouts/composable/horizontal-nav/useHorizontalNavMenuLink'
import themeConfig from '@themeConfig'
import store from '@/store'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useHorizontalNavMenuLink(props.item)

    const consolePersistant = item => {
      const menuTab = {
        attribute: item.title,
      }
      store.dispatch('tabPersist/SET_TAB_STATE', menuTab)
    }

    return {
      consolePersistant,
      isActive,
      linkProps,
      // alternate icons
      alternateIcon: themeConfig.menu.groupChildIcon,
    }
  },
}
</script>
