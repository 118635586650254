<template>
  <v-app
    class="content-layout horizontal-nav"
    :class="{ 'content-full': appContentWidth === 'full' }"
  >
    <v-navigation-drawer v-model="launcherDrawer" app temporary right width="450">
      <div class="text-right my-3 mx-9">
        <v-icon color="dark" @click="launcherDrawer = false">{{ mdiClose }}</v-icon>
      </div>
      <Launcherdrawer @close="launcherDrawer = false" />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="openTaskBar"
      temporary
      overlay-opacity="0.1"
      app
      right
      width="720"
      class="app-task-bar"
    >
      <ViewTaskBar v-if="openTaskBar" />
    </v-navigation-drawer>
    <!-- Navbar -->
    <v-system-bar
      app
      height="52"
      color="primary"
      :absolute="appBarType === 'static'"
      :class="[
        { 'app-system-bar-boxed': appContentWidth === 'boxed' },
        { 'bg-blur': appBarIsBlurred },
      ]"
      class="app-system-bar"
    >
      <slot name="navbar" :launcherDrawer="setLauncherDrawer"></slot>
    </v-system-bar>

    <!-- Horizontal Nav Menu -->
    <v-app-bar
      v-if="resolveTabComponent"
      class="navigation-menu"
      color="#fff"
      app
      :height="43"
      flat
      :absolute="appBarType === 'static'"
      :elevation="$vuetify.theme.isDark ? 4 : 0"
    >
      <component :is="resolveTabComponent" :is-border-visible="true"></component>
    </v-app-bar>
    <slot name="v-app-content"></slot>

    <v-main>
      <!-- <app-content-container> -->
      <slot></slot>
      <!-- </app-content-container> -->
    </v-main>
    <v-overlay
      :value="$store.state.app.shallContentShowOverlay"
      z-index="6"
      absolute
      class="content-overlay"
    ></v-overlay>

    <!-- <v-footer
      v-if="footerType !== 'hidden'"
      app
      inset
      :absolute="footerType === 'static'"
      padless
      :color="footerType === 'static' ? 'transparent' : null"
    >
      <v-col cols="12">
        <slot name="footer"></slot>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import HorizontalTabMenu from '@/@core/layouts/components/horizontal-tab-menu/HorizontalTabMenu.vue'
import CrmMenu from '@/@core/layouts/components/horizontal-tab-menu/CrmMenu.vue'
import SetupMenu from '@/@core/layouts/components/horizontal-tab-menu/SetupMenu.vue'
import TaskMenu from '@/@core/layouts/components/horizontal-tab-menu/TaskMenu.vue'
import VendorMenu from '@/@core/layouts/components/horizontal-tab-menu/VendorMenu.vue'
import MaterialMenu from '@/@core/layouts/components/horizontal-tab-menu/MaterialMenu.vue'
import EstimateMenu from '@/@core/layouts/components/horizontal-tab-menu/EstimateMenu.vue'
import ReportMenu from '@/@core/layouts/components/horizontal-tab-menu/ReportMenu.vue'
import DashboardMenu from '@/@core/layouts/components/horizontal-tab-menu/DashboardMenu.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import ViewTaskBar from '@/components/task-management/ViewTaskBar.vue'
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import { useRouter } from '@core/utils'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import { storeGetters } from '@/store/storeConstants'
import Launcherdrawer from '@/components/shared-components/Launcherdrawer.vue'
import { mdiClose } from '@mdi/js'

export default {
  components: {
    AppContentContainer,
    HorizontalTabMenu,
    SetupMenu,
    TaskMenu,
    EstimateMenu,
    MaterialMenu,
    VendorMenu,
    CrmMenu,
    ReportMenu,
    DashboardMenu,
    Launcherdrawer,
    ViewTaskBar,
  },
  setup(props, { root }) {
    const launcherDrawer = ref(false)
    const setLauncherDrawer = () => {
      launcherDrawer.value = !launcherDrawer.value
    }
    const { route } = useRouter()
    const { GET_SUB_TAB, GET_FIXED_SUBTAB } = storeGetters
    const { menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } =
      useAppConfig()

    const resolveTabComponent = computed(() => {
      if (route.value.meta.app === 'set-up') return 'setup-menu'
      if (route.value.meta.app === 'estimate') return 'estimate-menu'
      if (route.value.meta.app === 'taskManagement') return 'task-menu'
      if (route.value.meta.app === 'vendorManagement') return 'vendor-menu'
      if (route.value.meta.app === 'reports') return 'report-menu'
      if (route.value.meta.app === 'dashboards') return 'dashboard-menu'
      if (route.value.meta.app === 'materialManagement') return 'material-menu'
      if (route.value.meta.app === 'crm') return 'crm-menu'

      return null // crm
    })

    const isSubTab = computed(() => {
      const subtab = root.$store.getters[`tabs/${GET_SUB_TAB}`]
      const fixedSubTab = root.$store.getters[`setupTab/${GET_FIXED_SUBTAB}`]
      if (subtab.length || fixedSubTab) {
        return true
      }

      return false
    })

    const openTaskBar = computed({
      get() {
        return store.getters['taskManagement/getOpenTaskBar']
      },
      set(value) {
        store.commit('taskManagement/SET_OPEN_BAR', value)
      },
    })

    return {
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      resolveTabComponent,
      isSubTab,
      launcherDrawer,
      openTaskBar,
      setLauncherDrawer,
      mdiClose,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.app-content-container {
  padding: $content-padding-horizontal-navigation-menu;
  &.app-content-container-boxed {
    padding: $boxed-content-padding-horizontal-navigation-menu;
  }
}

@include theme(v-application) using ($material) {
  .app-system-bar {
    box-shadow: 0 1px 0 0 map-deep-get($material, 'dividers');
  }
  .v-app-bar.navigation-menu {
    border-bottom: thin solid map-deep-get($material, 'dividers');
  }
}

.v-application {
  // System bar
  .app-system-bar {
    padding: 0 !important;
    // border-bottom: thin solid rgba(94, 86, 105, 0.14);
    // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);

    &.app-system-bar-boxed {
      ::v-deep > div:first-child {
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    ::v-deep > div:first-child {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;
    }
  }

  // App Bar
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding: 0;
    }

    .horizontal-nav-menu {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;

      &.horizontal-nav-menu-boxed {
        width: 1440px;
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
      }
    }
  }

  // @media screen and (max-width: 1711px) {
  //   margin-left: 1.5rem !important;
  //   margin-right: 1.5rem !important;
  // }

  // Footer
  .v-footer > div {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    // Padding value is from `$boxed-content-padding-horizontal-navigation-menu`
    // We will keep top and buttom padding value of footer as it is
    padding-left: 3rem;
    padding-right: 3rem;

    @at-root .content-layout.content-full {
      .v-footer > div {
        max-width: unset;
        padding-left: $content-padding-vertical-navigation-menu !important;
        padding-right: $content-padding-vertical-navigation-menu !important;
      }
    }
  }
}
</style>
