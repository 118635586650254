import {
    mdiDesktopMacDashboard,
    mdiCommentMultipleOutline,
    mdiContacts,
    mdiFirebase 
  } from '@mdi/js'
  
  export default [
    {
      title: 'Dashboard',
      icon: mdiDesktopMacDashboard,
      to: 'admin-dashboard',
    },
  
    {
      title: 'Customer License',
      icon: mdiCommentMultipleOutline,
      children: [
  
        {
          title: 'Customer',
          to: 'customer-license',
        },
        {
          title: 'Coupon',
          to: 'coupon',
        },
        {
          title:'Offline Purchase',
          to:'offline-purchase',
        },
        {
          title: 'Pending Approvals',
          to: 'pending-activation',
        },
        {
          title:'Approval History',
          to:'approval-history',
        },
      ],
    },
    {
      title: 'Knowledge-Base',
      icon: mdiFirebase,
      to: 'knowledge-base',
    },
    {
      title: 'Support',
      icon: mdiContacts,
      to: 'admin-support',
    },
    {
      title: 'Escalation ',
      icon: mdiContacts,
      to: 'escalation',
    },
  ]
  