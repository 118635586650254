<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="5"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="getDate"
        :placeholder="label"
        :prepend-inner-icon="icons.mdiClockTimeFourOutline"
        readonly
        :rules="[...rules]"
        hide-details="auto"
        solo
        flat
        dense
        outlined
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="getDate"
      color="primary"
      full-width
      v-bind="$attrs"
      @click:minute="handleInput"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { mdiClockTimeFourOutline } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  props: {
    label: {
      type: String,
      default: 'Date',
    },
    date: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const menu = ref(false)
    const getDate = computed({
      get() {
        return props.date
      },
      set(val) {
        emit('update:date', val)
      },
    })

    const handleInput = () => {
      menu.value = false
      emit('inputEvent')
    }

    return {
      menu,
      getDate,
      handleInput,
      required,
      icons: {
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>

<style></style>
