<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isVisible" max-width="650px" scrollable @keydown.esc="closeModal" @click:outside="closeModal">
    <v-card id="task-board-modal" class="user-edit-info">
      <v-card-title class="d-flex justify-space-between text-base px-10 pt-10">
        <span
          >Share <b>"{{ folderData.name }}"</b>
        </span>
        <span>
          <v-icon @click="closeModal">{{ icons.mdiClose }}</v-icon>
        </span>
      </v-card-title>

      <v-card-text class="px-10 pb-10">
        <v-form ref="folderForm" class="multi-col-validation mt-4">
          <v-row>
            <v-col cols="7" class="">
              <p class="mb-2 text--primary font-weight-medium text-sm">Add People</p>
              <v-autocomplete
                v-model="receiverId"
                :items="userList"
                outlined
                item-text="username"
                item-value="uuid"
                :rules="[required]"
                dense
                autocomplete="null"
                placeholder="Search with name"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" class="text-center px-0">
              <v-autocomplete
                v-model="permissionType"
                :items="permissionList"
                outlined
                item-text="text"
                item-value="value"
                :rules="[required]"
                class="mt-7"
                dense
                autocomplete="null"
                label="Access level"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                color="primary"
                tile
                class="mt-8 py-4"
                :loading="loading"
                small
                @click.prevent="add"
              >
                Share
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          :headers="tableHeaders"
          :items="sharedUserList"
          :items-per-page="5"
          class="borderless-table"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name }} </span>
          </template>
          <template v-slot:[`item.permission`]="{ item }">
            <div>
              <TaskSelectInput
                :item="item"
                :version-data="folderData"
                :permission-list="permissionList"
                @fetchData="loadSharedUser"
                @onUpdate="onUpdate"
              />
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="deleteUser(item)"> {{ icons.mdiDelete }}</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import Toaster from '@core/utils/sweetToast'
import {
  mdiContentCopy,
  mdiClose,
  mdiDelete,
  mdiLockOpenOutline,
  mdiRefresh,
  mdiCalendar,
} from '@mdi/js'
import TaskSelectInput from '@/components/shared-components/TaskSelectInput.vue'
import { ERROR_RESPONSE, SUCCESS_RESPONSE } from '@/constants/appConstants'
import moment from 'moment'

export default {
  components: {
    TaskSelectInput,
  },
  props: {
    isShareDialogOpen: {
      type: Boolean,
      required: true,
    },
    folderData: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: () => ['Email', 'Link'],
    },
  },
  setup(props, { emit }) {
    const { GET_ALL_RECORDS, CREATE, DELETE_BY_ID } = storeActions
    const folderForm = ref(null)
    const loading = ref(false)
    const receiverId = ref(null)
    const permissionType = ref(null)
    const publicPermission = ref(null)
    const valid_till = ref(null)
    const userList = ref([])
    const sharedUserList = ref([])
    const publicUrlList = ref([])
    const openLockModal = ref(false)
    const lockForm = ref({})
    const isPickerOpen = ref(false)
    const tableHeaders = [
      {
        text: 'Recent Users',
        value: 'name',
        align: 'start',
        sortable: false,
      },
      {
        text: '',
        value: 'permission',
        sortable: false,
      },
      {
        text: '',
        value: 'action',
        align: 'end',
        sortable: false,
      },
    ]

    const permissionList = [
      { text: 'View', value: 0 },
      { text: 'Edit', value: 1 },
      // { text: 'View & Download', value: 'viewAndDownload' },
      { text: 'Full Access', value: 2 },
    ]
    const tabType = ref('Link')
    const urlLoading = ref(false)
    const revokeLoading = ref(false)
    const switch1 = ref(true)
    const sharedUrl = ref(null)
    const reCreateLink = ref(false)
    const isVisible = computed({
      get: () => props.isShareDialogOpen,
      set: () => {
        return false
      },
    })
    const getPermissionText = text => {
      const find = permissionList.find(opt => {
        return opt.value === text
      })
      if (find) {
        return find.text
      }

      return 'N/A'
    }
    const lockUrl = data => {
      openLockModal.value = true
      lockForm.value = { ...data }
    }
    const closeModal = () => {
      folderForm.value?.reset()
      tabType.value = 'Link'
      valid_till.value = null
      emit('closeModal')
    }

    const loadSharedUser = () => {
      const payload = {
        component: `task-management/folders/share/${props.folderData.uuid}`,
      }
      store.dispatch(`common/${GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          sharedUserList.value = data.data
        }
      })
    }

    const deleteUser = item => {
      Toaster.confirmation().then(resp => {
        if (resp.isConfirmed) {
          const payload = {
            component: 'task-management/folders/share',
            objectId: `${props.folderData.uuid}?shared_user_id=${item.uuid}`,
          }

          store
            .dispatch(`common/${DELETE_BY_ID}`, payload)
            .then(({ data }) => {
              if (data.success) {
                loadSharedUser()
                Toaster.success(SUCCESS_RESPONSE, 'warning')
              } else {
                Toaster.error(data.message, 'warning')
              }
            })
            .catch(err => {
              if (err?.response.status === 403) {
                Toaster.error(err.response.data.message)
              } else {
                Toaster.error()
              }
            })
        }
      })
    }

    loadSharedUser()

    const loadMore = () => {
      const payload = {
        component: 'security/users',
        filter: {
          limit: -1,
        },
      }
      store.dispatch(`common/${GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          userList.value = data.data
        }
      })
    }

    loadMore()

    const add = () => {
      const isValid = folderForm.value.validate()
      if (!isValid) return
      loading.value = true
      const payLoad = {
        object: {
          folder_id: props.folderData.uuid,
          shared_user_id: receiverId.value,
          permission: permissionType.value,
        },
        component: 'task-management/folders/share',
      }

      store
        .dispatch(`common/${CREATE}`, payLoad)
        .then(() => {
          loading.value = false
          Toaster.success('Item shared successfully', 'success')
          emit('fetchData')
          closeModal()
        })
        .catch(err => {
          if (err?.response.status === 403) {
            Toaster.error(err.response.data.message)
          } else {
            Toaster.error()
          }
          loading.value = false
        })
    }

    const onUpdate = () => {
      const payLoad = {
        component: 'task-management/folders/share',
        object: {
          folder_id: props.fetchData.uuid,
          permission: this.input,
          shared_user_id: this.item.uuid,
          folder_id: '281715b1-dafb-4275-9541-74ec3573b44c',
          shared_user_id: '947812b2-f95b-4642-ad59-45fbcbd1a5ba',
          permission: 'read',
        },
      }
      this.isLoading = true
      this.$store
        .dispatch(`common/${storeActions.UPDATE}`, payLoad)
        .then(({ data }) => {
          this.isLoading = false
          if (data.success) {
            Toaster.success('Permission Updated successfully', 'success')
            this.$emit('fetchData')
            this.isEditable = false
          } else {
            Toaster.error(data.mesage, 'warning')
          }
        })
        .catch(() => {
          this.isLoading = false
          Toaster.error('An eror occur, please try again later', 'warning')
        })
    }

    const allowedDates = val => {
      if (moment().isSame(val, 'day')) {
        return true
      }

      return moment(val, 'YYYY-MM-DD').isAfter(moment())
    }

    return {
      folderForm,
      allowedDates,
      loading,
      isVisible,
      onUpdate,
      userList,
      receiverId,
      tabType,
      permissionList,
      permissionType,
      reCreateLink,
      mdiRefresh,
      publicPermission,
      revokeLoading,
      openLockModal,
      lockForm,
      isPickerOpen,
      sharedUserList,
      tableHeaders,
      switch1,
      urlLoading,
      publicUrlList,
      sharedUrl,
      deleteUser,
      getPermissionText,
      lockUrl,
      valid_till,

      // methods
      add,
      closeModal,
      loadSharedUser,

      // validation rules
      required,
      icons: {
        mdiContentCopy,
        mdiClose,
        mdiLockOpenOutline,
        mdiDelete,
        mdiRefresh,
        mdiCalendar,
      },
    }
  },
}
</script>
<style lang="scss">
.shared-link-container {
  button.link-btn {
    border: thin solid #e2e1e4;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    height: 32px !important;
    min-height: 32px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.custom-tab {
  &.active-email {
    padding-bottom: 7px;
    border-bottom: 3px solid #007eb9;
  }
  &.active-link {
    padding-bottom: 7px;
    border-bottom: 3px solid #007eb9;
  }
}
</style>
