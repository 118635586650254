<template>
  <v-card flat class="pa-4 mb-4" style="border: thin solid #dadbdf">
    <div class="d-flex justify-space-between mb-3">
      <div class="d-flex align-center">
        <v-avatar v-if="comment.commented_by" :color="'info'" size="35">
          <span class="font-weight-medium white--text">{{
            avatarText(`${comment.commented_by.firstname} ${comment.commented_by.lastname}`)
          }}</span>
        </v-avatar>

        <div class="ml-2">
          <p v-if="comment.commented_by" class="mb-0 text--primary text-sm">
            {{ comment.commented_by.firstname }} {{ comment.commented_by.lastname }}
          </p>
          <p class="mb-0 text--primary text-xs">{{ moment(comment.createdAt).fromNow() }}</p>
        </div>
      </div>
      <div v-if="userInfo && comment.commented_by && userInfo.uuid === comment.commented_by.uuid">
        <v-menu
          bottom
          left
          offset-y
          content-class="menu-component"
          transition="scale-transition"
          origin
          min-width="120"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon :data-cy="`action-post-${index}`" v-bind="attrs" v-on="on">
              {{ icons.mdiArrowDownDropCircleOutline }}
            </v-icon>
          </template>

          <v-list class="px-2 menu-list">
            <v-list-item
              :data-cy="`edit-post-${index}`"
              link
              class="menu-list-item"
              @click="editPost(comment)"
            >
              <v-list-item-title class="text-sm"> Edit</v-list-item-title>
            </v-list-item>
            <v-list-item
              :data-cy="`delete-post-${index}`"
              link
              class="menu-list-item"
              @click="deletePost()"
            >
              <v-list-item-title class="text-sm"> Delete </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <span
      class="mb-3 text--primary comment-img"
      @click="viewTargetElm"
      v-html="comment.comment"
    ></span>
    <v-divider class="mt-2 mb-2"></v-divider>
    <!-- <div class="d-flex justify-space-between">
      <div>
        <span @click="isComment = true">
          <v-icon size="17">{{ icons.mdiCommentProcessingOutline }}</v-icon> Reply
        </span>
      </div>
      <div>
        <span class="text-link" @click="fetchReplies">{{ comment.reply_count }} replies</span>
      </div>
    </div> -->

    <!-- <v-divider class="mt-2 mb-2"></v-divider> -->

    <!-- <div
      v-for="reply in replies"
      :key="reply.uuid"
      class="d-flex align-start justify-space-between mt-2"
    >
      <div class="d-flex align-start">
        <v-avatar v-if="reply.commented_by" :color="'info'" size="35">
          <span class="font-weight-medium white--text">{{
            avatarText(`${reply.commented_by.firstname} ${reply.commented_by.lastname}`)
          }}</span>
        </v-avatar>
        <div class="ml-2">
          <p v-if="reply.commented_by" class="mb-0 text--primary text-sm">
            {{ reply.commented_by.firstname }} {{ reply.commented_by.lastname }}
          </p>
          <p class="mb-0 text--primary text-xs">{{ moment(reply.createdAt).fromNow() }}</p>

          <div
            class="text--primary bg-light comment-img"
            v-html="reply.comment"
            @click="viewTargetElm"
          ></div>
        </div>
      </div>

      <div>
        <v-menu
          bottom
          left
          offset-y
          content-class="menu-component"
          transition="scale-transition"
          origin
          min-width="120"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ icons.mdiArrowDownDropCircleOutline }}</v-icon>
          </template>

          <v-list class="px-2 menu-list">
            <v-list-item link class="menu-list-item" @click="editPost(reply)">
              <v-list-item-title class="text-sm"> Edit</v-list-item-title>
            </v-list-item>
            <v-list-item link class="menu-list-item" @click="deletePost(reply.uuid)">
              <v-list-item-title class="text-sm"> Delete </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mt-2">
      <v-text-field
        v-if="!isComment"
        placeholder="Write a reply"
        :data-cy="`reply-input-${index}`"
        class="custom-input"
        outlined
        hide-details
        dense
        @focus="isComment = true"
      ></v-text-field>
      <div v-else :data-cy="`reply-comment-${index}`">
        <chatter-post
          :prop-type="propType"
          :prop-id="propId"
          :comment-form="commentForm"
          @commentEvent="commentEvent"
          @cancelComment="isComment = false"
        ></chatter-post>
      </div>
    </div> -->
    <comment-modal
      :open-comment-modal="openCommentModal"
      :modal-form="modalForm"
      :prop-type="propType"
      :prop-id="propId"
      @fetchData="fetchReplies(), $emit('fetchData')"
      @close="openCommentModal = false"
    ></comment-modal>
    <image-viewer
      v-if="isOpen"
      :viewerobj="previewData"
      :show-download="false"
      :loading="false"
      @close="isOpen = false"
    />
  </v-card>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import moment from 'moment'
import { storeActions, storeGetters } from '@/store/storeConstants'
import store from '@/store'
import { useRouter } from '@core/utils'
import Toaster from '@core/utils/sweetToast'
import { SUCCESS_RESPONSE, ERROR_RESPONSE } from '@/constants/appConstants'
import { avatarText } from '@core/utils/filter'
import ImageViewer from '@/components/shared-components/ImageViewer.vue'
import {
  mdiArrowDownDropCircleOutline,
  mdiThumbUpOutline,
  mdiCommentProcessingOutline,
} from '@mdi/js'
import ChatterPost from './ChatterPost.vue'
import CommentModal from './CommentModal.vue'

export default {
  components: {
    ChatterPost,
    CommentModal,
    ImageViewer,
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    propType: {
      type: String,
      required: true,
    },
    propId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const isComment = ref(false)
    const { route } = useRouter()
    const replies = ref([])
    const openCommentModal = ref(false)
    const previewData = ref({})
    const modalForm = ref({})
    const isOpen = ref(false)
    const commentForm = ref({
      attachments: [],
      comment: null,
      loading: false,
    })

    const customToolbar = [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: 'left' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ color: 'color-picker' }],
    ]
    const userInfo = computed(() => store.getters[`auth/${storeGetters.GET_USER_INFO}`])

    const fetchReplies = () => {
      const payload = {
        component: 'setup/comments/list',
        object: {
          parent_id: props.comment.uuid,
        },
      }
      store.dispatch(`common/${storeActions.CREATE}`, payload).then(({ data }) => {
        if (data.success) {
          replies.value = data.data
        } else {
          replies.value = []
        }
      })
    }

    watch(
      () => root.$route.query,
      () => {
        if (route.value.query.chatter === 'true' && route.value.query.chatterId) {
          fetchReplies()
        }
      },
      { immediate: true },
    )
    const deletePost = (replyId = null) => {
      Toaster.confirmation().then(resp => {
        if (resp.isConfirmed) {
          const payload = {
            component: 'setup/comments',
            objectId: replyId || props.comment.uuid,
          }
          store
            .dispatch(`common/${storeActions.DELETE_BY_ID}`, payload)
            .then(({ data }) => {
              if (data.success) {
                Toaster.success(data.message)
                if (replyId) {
                  fetchReplies()
                  emit('fetchData')
                } else {
                  emit('fetchData')
                }
              } else {
                Toaster.error(data.message, 'warning')
              }
            })
            .catch(err => {
              if (err?.response.status === 403) {
                Toaster.error(err.response.data.message)
              } else {
                Toaster.error()
              }
            })
        }
      })
    }

    const viewTargetElm = e => {
      if (e.target.tagName === 'IMG') {
        previewData.value = { path: e.target.src, type: null, file: null }
        isOpen.value = true
      }
    }
    const editPost = item => {
      openCommentModal.value = true
      modalForm.value = {
        postId: item.uuid,
        attachments: [],
        comment: item.comment,
        loading: false,
      }
    }

    const commentEvent = () => {
      commentForm.value.loading = true
      const pattern =
        /"[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let mentionIds = commentForm.value.comment.match(pattern)
      if (mentionIds) {
        mentionIds = mentionIds.map(opt => {
          return opt.substring(1)
        })
      }

      const attachmentPattern =
        /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let attachIds = commentForm.value.comment.match(attachmentPattern)
      if (attachIds) {
        attachIds = attachIds.map(opt => {
          return opt.substring(1)
        })
      }

      const payLoad = {
        object: {
          tagged_users: mentionIds || [],
          attachments: attachIds || [],
          comment: commentForm.value.comment,
          parent_id: props.comment.uuid,
        },
        component: 'setup/comments',
      }
      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(({ data }) => {
          commentForm.value.loading = false
          if (data.success) {
            commentForm.value = {
              loading: false,
              comment: null,
              attachments: [],
            }
            fetchReplies()
            emit('fetchData')
            isComment.value = false
            Toaster.success(data.message)
          } else {
            Toaster.error(data.message, 'warning')
          }
        })
        .catch(err => {
          commentForm.value.loading = false
          if (err?.response.status === 403) {
            Toaster.error(err.response.data.message)
          } else {
            Toaster.error()
          }
        })
    }

    return {
      isComment,
      customToolbar,
      fetchReplies,
      deletePost,
      openCommentModal,
      replies,
      editPost,
      userInfo,
      isOpen,
      previewData,
      commentEvent,
      modalForm,
      moment,
      commentForm,
      viewTargetElm,
      avatarText,
      icons: {
        mdiArrowDownDropCircleOutline,
        mdiThumbUpOutline,
        mdiCommentProcessingOutline,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.bg-light {
  background: #f0f2f5;
  /* padding: 7px; */
  border-radius: 23px;
  /* padding: 0px 17px; */
  padding-right: 17px;
  padding-left: 17px;
  padding-top: 15px;
  padding-bottom: 0.1px;
}
</style>
<style lang="scss">
.comment-img {
  img {
    max-width: 100% !important;
  }
}
</style>
