<template>
  <v-list-item
    v-bind="linkProps"
    :class="{ 'v-item--active v-list-item--active': isActive }"
    class="white--text"
    @click="$emit('consolePersistant', item)"
  >
    <v-list-item-icon>
      <v-icon class="white--text" :size="item.icon ? 22 : 15">
        {{ item.icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import useHorizontalNavMenuLink from '@core/layouts/composable/horizontal-nav/useHorizontalNavMenuLink'
import themeConfig from '@themeConfig'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useHorizontalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,

      // alternate icons
      alternateIcon: themeConfig.menu.groupChildIcon,
    }
  },
}
</script>
