const dashboard = [
  // {
  //   path: '/crm/dashboard-exp',
  //   name: 'dashboard-crm',
  //   component: () => import('@/views/dashboards/crm/Crm.vue'),
  //   meta: {
  //     layout: 'tab',
  //     title: 'CRM',
  //     app: 'crm',
  //     appId: 1,
  //   },
  // },
  {
    path: '/crm/dashboard',
    name: 'dashboard-crm',
    component: () => import('@/views/crm/dashboard/dashboard.vue'),
    meta: {
      layout: 'tab',
      title: 'CRM',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/account',
    name: 'crm-account',
    component: () => import('@/views/crm/accounts/Accounts.vue'),
    meta: {
      layout: 'tab',
      title: 'Account',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/account',
    name: 'account-layout',
    component: () => import('@/views/crm/CrmLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      permission: 'account',
      permissionType: 'v',
      appId: 1,
    },
    children: [
      {
        path: ':uuid/view/:id?',
        name: 'account-view',
        component: () => import('@/views/crm/accounts/AccountView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'account',
          permissionType: 'v',
          appId: 1,
        },
      },
      {
        path: ':uuid/opportunity',
        name: 'account-opportunity',
        component: () => import('@/views/crm/opportunity/OpportunityView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'opportunity',
          permissionType: 'v',
          appId: 1,
        },
      },
      {
        path: ':uuid/contact',
        name: 'account-contact',
        component: () => import('@/views/crm/contacts/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'contact',
          permissionType: 'v',
          appId: 1,
        },
      },
    ],
  },
  {
    path: '/crm/opportunity',
    name: 'crm-opportunity',
    component: () => import('@/views/crm/opportunity/opportunity.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/opportunity',
    name: 'opportunity-layout',
    component: () => import('@/views/crm/CrmLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      permission: 'opportunity',
      permissionType: 'v',
      appId: 1,
    },
    children: [
      {
        path: ':uuid/view',
        name: 'opportunity-view',
        component: () => import('@/views/crm/opportunity/OpportunityView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'opportunity',
          permissionType: 'v',
          appId: 1,
        },
      },
      {
        path: ':uuid/contact',
        name: 'opportunity-contact',
        component: () => import('@/views/crm/contacts/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'contact',
          permissionType: 'v',
          appId: 1,
        },
      },
      {
        path: ':uuid/contact',
        name: 'opportunity-account',
        component: () => import('@/views/crm/accounts/AccountView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          permission: 'account',
          permissionType: 'v',
          appId: 1,
        },
      },
    ],
  },
  {
    path: '/crm/report/:id?',
    name: 'dashboard-eCommerce',
    component: () => import('@/views/dashboards/crm/Crm.vue'),
    meta: {
      layout: 'tab',
      title: 'Report',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/contact',
    name: 'crm-contact',
    component: () => import('@/views/crm/contacts/Contacts.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/contact',
    name: 'contact-layout',
    component: () => import('@/views/crm/CrmLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
      permission: 'contact',
      permissionType: 'v',
    },
    children: [
      {
        path: ':uuid/view',
        name: 'contact-view',
        component: () => import('@/views/crm/contacts/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          appId: 1,
          permission: 'contact',
          permissionType: 'v',
        },
      },
      {
        path: ':uuid/opportunity',
        name: 'contact-opportunity',
        component: () => import('@/views/crm/opportunity/OpportunityView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          appId: 1,
          permission: 'opportunity',
          permissionType: 'v',
        },
      },
    ],
  },
  {
    path: '/crm/lead',
    name: 'crm-lead',
    component: () => import('@/views/crm/lead/lead.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/lead',
    name: 'lead-layout',
    component: () => import('@/views/crm/CrmLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
      permission: 'lead',
      permissionType: 'v',
    },
    children: [
      {
        path: ':uuid/view',
        name: 'lead-view',
        component: () => import('@/views/crm/lead/LeadView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          appId: 1,
          permission: 'lead',
          permissionType: 'v',
        },
      },
      {
        path: ':uuid/account',
        name: 'lead-account',
        component: () => import('@/views/crm/accounts/AccountView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          appId: 1,
          permission: 'account',
          permissionType: 'v',
        },
      },
      {
        path: ':uuid/opportunity',
        name: 'lead-opportunity',
        component: () => import('@/views/crm/opportunity/OpportunityView.vue'),
        meta: {
          layout: 'tab',
          app: 'crm',
          appId: 1,
          permission: 'opportunity',
          permissionType: 'v',
        },
      },
    ],
  },
  {
    path: '/crm/lead/:uuid/convert/:convertId',
    name: 'lead-convert',
    component: () => import('@/views/crm/lead/LeadConvert.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/:prop_type/import',
    name: 'import-data',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
  {
    path: '/crm/recent-changes',
    name: 'crm-recent-changes',
    component: () => import('@/views/crm/CrmRecentChanges.vue'),
    meta: {
      layout: 'tab',
      app: 'crm',
      appId: 1,
    },
  },
]

export default dashboard
