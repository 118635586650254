import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'

const { GET_ALL_TAKE_OFF, GET_TAKE_OFF, GET_MATERIALS, GET_TAKE_OFF_LOADER, GET_ESTIMATE_ID, GET_TAKE_OFF_BY_ASSEMBLY } = storeGetters
const { FETCH_TAKEOFF, ESTIMATE_SELECTED, CHANGE_TAKEOFF, TAKE_OFF_FILTERS, ASSEMBLY_SELECTED, FETCH_ASSEMBLY_DETAILS } = storeActions
const { RESET_TAKE_OFF, SET_TAKE_OFF, SET_ACTIVE_ASSEMBLY, SET_TAKE_OFF_FILTERS, SET_ASSEMBLY_DETAILS, SET_ESTIMATE_ID, SET_TAKE_OFF_LOADER } = storeMutations

export default {
  namespaced: true,
  state: {
    takeoff: [],
    filteredTakeOffs: [],
    takeoffLoader: false,
    assemblyId: null,
    assemblyDetails: {},
    estimateId: null,
  },
  getters: {
    [GET_ALL_TAKE_OFF]: state => {
      return state.takeoff
    },
    [GET_TAKE_OFF]: state => {
      // if assembly is selected filter out the takeoff else get all takeoff
      return state.takeoff
        .filter(opt => ((state.assemblyId ? opt.assembly_id === state.assemblyId : true)
          &&
          (state.filteredTakeOffs.length ? state.filteredTakeOffs.includes(opt.uuid) : true)
          ))
        .map(tf => {
          if (tf.path_style == null) {
            return { ...tf, path_style: { strokeColor: '#ff0000' } }
          }

          return tf
        })
    },
    [GET_TAKE_OFF_BY_ASSEMBLY]: state => assemblyId => {
      return state.takeoff.filter(i => i.assembly_id === assemblyId)
    },
    [GET_MATERIALS]: state => {
      return (
        state.assemblyDetails[state.assemblyId] &&
        state.assemblyDetails[state.assemblyId].details.materials
      )
    },
    [GET_TAKE_OFF_LOADER]: state => {
      return state.takeoffLoader
    },
    [GET_ESTIMATE_ID]: state => {
      return state.estimateId
    },
    GET_ASSEMBLY_ID: state => {
      return state.assemblyId
    },
    GET_TAKE_OFF_BY_ID: state => id => {
      return state.takeoff.find(i => i.uuid === id)
    }
  },
  mutations: {
    [SET_TAKE_OFF]: (state, payload) => {
      state.takeoff = payload
    },
    [RESET_TAKE_OFF]: state => {
      state.takeoff = []
    },
    [SET_ACTIVE_ASSEMBLY]: (state, payload) => {
      state.assemblyId = payload
    },
    [SET_ASSEMBLY_DETAILS]: (state, payload) => {
      state.assemblyDetails = payload
    },
    [SET_ASSEMBLY_DETAILS]: (state, payload) => {
      state.assemblyDetails = payload
    },
    [SET_TAKE_OFF_LOADER]: (state, payload) => {
      state.takeoffLoader = payload
    },
    [SET_ESTIMATE_ID]: (state, payload) => {
      state.estimateId = payload
    },
    [SET_TAKE_OFF_FILTERS]:  (state, payload) => {
      state.filteredTakeOffs = payload
    }
  },
  actions: {
    [FETCH_TAKEOFF]: ({ commit }, payload) => {
      commit(SET_TAKE_OFF_LOADER, true)
      api
        .findByParams(`${payload.component}`, { params: payload.filter })
        .then(({ data }) => {
          if (data.success && data.data) {
            const response = data.data.map(opt => {
              return {
                ...opt,
              }
            })
            commit(SET_TAKE_OFF, response)
          } else {
            commit(RESET_TAKE_OFF)
          }
        })
        .catch(() => {
          commit(RESET_TAKE_OFF)
        })
        .finally(() => {
          commit(SET_TAKE_OFF_LOADER, false)
        })
    },
    [CHANGE_TAKEOFF]: ({ commit, getters }, payload) => {
      const takeOffArray = [...getters[GET_TAKE_OFF]]

      const { uuid, details } = payload

      const index = takeOffArray.findIndex(opt => {
        return opt.uuid === uuid
      })

      if (index !== -1) {
        takeOffArray[index].details = details
        commit(SET_TAKE_OFF, takeOffArray)
      }
    },
    [ASSEMBLY_SELECTED]: ({ commit, getters }, uuid) => {
      const currentAssemblyId = getters.GET_ASSEMBLY_ID
      // select or deselect assembly on click
      if (currentAssemblyId === uuid) {
        commit(SET_ACTIVE_ASSEMBLY, null)
      } else {
        commit(SET_ACTIVE_ASSEMBLY, uuid)
      }
    },
    [TAKE_OFF_FILTERS]: ({ commit }, payload) => {
      let takeOffs = []
      payload.filters.forEach(item => {
        takeOffs = takeOffs.concat(payload.filterValues[item])
      })
      commit(SET_TAKE_OFF_FILTERS,  [ ...new Set(takeOffs)])
    },
    [ESTIMATE_SELECTED]: ({ commit }, payload) => {
      commit(SET_ESTIMATE_ID, payload)
    },
    [FETCH_ASSEMBLY_DETAILS]: ({ commit }, payload) => {
      commit(SET_ASSEMBLY_DETAILS, payload)
    },
  },
}
