import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import { ref, toRefs, reactive } from '@vue/composition-api'
import { isAdmin } from '@/plugins/authService'

export default () => {
  const workflowOptions = ref([])
  const inlineEditing = reactive({
    assigneeEdit: false,
    accessEdit: false,
    statusEdit: false,
    workflowEdit: false,
    titleEdit: false,
    summaryEdit: false,
    dueDateEdit: false,
  })
  const getWorkflowList = () => {
    const payLoad = {
      component: 'setup/taskManagement/workflow',
    }
    store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payLoad).then(({ data }) => {
      if (data.success) {
        workflowOptions.value = data.data.map(opt => {
          return {
            value: opt.uuid,
            text: opt.name,
          }
        })
      }
    })
  }

  const getTaskFolders = async () => {
    const payload = {
      component: 'task-management/folders',
      filter: {
        allFolders: true,
        type: 'folder',
      },
    }
    try {
      const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
      if (data.success) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  const getFoldersAndBoard = async search => {
    const payload = {
      component: 'task-management/folders',
      filter: {
        search,
        allFolders: true,
      },
    }
    try {
      const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
      if (data.success) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  const getPolicyPermission = (permission, policyPermission) => {
    if (permission === 'edit') return isAdmin() || policyPermission?.e
    if (permission === 'delete') return isAdmin() || policyPermission?.d
    if (permission === 'add') return isAdmin() || policyPermission?.a
    if (permission === 'import') return isAdmin() || policyPermission?.i
    if (permission === 'export') return isAdmin() || policyPermission?.x

    return false
  }
  const getFolderPermission = (permission, accessType) => {
    const access = Number(accessType)
    if (access === 0 && ['filter'].includes(permission)) return true
    if (access === 1 && ['edit', 'delete', 'filter', 'add'].includes(permission)) return true
    if (access === 2) return true

    return false
  }

  const tastEditing = e => {
    const titleElm = document.getElementById('title-area')
    const summaryElm = document.getElementById('summary-area')
    const assignElm = document.getElementById('assignee-area')
    const accessElm = document.getElementById('access-area')
    const statusElm = document.getElementById('staus-area')
    const workflowElm = document.getElementById('workflow-area')

    if (titleElm) {
      if (!titleElm.contains(e.target)) {
        inlineEditing.titleEdit = false
      }
    }
    if (summaryElm) {
      if (!summaryElm.contains(e.target)) {
        inlineEditing.summaryEdit = false
      }
    }
    if (workflowElm) {
      if (
        e.target.classList.contains('v-list-item__title') ||
        e.target.classList.contains('v-list-item__content') ||
        e.target.classList.contains('v-list-item')
      ) {
        inlineEditing.workflowEdit = true
      } else if (!workflowElm.contains(e.target)) {
        inlineEditing.workflowEdit = false
      }
    }

    if (assignElm) {
      if (
        e.target.classList.contains('v-list-item__title') ||
        e.target.classList.contains('v-list-item__content') ||
        e.target.classList.contains('v-list-item')
      ) {
        inlineEditing.assigneeEdit = true
      } else if (!assignElm.contains(e.target)) {
        inlineEditing.assigneeEdit = false
      }
    }
    if (accessElm) {
      if (
        e.target.classList.contains('v-list-item__title') ||
        e.target.classList.contains('v-list-item__content') ||
        e.target.classList.contains('v-list-item')
      ) {
        inlineEditing.accessEdit = true
      } else if (!accessElm.contains(e.target)) {
        inlineEditing.accessEdit = false
      }
    }
    if (statusElm) {
      if (
        e.target.classList.contains('v-list-item__title') ||
        e.target.classList.contains('v-list-item__content') ||
        e.target.classList.contains('v-list-item')
      ) {
        inlineEditing.statusEdit = true
      } else if (!statusElm.contains(e.target)) {
        inlineEditing.statusEdit = false
      }
    }
  }

  const loadTask = (path, data) => {
    const payload = {
      component: path,
      object: {
        ...data,
      },
    }

    return store.dispatch(`common/${storeActions.CREATE}`, payload)
  }

  const loadSharedTask = data => {
    const payload = {
      component: 'task-management/spaces/item/share/list',
      object: {
        ...data,
      },
    }

    return store.dispatch(`common/${storeActions.CREATE}`, payload)
  }

  const fetchWorkflowList = () => {
    const payLoad = {
      component: 'setup/taskManagement/workflow/list',
      object: {
        limit: -1,
      },
    }

    return store
      .dispatch(`common/${storeActions.CREATE}`, payLoad)
      .then(({ data }) => {
        if (data.success) {
          return data.data.map(opt => {
            return {
              value: opt.uuid,
              text: opt.name,
              module: opt.module,
            }
          })
        }

        return []
      })
      .catch(() => {
        return []
      })
  }

  const fetchWorkflowStatus = uuid => {
    const config = {
      component: `setup/taskManagement/workflow/${uuid}/status`,
    }

    return store
      .dispatch(`common/${storeActions.GET_ALL_RECORDS}`, config)
      .then(({ data }) => {
        if (data.success) {
          return data.data.map(opt => {
            return {
              value: opt.uuid,
              text: opt.name,
            }
          })
        }

        return []
      })
      .catch(() => {
        return []
      })
  }

  return {
    getWorkflowList,
    getTaskFolders,
    getFolderPermission,
    getPolicyPermission,
    getFoldersAndBoard,
    workflowOptions,
    fetchWorkflowList,
    loadSharedTask,
    fetchWorkflowStatus,
    loadTask,
    tastEditing,
    ...toRefs(inlineEditing),
  }
}
