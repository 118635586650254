import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import useProject from '@/components/project-management/project/useProject'

const { GET_PROJECT_ID, GET_PROJECT_DETAIL} = storeGetters
const { SET_PROJECT_ID, SET_PROJECT_DETAIL } = storeMutations
const { SET_PROJECT, SET_PROJECT_DETAIL_STATE } = storeActions
const { getProjectDetails } = useProject()

export default {
  namespaced: true,
  state: {
    projectId: null,
    detail: {}
  },
  getters: {
    [GET_PROJECT_ID]: state => state.projectId,
    [GET_PROJECT_DETAIL]: state => state.detail,
  },
  mutations: {
    [SET_PROJECT_ID]: (state, payload) => {
      state.projectId = payload.projectId
    },
    [SET_PROJECT_DETAIL]: (state, payload) => {
      state.detail = payload
    },
  },
  actions: {
    [SET_PROJECT]: ({ commit }, payload) => {
      commit(SET_PROJECT_ID, payload) 
      getProjectDetails()
    },
    [SET_PROJECT_DETAIL_STATE]: ({ commit }, payload) => {
      commit(SET_PROJECT_DETAIL, payload) 
    },
  },
}
