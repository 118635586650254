/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */
import { storeActions } from '@/store/storeConstants'
import axios from 'axios'
import router from '../router'
import store from '../store'

const { LOGOUT } = storeActions
class ApiService {
  constructor() {
    const service = axios.create({
      baseURL: process.env.VUE_APP_ROOT_API,
      // baseURL: process.env.VUE_APP_CRM_API,
    })

    // request interceptor
    service.interceptors.request.use(this.requestHandleSuccess, this.requestHandleError)
    // response interceptor
    // service.interceptors.request.use(config => {
    //   const token = localStorage.getItem('token')
    //   const newConfig = { ...config }
    //   if (token) {
    //     newConfig.headers['access-token'] = token
    //   }

    //   return config
    // })

    service.interceptors.response.use(this.responseHandleSuccess, this.responseHandleError)
    this.service = service
  }

  save(path, payLoad, config) {
    if (config) return this.service.post(path, payLoad, config)

    return this.service.post(path, payLoad)
  }

  update(path, payLoad, config) {
    return this.service.put(path, payLoad, config)
  }

  findByParams(path, params) {
    return this.service.get(path, params)
  }

  findAll(path) {
    return this.service.get(path)
  }

  delete(path, payLoad) {
    return this.service.delete(path, payLoad)
  }

  downlaod(path, params) {
    return this.service.get(path, params)
  }

  setCustomHeaders(key, value) {
    this.service.defaults.headers.common[key] = value
  }

  deleteCustomHeaders(key) {
    delete this.service.defaults.headers.common[key]
  }

  requestHandleSuccess(config) {
    const token = localStorage.getItem('token')

    // for public drive & password prottected set these token in api header
    const publicToken = sessionStorage.getItem('public-token')
    const shortToken = sessionStorage.getItem('short-token')
    const newConfig = { ...config }

    // for public url we can change token from here also
    if (token) {
      newConfig.headers['access-token'] = token
      newConfig.headers['x-referer'] = window.location.href
      newConfig.headers['x-bldon-admin'] = 1
    }
    if (publicToken) {
      newConfig.headers['public-token'] = publicToken
    }
    if (shortToken) {
      newConfig.headers['short-token'] = shortToken
    }

    return config
  }

  requestHandleError = error => Promise.reject(error)

  responseHandleSuccess(response) {
    return response
  }

  responseHandleError = error => {
    switch (error.response?.status) {
      case 400:
        console.log('requested resource error')
        break
      case 401:
        store.dispatch(`auth/${LOGOUT}`)
        router.push({ name: 'auth-login' })
        break
      case 404:
        console.log('requested resource not found')
        break
      case 403:
        // for public password protected file open passsword modal from here
        if (error.response.data.message === 'password protected file') {
          store.commit('workDrive/setPasswordProtectedState', true)
        }
        break
      default:
        console.log('default requested intecept')
        break
    }

    return Promise.reject(error)
  }
}
const api = new ApiService()
export default api
