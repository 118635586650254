const accounting = [
  {
    path: '/accounting/dashboard',
    name: 'account-dashboard',
    component: () => import('@/views/accounting/dashboard.vue'),
    meta: {
      layout: 'content',
      app: 'accounting',
      appId: 10,
    },
  },
  {
    path: '/accounting/accounts',
    name: 'account-setup',
    component: () => import('@/views/accounting/general/AccountsList.vue'),
    meta: {
      layout: 'content',
      app: 'accounting',
      appId: 10,
    },
  },
  {
    path: '/accounting/categories',
    name: 'account-category',
    component: () => import('@/views/accounting/categories/CategorieList.vue'),
    meta: {
      layout: 'content',
      app: 'accounting',
      appId: 10,
    },
  },
  {
    path: '/accounting/cost-code',
    name: 'account-cost-code',
    component: () => import('@/views/accounting/cost-code/CodeList.vue'),
    meta: {
      layout: 'content',
      app: 'accounting',
      appId: 10,
    },
  },
  {
    path: '/accounting/recent-change',
    name: 'account-recent-change',
    component: () => import('@/views/accounting/AccountingRecentChanges.vue'),
    meta: {
      layout: 'content',
      app: 'accounting',
      appId: 10,
    },
  },
]

export default accounting
