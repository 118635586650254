import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import Toaster from '@core/utils/sweetToast'

export default () => {
  const uploadStep1 = async payLoad => {
    try {
      const response = await store.dispatch(`common/${storeActions.CREATE}`, payLoad)

      return response
    } catch (err) {
      // this.loading = false
      Toaster.error('An eror occur, please try again later', 'warning')

      return false
    }
  }
  const uploadStep2 = async payLoad => {
    try {
      await store.dispatch(`common/${storeActions.UPDATE_FILE}`, payLoad)

      return true
    } catch (error) {
      // this.loading = false
      Toaster.error('An eror occur, please try again later', 'warning')

      return false
    }
  }

  const uploadStep3 = async (data, requestUrl = false) => {
    const payLoad = {
      object: {
        cloud_status: true,
        type: 1,
        requestUrl,
      },
      component: `workdrive/${data.uuid}`,
    }

    try {
      const resp = await store.dispatch(`common/${storeActions.UPDATE}`, payLoad)

      return resp
    } catch {
      Toaster.error('An eror occur, please try again later', 'warning')

      return false
    }
  }

  return {
    uploadStep1,
    uploadStep2,
    uploadStep3,
  }
}
