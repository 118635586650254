<template>
  <v-app>
    <navbar></navbar>
    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/shared-components/Navbar.vue'

export default {
  components: { Navbar },
}
</script>

<style></style>
