import {
  mdiFolderMultiple,
  mdiViewDashboard,
  mdiCommentMultipleOutline,
  mdiContacts,
  mdiFileDocumentMultiple,
  mdiOrderBoolAscendingVariant,
  mdiOrderBoolDescendingVariant,
  mdiRadar,
  mdiClipboardListOutline,
  mdiBookOpen,
} from '@mdi/js'

export default {
  default: [
    {
      title: 'Dashboard',
      icon: mdiViewDashboard,
      to: 'pm-dashboard',
      default: true,
    },
    {
      title: 'Projects',
      icon: mdiFolderMultiple,
      to: 'project-management',
      default: true,
    },
  ],
  more: [
    {
      title: 'Project Details',
      icon: mdiBookOpen,
      to: 'project-dashboard',
    },
    {
      title: 'Contact Directory',
      icon: mdiContacts,
      to: 'pm-contacts',
    },
    {
      title: 'RFI',
      icon: mdiCommentMultipleOutline,
      to: 'project-management-rfi',
    },
    {
      title: 'Punchlist',
      icon: mdiClipboardListOutline,
      to: 'punchlist',
    },
    {
      title: 'Change Order',
      icon: mdiOrderBoolAscendingVariant,
      to: 'change-order',
    },
    {
      title: 'Master Change Order',
      icon: mdiOrderBoolDescendingVariant,
      to: 'master-change-order',
    },
    {
      title: 'Submittal',
      icon: mdiRadar,
      to: 'submittal',
    },
    // {
    //   subheader: 'PROJECT MANAGEMENT',
    //   component: 'projects-nav',
    // },
    // {
    //   title: 'Project Details',
    //   icon: mdiDesktopMacDashboard,
    //   to: 'project-dashboard',
    //   component: 'vertical-project-menu-link',
    // },
    // {
    //   title: 'Communication',
    //   icon: mdiCommentMultipleOutline,
    //   children: [
    //     {
    //       title: 'RFI',
    //       to: 'project-management-rfi',
    //       component: 'vertical-project-menu-link',
    //     },
    //     {
    //       title: 'Email Communication',
    //       to: 'email-communication',
    //       component: 'vertical-project-menu-link',
    //     },
    //   ],
    // },

    {
      title: 'Document Management',
      icon: mdiFileDocumentMultiple,

      children: [
        {
          title: 'Document Management',
          to: 'document',
        },
        {
          title: 'Trash',
          to: 'trash',
        },
      ],
    },
  ],
}
