<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="openCommentModal" max-width="670px" scrollable @keydown.esc="closeModal" @click:outside="closeModal">
    <v-card data-cy="edit-comment" class="user-edit-info">
      <v-card-title class="d-flex justify-space-between text-lg text-primary pa-6 pb-4">
        <span> Edit Comment </span>
        <span>
          <v-icon @click="closeModal">{{ icons.mdiClose }}</v-icon>
        </span>
      </v-card-title>
      <v-card-text class="mt-3">
        <chatter-post
          :prop-id="propId"
          :prop-type="propType"
          :comment-form="modalForm"
          @commentEvent="commentEvent"
        ></chatter-post>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import { required } from '@/@core/utils/validation'
import Toaster from '@core/utils/sweetToast'
import { useRouter } from '@core/utils'
import { mdiClose } from '@mdi/js'
import { ERROR_RESPONSE } from '@/constants/appConstants'
import ChatterPost from './ChatterPost.vue'

export default {
  components: {
    ChatterPost,
  },
  props: {
    openCommentModal: {
      type: Boolean,
      default: false,
    },
    modalForm: {
      type: Object,
      required: true,
    },
    propType: {
      type: String,
      required: true,
    },
    propId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const userForm = ref(null)
    const { route } = useRouter()
    const statusOptions = [
      { title: 'True', value: true },
      { title: 'False', value: false },
    ]

    const modalFormData = computed(() => {
      return props.modalForm
    })
    const closeModal = () => {
      emit('close')
    }

    const commentEvent = () => {
      modalFormData.value.loading = true
      const pattern =
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let mentionIds = modalFormData.value.comment.match(pattern)

      if (mentionIds) {
        mentionIds = mentionIds.map(opt => {
          return opt.substring(1)
        })
      }

      const attachmentPattern =
        /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let attachIds = modalFormData.value.comment.match(attachmentPattern)
      if (attachIds) {
        attachIds = attachIds.map(opt => {
          return opt.substring(1)
        })
      }

      const payLoad = {
        object: {
          prop_id: route.value.params.uuid,
          prop_type: props.propType,
          tagged_users: mentionIds || [],
          attachments: attachIds || [],
          comment: props.modalForm.comment,
        },
        component: 'setup/comments',
        objectId: props.modalForm.postId,
      }
      store
        .dispatch(`common/${storeActions.UPDATE_BY_ID}`, payLoad)
        .then(({ data }) => {
          modalFormData.value.loading = false
          if (data.success) {
            modalFormData.value.comment = null
            modalFormData.value.attachments = []

            emit('fetchData')
            closeModal()
            Toaster.success(data.message)
          } else {
            Toaster.error(data.message, 'warning')
          }
        })
        .catch(err => {
          modalFormData.value.loading = false
          Toaster.error(err?.response?.data?.message ?? ERROR_RESPONSE)
        })
    }

    return {
      statusOptions,
      modalFormData,
      userForm,
      loading,
      required,
      commentEvent,
      closeModal,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.custom-form .form-sub-header {
  background-color: #f3f3f3;
  color: #000;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 0 0 6px 6px;
}
</style>
