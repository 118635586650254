<template>
  <div class="d-flex justify-space-between input-design align-center px-2">
    <p class="mb-0 dark--text">{{ label }}</p>
    <v-icon size="19" @click="$emit('handleClose')">{{ mdiClose }}</v-icon>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },

  setup() {
    return {
      mdiClose,
    }
  },
}
</script>

<style scoped>
.input-design {
  background: #ebecf0;
  height: 44px;
  border-radius: 5px;
}
</style>
