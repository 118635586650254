<template>
  <div>
    <div class="d-flex mb-3">
      <div class="w-one-third">
        <v-autocomplete
          v-model="generalInfo.workflow_id"
          :items="workflowOptions"
          hide-details="auto"
          class="inline-input"
          filled
          :readonly="hasReadOnlyAccess"
          rounded
          label="workflow"
          @change="onWorkflowChange"
        >
          <template v-slot:item="data">
            <v-list-item-title class="text-xs3">{{ data.item.text }}</v-list-item-title>
          </template>
        </v-autocomplete>
      </div>
      <div class="w-one-third">
        <v-autocomplete
          v-model="generalInfo.workflow_status_id"
          :items="workflowStatusOptions"
          :readonly="hasReadOnlyAccess"
          hide-details="auto"
          label="Status"
          class="inline-input ml-2"
          filled
          rounded
          @change="updateWorkFlow"
        >
          <template v-slot:item="data">
            <v-list-item-title class="text-xs3">{{ data.item.text }}</v-list-item-title>
          </template>
        </v-autocomplete>
      </div>
      <div class="ml-2 w-one-third">
        <v-menu
          ref="menuRef"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
          :return-value.sync="dates"
          clearable
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" class="autocomplete-btn" v-on="on">
              <p class="mb-1 text-xs2 label">Start & End Date</p>
              <p class="mb-0 text">{{ dateRangeText }}</p>
            </div>
          </template>
          <v-date-picker v-model="dates" no-title range :readonly="hasReadOnlyAccess">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="onChangeDate"> Save </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="d-flex">
      <div class="w-one-third">
        <v-menu
          :close-on-content-click="false"
          max-width="250px"
          nudge-top="35"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" class="autocomplete-btn" v-on="on">
              <p class="mb-1 text-xs2 label">Assignee</p>
              <span v-for="item in generalInfo.assigned_users" :key="item.uuid" class="chip mr-1">
                {{ avatarText(item.name) }}
              </span>
            </div>
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-autocomplete
                v-model="generalInfo.assignee_ids"
                :items="userOptions"
                hide-details="auto"
                class="inline-input"
                outlined
                :readonly="hasReadOnlyAccess"
                multiple
                placeholder="Assignee"
                @change="updateAssignee"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    label
                    class="task-chip"
                    close
                    light
                    :input-value="data.selected"
                    @click:close="removeAssignee(data.item)"
                  >
                    <v-avatar color="warning" rounded size="30" class="mr-2">
                      <span class="font-weight-semibold white--text text-xs">{{
                        avatarText(data.item.text)
                      }}</span>
                    </v-avatar>
                    <span class="dark--text font-weight-medium"> {{ data.item.text }} </span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div class="ml-2 w-one-third">
        <v-menu
          v-model="dueMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          left
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" class="autocomplete-btn" v-on="on">
              <p class="mb-1 text-xs2 label">Due Date</p>
              <p class="mb-0 text">{{ generalInfo.due_date }}</p>
            </div>
          </template>
          <v-date-picker
            v-model="generalInfo.due_date"
            :readonly="hasReadOnlyAccess"
            no-title
            @input="onChangeDueDate"
          >
          </v-date-picker>
        </v-menu>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import useSecurityService from '@/components/security/useSecurityService'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import moment from 'moment'
import { storeActions } from '@/store/storeConstants'
import { useRouter } from '@core/utils'
import eventBus from '@/@core/utils/eventBus'
import useTaskService from '../useTaskService'

export default {
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const { fetchWorkflowList, fetchWorkflowStatus } = useTaskService()
    const { searchUsers } = useSecurityService()
    const generalInfo = ref({})
    const workflowOptions = ref([])
    const userOptions = ref([])
    const workflowStatusOptions = ref([])
    const dates = ref([])
    const menu = ref(false)
    const dueMenu = ref(false)

    const menuRef = ref(null)

    const stateInit = () => {
      // copy the userInfo object
      const state = structuredClone(props.taskInfo)
      generalInfo.value = {
        name: state.name,
        uuid: state.uuid,
        spaceId: state.space_id,
        assigned_users: state.assigned_users.filter(opt => opt.uuid) || [],
        assignee_ids: state.assigned_users.filter(opt => opt.uuid).map(opt => opt.uuid),
        workflow_status_color: state.workflow_status_color,
        from_date: moment(state.from_date).isValid()
          ? moment(state.from_date)?.format('YYYY-MM-DD')
          : null,
        to_date: moment(state.to_date).isValid()
          ? moment(state.to_date)?.format('YYYY-MM-DD')
          : null,
        due_date: moment(state.due_date).isValid()
          ? moment(state.due_date).format('YYYY-MM-DD')
          : null,
        workflow: state.workflow,
        workflow_status: state.workflow_status,
        workflow_id: state.workflow_id,
        workflow_status_id: state.workflow_status_id,
      }
      dates.value = [generalInfo.value.from_date, generalInfo.value.to_date]
    }

    stateInit()
    watch(
      () => props.taskInfo,
      () => {
        stateInit()
      },
    )

    const dateRangeText = computed(() => {
      if (!dates.value) return '-'

      return dates.value.join(' ~ ')
    })

    const fetchWorflow = async () => {
      workflowOptions.value = await fetchWorkflowList()
    }

    const onWorkflowChange = async () => {
      if (!generalInfo.value.workflow_id) return
      workflowStatusOptions.value = await fetchWorkflowStatus(generalInfo.value.workflow_id)
    }
    const getuserList = async () => {
      userOptions.value = (await searchUsers()).map(opt => {
        return {
          value: opt.uuid,
          text: `${opt.firstname} ${opt.lastname}`,
        }
      })
    }

    fetchWorflow()
    getuserList()

    const updateTask = payload => {
      const routeName = route.value.name
      if (routeName === 'table-task' || routeName === 'share-list') {
        store.dispatch('tableView/UPDATE_TASK', {
          ...payload,
        })
      } else if (routeName === 'kanban-task') {
        store.dispatch('kanbanView/UPDATE_TASK', {
          ...payload,
        })
      } else {
        store
          .dispatch('tableView/UPDATE_TASK_ONLY', {
            ...payload,
          })
          .then(() => {
            eventBus.$emit('onTaskUpdate')
          })
      }
    }

    const onChangeDate = () => {
      menuRef.value.save(dates.value)
      menu.value = false
      generalInfo.value.from_date = dates.value[0]
      generalInfo.value.to_date = dates.value[1]

      updateTask({
        from_date: generalInfo.value.from_date,
        to_date: generalInfo.value.to_date,
        uuid: generalInfo.value.uuid,
      })
    }

    const onChangeDueDate = () => {
      dueMenu.value = false

      updateTask({ due_date: generalInfo.value.due_date, uuid: generalInfo.value.uuid })
    }

    const getAssigneeName = computed(() => {
      return userOptions.value.filter(opt => {
        return props.taskInfo?.assignee_id?.includes(opt.value)
      })
    })

    const updateWorkFlow = () => {
      updateTask({
        uuid: props.taskInfo.uuid,
        workflow_status_id: generalInfo.value.workflow_status_id,
        workflow_id: generalInfo.value.workflow_id,
      })
    }

    const updateAssignee = id => {
      const payLoad = {
        component: `task-management/spaces/item/assign/${props.taskInfo.uuid}`,
        object: {
          assignee_ids: id,
        },
      }
      store.dispatch(`common/${storeActions.CREATE}`, payLoad).then(({ data }) => {
        if (data.success) {
          generalInfo.value = {
            ...generalInfo.value,
            assigned_users: data.data.assigned_users,
          }
          const routeName = route.value.name

          if (
            routeName === 'table-task' ||
            routeName === 'kanban-task' ||
            routeName === 'share-list'
          ) {
            const path =
              routeName === 'table-task' || routeName === 'share-list'
                ? 'tableView/UPDATE_FIELD'
                : 'kanbanView/UPDATE_FIELD'

            store.commit(path, {
              uuid: props.taskInfo.uuid,
              data: data.data,
            })
          }
        }
      })
    }

    onMounted(() => {
      onWorkflowChange()
    })

    const removeAssignee = item => {
      const index = generalInfo.value.assignee_ids.indexOf(item.value)
      if (index >= 0) {
        generalInfo.value.assignee_ids.splice(index, 1)
        updateAssignee(generalInfo.value.assignee_ids)
      }
    }

    const hasReadOnlyAccess = computed(() => {
      if (!props.taskInfo?.permission) return false
      // 0 is no access 1 is read, 2 is editor, 3-4 is full or admin access
      if ([1, 0].includes(props.taskInfo.permission)) {
        return true
      }

      return false
    })

    return {
      workflowOptions,
      onWorkflowChange,
      avatarText,
      onChangeDate,
      userOptions,
      hasReadOnlyAccess,
      menuRef,
      updateAssignee,
      removeAssignee,
      dueMenu,
      getAssigneeName,
      updateWorkFlow,
      dateRangeText,
      generalInfo,
      dates,
      menu,
      onChangeDueDate,
      workflowStatusOptions,
    }
  },
}
</script>
<style scoped lang="scss">
.w-one-third {
  width: 33%;
}

::v-deep {
  .v-input input,
  .v-input textarea {
    color: #212121;
  }
  .v-input__slot .v-select__slot input {
    font-size: 14px !important;
  }
  .inline-input.v-text-field--filled {
    background: #f3fbff;
    border-radius: 5px;
    .v-input__slot {
      padding: 0 12px;
    }
  }

  .task-chip.v-chip {
    background: #00398012;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 28px;

    button svg {
      fill: black;
    }
  }
}

.autocomplete-btn {
  padding: 10px;
  background: #eef6fa;
  border-radius: 5px;
  min-height: 56px;

  .label {
    color: rgb(94 86 105 / 68%);
  }
  .text {
    font-size: 14px;
  }
}
.chip {
  margin-bottom: 0px;
  font-size: 11px;
  background: #d81b60;
  border-radius: 4px;
  padding: 1px 3px;
  color: white;
  font-weight: 500;
}
</style>
