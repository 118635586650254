import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import Notifications from 'vue-notification'
import VueTheMask, { mask } from 'vue-the-mask'
import Datetime from 'vue-datetime'
import VueYoutube from 'vue-youtube'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
// import posthogPlugin from './plugins/posthog'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(VueYoutube)

Vue.use(VueTheMask)
// Vue.use(posthogPlugin)
Vue.use(Datetime)

Vue.directive('mask', mask)

Vue.use(Notifications)
Vue.use(vueDebounce)
Vue.config.productionTip = false
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#bldonApp')
