<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What We Provide In Material Management
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Material Management</p>
        <p class="dark--text">
          Procuring the right materials at the right cost is crucial for a successful project, and the tool assists with just that. You can create different directories, such as alloy and glass, and list all materials under these headers. Then, you can fill in material details, assign them an identifier, and specify characteristics for later use in the estimation process. Additionally, you can check the list of vendors supplying any particular material and place an order request.
        </p>
      </div>
       <div class="w-half">
        <p class="warning--text text-base">Assemblies</p>
        <p class="dark--text">
          You can use pre-built assemblies containing commonly used materials and components all grouped together. Alternatively, you can also compile your own assemblies by listing the items you need, which can then be easily used for cost calculations during takeoffs.
        </p>
      </div>
      <!-- <div class="w-half">
        <p class="warning--text text-base">Material Brand</p>
        <p class="dark--text">
         Procuring the right materials at the right cost is crucial for a successful project, and the tool assists with just that. You can create different directories, such as alloy and glass, and list all materials under these headers. Then, you can fill in material details, assign them an identifier, and specify characteristics for later use in the estimation process. Additionally, you can check the list of vendors supplying any particular material and place an order request.
        </p>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
