<template>
  <layout-content-vertical-nav :nav-menu-items="resolveMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-notification :light="true"></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppBarUserMenu from '@core/layouts/partner/AppBarUserMenu.vue'
import { useRouter } from '@core/utils'
import { mdiHeartOutline, mdiMenu, mdiFaceAgent, mdiTextBox } from '@mdi/js'
import { computed } from '@vue/composition-api'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// Search Data
import navMenuItems from '@/navigation/vertical'
import navPartnerPortalMenuItems from '@/navigation/partner-portal'

export default {
  components: {
    LayoutContentVerticalNav,
    AppBarUserMenu,
    // AppBarNotification,
  },
  setup() {
    const { route } = useRouter()
    const resolveMenuItems = computed(() => {
      if (route.value.meta.app === 'partnerPortal') return navPartnerPortalMenuItems

      return navMenuItems
    })

    return {
      resolveMenuItems,
      // Search
      icons: {
        mdiMenu,
        mdiTextBox,
        mdiHeartOutline,
        mdiFaceAgent,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
