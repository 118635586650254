<template>
  <nav class="task-drawer" :class="{ 'is-open-bar': value }">
    <slot />
  </nav>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.task-drawer {
  background: #0c2a55;
  position: fixed;
  height: 100vh;
  min-width: 270px;
  z-index: 9;
  transform: translatex(-100%);
  &.is-open-bar {
    transform: translatex(260px);
  }
}
</style>
