import { render, staticRenderFns } from "./TaskFolderSharedComponent.vue?vue&type=template&id=5833a906&scoped=true&"
import script from "./TaskFolderSharedComponent.vue?vue&type=script&lang=js&"
export * from "./TaskFolderSharedComponent.vue?vue&type=script&lang=js&"
import style0 from "./TaskFolderSharedComponent.vue?vue&type=style&index=0&id=5833a906&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5833a906",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VListGroup,VListItemTitle,VTreeview})
