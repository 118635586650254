import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'
import { mappingAppsWithId } from '@/plugins/authService'
import { baseApps, ManagementApps } from '@/constants/appConstants'
import posthog from 'posthog-js'

const { LOGIN, LOGOUT, REGISTER, USER_PROFILE, FETCH_APPS } = storeActions
const {
  GET_ALL_PERMISSIONS,
  GET_USER_INFO,
  DECODE_TOKEN_INFO,
  GET_AUTH_TOKEN,
  GET_PURCHASE_APP,
  GET_EMAIL_TOKEN,
  GET_LAUNCHER_APP,
} = storeGetters
const {
  AUTH_USER,
  SET_PERMISSION,
  SET_LAUNCHER_BASE_APP,
  SET_LAUNCHER_MANAGEMENT_APP,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
  SET_PURCHASE_APP,
  EMAIL_TOKEN,
} = storeMutations

const compareApps = (keys, appType) => {
  if (appType === 'baseApp') {
    return baseApps.filter(opt => {
      return keys.some(element => {
        return opt.value === element
      })
    })
  }

  return ManagementApps.filter(opt => {
    return keys.some(element => {
      return opt.value === element
    })
  })
}

const getUser = () => {
  const consoleState = JSON.parse(localStorage.getItem('user'))
  if (consoleState) {
    return consoleState
  }

  return {}
}
const getPermissions = () => {
  const consoleState = localStorage.getItem('session')
  if (consoleState) {
    try {
      const data = Buffer.from(consoleState, 'base64').toString('ascii')

      return JSON.parse(data)
    } catch (error) {
      window.localStorage.clear()
      window.location.reload()
    }
  }

  return {}
}
const getToken = () => {
  const consoleState = localStorage.getItem('token')
  if (consoleState) {
    return consoleState
  }

  return null
}
const getEmailToken = () => {
  const consoleState = localStorage.getItem('email-token')
  if (consoleState) {
    return consoleState
  }

  return null
}
const getPurchaseApps = () => {
  try {
    const consoleState = JSON.parse(localStorage.getItem('cache'))
    if (consoleState) {
      return consoleState
    }

    return []
  } catch (error) {
    return []
  }
}

export default {
  namespaced: true,
  state: {
    permissions: getPermissions(),
    user: getUser(),
    token: getToken(),
    purchasedAppId: getPurchaseApps(),
    emailToken: getEmailToken(),
    purchasedBaseApps: [],
    purchasedManagementApps: [],
  },
  getters: {
    [GET_ALL_PERMISSIONS]: state => {
      return state.permissions
    },
    [GET_USER_INFO]: state => {
      return state.user
    },
    [GET_AUTH_TOKEN]: state => {
      return state.token
    },
    [GET_EMAIL_TOKEN]: state => {
      return state.emailToken
    },
    [GET_PURCHASE_APP]: state => {
      return state.purchasedAppId
    },
    [DECODE_TOKEN_INFO]: state => {
      if (state.token) {
        const { token } = state
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(c => {
              return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
            })
            .join(''),
        )
        const jsonObject = JSON.parse(jsonPayload)

        return jsonObject
      }

      return {}
    },
    [GET_LAUNCHER_APP]: state => {
      return {
        purchasedBaseApps: state.purchasedBaseApps,
        purchasedManagementApps: state.purchasedManagementApps,
      }
    },
  },
  mutations: {
    [SET_PERMISSION]: (state, payload) => {
      state.permissions = payload
    },
    [AUTH_USER]: (state, res) => {
      state.user = res
    },
    [AUTH_SET_TOKEN]: (state, res) => {
      state.token = res
    },
    [EMAIL_TOKEN]: (state, res) => {
      window.localStorage.setItem('email-token', res)
      state.emailToken = res
    },
    [AUTH_LOGOUT]: state => {
      window.localStorage.clear()
      state.permissions = {}
      state.user = {}
      state.token = null
      window.location.reload()
    },
    [SET_PURCHASE_APP]: (state, payload) => {
      state.purchasedAppId = payload
    },
    [SET_LAUNCHER_BASE_APP]: (state, payload) => {
      state.purchasedBaseApps = payload
    },
    [SET_LAUNCHER_MANAGEMENT_APP]: (state, payload) => {
      state.purchasedManagementApps = payload
    },
  },
  actions: {
    [LOGIN]: ({ commit }, payload) => {
      return api.save('/security/auth/login', payload).then(({ data }) => {
        if (data.data && data.data.resetLink) {
          return data
        }
        if (data.success && data.data) {
          posthog.identify(payload.username, { uuid: data.data.uuid, time: new Date() })
          // eslint-disable-next-line no-unused-vars
          const permissions = {
            crm: {
              account: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              opportunity: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              lead: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              contact: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
            },
            pm: {
              documentManagement: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              changeManagement: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              emailCommunication: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
            },
            security: {
              user: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              userGroup: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
              role: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
            },
            workDrive: {
              Folder: { a: 1, v: 1, e: 1, d: 1, i: 1, x: 1 },
            },
          }
          try {
            const permissionDecoded = Buffer.from(data.data.session, 'base64').toString('ascii')

            commit(SET_PERMISSION, JSON.parse(permissionDecoded))
            commit(AUTH_SET_TOKEN, data.data.token)

            window.localStorage.setItem('token', data.data.token)
            window.localStorage.setItem('session', data.data.session)

            // set cache purchase app for root user
            if (data.data.cache) {
              commit(SET_PURCHASE_APP, data.data.cache)
              window.localStorage.setItem('cache', JSON.stringify(data.data.cache))
            }
          } catch (error) {
            return {
              success: false,
              message: 'Bad Authentication data. Malformed data',
            }
          }
        }

        return data
      })
    },
    [USER_PROFILE]: ({ commit }, payload) => {
      return api.save('/security/users/profile', payload).then(({ data }) => {
        const userData = data.data
        userData.profile = userData.profile || {}
        window.localStorage.setItem('user', JSON.stringify(userData))
        commit(AUTH_USER, userData)

        return data
      })
    },
    [REGISTER]: (context, payload) => {
      return api.save('/security/auth/signup', payload).then(res => res)
    },
    [LOGOUT]: ({ commit }) => {
      commit(AUTH_LOGOUT)
    },
    [FETCH_APPS]: ({ commit, getters }) => {
      if (getters.DECODE_TOKEN_INFO && getters.DECODE_TOKEN_INFO.a === 1) {
        api.findByParams('setup/module/purchased/object').then(({ data }) => {
          if (data.success) {
            const keys = Object.keys(data.data)
            const purchaseAppIds = mappingAppsWithId(keys)
            commit(SET_PURCHASE_APP, purchaseAppIds)
            window.localStorage.setItem('cache', JSON.stringify(purchaseAppIds))
            commit(SET_LAUNCHER_BASE_APP, compareApps(keys, 'baseApp'))
            commit(SET_LAUNCHER_MANAGEMENT_APP, compareApps(keys, 'managementApp'))
            // purchasedBaseApps.value = compareApps(keys, 'baseApp')
            // purchasedManagementApps.value = compareApps(keys, 'managementApp')
          }
        })
      } else {
        const permissions = getters.GET_ALL_PERMISSIONS
        if (permissions) {
          const keys = Object.keys(permissions)
          const appIds = mappingAppsWithId(keys)
          commit(SET_PURCHASE_APP, appIds)
          window.localStorage.setItem('cache', JSON.stringify(appIds))
          commit(SET_LAUNCHER_BASE_APP, compareApps(keys, 'baseApp'))
          commit(SET_LAUNCHER_MANAGEMENT_APP, compareApps(keys, 'managementApp'))
        }
      }
    },
    PARTNER_LOGIN: ({ commit }, payload) => {
      return api.save('/ac-api/partner/login', payload).then(({ data }) => {
        if (data && data.message.resetLink) {
          console.log(data, 'data')

          return data
        }
        if (data.success && data.data) {
          try {
            commit(AUTH_SET_TOKEN, data.data.token)

            window.localStorage.setItem('token', data.data.token)
            window.localStorage.setItem('session', Buffer.from('null').toString('base64'))
          } catch (error) {
            console.log(error, 'error')

            return {
              success: false,
              message: 'Bad Authentication data. Malformed data',
            }
          }
        }

        return data
      })
    },
  },
}
