<template>
  <div>
    <v-list-group
      ref="refVListGroup"
      class=""
      :class="[{ 'vertical-nav-menu-group-active': false }]"
    >
      <template #prependIcon>
        <img :src="require('@/assets/images/svg/folder-open.svg')" height="23px" width="23px" />
      </template>
      <template #activator>
        <v-list-item-title
          class="white--text d-flex justify-space-between align-center"
          style="font-size: 0.9rem"
        >
          <span>Folders</span>
        </v-list-item-title>
      </template>
      <div class="px-2">
        <v-treeview
          v-if="isRerender"
          :active.sync="active"
          :items="items"
          :load-children="fetchFolders"
          :open.sync="open"
          activatable
          color="warning"
          item-key="id"
          dense
          open-on-click
          transition
          return-object
        >
          <template #label="{ item: obj }">
            <div class="white--text text-xs3 d-flex justify-space-between align-center py-3">
              <span
                style="
                  text-overflow: ellipsis;
                  max-width: 170px;
                  white-space: nowrap;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  v-if="obj.type === 'board'"
                  class="mr-1"
                  :src="require('@/assets/images/svg/note-text1.svg')"
                />
                {{ obj.name }}
              </span>
              <span v-if="obj.permission === 1 || obj.permission === 2">
                <v-icon class="white--text mr-1" size="16" @click.stop="editRecord(obj)">{{
                  icons.mdiPencil
                }}</v-icon>

                <v-icon class="white--text mr-1" size="16" @click.stop="deleteRecord(obj)">{{
                  icons.mdiTrashCan
                }}</v-icon>
              </span>
            </div>
          </template>
        </v-treeview>
      </div>
    </v-list-group>
    <folder-task
      v-if="openTaskFolderModal"
      :is-folder-dialog-open="openTaskFolderModal"
      :modal-form="modalForm"
      @closeModal="closeModal"
      @fetchData="renderComponent"
    />
    <ShareTaskModal
       v-if="isSharDialogOpen"
      :folder-data="folderData"
      :is-share-dialog-open="isSharDialogOpen"
      @closeModal=";(isSharDialogOpen = false), (folderData = {})"
    />
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import { mdiPlus, mdiTrashCan, mdiPencil, mdiShareVariantOutline } from '@mdi/js'
import { ref, watch, nextTick } from '@vue/composition-api'
import FolderTask from '@/components/task-management/modals/FolderTask.vue'
import ShareTaskModal from '@/components/task-management/modals/ShareTaskModal.vue'
import useService from '@/components/shared-components/useCommonService'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import { useRouter } from '@core/utils'
import Toaster from '@core/utils/sweetToast'

export default {
  components: { FolderTask, ShareTaskModal },
  setup() {
    const { router } = useRouter()
    const { response } = useService()
    const items = ref([])
    const tree = ref([])
    const open = ref([])
    const active = ref([])
    const show = ref(false)
    const folderData = ref({})
    const isRerender = ref(true)
    const openTaskFolderModal = ref(false)
    const isSharDialogOpen = ref(false)

    const setBreadCrump = item => {
      const split = item.path.split('/')
      if (split.length) {
        const string = split[0]
        const capitalizedFirstString = string.charAt(0).toUpperCase() + string.slice(1)

        split[0] = capitalizedFirstString
        const breadCrump = [{ text: split.join(' / '), href: '#', key: 0, disabled: true }]

        const link = `/task-management/tasks/${item.id}`
        breadCrump.push({
          text: item.name,
          href: link,
          key: item.id,
        })

        store.dispatch('tabPersist/SET_TASKFOLDER_BREADCRUMP', breadCrump)
      }
    }

    watch(
      () => active.value,
      () => {
        if (active.value.length) {
          setBreadCrump(active.value[0])
          const data = active.value[0]
          router
            .push({ name: 'shared-task', params: { folderId: data.id, access: data.permission } })
            .catch(() => {})
        }
      },
    )
    const input = va => {
      console.log('[89] =>', va)
    }

    const modalForm = ref({
      parent_id: 0,
      type: 'folder',
      path: 'folders',
    })

    const openModal = () => {
      openTaskFolderModal.value = true
    }

    const fetchFolders = async item => {
      const payload = {
        component: '/task-management/folders',
        filter: {
          parent_folder_id: item.id,
        },
      }

      try {
        const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
        if (data.success) {
          const array = data.data.map(opt => {
            const obj = {
              id: opt.uuid,
              name: opt.name,
              type: opt.type,
              path: opt.path,
              permission: item.permission,
            }
            if (opt.type === 'folder') {
              return {
                ...obj,
                children: [],
              }
            }

            return { ...obj }
          })

          item.children.push(...array)
        }
      } catch (error) {}
    }

    const loadFolders = async () => {
      const payload = {
        component: '/task-management/folders/share',
      }
      try {
        const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
        if (data.success) {
          items.value = data.data.map(opt => {
            const obj = {
              id: opt.uuid,
              name: opt.name,
              type: opt.type,
              path: opt.path,
              permission: opt.permission,
            }
            if (opt.type === 'folder') {
              return {
                ...obj,
                children: [],
              }
            }

            return {
              ...obj,
            }
          })
        }
      } catch (error) {}
    }

    const closeModal = () => {
      modalForm.value = {
        parent_id: 0,
        type: 'folder',
        path: 'folders',
      }
      openTaskFolderModal.value = false
    }

    loadFolders()

    const renderComponent = async () => {
      await loadFolders()
      isRerender.value = false
      nextTick(() => {
        isRerender.value = true
      })
    }

    const deleteRecord = async item => {
      Toaster.confirmation("You won't be able to revert this.").then(resp => {
        if (resp.isConfirmed) {
          const config = {
            component: 'task-management/folders',
            object: { uuid: [item.id] },
          }

          store.dispatch(`common/${storeActions.DELETE}`, config).then(({ data }) => {
            if (data.success) {
              renderComponent()
            }
          })
        }
      })
    }

    const editRecord = async item => {
      modalForm.value = {
        type: item.type,
        name: item.name,
        uuid: item.id,
      }

      openTaskFolderModal.value = true
    }

    const shareRecord = item => {
      isSharDialogOpen.value = true
      folderData.value = {
        name: item.name,
        uuid: item.id,
      }
    }

    return {
      modalForm,
      response,
      openTaskFolderModal,
      alternateIcon: themeConfig.menu.groupChildIcon,
      items,
      folderData,
      open,
      isRerender,
      shareRecord,
      deleteRecord,
      active,
      isSharDialogOpen,
      loadFolders,
      editRecord,
      openModal,
      fetchFolders,
      closeModal,
      renderComponent,
      show,
      input,
      tree,
      icons: {
        mdiPlus,
        mdiTrashCan,
        mdiPencil,
        mdiShareVariantOutline,
      },
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 12px;
  }

  .v-treeview {
    .v-treeview-node__level {
      width: 12px !important;
    }

    .v-treeview-node__content {
      border-bottom: 1px solid #8d8d8d;
    }
  }
}
</style>
