<template>
    <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="getDate"
        readonly
        :placeholder="label"
        hide-details="auto"
        :clearable="clearable"
        :rules="[...rules]"
        solo
        flat
        dense
        outlined
        v-bind="attrs"
        v-on="on"
      >
      <template slot="append">
      <v-icon v-on="on">{{ icons.mdiCalendarMonth }}</v-icon>
      </template>
    </v-text-field>
    </template>
    <v-date-picker
      v-model="getDate"
      elevation="20"
      no-title
      v-bind="$attrs"
      color="primary"
      @input="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendarMonth } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  props: {
    label: {
      type: String,
      default: 'Date',
    },
    date: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => ([]),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const menu = ref(false)
    const getDate = computed({
      get() {
        return props.date
      },
      set(val) {
        emit('update:date', val)
      },
    })

    const handleInput = () => {
      menu.value = false
      emit('inputEvent')
    }

    return {
      menu,
      handleInput,
      getDate,
      required,
      icons: {
        mdiCalendarMonth,
      },
    }
  },
}
</script>

<style></style>
