<template>
  <div class="module-list">
    <p class="text-xl mb-1 dark--text font-weight-semibold">Our Services To Learn</p>
    <p class="text-xs mb-12">Click Below to Lern about our modules and start creating</p>

    <v-row class="d-flex">
      <v-col v-for="(item, index) in app" :key="index" cols="4">
        <div class="d-flex">
          <div>
            <v-img :src="item.icon" width="40px" class="mx-auto" height="40px"></v-img>
          </div>
          <div class="ml-4 dark--text">
            <p class="text-lg mb-1 font-weight-semibold">{{ item.title }}</p>
            <p>{{ item.content }}</p>

            <p class="link text-sm warning--text cursor-pointer" @click="openApp(item)">
              Learn More
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CrmSvg from '@/assets/images/svg/crm-new.svg'
import Estimation from '@/assets/images/svg/estimation-new.svg'
import Security from '@/assets/images/svg/security-new.svg'
import Setup from '@/assets/images/svg/settingsvg.svg'
import workDrive from '@/assets/images/svg/workdrive-new.svg'
import email from '@/assets/images/svg/email-new.svg'
import task from '@/assets/images/svg/receipt-2.svg'
import cpu from '@/assets/images/svg/cpu.svg'
import material from '@/assets/images/svg/filter-square.svg'
import projectsvg from '@/assets/images/svg/projectsvg.svg'
import report from '@/assets/images/svg/note-text.svg'
import dashboard from '@/assets/images/svg/view-dashboard.svg'
import account from '@/assets/images/svg/book-account.svg'
import goals from '@/assets/images/svg/goals.svg'
import inventory from '@/assets/images/svg/inventory.svg'
import store from '@/store'

export default {
  setup() {
    const app = [
      {
        title: 'CRM',
        content: 'Helps you transform your leads into customers',
        app: 'crm',
        icon: CrmSvg,
      },
      {
        title: 'Work Drive',
        content: 'Keeps all your data safe and secure',
        app: 'workDrive',
        icon: workDrive,
      },
      {
        title: 'Security',
        content: 'Define different access levels and control who can gain access to various company resources.',
        app: 'security',
        icon: Security,
      },
      {
        title: 'Estimation',
        content: 'Helps you place the right bids so that you win them every time',
        app: 'estimation',
        icon: Estimation,
      },
      {
        title: 'Task Management',
        content: 'It keeps your team on track to deliver projects on time.',
        app: 'taskManagement',
        icon: task,
      },
      {
        title: 'Vendor Management',
        content: 'Maintains updated data of all the vendors for you.',
        app: 'vendorManagement',
        icon: account,
      },
      {
        title: 'Material Management',
        content: 'In publishing and graphic design, Lorem ipsum',
        app: 'materialManagement',
        icon: material,
      },
      {
        title: 'Project Management',
        content: 'Provides you with a directory of materials & list of vendors to ease the purchasing process.',
        app: 'projectManagement',
        icon: projectsvg,
      },
      {
        title: 'Reports',
        content: 'Provides you a comprehensive overview of various projects.',
        app: 'reports',
        icon: report,
      },
      {
        title: 'Inventory',
        content: 'Keep Track Of All Your Construction Materials With Ease',
        app: 'inventoryManagement',
        icon: inventory,
      },
      {
        title: 'Field Management',
        content: 'In publishing and graphic design, Lorem ipsum',
        app: 'fieldManagement',
        icon: inventory,
      },
    ]

    const openApp = item => {
      store.commit('documentation/SET_DETAIL_APP', item.app)
      store.commit('documentation/SET_DETAIL_MODAL', true)
    }

    return {
      app,
      openApp,
    }
  },
}
</script>
<style scoped>
.module-list {
  padding: 60px 100px;
}
.link {
  text-decoration: underline;
  line-height: 24px;
}
</style>
