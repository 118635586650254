<template>
  <div class="d-flex align-center custom-tab-button" :class="{ 'set-active-color': isActive }">
    <v-menu offset-y :left="true" flat>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="'transparent'"
          class="px-3 tab-button"
          style="font-size: 11px !important"
          tile
          dark
          large
          depressed
          @click="$emit('tabClick', item)"
        >
          <v-icon dark left :size="16">
            {{ item.icon || alternateIcon }}
          </v-icon>
          {{ textFormat(item.title) }}
        </v-btn>
        <v-btn
          v-if="pinned"
          :color="'transparent'"
          dark
          depressed
          tile
          large
          v-bind="attrs"
          style="min-width: 10px; padding: 5px"
          v-on="on"
        >
          <v-icon size="20">
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link>
          <v-list-item-title>{{ 'Pin Tab' }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="closeTab">
          <v-list-item-title>{{ 'Close Tab' }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div
      v-if="pinned"
      class="toggle-close"
      style="background: transparent; color: white; padding: 9px 0px; padding-right: 8px"
    >
      <v-icon size="15" style="cursor: pointer" dark @click="closeTab">
        {{ icons.mdiClose }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import useActiveLink from '@core/layouts/composable/horizontal-tab/useActiveLink'
import themeConfig from '@themeConfig'
import { mdiChevronDown, mdiClose } from '@mdi/js'
import useResizer from '@core/layouts/composable/horizontal-tab/useResizer'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    pinned: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useActiveLink(props.item)
    const { CANCEL_TAB_REQUEST } = storeActions

    const closeTab = () => {
      store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')
      store.dispatch(`tabs/${CANCEL_TAB_REQUEST}`, props.item)
      useResizer()
    }
    const textFormat = text => {
      if (text.length > 21) {
        return `${text.substring(0, 21)}..`
      }

      return text
    }

    return {
      isActive,
      updateIsActive,
      closeTab,
      textFormat,
      // alternate icons
      alternateIcon: themeConfig.menu.groupChildIcon,
      icons: {
        mdiChevronDown,
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.horizontal-nav-menu-header-link.header-link-active {
  box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
  @include elevationTransition();
}
</style>
