<template>
  <li
    class="nav-list flex-grow-1 mainlist text-center"
    :class="{ active: isActive || isSubTabActive }"
    @click="$emit('tabClick', item)"
  >
    <div v-if="loading" style="height: 100%" class="li-text">
      <VueSkeletonLoader type="rect" :height="10" animation="wave" :width="140" rounded />
    </div>
    <div v-else style="height: 100%" class="li-text">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <p
            :class="[isActive || isSubTabActive ? 'primary--text' : 'grey--text']"
            class="mb-0"
            style="font-size: 13px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              :size="16"
              class="mr-1"
              :color="isActive || isSubTabActive ? 'primary' : 'grey'"
            >
              {{ item.icon || alternateIcon }}
            </v-icon>
            {{ item.title }}
          </p>
        </template>
        <p class="mb-0 text-caption bg-primary">{{ item.title }}</p>
      </v-tooltip>
      <div>
        <!-- <v-menu offset-y min-width="100px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">{{ icons.mdiChevronDown }}</v-icon>
          </template>
          <v-list>
            <v-list-item @click="pin">
              <v-list-item-title>Pin Tab</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-icon
          size="15"
          class="ml-1"
          :color="isActive || isSubTabActive ? 'primary' : 'grey'"
          @click.stop="$emit('closeTab', { item, isActive })"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
    </div>
  </li>
</template>

<script>
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import useActiveLink from '@core/layouts/composable/horizontal-tab/useActiveLink'
import { mdiChevronDown, mdiClose } from '@mdi/js'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: { VueSkeletonLoader },
  props: {
    item: {
      type: Object,
      required: true,
    },
    pinned: {
      type: Boolean,
      default: true,
    },
    activeModule: {
      type: String,
      default: 'crm',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pin() {
      console.log('pin')
    },
  },
  setup(props, { emit }) {
    const { CANCEL_TAB_REQUEST } = storeActions
    const { isActive, isSubTabActive } = useActiveLink(props.item, props.activeModule)
    const textFormat = text => {
      if (text.length > 20) {
        return `${text.substring(0, 20)}..`
      }

      return text
    }

    const closeTab = () => {
      store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')
      store.dispatch(`tabs/${CANCEL_TAB_REQUEST}`, props.item)
      emit('onResize')
    }

    return {
      isActive,
      isSubTabActive,
      textFormat,
      closeTab,
      icons: {
        mdiClose,
        mdiChevronDown,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.nav-list {
  list-style: none;
  min-width: 190px;
  width: 190px;
  max-width: 240px;
  height: 42px;
  border-right: thin solid #d6d6d6;
  color: black;
}
.nav-list.mainlist.active {
  border-top: 3.5px solid #fe724c;
  border-bottom: 0px;
}
.nav-list .li-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  padding: 0 6px;
}
.nav-list.mainlist.active .li-text {
  background: #fff;
  color: black;
  margin-top: 2px;
}
.nav-list.active {
  background: #fff;
  color: black;
}
</style>
