<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What We Provide In Vendor Management
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-full">
        <p class="warning--text text-base">Vendor List</p>
        <p class="dark--text">
          If you have a strong list of suppliers, half the battle is won. Record all your vendor
          information, including their email IDs, phone numbers, the discounts they offer on their
          product range, and much more using this feature.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
