// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import moment from 'moment'
import Vue from 'vue'

// Toast config
const Toaster = {
  success(message = 'Request approved for performing operation', variant = 'success', duration = 2500, showTime = true) {
    Vue.notify({
      group: 'success',
      title: 'Success',
      text: `<div style="font-weight: 600">${message}</div>  <div>Date: ${moment().format(
        'llll',
      )}</div>`,
      type: variant,
      speed: 1000,
      duration,
    })
    // Swal.fire({
    //   toast: true,
    //   position: 'top-end',
    //   icon: variant,
    //   title: message,
    //   showConfirmButton: false,
    //   timerProgressBar: true,
    //   timer: 4500,
    //   didOpen: toast => {
    //     toast.addEventListener('mouseenter', Swal.stopTimer)
    //     toast.addEventListener('mouseleave', Swal.resumeTimer)
    //   },
    // })
  },
  error(message = 'An error occur, please try again later', variant = 'warning') {
    Vue.notify({
      group: 'error',
      title: 'Error',
      text: `<div style="font-weight: 600">${message}</div>  <div>Date: ${moment().format(
        'llll',
      )}</div>`,
      type: variant === 'warning' ? 'warn' : 'error',
      duration: 4000,
      speed: 1000,
    })
    // Swal.fire({
    //   toast: true,
    //   position: 'top-end',
    //   icon: variant,
    //   title: message,
    //   showConfirmButton: false,
    //   timerProgressBar: true,
    //   timer: 5000,
    //   didOpen: toast => {
    //     toast.addEventListener('mouseenter', Swal.stopTimer)
    //     toast.addEventListener('mouseleave', Swal.resumeTimer)
    //   },
    // })
  },
  confirmation(message = "You won't be able to revert this") {
    return Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Okay',
    })
  },
  fullToster(message) {
    Swal.fire({
      text: message,
      position: 'bottom',
      showClass: {
        popup: 'animate__animated animate__fadeInUp',
      },
      hideClass: {
        popup: ' animate__animated animate__fadeOutDown',
      },
      grow: 'row',
      showConfirmButton: false,
      showCloseButton: true,
      timer: 6000,
    })
  },
  warning(message = 'You will be able to revert this!') {
    return Swal.fire({
      title: 'Oops...',
      text: message,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Cancel',
    })
  },
  successInfo(message, icon = 'success') {
    return Swal.fire({
      icon,
      html: message,
    })
  },
}

export default Toaster
