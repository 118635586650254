import store from '@/store'
import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import CrmSvg from '@/assets/images/svg/crm-new.svg'
import Estimation from '@/assets/images/svg/estimation-new.svg'
import Security from '@/assets/images/svg/security-new.svg'
import Setup from '@/assets/images/svg/settingsvg.svg'
import workDrive from '@/assets/images/svg/workdrive-new.svg'
import email from '@/assets/images/svg/email-new.svg'
import task from '@/assets/images/svg/receipt-2.svg'
import cpu from '@/assets/images/svg/cpu.svg'
import material from '@/assets/images/svg/filter-square.svg'
import projectsvg from '@/assets/images/svg/projectsvg.svg'
import report from '@/assets/images/svg/note-text.svg'
import { ref } from '@vue/composition-api'
import { mappingAppsWithId } from '@/plugins/authService'

const useLauncherService = () => {
  const baseApps = [
    { icon: CrmSvg, title: 'CRM', value: 'crm', to: { name: 'dashboard-crm' } },
    { icon: workDrive, title: 'Work Drive', value: 'workdrive', to: { name: 'Drive-folder' } },
    {
      icon: Setup,
      title: 'Setup',
      value: 'setup',
      to: { name: 'setup-form-list' },
    },
    { icon: Security, title: 'Security', value: 'security', to: { name: 'security-dashboard' } },
    {
      icon: Estimation,
      title: 'Estimation',
      value: 'estimation',
      to: { name: 'estimation-dashboard' },
    },
    {
      icon: email,
      title: 'Email',
      value: 'email',
      to: { name: 'email-folder' },
    },
    {
      icon: email,
      title: 'Map',
      value: 'map',
      to: { name: 'email-folder' },
    },
  ]

  const ManagementApps = [
    {
      icon: task,
      title: 'Task Management',
      value: 'taskManagement',
      to: { name: 'task-dashboard' },
    },
    {
      icon: cpu,
      title: 'Vendor Management',
      value: 'vendorManagement',
      to: { name: 'vendors' },
    },
    {
      icon: material,
      title: 'Material Management',
      value: 'materialManagement',
      to: { name: 'material-management' },
    },
    {
      icon: projectsvg,
      title: 'Project Management',
      value: 'projectManagement',
      to: { name: 'pm-dashboard' },
    },
    {
      icon: report,
      title: 'Reports',
      value: 'reports',
      to: { name: 'reports' },
    },
    {
      icon: report,
      title: 'Dashboard',
      value: 'dashboards',
      to: { name: 'dashboards' },
    },
    {
      icon: report,
      title: 'Goal Management',
      value: 'goalManagement',
      to: { name: 'goals-dashboard' },
    },
  ]
  const purchasedBaseApps = ref([])
  const purchasedManagementApps = ref([])

  const decodeToken = store.getters[`auth/${storeGetters.DECODE_TOKEN_INFO}`]

  const compareApps = (keys, appType) => {
    if (appType === 'baseApp') {
      return baseApps.filter(opt => {
        return keys.some(element => {
          return opt.value === element
        })
      })
    }

    return ManagementApps.filter(opt => {
      return keys.some(element => {
        return opt.value === element
      })
    })
  }

  const fetchApps = async () => {
    if (decodeToken && decodeToken.a === 1) {
      const payload = {
        component: 'setup/module/purchased/object',
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload).then(({ data }) => {
        if (data.success) {
          const keys = Object.keys(data.data)
          const purchaseAppIds = mappingAppsWithId(keys)
          store.commit(`auth/${storeMutations.SET_PURCHASE_APP}`, purchaseAppIds)
          window.localStorage.setItem('cache', JSON.stringify(purchaseAppIds))
          purchasedBaseApps.value = compareApps(keys, 'baseApp')
          purchasedManagementApps.value = compareApps(keys, 'managementApp')
        }
      })
    } else {
      const permissions = store.getters[`auth/${storeGetters.GET_ALL_PERMISSIONS}`]

      if (permissions) {
        const keys = Object.keys(permissions)
        purchasedBaseApps.value = compareApps(keys, 'baseApp')
        purchasedManagementApps.value = compareApps(keys, 'managementApp')
      }
    }
  }

  return {
    fetchApps,
    purchasedBaseApps,
    purchasedManagementApps,
  }
}

export default useLauncherService
