import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'

const { GET_LABOR_PRODUCTIVITY, GET_SUBLABOR_PRODUCTIVITY } = storeGetters
const { FETCH_LABOR_PRODUCTIVITY } = storeActions
const { SET_LABOR_PRODUCTIVITY, RESET_LABOR_PRODUCTIVITY } = storeMutations

export default {
  namespaced: true,
  state: {
    laborProductivity: [],
  },
  getters: {
    [GET_LABOR_PRODUCTIVITY]: state => {
      return state.laborProductivity
    },
    [GET_SUBLABOR_PRODUCTIVITY]: (state) => parent => {
      const laborProductivity = state.laborProductivity.find(i => i.uuid == parent)
      if(laborProductivity != undefined){
        return laborProductivity.subcategory
      }
      return []
    },
  },
  mutations: {
    [SET_LABOR_PRODUCTIVITY]: (state, payload) => {
      state.laborProductivity = payload
    },
    [RESET_LABOR_PRODUCTIVITY]: state => {
      state.laborProductivity = []
    },
  },
  actions: {
    [FETCH_LABOR_PRODUCTIVITY]: ({ commit }, payload) => {
      api
        .findByParams(`${payload.component}`, { params: payload.filter })
        .then(({ data }) => {
          if (data.success && data.data) {
            const response = data.data.map(opt => {
              return {
                ...opt,
              }
            })
            commit(SET_LABOR_PRODUCTIVITY, response)
          } else {
            commit(RESET_LABOR_PRODUCTIVITY)
          }
        })
        .catch(() => {
          commit(RESET_LABOR_PRODUCTIVITY)
        })
    },
  },
}
