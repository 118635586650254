const security = [
  {
    path: '/material-management',
    name: 'material-management',
    component: () => import('@/views/material-management/MaterialManagement.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
  {
    path: '/material-brand',
    name: 'material',
    component: () => import('@/views/material-management/Material.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
  {
    path: '/assembly-notes',
    name: 'assemblyNotes',
    component: () => import('@/views/material-management/AssemblyNotes.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
  {
    path: '/material-assemblies',
    name: 'material-assemblies',
    component: () => import('@/views/material-management/Assemblies.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
  {
    path: '/material/:prop_type/import',
    name: 'import-material-data',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
  {
    path: '/material-recent-changes',
    name: 'material-recent-changes',
    component: () => import('@/views/material-management/MaterialRecentChanges.vue'),
    meta: {
      layout: 'tab',
      app: 'materialManagement',
      appId: 6,
    },
  },
]

export default security
