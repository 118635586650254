<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      Project Management Services
    </p>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-fill">
        <p class="warning--text text-base">Dashboard</p>
        <p class="dark--text">
          The Project Management dashboard is where you can zoom in and out to view various project
          statuses. It provides a clear idea of the total number of projects, including the number
          of active, closed, and overdue projects. Additionally, it lists the top projects, open
          submittals, open punch lists, and much more.
        </p>
      </div>
    </div>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">1. Project Dashboard</p>
        <p class="dark--text">
           Project Dashboard is a reservoir of all the project data. A centralized platform that
          visually represents different aspects of a project
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">2. Change Order</p>
        <p class="dark--text">
          Change Orders allows project managers to document and track any changes requested or
          required during the construction process. This includes changes to project specifications,
          designs, materials, or timelines.
        </p>
      </div>
    </div>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">3. RFI</p>
        <p class="dark--text">
          RFIs are used for communication between project stakeholders, including contractors,
          subcontractors, architects, engineers, and clients to seek clarification on project plans,
          drawings, specifications, or any other documents that may be unclear or ambiguous.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">4. Punch List</p>
        <p class="dark--text">
          Punch List allows project managers, inspectors, and other stakeholders to identify and
          document deficiencies, defects, or unfinished tasks observed during the final inspection
          or walkthrough of the construction site.
        </p>
      </div>
    </div>
    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">4. Document Control module</p>
        <p class="dark--text">
           Document Control module serves as a centralized repository for storing all project
          documents, including plans, drawings, specifications, contracts, permits, and other
          relevant files incorporating access control and permissions settings, allowing project
          managers to restrict access to sensitive documents and control who can view, edit, or
          download specific files.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
