const path = [
  {
    path: '/setup/dynamic-form',
    name: 'setup-dynamic-form',
    component: () => import('@/views/setup/dynamic-form/FormModuleList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/fields',
    name: 'setup-form-list',
    component: () => import('@/views/setup/dynamic-form/FieldListing.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/alignment',
    name: 'setup-form-alignment',
    component: () => import('@/views/setup/dynamic-form/FieldDragDrop.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/global-form',
    name: 'global-form',
    component: () => import('@/views/setup/dynamic-form/FormGlobal.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  // {
  //   path: '/setup/field-status',
  //   name: 'setup-field-status',
  //   component: () => import('@/views/setup/dynamic-form/FieldStatus.vue'),
  //   meta: {
  //     layout: 'content',
  //     app: 'set-up',
  //     appId: 4,
  //   },
  // },
  {
    path: '/setup/field-status-list',
    name: 'setup-status-list',
    component: () => import('@/views/setup/dynamic-form/FieldStatusList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup-manager/:id',
    name: 'setup-manager',
    component: () => import('@/views/setup/FieldListing.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/communication',
    name: 'setup-communication',
    component: () => import('@/views/setup/communication/CommunicationSetup.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/form-group',
    name: 'setup-formGroup',
    component: () => import('@/views/setup/dynamic-form/FormGroup.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/status',
    name: 'setup-status',
    component: () => import('@/views/setup/crm/ModuleStatus.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup-form/:id',
    name: 'setup-form',
    component: () => import('@/views/setup/FieldListing.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/workflow',
    name: 'setup-workflow',
    component: () => import('@/views/setup/task-management/CustomWorkflow.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/module-workflow',
    name: 'setup-module-workflow',
    component: () => import('@/views/setup/task-management/ModuleWorkflow.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/material-identifier',
    name: 'setup-material-identifier',
    component: () => import('@/views/setup/material/MaterialIdentifier.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/material-characteristics',
    name: 'setup-material-characteristic',
    component: () => import('@/views/setup/material/MaterialCharacteristics.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/labor-productivity',
    name: 'setup-labor-productivity',
    component: () => import('@/views/setup/material/LaborComponent/LaborProductivity.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/labor-cost',
    name: 'setup-labor-cost',
    component: () => import('@/views/setup/material/LaborComponent/LaborCost.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/labor-cost-template',
    name: 'setup-labor-cost-template',
    component: () => import('@/views/setup/material/LaborComponent/LaborCostTemplate.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/labor-cost-template/:template_id',
    name: 'setup-labor-cost-items',
    component: () => import('@/views/setup/material/LaborComponent/LaborCostItem.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/material/measurements',
    name: 'setup-material-measurement',
    component: () => import('@/views/setup/material/MeasurementSetup.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/company-setup',
    name: 'setup-company',
    component: () => import('@/views/setup/company/CompanySetup.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/email-setup',
    name: 'setup-email',
    component: () => import('@/views/setup/company/EmailSetUp.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/license-payment',
    name: 'setup-license',
    component: () => import('@/views/setup/company/LicensePayment.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/license-payment/success',
    name: 'setup-license-success',
    component: () => import('@/views/setup/company/PaymentSuccess.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/license-payment/cancel',
    name: 'setup-license-error',
    component: () => import('@/views/setup/company/LicensePayment.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/organization',
    name: 'setup-orgnization',
    component: () => import('@/views/setup/company/Organization.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/designation',
    name: 'setup-designation',
    component: () => import('@/components/set-up/company-setup/organization/OrganizationDesignation.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/qualifier',
    name: 'setup-qualifier',
    component: () => import('@/components/set-up/company-setup/organization/OrganizationQualifiers.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/modules',
    name: 'setup-modules',
    component: () => import('@/views/setup/company/ModulePricing.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/modules/:id',
    name: 'setup-submodules',
    component: () => import('@/views/setup/company/SubModule.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/territory',
    name: 'setup-territory',
    component: () => import('@/views/setup/territory/TerritoryList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/territory-view/:id',
    name: 'setup-territory-view',
    component: () => import('@/views/setup/territory/TerritoryView.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/pm-folder',
    name: 'setup-folder',
    component: () => import('@/views/setup/pm-folder/FolderList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/estimation-folder',
    name: 'setup-estimation-folder',
    component: () => import('@/views/setup/estimation-folder/FolderList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/inventory-management/setup',
    name: 'inventory-setup',
    component: () => import('@/views/setup/inventory-setup/index.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  // {
  //   path: '/setup/plugins',
  //   name: 'setup-plugin',
  //   component: () => import('@/views/setup/plugin/Plugin.vue'),
  //   meta: {
  //     layout: 'content',
  //     app: 'set-up',
  //     appId: 4,
  //   },
  // },
  {
    path: '/setup/plugins',
    name: 'plugin',
    component: () => import('@/views/setup/plugins/Plugin.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/callback',
    name: 'setup-callback',
    component: () => import('@/views/setup/plugins/CallBack.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/logs',
    name: 'setup-logs',
    component: () => import('@/views/setup/activity-logs/LogsList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
  {
    path: '/setup/dynamic-ids',
    name: 'setup-ids',
    component: () => import('@/views/setup/ids-setup/IdSetupList.vue'),
    meta: {
      layout: 'content',
      app: 'set-up',
      appId: 4,
    },
  },
]

export default path
