<template>
  <div class="d-flex align-center">
    <p v-if="!isEditable" class="pb-0 mb-0 mr-2">
      {{ getPermissionText(item.permission) }}
    </p>
    <v-icon v-if="!isEditable" size="13" color="primary" @click="isEditable = true">
      {{ icons.mdiPencil }}
    </v-icon>
    <v-form ref="userForm" class="multi-col-validation custom-form d-flex align-center">
      <div class="d-flex align-end">
        <v-select
          v-if="isEditable"
          v-model="input"
          :items="permissionList"
          label="Standard"
          style="max-width: 70%"
          outlined
          hide-details
          dense
        ></v-select>
        <v-icon v-if="isEditable" size="19" class="select-icon mr-1 ml-1" @click="onUpdate">
          {{ icons.mdiCheck }}
        </v-icon>

        <v-icon v-if="isEditable" size="19" class="select-icon" @click="isEditable = false">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mdiPencil, mdiCheck, mdiClose } from '@mdi/js'
import Toaster from '@core/utils/sweetToast'
import { storeActions } from '@/store/storeConstants'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    versionData: {
      type: Object,
      required: true,
    },
    permissionList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      input: this.item.permission,
      isEditable: false,
      isLoading: false,
    }
  },
  methods: {
    onUpdate() {
      const isValid = this.$refs.userForm.validate()
      if (!isValid) return
      const payLoad = {
        component: 'task-management/folders/share',
        object: {
          folder_id: this.versionData.uuid,
          permission: this.input,
          shared_user_id: this.item.uuid,
        },
      }
      this.isLoading = true
      this.$store
        .dispatch(`common/${storeActions.UPDATE}`, payLoad)
        .then(({ data }) => {
          this.isLoading = false
          if (data.success) {
            Toaster.success('Permission Updated successfully', 'success')
            this.$emit('fetchData')
            this.isEditable = false
          } else {
            Toaster.error(data.mesage, 'warning')
          }
        })
        .catch(() => {
          this.isLoading = false
          Toaster.error('An eror occur, please try again later', 'warning')
        })
    },
    getPermissionText(text) {
      const find = this.permissionList.find(opt => {
        return opt.value === text
      })

      if (find) {
        return find.text
      }

      return 'N/A'
    },
  },

  setup() {
    return {
      icons: {
        mdiPencil,
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>

<style scoped lang="scss">
button.select-icon {
  font-size: 19px;
  height: 24px;
  width: 23px;
  background: #f7f5f5;
  border-radius: 4px;
  border: thin solid #e2e1e1;
}
</style>
