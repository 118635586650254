<template>
  <v-app-bar absolute color="primary" dark app :elevation="$vuetify.theme.isDark ? 4 : 3">
    <v-toolbar-title>
      <v-img
        v-if="$vuetify.theme.dark"
        :src="require('@/assets/images/svg/original-logo.svg')"
        height="25"
        width="110"
      ></v-img>
      <v-img
        v-else
        :src="require('@/assets/images/svg/logoDark1.svg')"
        height="30"
        width="120"
      ></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn class="primary--text mr-3 px-3" href="/login" min-width="80px" color="lightGrey">Login</v-btn>
    <v-btn class="primary--text mr-3 px-3" href="https://www.bldon.com" color="lightGrey">Learn More</v-btn>
  </v-app-bar>
</template>

<script>
export default {}
</script>

<style></style>
