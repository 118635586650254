const security = [
  {
    path: '/task-management/dashboard',
    name: 'task-dashboard',
    component: () => import('@/views/task-management/TaskDashboard.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/tasks/:folderId?',
    name: 'all-task',
    component: () => import('@/views/task-management/AllTask.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/folder-tasks/:folderId?',
    name: 'folder-task',
    component: () => import('@/views/task-management/AllTask.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/tasks-list/:folderId?/:access?',
    name: 'shared-task',
    component: () => import('@/views/task-management/AllTask.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/my-todo',
    name: 'my-todo',
    component: () => import('@/views/task-management/MyTodoTask.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/create-space/:spaceId?',
    name: 'create-space',
    component: () => import('@/views/task-management/CreateSpace.vue'),
    meta: {
      layout: 'horizontal',
      app: 'taskManagement',
      appId: 8,
    },
  },
  {
    path: '/task-management/:spaceId/view',
    name: 'task-layout',
    component: () => import('@/views/task-management/TaskLayout.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
    children: [
      {
        path: 'table/:taskId?',
        name: 'table-task',
        component: () => import('@/views/task-management/TaskTableView.vue'),
        meta: {
          layout: 'moveable',
          app: 'taskManagement',
          appId: 8,
        },
      },
      {
        path: 'kanban/:taskId?',
        name: 'kanban-task',
        component: () => import('@/views/task-management/TaskKanbanView.vue'),
        meta: {
          layout: 'moveable',
          app: 'taskManagement',
          appId: 8,
        },
      },
    ],
  },
  {
    path: '/task-management/share/:spaceId',
    name: 'task-shared-layout',
    component: () => import('@/views/task-management/TaskShareLayout.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
    children: [
      {
        path: ':taskId?',
        name: 'share-list',
        component: () => import('@/views/task-management/TaskShareList.vue'),
        meta: {
          layout: 'moveable',
          app: 'taskManagement',
          appId: 8,
        },
      },
    ],
  },

  {
    path: '/task-management/:prop_type/import/:parent_folder_id?',
    name: 'import-task',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'moveable',
      app: 'taskManagement',
      appId: 8,
    },
  },
]

export default security
