import actions from './common.action'
import getters from './common.getters'
import mutations from './common.mutations'
import state from './common.state'

export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true,
}
