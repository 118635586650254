<template>
  <div id="task-folder-sidebar" class="vertical-nav-menu-container">
    <div class="d-flex justify-space-between pa-2 pt-4">
      <span class="mediumBlue--text cursor-pointer" @click="$emit('closeTaskBar')">
        <v-icon color="mediumBlue">{{ mdiChevronLeft }}</v-icon>
        Back
      </span>
      <span
        class="mediumBlue--text font-weight-semibold cursor-pointer"
        @click="$refs.taskFolderComp.openModal()"
      >
        Add Folder
      </span>
    </div>
    <div
      class="task-folder-scroll"
    >
      <v-list expand shaped class="" style="background: #0c2a55">
        <TaskFolderComponent ref="taskFolderComp" />
      </v-list>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import TaskFolderComponent from '@core/layouts/components/vertical-nav-menu/components/task-folders/TaskFolderComponent.vue'
import { mdiChevronLeft, mdiPlus } from '@mdi/js'

export default {
  components: {
    TaskFolderComponent,
    // folders comp for tasks
  },
  setup(_, { emit }) {
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const removeSidear = e => {
      const sidebarElm = document.getElementById('task-folder-sidebar')
      const modalElm = document.getElementById('task-board-modal')
      const sweetElm = document.querySelector('.swal2-container.swal2-center')
      const dropDown = document.querySelector('.v-list.v-select-list')

      // to prevent close sidebar we should check whether above element are present or not
      if (!dropDown && !sweetElm && !modalElm && sidebarElm && !sidebarElm.contains(e.target)) {
        emit('closeTaskBar')
      }
      if (!dropDown && modalElm && !modalElm.contains(e.target)) {
        emit('closeTaskBar')
      }
      if (!dropDown && sweetElm && !sweetElm.contains(e.target)) {
        emit('closeTaskBar')
      }
    }

    onMounted(() => {
      document.addEventListener('mouseup', removeSidear)
    })
    onUnmounted(() => {
      document.removeEventListener('mouseup', removeSidear)
    })

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    // provide('openGroups', ref([]))

    return {
      perfectScrollbarOptions,
      shallShadowBottom,

      // icons
      mdiChevronLeft,
      mdiPlus,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;

  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }

  .v-list-item {
    margin-top: 0.375rem;
  }

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}

@include app-elevation(
  'app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover',
  9
);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  // &#task-folder-sidebar {
  //   max-width: calc(100vw - 265px) !important;
  //   overflow-y: auto;
  // }
  height: 100%;
  .task-folder-scroll {
    height: calc(100% - 57px) !important;
    overflow: auto;
  }

}

@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 20px !important;
  }
  @include rtl() {
    padding-left: 20px !important;
  }
  @at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }

  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }

  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }

  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 5px;
    }
    @include rtl() {
      margin-right: 5px;
    }
  }

  .v-list-item {
    @include app-states($material);

    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;

    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }

    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
