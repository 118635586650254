<template>
  <v-dialog
    v-model="isVisible"
    max-width="950px"
    scrollable
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between text-lg text-primary pa-5 pb-4">
        <span class="text-base"> Create Project </span>
        <span>
          <v-icon @click="closeModal">{{ icons.mdiClose }}</v-icon>
        </span>
      </v-card-title>
      <v-card-text class="pa-3 d-flex">
        <div class="px-3 pr-5 first-sec">
          <v-form ref="formRef">
            <p class="mb-0 dark--text">Project Name</p>
            <v-text-field
              v-model="projectForm.name"
              :rules="[required]"
              outlined
              hide-details="auto"
              dense
              placeholder="Enter name"
            ></v-text-field>

            <div v-if="location" class="d-flex mt-3 align-center">
              <p class="mb-0 mr-2 dark--text">Location:</p>
              <v-chip label outlined>{{ location.name }}</v-chip>
            </div>

            <p class="mt-3 dark--text font-weight-semibold">Type</p>
            <v-radio-group v-model="projectForm.type">
              <v-radio value="project" class="pro-task">
                <template v-slot:label>
                  <div>
                    <p class="dark--text mb-0">Project</p>
                    <p class="ml-0 text-xs2 grey--text">
                      Use Projects to manage tasks that are part of a larger goal. Projects can have
                      their own attributes separate from tasks, like Status and Finish Date, which
                      you can track.
                    </p>
                  </div>
                </template>
              </v-radio>

              <v-radio value="folder" class="pro-task">
                <template v-slot:label>
                  <div>
                    <p class="dark--text mb-0">Folder</p>
                    <p class="ml-0 text-xs2 grey--text">
                      Use Folders as containers to organize and categorize work, making it easier to
                      find and share information. Unlike Projects, Folders do not have attributes
                      you can track.
                    </p>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-form>
        </div>
        <div class="pl-5">
          <p class="text-base dark--text mb-1 font-weight-semibold">Settings</p>
          <v-row>
            <v-col cols="6" class="input-padding">
              <label class="text--primary text-xs2">{{ 'Work Flow' }}</label>
              <v-autocomplete
                v-model="projectForm.workflow_id"
                :items="workflowOptions"
                outlined
                hide-details="auto"
                class="custom-input"
                dense
                placeholder="Work Flow"
                @change="onWorkflowChange"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="input-padding">
              <label class="text--primary text-xs2">{{ 'Workflow Status' }}</label>
              <v-autocomplete
                v-model="projectForm.workflow_status_id"
                :items="workflowStatusOptions"
                outlined
                hide-details="auto"
                class="custom-input"
                dense
                placeholder="Workflow Status"
              >
              </v-autocomplete>
            </v-col>
            <v-col v-if="projectForm.type === 'project'" cols="6">
              <label class="text--primary text-xs2">{{ 'Start & End Date' }}</label>
              <DateRangePicker
                label="Date Range"
                :start-date="projectForm['from_date']"
                :end-date="projectForm['to_date']"
                @onSelect="
                  ;(projectForm['from_date'] = $event[0]), (projectForm['to_date'] = $event[1])
                "
              />
            </v-col>
            <v-col cols="6"> </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="closeModal"> Close </v-btn>
        <v-btn color="primary" class="me-3" :loading="loading" @click="createSpace"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import Toaster from '@core/utils/sweetToast'
import DateRangePicker from '@/components/shared-components/DateRangePicker.vue'
import moment from 'moment'

export default {
  components: { DateRangePicker },
  props: {
    openTaskProjectModal: {
      type: Boolean,
      required: true,
    },
    location: {
      type: Object,
      default: undefined,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const formRef = ref(null)
    const workflowOptions = ref([])
    const workflowStatusOptions = ref([])
    const projectForm = ref({
      name: null,
      type: 'project',
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    })
    const { route, router } = useRouter()
    const getSelectedSpace = computed(() => store.getters['taskManagement/getSelectedSpace'])

    const isVisible = computed({
      get: () => props.openTaskProjectModal,
      set: () => {
        return false
      },
    })

    const closeModal = () => {
      formRef.value.resetValidation()
      projectForm.value = {
        name: null,
        type: 'project',
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
      emit('close')
    }

    const createSpace = () => {
      const isValid = formRef.value.validate()
      if (!isValid) return
      loading.value = true
      const payLoad = {
        object: {
          ...projectForm.value,
          space_id: getSelectedSpace.value,
          dependent_id: props.location?.uuid,
        },
        component: 'task-management/spaces/item',
      }
      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(({ data }) => {
          router.push({
            name: 'table-task',
            params: { spaceId: route.value.params.spaceId, taskId: data.data.uuid },
          })

          Toaster.success(data.data.message)
          emit('fetchData')
          closeModal()
        })
        .catch(err => {
          console.log('[209] =>', err)
          Toaster.error('An eror occur, please try again later', 'warning')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getWorkflowList = () => {
      const payLoad = {
        component: 'setup/taskManagement/workflow',
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payLoad).then(({ data }) => {
        if (data.success) {
          workflowOptions.value = data.data.map(opt => {
            return {
              value: opt.uuid,
              text: opt.name,
            }
          })
        }
      })
    }

    getWorkflowList()

    const onWorkflowChange = () => {
      const config = {
        component: `setup/taskManagement/workflow/${projectForm.value.workflow_id}/status`,
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, config).then(({ data }) => {
        if (data.success) {
          workflowStatusOptions.value = data.data.map(opt => {
            return {
              value: opt.uuid,
              text: opt.name,
            }
          })
        }
      })
    }

    return {
      workflowOptions,
      onWorkflowChange,
      workflowStatusOptions,
      loading,
      isVisible,
      required,
      formRef,
      projectForm,
      closeModal,
      createSpace,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style scoped lang="scss">
.first-sec {
  border-right: 1px solid #ddd;
  width: 45%;
}
::v-deep {
  .v-radio.pro-task {
    align-items: flex-start;
  }
}
</style>
