<template>
  <v-menu offset-y left nudge-bottom="14" min-width="260" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="30px" v-bind="attrs" color="lightBlue" class="primary--text ms-5" v-on="on">
        <v-img
          v-if="userData.profile && userData.profile.imageUrl && imageVisible"
          :src="userData.profile.imageUrl"
          @error="imageVisible = false"
        ></v-img>
        <span v-else class="font-weight-medium text-xs2">{{
          avatarText(`${userData.firstname} ${userData.lastname}`)
        }}</span>
      </v-avatar>
    </template>
    <v-list class="px-3">
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="lightBlue" class="primary--text">
            <v-img
              v-if="userData.profile && userData.profile.imageUrl && imageVisible"
              :src="userData.profile.imageUrl"
            ></v-img>
            <span v-else class="font-weight-medium">{{
              avatarText(`${userData.firstname} ${userData.lastname}`)
            }}</span>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.username }}
          </span>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'user-profile' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <!-- <v-list-item :to="{ name: 'apps-email' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Settings -->
      <!-- <v-list-item :to="{ name: 'page-account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>
      <!-- Customer Signup Flow -->
      <v-list-item @click="customerSignup">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLocationEnter }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Customer Signup</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiLocationEnter,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import store from '@/store'
import { storeMutations, storeGetters, storeActions } from '@/store/storeConstants'
import { computed, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

const { GET_USER_INFO } = storeGetters
export default {
  setup() {
    const { LOGOUT } = storeActions
    const { RESET_WORKSPACE_TAB } = storeMutations
    const { router } = useRouter()
    const imageVisible = ref(true)
    const logoutUser = () => {
      store.dispatch(`auth/${LOGOUT}`)
      store.commit(`tabs/${RESET_WORKSPACE_TAB}`)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const userData = computed(() => store.getters[`auth/${GET_USER_INFO}`])

    const getUserProfile = () => {
      if (userData.value && Object.keys(userData.value).length) return
      store
        .dispatch(`auth/${storeActions.USER_PROFILE}`)
        .then()
        .catch(error => {
          console.log(error)
        })
    }

    getUserProfile()

    const onError = () => {
      return '@/assets/images/avatars/1.png'
    }
    const customerSignup = () => {
      router.push({name: 'configure-module'}).catch(() => {})
    }

    return {
      logoutUser,
      onError,
      avatarText,
      customerSignup,
      userData,
      imageVisible,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiLocationEnter,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
