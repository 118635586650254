<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">
      What We Provide In Work Drive
    </p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">File Management</p>
        <p class="dark--text">
          The Workdrive with its easy-to-use interface and secure cloud storage feature allows you
          to organize, access and share files with zero hassle.
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">File Sharing</p>
        <p class="dark--text">
          Workdrive has made the file-sharing process effortless. You can simply upload your files,
          generate a shareable link, or provide access to specific team members. All this without
          worrying about important data being lost.
        </p>
      </div>
    </div>

    <div class="d-flex mt-3" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Restore From Trash</p>
        <p class="dark--text">
          You can easily recover important documents that were accidentally deleted with a few
          clicks
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Make Favorite</p>
        <p class="dark--text">
          You can instantly access your important files and folders by marking them as favourites.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
