<template>
  <v-list nav dense class="px-9 py-7">
    <h2 class="dark--text font-weight-semibold text-xl">Application</h2>
    <v-divider class="mediumGrey mt-3"></v-divider>
    <div class="grid mt-6">
      <div
        v-for="(app, index) in purchasedApps"
        :key="index"
        class="child text-center py-4 px-2 cursor-pointer"
        @click="gotoApp(app)"
      >
        <v-img :src="app.icon" width="40px" class="mx-auto"></v-img>
        <p class="dark--text mb-0 mt-5 text-sm font-weight-medium">{{ app.title }}</p>
      </div>
    </div>

    <!-- <h2 class="dark--text mt-5 font-weight-semibold text-xl">MANAGEMENT APPLICATIONS</h2>
    <v-divider class="mediumGrey mt-3"></v-divider> -->

    <div class="grid mt-5">
      <div
        v-for="(app, index) in []"
        :key="index"
        class="child text-center py-4 px-2 cursor-pointer"
        @click="gotoApp(app)"
      >
        <div class="justify-self-center">
          <v-img :src="app.icon" width="40px" class="mx-auto"></v-img>
          <p class="dark--text mb-0 mt-5 text-sm font-weight-medium">{{ app.title }}</p>
        </div>
      </div>
    </div>
  </v-list>
</template>
<script>
import CrmSvg from '@/assets/images/svg/CRM.svg'
import Estimation from '@/assets/images/svg/Estimation.svg'
import Security from '@/assets/images/svg/Security.svg'
import Setup from '@/assets/images/svg/Setup.svg'
import workDrive from '@/assets/images/svg/workDrive.svg'
import { mdiClose } from '@mdi/js'
import useLauncherService from '@core/comp-functions/app-launcher/launcherService'
import { useRouter } from '@core/utils'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const { fetchApps, purchasedBaseApps, purchasedManagementApps } = useLauncherService()
    const { router } = useRouter()

    const gotoApp = item => {
      let module = 'crm'
      if (item.title === 'CRM') {
        module = 'crm'
      }
      if (item.title === 'Estimation') {
        module = 'estimation'
      }
      if (item.title === 'Setup') {
        module = 'setup'
      }
      if (item.title === 'Reports') {
        module = 'report'
      }
      if (item.title === 'Dashboards') {
        module = 'dashboard'
      }

      router.push({ name: 'task-modules', params: { module } }).catch(() => {})
    }

    fetchApps()

    const purchasedApps = computed(() => {
      return purchasedBaseApps.value.filter(opt => {
        return opt.value === 'crm' || opt.value === 'estimation'
      })
    })

    return {
      purchasedBaseApps,
      purchasedManagementApps,
      gotoApp,
      purchasedApps,
      // icons
      CrmSvg,
      Estimation,
      Security,
      Setup,
      workDrive,
      mdiClose,
    }
  },
}
</script>
<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  /* grid-template-rows: repeat(3, calc(100%/3)); */
}

.child {
  border: 1px solid #f8ebe7;
  box-shadow: 0px 14px 18px rgba(225, 119, 89, 0.09);
  border-radius: 10px;
  height: 100%;
  width: 220px;
}
</style>
