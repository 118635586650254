import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'

const { SETUP_TAB_REQUEST, ESTIMATE_TAB_REQUEST, REPORT_TAB_REQUEST, DASHBOARD_TAB_REQUEST, VENDOR_TAB_REQUEST } = storeActions
const { GET_SETUP_PERSIST_TAB, GET_ESTIMATE_PERSIST_TAB, GET_SETUP_ACTIVE_ID, GET_REPORT_PERSIST_TAB, GET_DASHBOARD_PERSIST_TAB, GET_FIXED_SUBTAB, GET_VENDOR_PERSIST_TAB } =
  storeGetters
const {
  SET_TAB_SETUP_STATE,
  SET_ESTIMATE_TAB_STATE,
  SET_REPORT_TAB_STATE,
  SET_DASHBOARD_TAB_STATE,
  SET_VENDOR_TAB_STATE,
  ACTIVE_SETUP_ID,
  SET_SETUP_TAB,
  SET_FIXED_SUBTAB,
} = storeMutations

const getPersistState = persisitState => {
  const consoleState = JSON.parse(localStorage.getItem(persisitState))
  if (consoleState) {
    return consoleState
  }

  return {}
}

export default {
  namespaced: true,
  state: {
    setupTabs: [],
    setupSubTab: [],
    setupPersistTab: getPersistState('SetupTab'),
    activeSetupId: null,

    // tabs for estimate
    estimateTabs: [],
    estimateSubTab: [],
    estimatePersistTab: getPersistState('EstimateTab'),
    estiamteActiveId: null,

    // tabs for report
    reportTabs: [],
    reportPersistTab: getPersistState('ReportTab'),

    // tabs for dashboard
    dashboardTabs: [],
    dashboardPersistTab: getPersistState('DashboardTab'),

    // tabs for report
    vendorTabs: [],
    vendorPersistTab: getPersistState('VendorTab'),

    // fixed subtab length
    isFixedSubtab: false,
  },
  getters: {
    [GET_SETUP_PERSIST_TAB]: state => state.setupPersistTab,
    [GET_ESTIMATE_PERSIST_TAB]: state => state.estimatePersistTab,
    [GET_REPORT_PERSIST_TAB]: state => state.reportPersistTab,
    [GET_DASHBOARD_PERSIST_TAB]: state => state.dashboardPersistTab,
    [GET_VENDOR_PERSIST_TAB]: state => state.vendorPersistTab,
    [GET_SETUP_ACTIVE_ID]: state => state.activeSetupId,
    [GET_FIXED_SUBTAB]: state => state.isFixedSubtab,
  },
  mutations: {
    [SET_TAB_SETUP_STATE]: (state, payload) => {
      state.setupPersistTab = payload
    },
    [SET_ESTIMATE_TAB_STATE]: (state, payload) => {
      state.estimatePersistTab = payload
    },
    [SET_REPORT_TAB_STATE]: (state, payload) => {
      state.reportPersistTab = payload
    },
    [SET_DASHBOARD_TAB_STATE]: (state, payload) => {
      state.dashboardPersistTab = payload
    },
    [ACTIVE_SETUP_ID]: (state, payload) => {
      state.activeSetupId = payload
    },
    [SET_FIXED_SUBTAB]: (state, payload) => {
      state.isFixedSubtab = payload
    },
    [SET_SETUP_TAB]: (state, payload) => {
      state.setupTabs = payload
    },
    [SET_VENDOR_TAB_STATE]: (state, payload) => {
      state.vendorTabs = payload
    },
  },
  actions: {
    [SETUP_TAB_REQUEST]: ({ commit }, payload) => {
      localStorage.setItem('SetupTab', JSON.stringify(payload))
      commit(SET_TAB_SETUP_STATE, payload)
    },
    [ESTIMATE_TAB_REQUEST]: ({ commit }, payload) => {
      localStorage.setItem('EstimateTab', JSON.stringify(payload))
      commit(SET_ESTIMATE_TAB_STATE, payload)
    },
    [REPORT_TAB_REQUEST]: ({ commit }, payload) => {
      localStorage.setItem('ReportTab', JSON.stringify(payload))
      commit(SET_REPORT_TAB_STATE, payload)
    },
    [DASHBOARD_TAB_REQUEST]: ({ commit }, payload) => {
      console.log('hello', payload)
      localStorage.setItem('DashboardTab', JSON.stringify(payload))
      commit(SET_DASHBOARD_TAB_STATE, payload)
    },
    [VENDOR_TAB_REQUEST]: ({ commit }, payload) => {
      console.log('hello', payload)
      localStorage.setItem('VendorTab', JSON.stringify(payload))
      commit(SET_VENDOR_TAB_STATE, payload)
    },
  },
}
