<template>
  <layout-content-horizontal-nav :nav-menu-items="[]">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar="{ launcherDrawer }">
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <router-link to="/setup/master-dashboard">
            <v-img
              :src="require('@/assets/images/svg/logoDark1.svg')"
              height="28"
              width="122"
            ></v-img>
          </router-link>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            class="me-4"
          ></app-bar-search> -->
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->
          <v-btn icon @click="openDocumentModal">
            <v-icon color="white">{{ icons.mdiTextBox }}</v-icon>
          </v-btn>
          <v-btn href="/support" icon
            ><v-icon :color="light ? 'secondary' : 'white'">{{ icons.mdiFaceAgent }}</v-icon></v-btn
          >
          <app-bar-notification></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
          <app-launcher-button class="ml-4" @openLauncher="launcherDrawer"></app-launcher-button>
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'

// App Bar Components
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import { ref, watch } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { mdiHeartOutline, mdiFaceAgent, mdiTextBox } from '@mdi/js'
import AppLauncherButton from '../../../../@core/comp-functions/app-launcher/AppLauncherButton.vue'
import store from '@/store'

export default {
  components: {
    LayoutContentHorizontalNav,
    AppLauncherButton,
    AppBarUserMenu,
    AppBarNotification,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return (
        appBarSearchQuery.value &&
        isMatched &&
        totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
      )
    }

    const openDocumentModal = () => {
      store.commit('documentation/SET_DOCUMENT_MODAL', true)
    }

    return {
      // Search
      appBarSearchQuery,
      openDocumentModal,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
        mdiFaceAgent,
        mdiTextBox,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
