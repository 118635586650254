<template>
  <v-menu bottom left class="" max-width="300" content-class="menu-component" color="transparent">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        class="align-self-center menu-custom flex-grow-1 text--primary"
        color="transparent"
        depressed
        v-on="on"
      >
        More
        <v-icon right>{{ icons.mdiMenuDown }}</v-icon>
      </v-btn>
    </template>

    <v-list class="lighten-3 menu-list overflow-y-auto" style="max-height: 88vh">
      <v-list-item
        v-for="(item, i) in more"
        :key="i"
        class="menu-list-item text-sm"
        @click="$emit('addItem', item)"
      >
        <div class="d-flex w-full">
          <div class="text-truncate flex-grow-1">
            <v-icon :size="16" class="mr-2">
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </div>
          <v-icon size="15" class="ml-1" @click.stop="$emit('closeTab', { item, isActive: false })">
            {{ icons.mdiClose }}
          </v-icon>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiMenuDown, mdiClose } from '@mdi/js'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'

export default {
  props: {
    more: {
      type: Array,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const closeTab = item => {
      store.commit('tabPersist/RESET_TOGGLE_DROPDOWN')
      store.dispatch(`tabs/${storeActions.CANCEL_TAB_REQUEST}`, item)
      emit('onResize')
    }

    return {
      closeTab,
      icons: {
        mdiMenuDown,
        mdiClose,
      },
    }
  },
}
</script>

<style></style>
