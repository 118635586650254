import { reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import Toaster from '@/@core/utils/sweetToast'
import { SUCCESS_RESPONSE, ERROR_RESPONSE } from '@/constants/appConstants'

const { GET_BY_ID, DELETE_BY_ID, GET_ALL_RECORDS } = storeActions

export default () => {
  const state = reactive({
    loading: false,
    accounts: [],
    categories: [],
    costcode: [],
  })

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'

    return 'primary'
  }

  const editRecord = async (payload, url) => {
    const config = {
      component: url,
      objectId: payload.uuid,
    }
    try {
      const { data } = await store.dispatch(`common/${GET_BY_ID}`, config)
      if (data.success) {
        return data
      }
      Toaster.error(data.message, 'warning')

      return false
    } catch (error) {
      Toaster.error('Please try again later', 'warning')

      return false
    }
  }
  const deleteRecord = async (payload, url) => {
    const config = {
      component: url,
      objectId: payload.uuid,
    }
    try {
      const { data } = await store.dispatch(`common/${DELETE_BY_ID}`, config)
      if (data.success) {
        Toaster.success(SUCCESS_RESPONSE, 'success')

        return data
      }
      Toaster.error(data.message, 'warning')

      return false
    } catch (error) {
      Toaster.error(ERROR_RESPONSE, 'warning')

      return false
    }
  }

  const checkUniqueEmail = async value => {
    const config = {
      component: 'security/validate',
      filter: {
        email: value,
      },
    }

    try {
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, config)
      if (data.success) {
        return true
      }

      return false
    } catch {
      return false
    }
  }

  const searchUsers = async value => {
    const payLoad = {
      component: 'security/users',
      filter: {
        limit: -1,
        search: value,
      },
    }

    try {
      state.loading = true
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)
      if (data.success && data.data?.length) {
        state.loading = false

        return data.data
      }
      state.loading = false

      return []
    } catch (error) {
      state.loading = false

      return []
    }
  }
  const searchUsersGroup = async value => {
    const payLoad = {
      component: 'setup/user-group',
      filter: {
        limit: -1,
        search: value,
      },
    }

    try {
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)
      if (data.success && data.data?.length) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  const searchDepartments = async value => {
    const payLoad = {
      component: 'setup/organization/departments',
      filter: {
        limit: -1,
        search: value,
      },
    }

    try {
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)
      if (data.success && data.data?.length) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  return {
    resolveUserStatusVariant,
    editRecord,
    deleteRecord,
    searchUsers,
    searchUsersGroup,
    searchDepartments,
    checkUniqueEmail,
    ...toRefs(state),
  }
}
// role modules constant

export const modules = [
  { text: 'Crm', value: 'Crm' },
  { text: 'Security', value: 'Security' },
  { text: 'Workdrive', value: 'Workdrive' },
  { text: 'Estimation', value: 'Estimation' },
  { text: 'Setup', value: 'Setup' },
  { text: 'Task Management', value: 'Task Management' },
  { text: 'Inventory Management', value: 'Inventory Management' },
]

export const submodules = {
  crm: [
    { text: 'Account', value: 'account' },
    { text: 'Opportunity', value: 'opportunity' },
    { text: 'Lead', value: 'lead' },
    { text: 'Contact', value: 'contact' },
  ],
  security: [
    { text: 'roles', value: 'Roles' },
    { text: 'users', value: 'Opportunity' },
    { text: 'userGroup', value: 'userGroup' },
  ],
  workdrive: [{ text: 'folder', value: 'folder' }],
}

export const permissionObj = {
  crm: {
    account: null,
    opportunity: null,
    lead: null,
    contact: null,
  },
  security: {
    roles: null,
    users: null,
    userGroup: null,
  },
  workdrive: {
    folder: null,
  },
}
