<template>
  <div class="px-12 mb-8">
    <p class="dark--text font-weight-semibold" style="font-size: 26px">How to Get Proper Reports</p>

    <div class="d-flex" style="gap: 30px">
      <div class="w-half">
        <p class="warning--text text-base">Reports List</p>
        <p class="dark--text">
          Access a list of all the reports created, including information such as the 'Report Description,' 'Created By,' and the day the report was 'Created on.'
        </p>
      </div>
      <div class="w-half">
        <p class="warning--text text-base">Shared Reports</p>
        <p class="dark--text">
          You can also access a list of all the shared reports created, including information such as the 'Report Description,' 'Shared By,' and the day the report was 'Created on.'
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.w-half {
  width: 50%;
}
</style>
