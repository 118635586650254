// eslint-disable-next-line object-curly-newline
import {
  mdiAccountBoxOutline,
  mdiChartTimelineVariant,
  mdiHomeOutline,
  mdiMonitorDashboard,
  mdiLightbulbOutline,
  mdiAccountCheckOutline,
  mdiSemanticWeb,
} from '@mdi/js'

export default [
  {
    title: 'CRM Dashboard',
    icon: mdiHomeOutline,
    children: [
      {
        icon: mdiMonitorDashboard,
        title: 'CRM Dashboard',
        to: 'dashboard-crm',
        moduleKey: 'dashboard',
      },
      {
        icon: mdiChartTimelineVariant,
        title: 'Account',
        to: 'crm-account',
        moduleKey: 'account',
      },
      {
        icon: mdiLightbulbOutline,
        title: 'Opportunity',
        to: 'crm-opportunity',
        moduleKey: 'opportunity',
      },
      {
        icon: mdiAccountBoxOutline,
        title: 'Contact',
        to: 'crm-contact',
        moduleKey: 'contact',
      },
      {
        icon: mdiAccountCheckOutline,
        title: 'Lead',
        to: 'crm-lead',
        moduleKey: 'lead',
      },
      {
        icon: mdiSemanticWeb,
        title: 'Recent Changes',
        to: 'crm-recent-changes',
        moduleKey: 'recent-changes',
      },
    ],
  },
]
