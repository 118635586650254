<template>
  <div class="mt-4">
    <div v-if="!hasReadOnlyAccess" class="d-flex align-center file-subitem">
      <div class="d-flex align-center cursor-pointer">
        <label class="file-layout ml-2">
          <v-file-input
            id="fileUploader"
            v-model="file"
            multiple
            style="display: none"
            class="custom-field w-icon"
            @change="fileUpload"
          ></v-file-input>

          <v-icon size="15" color="dark">
            {{ mdiPaperclip }}
          </v-icon>
          <span class="ml-1 text-xs3 black--text font-weight-medium"
            >+File ({{ attachments.length }})</span
          >
        </label>
        <div class="ml-3" @click="showInput = !showInput">
          <v-icon size="15" color="dark">{{ mdiFileTreeOutline }}</v-icon>
          <span class="ml-1 text-xs3 black--text font-weight-medium">+Sub-Item</span>
        </div>
      </div>
    </div>
    <p class="text-sm mt-3 mb-2">Description</p>
    <v-textarea
      v-model="taskInfo.details"
      auto-grow
      hide-details
      :readonly="hasReadOnlyAccess"
      rows="1"
      row-height="8"
      placeholder="Enter description"
      class="input-detail-textarea"
      @blur="saveDetail"
    ></v-textarea>
    <p class="text-sm mt-3 mb-2">Subitems ({{ subitemList.length }})</p>
    <div>
      <div
        v-for="sub in subitemList"
        :key="sub.uuid"
        class="d-flex justify-space-between px-3 sub-item py-2"
      >
        <p class="mb-0 text-sm font-weight-medium cursor-pointer" @click="openTask(sub)">
          <v-icon size="19" class="mr-1">{{ mdiFileOutline }}</v-icon>
          {{ sub.name }}
        </p>
        <v-chip
          class="font-weight-semibold"
          style="height: 25px"
          label
          outlined
          small
          :color="sub.workflow_status_color"
        >
          {{ sub.workflow_status }}
        </v-chip>
      </div>
      <div v-if="showInput" class="d-flex align-center item-box pr-3">
        <v-text-field
          v-model="taskName"
          hide-details
          solo
          placeholder="Enter name"
          @blur="onClickOutside"
          @keyup.enter="onClickOutside"
        ></v-text-field>

        <v-btn class="skyBlue" depressed x-small> Task </v-btn>
      </div>
    </div>
    <p class="text-sm mt-3 mb-2">Files</p>
    <div class="attachment-list mt-2">
      <div
        v-for="(item, index) in attachments"
        :key="index"
        class="attach-image d-flex align-center cursor-pointer"
        @click="btnEvent('Preview', item)"
      >
        <img :src="getFileThumbnail(item.name)" style="height: 20px" class="pl-1" />

        <div class="image-text pa-2 pl-1 text-xs3">
          {{ item.name.slice(0, 14) }}
        </div>
        <v-menu
          v-if="!hasReadOnlyAccess"
          offset-y
          rounded
          origin="center center"
          transition="scale-transition"
          content-class="width-unset"
          min-width="150px"
          :left="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" size="19" v-on="on">
              {{ mdiDotsVertical }}
            </v-icon>
          </template>
          <v-list class="btn-dropdown pa-2">
            <v-list-item @click="btnEvent('Download', item)">
              <v-list-item-title class="d-flex justify-start align-center">
                <span>{{ 'Download' }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="btnEvent('Trash', item)">
              <v-list-item-title class="d-flex justify-start align-center">
                <span>{{ 'Delete' }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <image-viewer
      v-if="isOpenViewer"
      :viewerobj="previewData"
      :loading="previewPageLoading"
      @download="btnEvent('Download', previewData.file)"
      @close="isOpenViewer = false"
    />
  </div>
</template>
<script>
import { mdiPaperclip, mdiDotsVertical, mdiFileTreeOutline, mdiFileOutline } from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import useTaskService from '@/components/task-management/useTaskService'
import { storeActions } from '@/store/storeConstants'
import useCrmService from '@/components/crm/useCrmService'
import { formatDate, avatarText, getFileThumbnail, getFileExtension } from '@core/utils/filter'
import ImageViewer from '@/components/shared-components/ImageViewer.vue'
import useDriveService from '@/components/drive/useDriveService'
import eventBus from '@/@core/utils/eventBus'
import { nanoid } from 'nanoid'

export default {
  components: { ImageViewer },
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { deleteFileAndFolder, downloadFile, previewUrl } = useDriveService()
    const { loadTask } = useTaskService()
    const taskBarId = computed(() => store.getters['taskManagement/getTaskBarId'])
    const getSelectedSpace = computed(() => store.getters['taskManagement/getSelectedSpace'])

    const taskName = ref(null)

    const showInput = ref(false)
    const attachments = ref([])
    const file = ref(null)
    const { fileUploader } = useCrmService()
    const { route, router } = useRouter()
    const isOpenViewer = ref(false)
    const previewData = ref({})
    const previewPageLoading = ref(false)
    const subitemList = ref([])
    const record = computed(() => store.getters['tableView/getTasks'])

    const loadImage = () => {
      const config = {
        component: 'workdrive/',
        filter: {
          limit: 9999,
          offset: 0,
          type: 1,
          prop_id: props.taskInfo.uuid,
          prop_type: 'space_items',
        },
      }
      store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, config).then(({ data }) => {
        if (data.success && data.data) {
          attachments.value = data.data
        } else {
          attachments.value = []
        }
      })
    }

    const fileUpload = async () => {
      const propsObj = {
        prop_type: 'space_items',
        prop_id: props.taskInfo.uuid,
      }

      await fileUploader(file.value, 0, propsObj)
      loadImage()
      file.value = null
    }

    const updateTask = payload => {
      const routeName = route.value.name
      if (routeName === 'table-task' || routeName === 'share-list') {
        store.dispatch('tableView/UPDATE_TASK', {
          ...payload,
        })
      } else if (routeName === 'kanban-task') {
        store.dispatch('kanbanView/UPDATE_TASK', {
          ...payload,
        })
      } else {
        store
          .dispatch('tableView/UPDATE_TASK_ONLY', {
            ...payload,
          })
          .then(() => {
            eventBus.$emit('onTaskUpdate')
          })
      }
    }

    const saveDetail = () => {
      updateTask({ details: props.taskInfo.details, uuid: props.taskInfo.uuid })
    }

    const fileDownload = item => {
      const payload = {
        ...item,
        propType: 'space_items',
        propId: props.taskInfo.uuid,
      }
      downloadFile(payload)
    }

    const btnEvent = async (type, item) => {
      if (type === 'Download') {
        fileDownload(item)
      }

      if (type === 'Trash') {
        const resp = await deleteFileAndFolder(item.uuid)
        if (resp) loadImage()
      }
      if (type === 'Preview') {
        const fileType = getFileExtension(item.name)
        if (fileType && ['.pdf', '.svg', '.png', '.jpg', '.jpeg', '.gif'].includes(fileType[0])) {
          previewData.value = { path: '', type: fileType[0], file: item }
          isOpenViewer.value = true
          previewPageLoading.value = true
          const payload = {
            ...item,
            propType: 'space_items',
            propId: props.taskInfo.uuid,
          }
          const preview = await previewUrl(payload)
          previewPageLoading.value = false
          if (preview) {
            previewData.value.path = preview
          }
        } else {
          fileDownload(item)
        }
      }
    }

    const fetchSubitem = async () => {
      const payload = {
        dependent_id: taskBarId.value,
        space_id: props.taskInfo.space_id,
      }
      loadTask('task-management/spaces/item/list', payload).then(({ data }) => {
        subitemList.value = data.data
      })
    }

    const onClickOutside = e => {
      if (!taskName.value) {
        showInput.value = false

        return
      }

      const payLoad = {
        object: {
          name: taskName.value,
          dependent_id: taskBarId.value,
          space_id: props.taskInfo.space_id,
          type: 'task',
        },
        component: 'task-management/spaces/item',
      }

      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(({ data }) => {
          if (data.success) {
            taskName.value = null
            fetchSubitem()
            if (route.value.name === 'table-task' || route.value.name === 'share-list') {
              const findItems = record.value.filter(opt => {
                return opt.uuid === props.taskInfo.uuid
              })

              findItems.forEach(element => {
                const taskData = data.data
                taskData.id = nanoid()
                taskData.parent_id = element.id
                store.dispatch('tableView/UPDATE_SPECIFIC_TASK', {
                  createdTask: taskData,
                  taskId: element.id,
                  type: 'add',
                })
              })
            }
          }
        })
        .finally(() => {
          showInput.value = false
        })
    }

    const openTask = sub => {
      router.push({
        name: 'table-task',
        params: { spaceId: getSelectedSpace.value, taskId: sub.uuid },
      })
      store.commit('taskManagement/SET_OPEN_BAR', false)
    }
    fetchSubitem()
    loadImage()

    const hasReadOnlyAccess = computed(() => {
      if (!props.taskInfo?.permission) return false
      // 0 is no access 1 is read, 2 is editor, 3-4 is full or admin access
      if ([1, 0].includes(props.taskInfo.permission)) {
        return true
      }

      return false
    })

    return {
      saveDetail,
      file,
      attachments,
      fileUpload,
      isOpenViewer,
      openTask,
      previewData,
      previewPageLoading,
      formatDate,
      getFileThumbnail,
      hasReadOnlyAccess,
      avatarText,
      btnEvent,
      mdiPaperclip,
      taskName,
      showInput,
      onClickOutside,
      subitemList,
      mdiDotsVertical,
      mdiFileTreeOutline,
      mdiFileOutline,
    }
  },
}
</script>
<style scoped lang="scss">
.file-subitem {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 7px 0;
}
.file-layout {
  cursor: pointer;
}
::v-deep {
  .input-detail-textarea.v-text-field {
    padding-top: 0px;
  }
  .input-detail-textarea.v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }
  .input-detail-textarea.v-text-field > .v-input__control > .v-input__slot textarea {
    font-size: 14.5px;
    line-height: 18px;
    min-height: 18px;
  }
}
.attachment-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.attach-image {
  position: relative;
  border: 1px solid #dbdbdb;
  border-radius: 5px;

  .image-btn {
    position: absolute;
    top: 0px;
    right: 2px;
    opacity: 0;
  }
  .image-text {
    width: 100%;
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    .image-btn {
      opacity: 1;
    }
  }
}

::v-deep {
  .v-text-field.v-text-field--solo {
    border-radius: 0px;
    .v-input__control {
      min-height: 32px;
      border: 0;
      .v-input__slot {
        box-shadow: none !important;
      }
    }
  }

  .item-box {
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }
}

.sub-item {
  border-bottom: 1px solid #ddd;
}

.sub-item:hover {
  background: #f4f4f4;
}
</style>
