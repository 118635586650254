<template>
  <div class="call-bg position-relative">
    <div class="position-absolute content-area white--text w-full">
      <p class="content">Talk to our Experts To Understand <br />Better</p>
      <p class="text-sm">
        Talk to our experts to better understand how our comprehensive suite of construction tools
        can benefit <br /> your business. They are here to answer your questions and provide guidance to
        meet your specific needs.
      </p>
      <div class="d-flex">
        <div class="white pa-3">
          <a href="mailto:sv@bldon.com">
            <v-icon size="35" color="black">{{ mdiEmailOutline }}</v-icon>
          </a>
        </div>
        <div class="white pa-3 ml-2">
          <a href="tel:+146935060000">
            <v-icon size="35" color="black">{{ mdiPhoneOutline }}</v-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdiPhoneOutline, mdiEmailOutline } from '@mdi/js'

export default {
  setup() {
    return {
      mdiPhoneOutline,
      mdiEmailOutline,
    }
  },
}
</script>
<style scoped lang="scss">
.call-bg {
  background: url('~@/assets/images/svg/call.svg');
  height: 453px;
  width: 100%;
  .content-area {
    margin-top: 120px;
    padding-left: 120px;
  }
  .content {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
  }
}
</style>
