import { storeActions } from '@/store/storeConstants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    selectedWorkflow: null,
    workflowStatusOptions: [],
    kanbanData: [],
  },
  getters: {
    getKanbanTasks: state => state.kanbanData,
    getSelectedWorkflow: state => state.selectedWorkflow,
    getWorkflowStatusOptions: state => state.workflowStatusOptions,
  },
  mutations: {
    SET_WORKFLOW(state, payload) {
      state.selectedWorkflow = payload
    },
    SET_KANBAN_TASK(state, payload) {
      state.kanbanData = payload
    },
    UPDATE_FIELD(state, payload) {
      // find index and update specific task and maintain its reactivity
      const index = state.kanbanData.findIndex(opt => {
        return opt.uuid === payload.uuid
      })
      if (index !== -1) {
        let taskToBeUpdated = state.kanbanData[index]
        taskToBeUpdated = {
          ...taskToBeUpdated,
          ...payload.data,
        }
        Vue.set(state.kanbanData, index, taskToBeUpdated)
      }
    },
    REMOVE_ITEM(state, payload) {
      const index = state.kanbanData.findIndex(opt => {
        return opt.uuid === payload.uuid
      })

      if (index !== -1) {
        state.kanbanData.splice(index, 1)
      }
    },
    SET_WORKFLOW_STATUS(state, payload) {
      state.workflowStatusOptions = payload
    },
  },
  actions: {
    LOAD_KANBAN_TASK: ({ commit, dispatch }, payload) => {
      const obj = {
        component: '/task-management/spaces/item/kanban',
        object: {
          ...payload,
        },
      }

      return dispatch(`common/${storeActions.CREATE}`, obj, { root: true }).then(({ data }) => {
        const taskData = data.data.map(opt => {
          return {
            ...opt,
            assigned_users: opt.assigned_users.filter(elm => elm.uuid),
          }
        })
        commit('SET_KANBAN_TASK', taskData)
      })
    },
    UPDATE_TASK: ({ commit, dispatch }, payload) => {
      const object = {
        component: 'task-management/spaces/item',
        objectId: payload.uuid,
        object: { ...payload },
      }

      return dispatch(`common/${storeActions.UPDATE_BY_ID}`, object, { root: true }).then(
        ({ data }) => {
          if (data.success) {
            const dataToBeUpdated = {
              ...data.data,
              assigned_users: data.data.assigned_users.filter(elm => elm.uuid),
            }
            commit('UPDATE_FIELD', {
              uuid: payload.uuid,
              data: dataToBeUpdated,
            })
          }
        },
      )
    },
    LOAD_WORKFLOW_STATUS: ({commit, dispatch}, payload) => {
      const config = {
        component: `setup/taskManagement/workflow/${payload.workflow_uuid}/status`,
      }
      dispatch(`common/${storeActions.GET_ALL_RECORDS}`, config, {root: true}).then(({ data }) => {
        if (data.success) {
          const apiData = data.data.sort((a, b) => a.sequence - b.sequence)
          commit('SET_WORKFLOW_STATUS', apiData)
        }
      })
    },
  },
}
