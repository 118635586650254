import { storeActions, storeMutations } from '@/store/storeConstants'
import store from '@/store'
import { nanoid } from 'nanoid'

const { SET_FILES_STATUS, SET_FILES_ARRAY, SET_UPLOADING_START, SET_UPLOADING_PERCENTAGE, SET_RESET_NOTIFY } = storeMutations

export default () => {
  const fileUpload = async (file, parentId, propsObj) => {
    const fileObject = {
      name: file.name,
      id: nanoid(),
      percentage: 0,
      status: 'uploading',
    }
    try {
      store.commit(`notify/${SET_UPLOADING_START}`, fileObject)

      const config = {
        object: {
          name: file.name,
          type: 1,
          parent_id: parentId || 0,
          ...propsObj,
        },
        component: 'workdrive/',
      }
      const { data } = await store.dispatch(`common/${storeActions.CREATE}`, config)

      const object = {
        component: data.data.url,
        file,
        config: {
          onUploadProgress: progressEvent => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
              id: fileObject.id,
              percentage: 5 + Math.round(percentage / 1.052),
            })
            if (percentage === 100) {
              setTimeout(() => {
                store.commit(`notify/${SET_RESET_NOTIFY}`)
              }, 2000)
            }
          },
        },
      }

      // upload to s3
      await store.dispatch(`common/${storeActions.UPDATE_FILE}`, object)
      const payLoad = {
        object: {
          cloud_status: true,
          type: 1,
          ...propsObj,
        },
        component: `workdrive/${data.data.uuid}`,
      }

      // upload to thrid step
      const { data: responeData } = await store.dispatch(`common/${storeActions.UPDATE}`, payLoad)
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 100,
        status: 'success',
      })

      return !!responeData.success
    } catch (error) {
      store.commit(`notify/${SET_UPLOADING_PERCENTAGE}`, {
        id: fileObject.id,
        percentage: 0,
        status: 'failed',
      })

      return false
    }
  }

  const fileUploader = async (files, parentId, propsObj) => {
    const names = Array.from(files).map(file => {
      return file.name
    })

    store.commit(`notify/${SET_FILES_ARRAY}`, names)
    const promisesToAwait = []

    files.forEach(file => {
      promisesToAwait.push(fileUpload(file, parentId, propsObj))
    })

    await Promise.all(promisesToAwait)

    store.commit(`notify/${SET_FILES_STATUS}`)
  }

  const fetchAccounts = async search => {
    const payload = {
      component: 'crm/accounts',
      filter: {
        search,
      },
    }
    try {
      const { data } = await store.dispatch(`common/${storeActions.GET_ALL_RECORDS}`, payload)
      if (data.success) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  return {
    fileUploader,
    fetchAccounts,
  }
}
