import { storeActions } from '@/store/storeConstants'
import Vue from 'vue'
import { nanoid } from 'nanoid'

const canTaskDeleted = (item, selectedTask) => {
  return selectedTask.some(elm => {
    if (elm.uuid === item.uuid && elm.parent_id === item.parent_id) return true

    return false
  })
}

export default {
  namespaced: true,
  state: {
    tasks: [],
    selectedTask: [],
    overlayLoading: false,
    tableLoading: false,
    customHeader: [],
  },
  getters: {
    getTasks: state => state.tasks,
    getSelectedTask: state => state.selectedTask,
    getOverlayLoading: state => state.overlayLoading,
    getTableLoading: state => state.tableLoading,
    getCustomHeader: state => state.customHeader,
  },
  mutations: {
    SET_TASK(state, payload) {
      state.tasks = payload
    },
    SET_SINGLE_TASK(state, payload) {
      // append new created task to the end of list
      Vue.set(state.tasks, state.tasks.length, payload)
    },
    SET_SELECTED_TASK(state, payload) {
      state.selectedTask = [...payload]
    },
    SET_OVERLAY_LOADING(state, payload) {
      state.overlayLoading = payload
    },
    SET_Table_LOADING(state, payload) {
      state.tableLoading = payload
    },
    UPDATE_FIELD(state, payload) {
      // by use of map we update the required items in state based unpon uuid
      // one task can be multiple times inside state
      const newitems = state.tasks.map(opt => {
        return opt.uuid === payload.uuid ? { ...opt, ...payload.data } : { ...opt }
      })
      state.tasks = newitems
      // find index and update specific task and maintain its reactivity
      // const index = state.tasks.findIndex(opt => {
      //   return opt.uuid === payload.uuid
      // })

      // if (index !== -1) {
      //   let taskToBeUpdated = state.tasks[index]
      //   taskToBeUpdated = {
      //     ...taskToBeUpdated,
      //     ...payload.data,
      //   }
      //   Vue.set(state.tasks, index, taskToBeUpdated)
      // }
    },
    SET_SPECIFIC_TASK(state, payload) {
      state.tasks.splice(payload.index, 0, payload.data)
    },
    DELETE_SPECIFIC_TASK(state, payload) {
      state.tasks.splice(payload.index, 1)
    },
    SET_CUSTOM_HEADER(state, payload) {
      state.customHeader = payload
    },
  },
  actions: {
    SELECTED_TASK: ({ commit, getters }, payload) => {
      const allTasks = [...getters.getTasks]
      const selectItem = allTasks.filter(opt => {
        return payload.includes(opt.uuid)
      })

      commit('SET_SELECTED_TASK', selectItem)
    },
    LOAD_TASK: ({ commit, dispatch }, payload) => {
      const obj = {
        component: '/task-management/spaces/item/list',
        object: {
          ...payload,
        },
      }

      return dispatch(`common/${storeActions.CREATE}`, obj, { root: true }).then(({ data }) => {
        const taskData = data.data.map(opt => {
          return {
            ...opt,
            assigned_users: opt.assigned_users.filter(elm => elm.uuid),
            id: nanoid(),
            parent_id: null,
            level: 0,
            isShow: true,
            isExpand: false,
          }
        })
        commit('SET_TASK', taskData)
      })
    },
    LOAD_SHARE_TASK: ({ commit, dispatch }, payload) => {
      const obj = {
        component: '/task-management/spaces/item/share/list',
        object: {
          ...payload,
        },
      }

      return dispatch(`common/${storeActions.CREATE}`, obj, { root: true }).then(({ data }) => {
        const taskData = data.data.map(opt => {
          return {
            ...opt,
            assigned_users: opt.assigned_users.filter(elm => elm.uuid),
            parent_id: null,
            id: nanoid(),
            level: 0,
            isShow: true,
            isExpand: false,
          }
        })
        commit('SET_TASK', taskData)
      })
    },
    DELETE_RECORD: ({ commit, dispatch, getters }) => {
      const tasksToBeDeleted = [...getters.getSelectedTask].map(opt => opt.uuid)
      const payload = {
        component: 'task-management/spaces/item',
        object: { uuid: tasksToBeDeleted },
      }

      return dispatch(`common/${storeActions.DELETE}`, payload, { root: true }).then(({ data }) => {
        commit('SET_SELECTED_TASK', [])
      })
    },
    UPDATE_TASK: ({ commit, dispatch }, payload) => {
      const object = {
        component: 'task-management/spaces/item',
        objectId: payload.uuid,
        object: { ...payload },
      }

      return dispatch(`common/${storeActions.UPDATE_BY_ID}`, object, { root: true }).then(
        ({ data }) => {
          if (data.success) {
            const dataToBeUpdated = {
              ...data.data,
              assigned_users: data.data.assigned_users.filter(elm => elm.uuid),
            }
            commit('UPDATE_FIELD', {
              uuid: payload.uuid,
              data: dataToBeUpdated,
            })
          }
        },
      )
    },
    UPDATE_TASK_ONLY: ({ dispatch }, payload) => {
      const object = {
        component: 'task-management/spaces/item',
        objectId: payload.uuid,
        object: { ...payload },
      }

      return dispatch(`common/${storeActions.UPDATE_BY_ID}`, object, { root: true })
    },

    UPDATE_SPECIFIC_TASK: ({ commit, getters }, payload) => {
      // first check is task is open
      // if task is open than add created task in the add to below its parent task
      const { taskId, createdTask, type } = payload
      const allTasks = [...getters.getTasks]

      const parentTaskIndex = allTasks.findIndex(opt => {
        return opt.id === taskId
      })
      if (parentTaskIndex === -1) return
      const parentTask = allTasks[parentTaskIndex]

      if (!parentTask.isExpand || !parentTask.isShow) return
      const data = {
        ...createdTask,
        assigned_users: createdTask.assigned_users.filter(elm => elm.uuid),
        level: parentTask.level + 1,
        isShow: true,
        isExpand: false,
      }
      if (type === 'delete') {
        commit('DELETE_SPECIFIC_TASK', {
          index: parentTaskIndex + 1,
        })
      } else {
        commit('SET_SPECIFIC_TASK', {
          index: parentTaskIndex + 1,
          data,
        })
      }
    },
    LOAD_CUSTOM_HEADER: ({ commit, dispatch }, payload) => {
      const obj = {
        component: payload.url,
      }

      return dispatch(`common/${storeActions.GET_ALL_RECORDS}`, obj, { root: true }).then(
        ({ data }) => {
          commit('SET_CUSTOM_HEADER', data.data)
        },
      )
    },
  },
}
