<template>
  <div>
    <v-list-item
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
      @click="openTaskBar"
    >
      <v-list-item-icon>
        <v-icon :class="{ 'alternate-icon-small': !item.icon }" color="white">
          {{ item.icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title class="white--text" style="font-size: 0.9rem">
        {{ item.title }}
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import useService from '@/components/shared-components/useCommonService'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openTaskBar() {
      this.$router.push({ name: 'shared-task' })
      this.$emit('openSharedTaskBar')
    },
  },
  setup() {
    const { response } = useService()
    const openTaskFolderModal = ref(false)

    return {
      response,
      openTaskFolderModal,
      alternateIcon: themeConfig.menu.groupChildIcon,
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 12px;
  }
}
</style>
