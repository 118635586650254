<template>
  <div>
    <vue-editor
      ref="quillEditor"
      v-model="commentFormData.comment"
      data-cy="quill-editor"
      class="tm-editor"
      placeholder="Add a comment"
      :editor-options="editorSettings"
      :editor-toolbar="customToolbar"
      use-custom-image-handler
      @image-added="handleImage"
    ></vue-editor>
    <div class="text-right btn-action">
      <v-btn
        small
        data-cy="comment-btn"
        text
        :loading="commentFormData.loading"
        @click="addComment"
      >
        <v-icon>{{ mdiSend }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { storeGetters } from '@/store/storeConstants'
import { VueEditor, Quill } from 'vue2-editor'
import { ref, computed } from '@vue/composition-api'
import quillMention from 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import accountService from '@/components/crm/account/accountService'
import useFileUploader from '@core/utils/useFileUploader'
import Toaster from '@core/utils/sweetToast'
import { useRouter } from '@core/utils'
import ImageResize from 'quill-image-resize-vue'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { ERROR_RESPONSE } from '@/constants/appConstants'
import { mdiSend } from '@mdi/js'

Quill.register('modules/mention', quillMention)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export default {
  components: {
    VueEditor,
  },
  props: {
    propType: {
      type: String,
      required: true,
    },
    propId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: undefined,
    },
    commentForm: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root, emit }) {
    const { GET_ALL_USER } = storeGetters
    const { uploadStep1, uploadStep2, uploadStep3 } = useFileUploader()
    const { route } = useRouter()

    const comment = ref(null)
    const loading = ref(false)
    const quillEditor = ref(null)
    const customToolbar = [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ]

    const { fetchUsers } = accountService()
    const userList = computed(() => root.$store.getters[`users/${GET_ALL_USER}`])

    const imageHandler = async (imageDataUrl, type, imageData) => {
      const file = imageData.toFile()

      const payload = {
        object: {
          prop_id: props.propId,
          prop_type: props.propType,
          type: 1,
          parent_id: 0,
          name: file.name,
        },
        component: 'workdrive/',
      }
      const { data } = await uploadStep1(payload)
      if (data && data.data) {
        // upload to s3
        const object = {
          component: data.data.url,
          file,
        }
        const response = await uploadStep2(object)
        if (response) {
          const resp = await uploadStep3(data.data, true)
          if (resp.data && resp.data.success) {
            let index = (quillEditor.value.quill.getSelection() || {}).index
            if (index === undefined || index < 0) index = quillEditor.value.quill.getLength()
            quillEditor.value.quill.insertEmbed(index, 'image', resp.data.data, 'user')
          } else {
            Toaster.error(ERROR_RESPONSE, 'warning')
          }
        }
      } else {
        Toaster.error(ERROR_RESPONSE, 'warning')
      }
    }

    const editorSettings = {
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@', '#'],
          async source(searchTerm, renderList) {
            if (!searchTerm) return
            await fetchUsers(searchTerm)
            renderList(userList.value)
          },
        },
        imageResize: {
          modules: ['Resize'],
        },
        imageDropAndPaste: {
          handler: imageHandler,
        },
      },
    }

    const commentFormData = computed(() => {
      return props.commentForm
    })

    const addComment = () => {
      if (!commentFormData.value.comment) return
      emit('commentEvent', () => {
        quillEditor.value.quill.setText('')
      })
    }
    const handleImage = async (file, Editor, cursorLocation, resetUploader) => {
      const payload = {
        object: {
          prop_id: props.propId,
          prop_type: props.propType,
          type: 1,
          parent_id: 0,
          name: file.name,
        },
        component: 'workdrive/',
      }
      const { data } = await uploadStep1(payload)
      if (data && data.data) {
        // upload to s3
        const object = {
          component: data.data.url,
          file,
        }
        const response = await uploadStep2(object)
        if (response) {
          const resp = await uploadStep3(data.data, true)
          if (resp.data && resp.data.success) {
            let pos = 0
            if (commentFormData.value.comment) {
              Editor.insertText(cursorLocation, '\n')
              pos = 1
            }
            // upload image uuid inserting from uploadStep1
            Editor.insertEmbed(cursorLocation + pos, 'image', resp.data.data)
            resetUploader()
          } else {
            Toaster.error(ERROR_RESPONSE, 'warning')
          }
        }
      } else {
        Toaster.error(ERROR_RESPONSE, 'warning')
      }
    }

    return {
      comment,
      quillEditor,
      customToolbar,
      editorSettings,
      userList,
      addComment,
      handleImage,
      loading,
      commentFormData,
      mdiSend,
    }
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0 !important;
    padding-top: 4px !important;

    .ql-formats {
      margin-bottom: 6px !important;
    }
  }
  .ql-container.ql-snow {
    border-bottom: 0;
  }

  .ql-editor {
    min-height: 25px !important;
    padding: 10px 10px 0 10px;
    font-size: 14.8px;
  }

  .btn-action {
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
