import moment from 'moment'
import CrmSvg from '@/assets/images/svg/crm-new.svg'
import Estimation from '@/assets/images/svg/estimation-new.svg'
import Security from '@/assets/images/svg/security-new.svg'
import Setup from '@/assets/images/svg/settingsvg.svg'
import workDrive from '@/assets/images/svg/workdrive-new.svg'
import email from '@/assets/images/svg/email-new.svg'
import task from '@/assets/images/svg/receipt-2.svg'
import cpu from '@/assets/images/svg/cpu.svg'
import material from '@/assets/images/svg/filter-square.svg'
import projectsvg from '@/assets/images/svg/projectsvg.svg'
import report from '@/assets/images/svg/note-text.svg'
import dashboard from '@/assets/images/svg/view-dashboard.svg'
import account from '@/assets/images/svg/book-account.svg'
import goals from '@/assets/images/svg/goals.svg'
import inventory from '@/assets/images/svg/inventory.svg'
import form from '@/assets/images/svg/form.png'
import {
  mdiLeadPencil,
  mdiCloud,
  mdiChatOutline,
  mdiArrowExpand,
  mdiTable,
  mdiAlphabetical,
  mdiCircle,
  mdiCircleOutline,
  mdiRectangleOutline,
  mdiRectangle,
  mdiPolymer,
  mdiChartLineVariant,
} from '@mdi/js'

// bldon App constant don't change them
export const bldonApps = {
  crm: { text: 'crm', value: 1 },
  workdrive: { text: 'workdrive', value: 2 },
  security: { text: 'security', value: 3 },
  setup: { text: 'setup', value: 4 },
  vendorManagement: { text: 'vendorManagement', value: 5 },
  materialManagement: { text: 'materialManagement', value: 6 },
  estimation: { text: 'estimation', value: 7 },
  taskManagement: { text: 'taskManagement', value: 8 },
  projectManagement: { text: 'projectManagement', value: 9 },
  reports: { text: 'reports', value: 14 },
  email: { text: 'email', value: 15 },
  goalManagement: { text: 'goalManagement', value: 16 },
  dashboards: { text: 'dashboards', value: 17 },
  map: { text: 'Map', value: 19 },
  accounting: { text: 'accounting', value: 10 },
  inventoryManagement: { text: 'inventoryManagement', value: 11 },
  formBuilder: { text: 'formBuilder', value: 12 },
  adminConsole: { text: 'adminConsole', value: 18 },
  pos: { text: 'POS', value: 20 },
}

// launcher drawer apps divide into two section baseApps & Management Apps
export const baseApps = [
  {
    icon: CrmSvg,
    title: 'CRM',
    appId: bldonApps.crm.value,
    value: 'crm',
    to: { name: 'dashboard-crm' },
  },
  {
    icon: workDrive,
    title: 'Work Drive',
    appId: bldonApps.workdrive.value,
    value: 'workdrive',
    to: { name: 'Drive-folder' },
  },
  {
    icon: Setup,
    title: 'Setup',
    appId: bldonApps.setup.value,
    value: 'setup',
    to: { name: 'setup-form-list' },
  },
  {
    icon: Security,
    title: 'Security',
    appId: bldonApps.security.value,
    value: 'security',
    to: { name: 'security-dashboard' },
  },
  {
    icon: Estimation,
    title: 'Estimation',
    value: 'estimation',
    appId: bldonApps.estimation.value,
    to: { name: 'estimation-dashboard' },
  },
  {
    icon: email,
    title: 'Email',
    value: 'email',
    appId: bldonApps.email.value,
    to: { name: 'email-folder' },
  },
  {
    icon: email,
    title: 'Map',
    value: 'map',
    appId: bldonApps.map.value,
    to: { name: 'email-folder' },
  },
  {
    icon: task,
    title: 'Admin Console',
    value: 'adminConsole',
    appId: bldonApps.adminConsole.value,
    to: { name: 'admin-dashboard' },
  },
  {
    icon: cpu,
    title: 'POS',
    value: 'pos',
    appId: bldonApps.pos.value,
    to: { name: 'point-of-sale' },
  },
]

export const ManagementApps = [
  {
    icon: task,
    title: 'Task Management',
    appId: bldonApps.taskManagement.value,
    value: 'taskManagement',
    to: { name: 'task-dashboard' },
  },
  {
    icon: cpu,
    title: 'Vendor Management',
    value: 'vendorManagement',
    appId: bldonApps.vendorManagement.value,
    to: { name: 'vendors' },
  },
  {
    icon: material,
    title: 'Material Management',
    value: 'materialManagement',
    appId: bldonApps.materialManagement.value,
    to: { name: 'material-management' },
  },
  {
    icon: projectsvg,
    title: 'Project Management',
    value: 'projectManagement',
    appId: bldonApps.projectManagement.value,
    to: { name: 'pm-dashboard' },
  },
  {
    icon: report,
    title: 'Reports',
    value: 'report',
    appId: bldonApps.reports.value,
    to: { name: 'reports' },
  },
  {
    icon: dashboard,
    title: 'Dashboard',
    value: 'dashboard',
    appId: bldonApps.dashboards.value,
    to: { name: 'dashboards' },
  },
  {
    icon: account,
    title: 'Accounting',
    value: 'accounting',
    appId: bldonApps.accounting.value,
    to: { name: 'account-setup' },
  },
  {
    icon: goals,
    title: 'Goal Management',
    value: 'goalManagement',
    appId: bldonApps.goalManagement.value,
    to: { name: 'goals-dashboard' },
  },
  {
    icon: inventory,
    title: 'Inventory Management',
    value: 'inventoryManagement',
    appId: bldonApps.inventoryManagement.value,
    to: { name: 'Warehouse' },
  },
  {
    icon: form,
    title: 'Form Builder',
    value: 'formBuilder',
    appId: bldonApps.inventoryManagement.value,
    to: { name: 'form-builder-list' },
  },
]

export const adminConsoleApps = [
  {
    icon: task,
    title: 'Task Management',
    appId: bldonApps.taskManagement.value,
    value: 'taskManagement',
    to: { name: 'task-dashboard' },
  },
  {
    icon: Security,
    title: 'Security',
    appId: bldonApps.security.value,
    value: 'security',
    to: { name: 'security-dashboard' },
  },
  {
    icon: CrmSvg,
    title: 'CRM',
    appId: bldonApps.crm.value,
    value: 'crm',
    to: { name: 'dashboard-crm' },
  },
  {
    icon: task,
    title: 'Admin Console',
    value: 'adminConsole',
    appId: bldonApps.adminConsole.value,
    to: { name: 'admin-dashboard' },
  },
]

// prop type origin object
export const propTypeOrigin = {
  accounts: 'crm',
  opportunities: 'crm',
  contacts: 'crm',
  leads: 'crm',
  estimations: 'estimations',
  vendors: 'vendors',
}
// crm
export const accountType = [
  { text: 'Customer', value: 'customer' },
  { text: 'Vendor', value: 'vendor' },
  { text: 'Bidder', value: 'bidder' },
]

export const modeOfCommunication = [
  { text: 'SMS', value: 'SMS' },
  { text: 'Email', value: 'Email' },
]

export const leadSource = [
  { text: 'Phone', value: 0 },
  { text: 'Web', value: 1 },
  { text: 'Referral', value: 2 },
  { text: 'Other', value: 4 },
]

export const leadStatus = [
  { text: 'Open', value: 0 },
  { text: 'Working', value: 1 },
  { text: 'Closed', value: 2 },
  { text: 'Converted', value: 3 },
]

export const dataType = [
  'text',
  'number',
  'email',
  'custom',
  'textarea',
  'link',
  'password',
  'date',
  'time',
  'selectbox',
  'status',
  'multiselectbox',
]

export const fieldDatatype = [
  'text',
  'number',
  'email',
  'link',
  'password',
  'date',
  'time',
  'selectbox',
  'multiselectbox',
]

export const groupType = [
  { text: 'Account', value: 'account' },
  { text: 'Opportunity', value: 'opportunity' },
  { text: 'Contact', value: 'contact' },
  { text: 'Lead', value: 'lead' },
  { text: 'Vendor', value: 'vendor' },
  { text: 'Task', value: 'task' },
  { text: 'Project', value: 'project' },
]

// estimate pdf menu's
export const FileMenu = [
  { text: 'Home', value: 'home' },
  { text: 'Save As', value: 'save-as' },
  { text: 'Compare', value: 'compare' },
  // { text: 'Duplicate', value: 'duplicate' },
  { text: 'Export Pdf', value: 'export-pdf' },
  // { text: 'Delete', value: 'deletes' },
  // { text: 'Open Revision', value: 'open-revision' },
  // { text: 'Print', value: 'print' },
]
export const EditMenu = [
  { text: 'Zoom +-', value: 'zoom' },
  { text: 'Caliberation', value: 'caliberations' },
  { text: 'Search', value: 'search' },
  { text: 'Rotate', value: 'rotate' },
  { text: 'Undo', value: 'undo' },
  { text: 'Crop', value: 'crop' },
]

export const toolConstants = {
  area: 2,
  circle: 3,
  linear: 4,
  select: 5,
  rotatePage: 6,
  linearArea: 10,
  pitchArea: 11,
  highlight: 15,
  textArea: 16,
  callout: 17,
  cloud: 18,
  legend: 19,
  dimension: 20,
  comment: 21,
  image: 22,
  bookmark: 23,
  stamp: 24,
  ocr: 25,
  download: 26,
  arc: 27,
  count: 28,
  circleArea: 29,
  rectangleArea: 30,
  linearDrop: 31,
}

export const InsertMenu = [
  { text: 'Highlight', value: toolConstants.highlight },
  { text: 'Text area', value: toolConstants.textArea },
  { text: 'Callout', value: toolConstants.callout },
  { text: 'Cloud', value: toolConstants.cloud },
  { text: 'Legend', value: toolConstants.legend },
  { text: 'Dimension', value: toolConstants.dimension },
  { text: 'Comment', value: toolConstants.comment },
  { text: 'Image', value: toolConstants.image },
  { text: 'Place Bookmark', value: toolConstants.bookmark },
  { text: 'Stamp', value: toolConstants.stamp },
  { text: 'Run OCR', value: toolConstants.ocr },
  // { text: 'Download', value: toolConstants.download },
]

export const MarkupToolsMenu = [
  { name: 'Highlight', type: toolConstants.highlight, icon: mdiLeadPencil },
  { name: 'Cloud', type: toolConstants.cloud, icon: mdiCloud },
  { name: 'Callout', type: toolConstants.callout, icon: mdiChatOutline },
  { name: 'Dimention', type: toolConstants.dimension, icon: mdiArrowExpand },
  { name: 'Textarea', type: toolConstants.textArea, icon: mdiAlphabetical },
  { name: 'Legend', type: toolConstants.legend, icon: mdiTable },
]

export const ToolsMenu = [
  { name: 'linear', type: toolConstants.linear, img: 'line.svg' },
  { name: 'linearDrop', type: toolConstants.linearDrop, img: 'lineDrop.svg' },
  { name: 'count', type: toolConstants.count, img: 'checkmark.svg' },
  // { name: 'circle', type: toolConstants.circle, img: 'circle.svg' },
  // { name: 'area', type: toolConstants.area, img: 'rectangle.svg' },
  // { name: 'linear-area', type: toolConstants.linearArea, img: 'lshape.svg' },
  // { name: 'pitched-area', type: toolConstants.pitchArea, img: 'pitched-area.svg' },
  { name: 'select', type: toolConstants.select, img: 'location.svg' },
]

export const ToolSubMenu = [
  {
    name: 'circle',
    type: toolConstants.circle,
    img: 'circle.svg',
    submenu: [
      {
        type: toolConstants.circleArea,
        icon: mdiCircle,
        name: 'linear-circle',
      },
      {
        type: toolConstants.circle,
        icon: mdiCircleOutline,
        name: 'area-circle',
      },
    ],
  },
  {
    name: 'area',
    type: toolConstants.area,
    img: 'rectangle.svg',
    submenu: [
      {
        type: toolConstants.rectangleArea,
        icon: mdiRectangle,
        name: 'linear-rectangle',
      },
      {
        type: toolConstants.area,
        icon: mdiRectangleOutline,
        name: 'area-rectangle',
      },
    ],
  },
  {
    name: 'linear-area',
    type: toolConstants.linearArea,
    img: 'lshape.svg',
    submenu: [
      {
        type: toolConstants.linearArea,
        icon: mdiChartLineVariant,
        name: 'linear-area',
      },
      {
        type: toolConstants.pitchArea,
        icon: mdiPolymer,
        name: 'pitched-area',
      },
    ],
  },
]

export const TakeOff = [
  { text: 'Area', value: 'area' },
  { text: 'Count', value: 'count' },
  { text: 'Pitched Area', value: 'pitched-area' },
  { text: 'Pitched Linear', value: 'pitched-linear' },
  { text: 'Linear', value: 'linear' },
  { text: 'Linear Drop', value: 'linear-drop' },
  { text: 'Volume 2D', value: 'volume-2d' },
  { text: 'Volumen 3D', value: 'volume-3d' },
  { text: 'Surface Area', value: 'surface-area' },
  { text: 'Spiral Area', value: 'spiral-area' },
]

export const caliberation = [
  { text: 'Scale Input', value: 'scale-input' },
  { text: 'Caliberate Scale', value: 'caliberate-scale' },
]
// toaster messages
export const SUCCESS_RESPONSE = 'Request approved for performing operation'
export const ERROR_RESPONSE = 'An error occur, please try again later'
export const PERMANANT_DELETE = "You won't be able to revert this!"
export const SOFT_DELETE = 'You will be able to revert this!'

// dynamic form constant
export const modules = [
  { text: 'CRM', value: 'crm' },
  { text: 'Task Management', value: 'task' },
  { text: 'Project Management', value: 'project' },
  { text: 'Vendor Management', value: 'vendor' },
  { text: 'Material Management', value: 'material' },
  { text: 'Inventory Management', value: 'inventory' },
]

export const subModules = [
  { text: 'CRM', value: 'crm' },
  { text: 'Vendor Management', Value: 'vendor' },
]
// access options for task
export const accessOptions = [
  { value: 0, text: 'Read & Write' },
  { value: 1, text: 'Read' },
]

export const taskAccessOptions = [
  { text: 'Read', value: 1, visible: true },
  { text: 'Editor', value: 2, visible: true },
  { text: 'Full access', value: 3, visible: true },
  { text: 'Admin access', value: 4, visible: false },
]

export const operators = [
  { text: 'Equal', value: 'equal', id: 1, string: true, number: true, date: true, boolean: true },
  {
    text: 'Not Equal',
    value: 'not equal',
    id: 2,
    string: true,
    number: true,
    date: true,
    boolean: true,
  },
  { text: 'Contain', value: 'ilike', string: true, id: 3, number: true, date: true, boolean: true },
  {
    text: 'Greater than or equal',
    value: 'greater than or equal',
    id: 4,
    string: true,
    number: true,
    date: true,
    boolean: false,
  },
  {
    text: 'Less than or equal',
    value: 'less than or equal',
    id: 5,
    string: true,
    number: true,
    date: true,
    boolean: false,
  },
  {
    text: 'Greater than',
    value: 'greater than',
    id: 6,
    string: true,
    number: true,
    date: true,
    boolean: false,
  },
  {
    text: 'Less than',
    value: 'less than',
    id: 7,
    string: true,
    number: true,
    date: true,
    boolean: false,
  },
  { text: 'In', value: 'in', id: 8, string: true, number: true, date: true, boolean: false },
  {
    text: 'Not in',
    value: 'not in',
    id: 9,
    string: true,
    number: true,
    date: true,
    boolean: false,
  },
]

export const geoRadius = [
  { text: '15mi', value: 15 },
  { text: '20mi', value: 20 },
  { text: '50mi', value: 50 },
]

export const itemsPerPage = {
  'items-per-page-options': [10, 15, 50],
}
export const initialTaskFilter = [
  {
    queryType: 'AND',
    filters: [
      {
        type: 'row',
        column: {
          column: 'from_date',
          name: 'From Date',
          schema: 'Tasks',
          subQuery: false,
          type: 'date',
        },
        value: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        operation: 'greater than',
      },
      {
        type: 'row',
        column: {
          column: 'to_date',
          name: 'To Date',
          schema: 'Tasks',
          subQuery: false,
          type: 'date',
        },
        value: moment().format('YYYY-MM-DD'),
        operation: 'less than',
      },
    ],
  },
]

export const initFilter = [
  {
    queryType: 'AND',
    filters: [
      {
        type: 'row',
        column: {},
        value: null,
        operation: 'equal',
      },
    ],
  },
]

export const workflowStatus = [
  {
    text: 'Active',
    value: 0,
  },
  {
    text: 'Completed',
    value: 1,
  },
  {
    text: 'In Process',
    value: 2,
  },
  {
    text: 'Cancelled',
    value: 3,
  },
]

export const laborCostItems = [
  {
    text: 'Regular Cost',
    value: 'regular_cost',
  },
  {
    text: 'Overtime Cost',
    value: 'overtime_cost',
  },
  {
    text: 'Double Overtime Cost',
    value: 'double_overtime_cost',
  },
  {
    text: 'Nightshift Cost',
    value: 'nightshift_cost',
  },
]

export const laborVariables = [
  {
    variable: 'regular_hours',
    unit: 'H',
  },
  {
    variable: 'overtime_hours',
    unit: 'H',
  },
  {
    variable: 'weekend_hours',
    unit: 'D',
  },
  {
    variable: 'total_labor_cost',
    unit: '$',
  },
  {
    variable: 'COST',
    unit: '$',
  },
  {
    variable: 'quantity',
  },
  {
    variable: 'labor_sell_price',
    unit: '$',
  },
  {
    variable: 'calendar_days',
    unit: 'D',
  },
  {
    variable: 'working_days',
    unit: 'D',
  },
  {
    variable: 'employees',
  },
  {
    variable: 'miles',
    unit: 'km',
  },
  {
    variable: 'trips',
  },
]

export const formTypeName = {
  TEXT: 'text',
  TEXTAREA: 'textArea',
  CHOICE: 'choice',
  RATING: 'rating',
  DATE: 'date',
  SELECT: 'select',
  MULTISELECT: 'multiSelect',
  TWOCOLUMN: 'twoColumn',
  FILE: 'file',
  DBCOLUMN: 'dbColumn',
  SIGNATURE: 'signature',
}
export const dropdownFormTypeArray = [
  {
    text: 'Text',
    value: formTypeName.TEXT,
  },
  {
    text: 'Choice',
    value: formTypeName.CHOICE,
  },
  {
    text: 'Rating',
    value: formTypeName.RATING,
  },
  {
    text: 'Date',
    value: formTypeName.DATE,
  },
  {
    text: 'Select',
    value: formTypeName.SELECT,
  },
  {
    text: 'Multi Select',
    value: formTypeName.MULTISELECT,
  },
  {
    text: 'File',
    value: formTypeName.FILE,
  },
  {
    text: 'dbColumn',
    value: formTypeName.DBCOLUMN,
  },
  {
    text: 'signature',
    value: formTypeName.SIGNATURE,
  },
]
export const formBuilderTypes = {
  [formTypeName.TEXT]: {
    title: 'Question',
    answer: null,
    type: 'text',
    is_required: false,
    long_answer: false,
  },
  [formTypeName.CHOICE]: {
    title: 'Question',
    type: 'choice',
    answer: null,
    is_required: false,
    options: ['Option 1', 'Option 2'],
  },
  [formTypeName.RATING]: {
    title: 'Question',
    type: 'rating',
    answer: null,
    is_required: false,
    rating_levels: 5,
    value: 0,
  },
  [formTypeName.DATE]: {
    title: 'Question',
    type: 'date',
    is_required: false,
  },
  [formTypeName.SELECT]: {
    title: 'Question',
    type: 'select',
    answer: null,
    is_required: false,
    options: ['Option 1', 'Option 2'],
  },
  [formTypeName.MULTISELECT]: {
    title: 'Question',
    type: 'multiSelect',
    answer: null,
    is_required: false,
    options: ['Option 1', 'Option 2'],
  },
  [formTypeName.FILE]: {
    title: 'Question',
    type: 'file',
    is_required: false,
    max_files: 1,
    max_size: '2MB',
    accept_file: ['*'],
  },
  [formTypeName.DBCOLUMN]: {
    title: 'Question',
    type: 'query',
    is_required: false,
    is_db_column: true,
  },
  [formTypeName.SIGNATURE]: {
    title: 'Signature',
    type: 'signature',
    is_required: false,
    max_files: 1,
    max_size: '2MB',
    accept_file: ['*'],
  },
  [formTypeName.TWOCOLUMN]: {
    type: 'twoColumn',
    columns: [
      {
        title: 'Question',
        answer: null,
        type: 'text',
        is_required: false,
      },
      {
        title: 'Question',
        answer: null,
        type: 'text',
        is_required: false,
      },
    ],
  },
}
export const predefinedModules = {
  globalForm: {
    name: 'customFieldsForAllModules',
    description: 'Configure Custom Fields for All Modules',
    links: [{ name: 'Configure Custom Fields', route: 'setup-form-list', formLink: 'all_modules' }],
  },
  companySetup: {
    name: 'companySetup',
    description: 'We need to complete the setup before using Company Setup',
    links: [
      { name: 'Configure Company Detail', route: 'setup-company', formLink: 'companySetup' },
      { name: 'Configure Department', route: 'setup-orgnization', formLink: 'org_department' },
      { name: 'Configure Level', route: 'setup-orgnization', formLink: 'org_level' },
      { name: 'Configure Archival', route: 'setup-orgnization', formLink: 'org_archival' },
      { name: 'Configure Material', route: 'setup-orgnization', formLink: 'org_material' },
    ],
  },
  security: {
    name: 'security',
    description: 'Security settings',
    links: [
      { name: 'Configure General info', route: 'security-user-overview', formLink: 'user_info' },
      { name: 'Configure Password', route: 'security-user-overview', formLink: 'user_password' },
      {
        name: 'Configure Roles and Permission',
        route: 'security-user-overview',
        formLink: 'roles',
      },
      {
        name: 'Configure Contact info',
        route: 'security-user-overview',
        formLink: 'users_contacts',
      },
      {
        name: 'Configure Organization info',
        route: 'security-user-overview',
        formLink: 'user_organization',
      },
      { name: 'Configure User Group', route: 'security-usergroup', formLink: 'user_group' },
      { name: 'Configure Access Level', route: 'security-roles', formLink: 'access_level' },
    ],
  },
  crm: {
    name: 'crm',
    description: 'CRM settings',
    links: [
      { name: 'Configure Territory', route: 'setup-territory', formLink: 'territory' },
      { name: 'Configure Email', route: 'email-folder', formLink: 'email' },
    ],
  },
  taskManagement: {
    name: 'taskManagement',
    description: 'Tasks settings',
    links: [
      { name: 'Configure Task Management Workflow', route: 'setup-workflow', formLink: 'workflow' },
    ],
  },
  materialManagement: {
    name: 'materialManagement',
    description: 'Material settings',
    links: [
      {
        name: 'Configure Identifiers',
        route: 'setup-material-identifier',
        formLink: 'material_identifier',
      },
      {
        name: 'Configure Characteristics',
        route: 'setup-material-characteristic',
        formLink: 'material_characteristics',
      },
      {
        name: 'Configure Labor Productivity',
        route: 'setup-labor-productivity',
        formLink: 'labor_productivity',
      },
      { name: 'Configure Labor Cost', route: 'setup-labor-cost', formLink: 'labor_cost' },
      {
        name: 'Configure Labor Template',
        route: 'setup-labor-cost-template',
        formLink: 'labor_cost_template',
      },
      {
        name: 'Configure Material Unit Of Measure',
        route: 'setup-material-measurement',
        formLink: 'unit_measure',
      },
    ],
  },
  inventoryManagement: {
    name: 'inventoryManagement',
    description: 'Inventory settings',
    links: [
      { name: 'Configure Control', route: 'inventory-setup', formLink: 'control_file' },
      { name: 'Configure Price', route: 'inventory-setup', formLink: 'price_rule' },
      // { name: 'Configure Tax', route: 'inventory-setup', formLink: 'taxes' },
      { name: 'Configure Shipping Instruction', route: 'inventory-setup', formLink: 'shipping' },
      { name: 'Configure Stock Types', route: 'inventory-setup', formLink: 'stock_type' },
    ],
  },
  estimation: {
    name: 'estimation',
    description: 'Estimation settings',
    links: [
      { name: 'Configure Folders', route: 'setup-estimation-folder', formLink: 'estimation' },
    ],
  },
  projectManagement: {
    name: 'projectManagement',
    description: 'Project Management settings',
    links: [{ name: 'Configure Folders', route: 'setup-folder', formLink: 'project' }],
  },
}

export const inventoryLandedTaxes = {
  freight_percentage: {
    title: 'Freight Percentage',
    status: false,
    value: 0,
  },
  duty_percentage: {
    title: 'Duty',
    status: false,
    value: 0,
  },
  packing_percentage: {
    title: 'Packaging',
    status: false,
    value: 0,
  },
  tariff_percentage: {
    title: 'Tariff',
    status: false,
    value: 0,
  },
  cushion: {
    title: 'Cushion',
    status: false,
    value: 0,
  },
  overhead_percentage: {
    title: 'Overhead',
    status: false,
    value: 0,
  },
}
