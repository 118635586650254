export default [
  {
    path: '/pos/point-of-sale',
    name: 'point-of-sale',
    component: () => import('@/views/pos/PointOfSale.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos',
    name: 'pos',
    component: () => import('@/views/pos/PosLayout.vue'),
    meta: {
      layout: 'horizontal',
    },
    children: [
      {
        path: 'order/standard/:registerId/:orderId?',
        name: 'pos-standard-order',
        component: () => import('@/views/pos/order/StandardOrderView.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'order/:registerId/:orderId?',
        name: 'pos-order',
        component: () => import('@/views/pos/order/OrderBook.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'payment/:registerId/:orderId',
        name: 'pos-payment',
        component: () => import('@/views/pos/PosPayment.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'payment/:registerId/:orderId/success',
        name: 'pos-payment-success',
        component: () => import('@/views/pos/PosPaymentSucess.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'orders/:registerId/list',
        name: 'pos-order-list',
        component: () => import('@/views/pos/OrderList.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'customer-account/:registerId',
        name: 'customer-account-list',
        component: () => import('@/views/pos/CustomerAccount.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
    ],
  },
]
