<template>
  <div>
    <template v-if="loading">
      <ViewTaskLoader />
    </template>
    <template v-else-if="!taskInfo">
      <p class="pa-5">No Task Available</p>
    </template>
    <template v-else>
      <div class="d-flex justify-space-between mb-1 px-3 mt-2">
        <div class="flex-grow-1 mr-5">
          <v-textarea
            v-model="taskInfo.name"
            auto-grow
            hide-details
            :readonly="hasReadOnlyAccess"
            rows="1"
            row-height="8"
            class="input-textarea"
            @blur="saveText"
          ></v-textarea>
        </div>
        <div class="text-right">
          <v-icon color="dark" @click="openTaskBar = false">{{ mdiClose }}</v-icon>
        </div>
      </div>

      <div class="px-3 mb-2">
        <ParentSection :task-info="taskInfo" @fetchTask="fetchTask" />
      </div>
      <div class="d-flex flex-column scroll-section">
        <div class="flex-grow-1 overflow-auto">
          <TaskbarTopSection :task-info="taskInfo" class="px-3" />
          <CustomFieldSection :task-info="taskInfo" class="px-3" />

          <FileAndSubtemSection :task-info="taskInfo" class="px-3" />
          <TaskbarActivitySection ref="taskActivity" :task-info="taskInfo" />
        </div>
        <div class="px-3 mb-2">
          <CommentEditor
            ref="chatterPost"
            prop-type="space_items"
            :prop-id="taskInfo.uuid"
            :comment-form="commentForm"
            @commentEvent="commentEvent"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mdiClose, mdiCloudUploadOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import Toaster from '@core/utils/sweetToast'
import { useRouter } from '@core/utils'
import eventBus from '@/@core/utils/eventBus'
import ViewTaskLoader from './ui/ViewTaskLoader.vue'
import TaskbarTopSection from './ui/TaskbarTopSection.vue'
import TaskbarActivitySection from './ui/TaskbarActivitySection.vue'
import FileAndSubtemSection from './ui/FileAndSubtemSection.vue'
import CustomFieldSection from './ui/CustomFieldSection.vue'
import ParentSection from './ui/ParentSection.vue'
import CommentEditor from './ui/CommentEditor.vue'

// import { useEventBus } from '@vueuse/core'

export default {
  components: {
    TaskbarTopSection,
    ViewTaskLoader,
    TaskbarActivitySection,
    CommentEditor,
    CustomFieldSection,
    ParentSection,
    FileAndSubtemSection,
  },
  setup(props) {
    const { route } = useRouter()
    const space = ref('india')
    const taskInfo = ref(null)
    const loading = ref(false)
    const taskActivity = ref(null)
    const commentForm = ref({
      attachments: [],
      comment: null,
      loading: false,
    })
    const openTaskBar = computed({
      get() {
        return store.getters['taskManagement/getOpenTaskBar']
      },
      set(value) {
        store.commit('taskManagement/SET_OPEN_BAR', value)
      },
    })

    const taskBarId = computed(() => store.getters['taskManagement/getTaskBarId'])

    const fetchTaskDetails = payload => {
      return store.dispatch(`common/${storeActions.GET_BY_ID}`, payload).then(({ data }) => {
        if (data.success) {
          taskInfo.value = data.data
        }
      })
    }

    const fetchTask = () => {
      const url =
        route.value.name === 'share-list'
          ? '/task-management/spaces/item/share'
          : '/task-management/spaces/item'

      const payload = {
        component: url,
        objectId: taskBarId.value,
      }
      fetchTaskDetails(payload)
    }

    const loadTask = () => {
      // shared task has different api
      const url =
        route.value.name === 'share-list'
          ? '/task-management/spaces/item/share'
          : '/task-management/spaces/item'

      const payload = {
        component: url,
        objectId: taskBarId.value,
      }
      loading.value = true
      fetchTaskDetails(payload).finally(() => {
        loading.value = false
      })
    }

    watch(
      () => taskBarId.value,
      () => {
        loadTask()
      },
      { immediate: true },
    )

    const saveText = () => {
      // if route is table view
      const routeName = route.value.name
      if (routeName === 'table-task' || routeName === 'share-list') {
        store.dispatch('tableView/UPDATE_TASK', {
          name: taskInfo.value.name,
          uuid: taskInfo.value.uuid,
        })
      } else if (routeName === 'kanban-task') {
        store.dispatch('kanbanView/UPDATE_TASK', {
          name: taskInfo.value.name,
          uuid: taskInfo.value.uuid,
        })
      } else {
        store
          .dispatch('tableView/UPDATE_TASK_ONLY', {
            name: taskInfo.value.name,
            uuid: taskInfo.value.uuid,
          })
          .then(() => {
            eventBus.$emit('onTaskUpdate')
          })
      }
    }

    const commentEvent = resetEditor => {
      commentForm.value.loading = true
      const pattern =
        /"[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let mentionIds = commentForm.value.comment.match(pattern)
      if (mentionIds) {
        mentionIds = mentionIds.map(opt => {
          return opt.substring(1)
        })
      }

      const attachmentPattern =
        /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm // for detect uuids
      let attachIds = commentForm.value.comment.match(attachmentPattern)
      if (attachIds) {
        attachIds = attachIds.map(opt => {
          return opt.substring(1)
        })
      }

      const payLoad = {
        object: {
          prop_id: taskInfo.value.uuid,
          prop_type: 'space_items',
          tagged_users: mentionIds || [],
          attachments: attachIds || [],
          comment: commentForm.value.comment,
        },
        component: 'setup/comments',
      }
      store
        .dispatch(`common/${storeActions.CREATE}`, payLoad)
        .then(({ data }) => {
          commentForm.value.loading = false
          if (data.success) {
            commentForm.value = {
              loading: false,
              comment: null,
              attachments: [],
            }
            taskActivity.value.fetchComments()
            resetEditor()
          } else {
            Toaster.error(data.message, 'warning')
          }
        })
        .catch(() => {
          commentForm.value.loading = false
          Toaster.error('An eror occur, please try again later', 'warning')
        })
    }

    const hasReadOnlyAccess = computed(() => {
      if (!taskInfo.value.permission) return false
      // 0 is no access 1 is read, 2 is editor, 3-4 is full or admin access
      if ([0, 1].includes(taskInfo.value.permission)) {
        return true
      }

      return false
    })

    return {
      space,
      loading,
      saveText,
      hasReadOnlyAccess,
      taskInfo,
      fetchTask,
      mdiClose,
      taskActivity,
      commentEvent,
      mdiCloudUploadOutline,
      openTaskBar,
      commentForm,
    }
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .input-textarea.v-text-field {
    padding-top: 0px;
  }

  .input-textarea textarea {
    color: #000000;
  }

  .input-textarea.v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }
  .input-textarea.v-text-field > .v-input__control > .v-input__slot textarea {
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    min-height: 24px;
  }
}

.scroll-section {
  height: calc(100vh - 40px - 35px);
  overflow: hidden;
}
</style>
