const dashboard = [
  {
    path: '/dashboards',
    name: 'dashboards',
    component: () => import('@/views/dashboard/List.vue'),
    meta: {
      layout: 'tab',
      app: 'dashboards',
      appId: 17,
    },
  },
  {
    path: '/dashboards/:uuid',
    name: 'dashboard-view',
    component: () => import('@/views/dashboard/View.vue'),
    meta: {
      layout: 'tab',
      app: 'dashboards',
      appId: 17,
    },
  },
  {
    path: '/dashboards-shared',
    name: 'dashboards-shared',
    component: () => import('@/views/dashboard/SharedList.vue'),
    meta: {
      layout: 'tab',
      app: 'dashboards',
      appId: 17,
    },
  },
]

export default dashboard
