<template>
  <div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      class="route-loader"
      color="primary"
    ></v-progress-linear>
    <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
      <transition :name="appRouteTransition" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </component>
    <!-- toaster component -->
    <success-toster></success-toster>
    <notification-toaster></notification-toaster>
    <DocumentModal v-if="openDocumentModal" :is-document-modal="openDocumentModal" />
    <DetailModuleModal v-if="openDetailModal" :is-document-modal="openDetailModal" />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentTabNav from '@/layouts/variants/content/horizontal-nav/LayoutContentTabNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import PartnerPortalLayout from '@/layouts/variants/content/vertical-nav/PartnerPortalLayout.vue'
import LayoutMoveable from '@/layouts/variants/content/moveable-nav/LayoutMoveable.vue'
import DocumentModal from '@/components/documentation/index.vue'
import DetailModuleModal from '@/components/documentation/DetailModuleModal.vue'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'
// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import { mdiPencil } from '@mdi/js'
import LayoutBlankWithNav from '@/layouts/variants/blank/LayoutBlankWithNav.vue'
// Dynamic vh
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import SuccessToster from './components/shared-components/toaster-components/SuccessToster.vue'
import NotificationToaster from './components/shared-components/toaster-components/NotificationToaster.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutContentTabNav,
    SuccessToster,
    LayoutMoveable,
    NotificationToaster,
    LayoutBlankWithNav,
    DocumentModal,
    DetailModuleModal,
    PartnerPortalLayout,
  },
  setup() {
    const loading = computed(() => store.getters['app/getRouteLoader'])
    const openDocumentModal = computed(() => store.getters['documentation/getOpenDocumentModal'])
    const openDetailModal = computed(() => store.getters['documentation/getOpenDetailModal'])
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'blankNav') return 'layout-blank-with-nav'
      if (route.value.meta.layout === 'moveable') return 'layout-moveable'
      if (route.value.meta.layout === 'content') {
        return `layout-content-${appContentLayoutNav.value}-nav`
      }
      if (route.value.meta.layout === 'tab') return 'layout-content-tab-nav'
      if (route.value.meta.layout === 'horizontal') return 'layout-content-horizontal-nav'
      if (route.value.meta.layout === 'partner') return 'partner-portal-layout'

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      mdiPencil,
      openDetailModal,
      loading,
      openDocumentModal,
    }
  },
  created() {
    // document.addEventListener('contextmenu', event => event.preventDefault());
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .route-loader {
    z-index: 10;
    .v-progress-linear__indeterminate--active {
      > .v-progress-linear__indeterminate {
        background: #fe724c;
      }
    }
  }
}
</style>
