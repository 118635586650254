<template>
    <div class="px-12 mb-8">
      <p class="dark--text font-weight-semibold" style="font-size: 26px">How To Manage Inventory</p>

      <div class="d-flex" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Warehouse</p>
          <p class="dark--text">
            The company registers all the information about the warehouse. The name of the warehouse, its capacity, location etc. under this tab.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Inventory</p>
          <p class="dark--text">
            This tab provides all the required information about the inventory present in different warehouses. Information like material ID, its name, the available quantity etc. is stored.
          </p>
        </div>
      </div>

      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Purchase order</p>
          <p class="dark--text">
            The information on the purchase orders made is placed under this tab. This includes the name of the vendor from whom the article needs to be purchased, who has created the purchase order and what is the due date to receive the order. A unique purchase order number is created for each order.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Change order</p>
          <p class="dark--text">
            A "change order" typically refers to a documented request to alter the details of an existing purchase order or work order.
          </p>
        </div>
      </div>
      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Receipt</p>
          <p class="dark--text">
            The purchased product that is received by the company is mentioned under this tab. The purchase order number is noted down with the name of the person who has received the product.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Delivery</p>
          <p class="dark--text">
            The number of articles delivered from the warehouse to the project site is mentioned under this tab. The ‘available quantity’ from the Inventory section is deducted accordingly.
          </p>
        </div>
      </div>
      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Requisition</p>
          <p class="dark--text">
            The requirement for articles created by the company employees is registered under this tab.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Sign Out</p>
          <p class="dark--text">
            If a sudden emergency comes up, some inventory is released with a ‘requisition’ which is termed a ‘sign out’. The amount of material taken out is directly deducted from the available quantity.
          </p>
        </div>
      </div>
      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Bring Back</p>
          <p class="dark--text">
            If some materials remain unused during a certain project and they are brought back to the warehouse they are registered under the ‘bring back’ tab.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Inventory Adjustment</p>
          <p class="dark--text">
            Adjust your inventory From one location to another and can follow thorough it.
          </p>
        </div>
      </div>
      <div class="d-flex mt-3" style="gap: 30px">
        <div class="w-half">
          <p class="warning--text text-base">Physical Count</p>
          <p class="dark--text">
            The number of articles present in the warehouse is registered under this tab.
          </p>
        </div>
        <div class="w-half">
          <p class="warning--text text-base">Inventory Movement</p>
          <p class="dark--text">
            You can record the movement of your inventory from one place to another under this category
          </p>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
  <style scoped>
  .w-half {
    width: 50%;
  }
  </style>
