<template>
  <div v-resize="onResize" class="nav-header w-full h-full">
    <div
      class="d-flex align-center h-full"
      style="border-bottom: 2px solid #e3e1e1; background: #fff"
    >
      <h4 class="mx-3" style="color: #e47911 !important; width: 50px">SETUP</h4>
      <v-menu
        ref="refMenu"
        offset-y
        eagers
        :open-on-hover="true"
        attach
        class="horizontal-nav-header-group"
        content-class="list-style elevation-0"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            ref="refActivator"
            depressed
            large
            style="
              font-size: 13px !important;
              min-width: 195px;
              max-width: 195px;
              border-left: 1px solid #e3e1e1 !important;
              border-right: 1px solid #e3e1e1 !important;
            "
            tile
            color="transparent"
            dark
            v-bind="attrs"
            class="text-capitalize text-base px-5 mr-0 tab-button text--primary"
            :class="[{ 'menu-open': isMenuActive }]"
            v-on="on"
          >
            <span class="mr-3">{{ menuTabTitle.attribute }}</span>
            <v-icon size="20">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list
          ref="refContent"
          :elevation="$vuetify.theme.dark ? 9 : 8"
          class="primary white--text"
        >
          <v-list-item-group>
            <MenuLink
              v-for="child in navMenuItems.children"
              :key="child.subheader || child.title"
              :item="child"
              @consolePersistant="consolePersistant"
            ></MenuLink>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <ul v-if="isVisible" class="nav-ul d-flex flex-grow-1">
        <SetupTab
          v-for="(item, index) in tabs"
          :key="index"
          class="justify-center"
          :item="item"
          :pinned="true"
          active-module="estimation"
          @tabClick="tabClick"
          @onResize="onResize"
        />
      </ul>
      <FixedMoreMenu
        v-if="more.length"
        class="flex-grow-1"
        :more="more"
        :pinned="true"
        @addItem="addItem"
        @onResize="onResize"
      />
    </div>
  </div>
</template>

<script>
import SetupTab from '@core/layouts/components/horizontal-tab-menu/components/SetupTab.vue'
import MenuLink from '@core/layouts/components/horizontal-tab-menu/components/set-up/MenuLink.vue'
import useVuetify from '@core/utils/vuetify'
import useTabMenuHeaderGroup from '@core/layouts/components/horizontal-tab-menu/components/horizontal-tab-menu-header-group/useTabMenuHeaderGroup'
import { computed, onUnmounted, ref, watchEffect, watch, nextTick } from '@vue/composition-api'
import { storeGetters, storeActions, storeMutations } from '@/store/storeConstants'
import navMenuItems from '@/navigation/set-up'
import { mdiChevronDown } from '@mdi/js'
import store from '@/store'
import setupSchema from '@/schema/setupSchema'
import { useRouter } from '@core/utils'
import FixedMoreMenu from './components/FixedMoreMenu.vue'

export default {
  components: {
    SetupTab,
    MenuLink,
    FixedMoreMenu,
  },
  props: {
    isBorderVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root }) {
    const { GET_SETUP_PERSIST_TAB, GET_SETUP_ACTIVE_ID } = storeGetters
    const { SETUP_TAB_REQUEST } = storeActions
    const { SET_FIXED_SUBTAB } = storeMutations
    const { router } = useRouter()
    const { isActive, updateIsActive, isOpen, updateGroupOpen } =
      useTabMenuHeaderGroup(navMenuItems)

    const tabs = ref([])
    const more = ref([])
    const isVisible = ref(true)

    onUnmounted(() => {
      store.commit(`setupTab/${SET_FIXED_SUBTAB}`, false)
    })
    // Templare ref & internal value
    const refMenu = ref(null)
    const isMenuActive = ref(false)
    watchEffect(() => {
      isMenuActive.value = refMenu.value ? refMenu.value.isActive : false
    })
    const menuTabTitle = computed(() => store.getters[`setupTab/${GET_SETUP_PERSIST_TAB}`])

    const totalTabs = computed(() => {
      const tab = menuTabTitle.value.attribute
      if (tab && setupSchema.headerTabs[tab]) {
        return setupSchema.headerTabs[tab].tabs
      }

      return setupSchema.headerTabs.crm.tabs
    })
    const workSpace = computed(() => [...totalTabs.value])

    const onResize = () => {
      isVisible.value = false
      const temp = totalTabs.value.slice()
      tabs.value = temp.splice(0, (window.innerWidth - 249) / 180 - 1)
      more.value = temp.splice(0)
      nextTick(() => {
        isVisible.value = true
      })
    }

    const subtabs = computed(() => {
      const activeId = root.$store.getters[`setupTab/${GET_SETUP_ACTIVE_ID}`]
      const index = workSpace.value.findIndex(opt => {
        return opt.id === activeId
      })
      if (index !== -1) {
        const { subTab } = workSpace.value[index]
        if (subTab.length) {
          store.commit(`setupTab/${SET_FIXED_SUBTAB}`, true)
        } else {
          store.commit(`setupTab/${SET_FIXED_SUBTAB}`, false)
        }

        return subTab
      }
      store.commit(`setupTab/${SET_FIXED_SUBTAB}`, false)

      return []
    })

    const { rootThemeClasses } = useVuetify()
    const resolveNavHeaderComponent = item => {
      if (item.children) return 'horizontal-tab-menu-header-group'
      if (item.tabChild) return 'horizontal-tab-header-dropdown'

      return 'horizontal-tab-menu-header-link'
    }
    const closeTab = item => {
      totalTabs.value = totalTabs.value.filter(opt => opt.title !== item.title)
    }

    const consolePersistant = item => {
      const menuTab = {
        attribute: item.key,
      }
      store.dispatch(`setupTab/${SETUP_TAB_REQUEST}`, menuTab)
    }

    const tabClick = item => {
      router.push({ name: item.to, params: { id: item.key } }).catch(() => {})
    }

    const addItem = item => {
      // store.dispatch(`tabs/${storeActions.TAB_POSITION_CHANGE}`, item)
      router.push({ name: item.to, params: { id: item.key } }).catch(() => {})
      onResize()
    }

    watch(
      () => totalTabs.value,
      () => {
        onResize()
      },
    )

    return {
      consolePersistant,
      rootThemeClasses,
      workSpace,
      addItem,
      navMenuItems,
      menuTabTitle,
      isMenuActive,
      isOpen,
      isActive,
      resolveNavHeaderComponent,
      closeTab,
      tabs,
      more,
      isVisible,
      updateGroupOpen,
      subtabs,
      updateIsActive,
      tabClick,
      onResize,
      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
<style scoped>
.nav-ul {
  padding: 0px !important;
}

.menu-custom {
  background: #007eb9;
  color: black !important;
  border-radius: 0px;
}
.v-btn__content {
  color: black !important;
}
</style>
